import config from './settings'
import { commonGet, commonPut, commonGetBlob, commonPutBlob } from './common'

class CareAssessment {

  static getCompletenessStats (token, providerId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/careassessment/completeness/stats`

    return commonGet(token, url)
  }

  static getCompletenessCsv (token, providerId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/careassessment/completeness/csv`

    return commonGetBlob(token, url)
  }

  static get (token, providerId, userId, endpoint) {
    const pathedEndpoint = endpoint === '' ? '' : `/${endpoint}`
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment${pathedEndpoint}`

    return commonGet(token, url)
  }

  static update (token, providerId, userId, endpoint, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment/${endpoint}`
    return commonPut(token, url, data)
  }

  static getSummary (token, providerId, userId) {
    return CareAssessment.get(token, providerId, userId, '')
  }

  static getAboutMe (token, providerId, userId) {
    return CareAssessment.get(token, providerId, userId, 'aboutme')
  }

  static updateAboutMe (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'aboutme',
      data
    )
  }

  static getCommunicationMethod (token, providerId, userId) {
    return CareAssessment.get(token, providerId, userId, 'communication')
  }

  static updateCommunicationMethod (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'communication',
      data
    )
  }

  static getPersonalCare (token, providerId, userId) {
    return CareAssessment.get(token, providerId, userId, 'personalcare')
  }

  static updatePersonalCare (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'personalcare',
      data
    )
  }

  static getMovingAndHandling (token, providerId, userId) {
    return CareAssessment.get(
      token,
      providerId,
      userId,
      'movingandhandling'
    )
  }

  static updateMovingAndHandling (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'movingandhandling',
      data
    )
  }

  static downloadMovingAndHandlingFile (token, providerId, userId, filename) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment/movingandhandling/files/${filename}`
    return commonGetBlob(token, url)
  }

  static getMedicationPlan (token, providerId, userId) {
    return CareAssessment.get(
      token,
      providerId,
      userId,
      'medicationplan'
    )
  }

  static updateMedicationPlan (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'medicationplan',
      data
    )
  }

  static getNutritionAndHydration (token, providerId, userId) {
    return CareAssessment.get(
      token,
      providerId,
      userId,
      'nutritionandhydration'
    )
  }

  static updateNutritionAndHydration (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'nutritionandhydration',
      data
    )
  }

  static getDomesticSupport (token, providerId, userId) {
    return CareAssessment.get(
      token,
      providerId,
      userId,
      'domesticsupport'
    )
  }

  static updateDomesticSupport (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'domesticsupport',
      data
    )
  }

  static getFinancialSupport (token, providerId, userId) {
    return CareAssessment.get(
      token,
      providerId,
      userId,
      'financialsupport'
    )
  }

  static updateFinancialSupport (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'financialsupport',
      data
    )
  }

  static getEnvironmentAssessment (token, providerId, userId) {
    return CareAssessment.get(
      token,
      providerId,
      userId,
      'environmentassessment'
    )
  }

  static updateEnvironmentAssessment (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'environmentassessment',
      data
    )
  }

  static getCapacityAndConsent (token, providerId, userId) {
    return CareAssessment.get(
      token,
      providerId,
      userId,
      'capacityandconsent'
    )
  }

  static updateCapacityAndConsent (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'capacityandconsent',
      data
    )
  }

  static getTreatmentAndCare (token, providerId, userId) {
    return CareAssessment.get(
      token,
      providerId,
      userId,
      'treatmentandcare'
    )
  }

  static updateTreatmentAndCare (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'treatmentandcare',
      data
    )
  }

  static getBodyMap (token, providerId, userId) {
    return CareAssessment.get(
      token,
      providerId,
      userId,
      'bodymap'
    )
  }

  static updateBodyMap (token, providerId, userId, data) {
    return CareAssessment.update(
      token,
      providerId,
      userId,
      'bodymap',
      data
    )
  }

  static getSignature (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment/signature.png`
    return commonGetBlob(token, url)
  }

  static updateSignature (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment/signature.png`
    return commonPutBlob(token, url, data)
  }

  static getCompletedSignature (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment/completedSignature.png`
    return commonGetBlob(token, url)
  }

  static setCompletedSignature (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment/completedSignature.png`
    return commonPutBlob(token, url, data)
  }

  static getAuditedSignature (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment/auditedSignature.png`
    return commonGetBlob(token, url)
  }

  static setAuditedSignature (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment/auditedSignature.png`
    return commonPutBlob(token, url, data)
  }

  static getPdf (token, providerId, userId, data = {}) {
    let url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment/pdf`

    if (data.filename) {
      url += '/' + data.filename
    }
    return commonGetBlob(token, url)
  }

  static getAuditHistory (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careassessment/auditHistory`
    return commonGet(token, url)
  }
}

export default CareAssessment
