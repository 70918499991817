import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
import actions from './actions'
import { getAuthToken } from '../../../helpers/tokens'
import Api from '../../../api'

export function * getUser () {
  yield takeEvery(actions.GET_USER_REQUEST, function * () {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.AuthUser.getUser,
        authToken.token
      )
      if (response) {
        yield put(actions.getUserSuccess(response))
      } else {
        yield put(actions.getUserError(error))
      }
      yield put(actions.configureSidebar())
    }
  })
}

export function * getAuthProvider () {
  yield takeEvery(actions.GET_AUTHPROVIDER_REQUEST, function * () {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.AuthUser.getProvider,
        authToken.token
      )
      if (response) {
        yield put(actions.getAuthProviderSuccess(response))
      } else {
        yield put(actions.getAuthProviderError(error))
      }
    }
  })
}

export function * getAuthUserServicesRequest () {
  yield takeEvery(actions.GET_AUTHUSER_SERVICES_REQUEST, function * () {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.AuthUser.getServices,
        authToken.token
      )
      if (response) {
        yield put(actions.getAuthUserServicesSuccess(response))
      } else {
        yield put(actions.getAuthUserServicesError(error))
      }
    }
  })
}

export function * getAuthUserPermissionsRequest () {
  yield takeEvery(actions.GET_AUTHUSER_PERMISSIONS_REQUEST, function * () {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.AuthUser.getPermissions,
        authToken.token
      )
      if (response) {
        yield put(actions.getAuthUserPermissionsSuccess(response))
      } else {
        yield put(actions.getAuthUserPermissionsError(error))
      }
    }
  })
}


export default function * rootSaga () {
  yield all([
    fork(getUser),
    fork(getAuthProvider),
    fork(getAuthUserPermissionsRequest),
    fork(getAuthUserServicesRequest)
  ])
}
