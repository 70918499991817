import React from 'react'
import {Redirect, Route, Switch} from 'react-router'
import {ConnectedRouter} from 'connected-react-router'
import {asyncComponent} from 'react-async-component'
import {useSelector} from 'react-redux'
import config from './config'

const AuthRoute = ({component: Component, isLoggedIn, ...rest}) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn
        ? (
          <Component {...props} />
        )
        : (
          <Redirect
            to={{
              pathname: '/signin',
              state: {from: props.location}
            }}
          />
        )
    }
  />
)

export default function BaseRoutes({history}) {
  const isLoggedIn = useSelector(state => state.Auth.loggedIn)
  return (
    <ConnectedRouter history={history}>
      <div>
        <Switch>
          <Route
            exact
            path={'/signin'}
            component={asyncComponent({
              resolve: () => import('./containers/SignIn/SignIn')
            })}
          />
          <Route
            exact
            path={'/pwreset'}
            component={asyncComponent({
              resolve: () => import('./containers/SignIn/SignIn')
            })}
          />
          <Route
            exact
            path={'/auth/samlCallback'}
            component={asyncComponent({
              resolve: () => import('./containers/SignIn/SamlAuth')
            })}
          />
          <Route
            exact
            path={'/user/register/:code'}
            component={(props) => {
              const {code} = props.match.params;
              const redirectTo = config.apiUrl + '/user/register/' + code + props.location.search
              console.log("redirectingTo", redirectTo)
              window.location.href = redirectTo;
              return null;
            }}
          />
          <AuthRoute
            path={'/app'}
            component={asyncComponent({
              resolve: () => import('./containers/App/AppWrapper')
            })}
            isLoggedIn={isLoggedIn}
          />
          <Route exact path={'/'}>
            <Redirect
              to={{
                pathname: '/app'
              }}
            />
          </Route>
        </Switch>
      </div>

    </ConnectedRouter>)
}
