import actions from './actions'

const initState = {
  auth: {
    data: {},
    loading: false,
    loaded: false,
    error: false
  },
  current: {
    data: {},
    loading: false,
    loaded: false,
    error: false
  }
}

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAR_HEALTHTRACKER:
      return initState

    case actions.HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_REQUEST:
      return {
        ...state,
        auth: {
          ...state.auth,
          loading: true
        }
      }
    case actions.HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          data: action.payload,
          loading: false,
          loaded: true,
          error: false
        }
      }
    case actions.HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_ERROR:
      return {
        ...state,
        auth: {
          ...state.auth,
          loading: false,
          error: action.error
        }
      }

    case actions.HEALTHTRACKER_GET_FITBIT_TOKEN_REQUEST:
      return {
        ...state,
        auth: {
          ...state.auth,
          loading: true
        }
      }
    case actions.HEALTHTRACKER_GET_FITBIT_TOKEN_SUCCESS:
      return {
        ...state,
        auth: {
          ...state.auth,
          data: action.payload,
          loading: false,
          loaded: true,
          error: false
        }
      }
    case actions.HEALTHTRACKER_GET_FITBIT_TOKEN_ERROR:
      return {
        ...state,
        auth: {
          ...state.auth,
          loading: false,
          error: action.error
        }
      }

    case actions.HEALTHTRACKER_DELETE_FITBIT_TOKEN_REQUEST:
      return {
        ...state,
        auth: {
          loading: true
        }
      }
    case actions.HEALTHTRACKER_DELETE_FITBIT_TOKEN_SUCCESS:
      return {
        ...state,
        auth: {
          data: {},
          loading: false,
          loaded: true,
          error: { }
        }
      }
    case actions.HEALTHTRACKER_DELETE_FITBIT_TOKEN_ERROR:
      return {
        ...state,
        auth: {
          loading: false,
          error: action.error
        }
      }

    case actions.HEALTHTRACKER_GET_CURRENT_REQUEST:
      return {
        ...state,
        current: {
          ...state.current,
          loading: true
        }
      }
    case actions.HEALTHTRACKER_GET_CURRENT_SUCCESS:
      return {
        ...state,
        current: {
          ...state.current,
          data: action.payload,
          loading: false,
          loaded: true,
          error: false
        }
      }
    case actions.HEALTHTRACKER_GET_CURRENT_ERROR:
      return {
        ...state,
        current: {
          ...state.current,
          loading: false,
          error: action.error
        }
      }

    default:
      return state
  }
}
export default reducers
