import React, {useContext, useEffect} from 'react'
import styled from 'styled-components'
import {Col, Form, Row} from "antd";
import DateRangeFilter from "./DateRangeFilter";
import {useDispatch, useSelector} from "react-redux";
import actions from '../../../../redux/app/rostering/actions'
import UsersFilter from "./UsersFilter";
import TitleFilter from "./TitleFilter";
import LocationsFilter from "./LocationsFilter";
import VisitTypeFilter from "./VisitTypeFilter";
import StatusFilter from "./StatusFilter";
import _ from "lodash"
import {AuthContext} from "../../AuthContextProvider";

const Wrapper = styled.div`
  margin-bottom: 10px;

  .ant-form-item {
    margin-bottom: 6px;
  }

`

export const FIELD_DATE_RANGE_PICKER = 'dateRange'
export const FIELD_STAFFUSERS = 'staffUsers'
export const FIELD_SERVICEUSERS = 'serviceUsers'
export const FIELD_VISIT_STATUS = 'visitStatus'
export const FIELD_AVAILABILITY_STATUS = 'availabilityStatus'
export const FIELD_TITLE = 'title'
export const FIELD_TYPE = 'visitType'
export const FIELD_LOCATIONS = 'locations'

const commonFilterFields = [
  FIELD_DATE_RANGE_PICKER,
  FIELD_SERVICEUSERS,
  FIELD_STAFFUSERS,
  FIELD_LOCATIONS,
  FIELD_TITLE
]
const filterFields = {
  scheduling: [...commonFilterFields, FIELD_TYPE, FIELD_VISIT_STATUS],
  availability: [...commonFilterFields, FIELD_AVAILABILITY_STATUS],
  rounds: [...commonFilterFields]
}

const statuses = {
  scheduling: {
    filterField: FIELD_VISIT_STATUS,
    langPrefix: 'scheduling.status',
    values: [
      'projected',
      'scheduled',
      'accepted',
      'started',
      'completed',
      'missedProjected',
      'missedScheduled'
    ]
  },
  availability: {
    filterField: FIELD_AVAILABILITY_STATUS,
    langPrefix: 'availability.status',
    values: [
      // 'available',
      'leave',
      'scheduledVisit',
      'acceptedVisit',
      'missedVisit'
    ]
  }
}

export default Form.create()(function Filters({mode, form, ...otherProps}) {
  const dispatch = useDispatch()
  const {providerRoles} = useContext(AuthContext)
  const {loading} = useSelector(state => state.Rostering.unifiedRoster)

  useEffect(() => {
    actions && _.isFunction(actions.loadFiltersFromQueryString) && dispatch(actions.loadFiltersFromQueryString())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Unrecognised mode
  if (!filterFields[mode]) {
    return <Wrapper {...otherProps} />
  }

  const handleSubmit = e => {
    e.preventDefault();
    form.validateFields(() => {
    });
  };

  return <Wrapper {...otherProps}>
    <Form onSubmit={handleSubmit}>
      <Row gutter={6}>
        <Col span={8}>
          <DateRangeFilter initialDateRange={[]} form={form}
                           disabled={loading || !filterFields[mode].includes(FIELD_DATE_RANGE_PICKER)}/>
        </Col>

        <Col span={8}>
          <TitleFilter form={form} placeholderText={'Title...'}
                       disabled={loading || !filterFields[mode].includes(FIELD_TITLE)}/>
        </Col>

        <Col span={8}>
          <LocationsFilter form={form} multiple={false} includeNoLocation={true}
                           disabled={loading || !filterFields[mode].includes(FIELD_LOCATIONS)}/>
        </Col>

      </Row>
      <Row gutter={6}>
        <Col span={8}>
          <UsersFilter form={form} field={FIELD_SERVICEUSERS} placeholderText={'Select Service User'}
                       roles={['serviceuser']} disabled={loading || !filterFields[mode].includes(FIELD_SERVICEUSERS)}/>
        </Col>

        <Col span={8}>
          <UsersFilter form={form} field={FIELD_STAFFUSERS} placeholderText={'Select Carer'}
                       roles={_.values(providerRoles).filter(r => r.isCareWorker).map(r => r.role)}
                       disabled={loading || !filterFields[mode].includes(FIELD_STAFFUSERS)}/>
        </Col>

        <Col span={4}>
          <VisitTypeFilter form={form} disabled={loading || !filterFields[mode].includes(FIELD_TYPE)}/>
        </Col>

        <Col span={4}>
          {statuses[mode] &&
            <StatusFilter form={form} statuses={statuses[mode].values} langPrefix={statuses[mode].langPrefix}
                          field={statuses[mode].filterField}
                          disabled={loading || !filterFields[mode].includes(statuses[mode].filterField)}/>}
        </Col>

      </Row>
    </Form>
  </Wrapper>
})


// COMMON FILTERS (* = notYetImplemented)
// AVAILABILITY           SCHEDULING             ROUNDS
// timeFrom               timeFrom               timeFrom
// timeTo                 timeTo                 timeTo
// serviceUserIds         serviceUserIds         serviceUserIds
// staffUserIds           staffUserIds           *staffUserIds
// *locationIds           locationIds            *locationIds
// *title                 title                  *title

// SPECIFIC FILTERS
// status                 status
//                        type

