function defaultInitState (dataPropName) {
  return {
    list: [],
    loading: false,
    loaded: false,
    listLoaded: false,
    error: null,
    modalActive: false,
    [dataPropName]: {}
  }
}

export default function crudReducers (
  actions,
  dataPropName = 'data',
  initState
) {
  initState = initState || defaultInitState(dataPropName)

  return (state = initState, action) => {
    switch (action.type) {
      case actions.CLEAR:
        return initState
      case actions.GET_LIST_REQUEST:
        state = {
          ...state,
          loading: true,
          listLoaded: false
        }
        // state[dataPropName] = initState[dataPropName]
        return state
      case actions.GET_LIST_SUCCESS:
        return {
          ...state,
          loading: false,
          listLoaded: true,
          error: null,
          list: action.payload
        }
      case actions.GET_LIST_ERROR:
        return {
          ...state,
          loading: false,
          error: action.error
        }
      case actions.GET_REQUEST:
        return {
          ...state,
          loading: true
        }
      case actions.GET_SUCCESS:
        state = {
          ...state,
          loading: false,
          loaded: true,
          error: null
        }
        state[dataPropName] = action.payload
        return state
      case actions.GET_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload
        }
      case actions.CREATE_REQUEST:
      case actions.UPDATE_REQUEST:
      case actions.DELETE_REQUEST:
        return {
          ...state,
          updating: true,
          error: null
        }
      case actions.CREATE_SUCCESS:
      case actions.UPDATE_SUCCESS:
      case actions.DELETE_SUCCESS:
        state = {
          ...state,
          updating: false,
          modalActive: false,
          error: null
        }
        state[dataPropName] = action.payload
        return state
      case actions.CREATE_ERROR:
      case actions.UPDATE_ERROR:
      case actions.DELETE_ERROR:
        return {
          ...state,
          updating: false,
          // modalActive: false,
          error: action.error,
          formError: action.payload.error
        }

      case actions.UPDATE_MODEL:
        state = {
          ...state
        }
        state[dataPropName] = action.payload.data
        return state
      case actions.TOGGLE_HANDLE_MODAL:
        state = {
          ...state,
          modalActive: !state.modalActive
        }
        state[dataPropName] =
          action.payload.data == null
            ? initState[dataPropName]
            : action.payload.data
        return state
      case actions.CLEAR_FORM_ERROR:
        return {
          ...state,
          formError: false
        }
      default:
        return state
    }
  }
}
