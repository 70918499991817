import config from "./settings";
import errorService from "./error.service";

class Auth {
  static login(email, password, providerCode) {
    return fetch(`${config.url}/getToken`, {
      method: "POST",
      body: JSON.stringify({ username: email, password, providerCode }),
      headers: config.configHeaders,
    })
      .then((response) => errorService.handleErrors(response))
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  }

  static loginO365() {
    // window.location.replace(`${config.url}/getTokenSAML`)
    return (
      fetch(`${config.url}/samlRedirectURL`, {
        method: "GET",
        headers: config.configHeaders,
        credentials: "include",
        mode: "no-cors",
      })
        // .then(response => errorService.handleErrors(response))
        .then((response) => {
          console.log(response);
          return response;
        })
        .catch((error) => ({ error }))
    );
  }

  static exchangeSamlToken(sessionId) {
    // window.location.replace(`${config.url}/getTokenSAML`)
    return fetch(
      `${config.url}/samlExchange?sessionId=${sessionId}&clientId=${config.clientId}`,
      {
        method: "POST",
        headers: config.configHeaders,
        body: JSON.stringify({
          sessionId: sessionId,
          clientId: config.clientId,
        }),
        // mode: "no-cors",
        // credentials: 'include',
      }
    )
      .then((response) => errorService.handleErrors(response))
      .then((response) => {
        console.log(response.token);
        return { response };
      })
      .catch((error) => ({ error }));
  }

  static refreshToken(refreshToken) {
    return fetch(`${config.url}/refreshToken`, {
      method: "POST",
      headers: {
        ...config.configHeaders,
        Authorization: "Bearer " + refreshToken.token,
      },
    })
      .then((response) => errorService.handleErrors(response))
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  }

  static sendPasswordResetLink(providerCode, email) {
    return fetch(`${config.url}/user/password/reset/sendlink`, {
      method: "POST",
      body: JSON.stringify({ email: email, providerCode: providerCode }),
      headers: config.configHeaders,
    })
      .then((response) => errorService.handleErrors(response))
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  }

  static resetPassword(email, password, code) {
    return fetch(`${config.url}/user/password/reset`, {
      method: "POST",
      body: JSON.stringify({ email: email, password: password, code: code }),
      headers: config.configHeaders,
    })
      .then((response) => errorService.handleErrors(response))
      .then((response) => ({ response }))
      .catch((error) => ({ error }));
  }
}

export default Auth;
