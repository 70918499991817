const actions = {
  SET_SERVICE_USER_ID: 'SET_SERVICE_USER_ID',
  setServiceUserId: (providerId, userId) => {
    return {
      type: actions.SET_SERVICE_USER_ID,
      payload: {
        providerId: providerId,
        userId: userId
      }
    }
  },
  PUSH_SERVICE_USER_HEADER: 'PUSH_SERVICE_USER_HEADER',
  POP_SERVICE_USER_HEADER: 'POP_SERVICE_USER_HEADER',
  pushServiceUserHeader: mode => {
    return {
      type: actions.PUSH_SERVICE_USER_HEADER,
      payload: {
        show: mode
      }
    }
  },
  popServiceUserHeader: () => {
    return {
      type: actions.POP_SERVICE_USER_HEADER
    }
  }
}
export default actions
