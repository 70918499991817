import {all, call, fork, put, race, take, takeEvery} from 'redux-saga/effects'
import {getAuthToken} from '../../../../helpers/tokens'
import Api from '../../../../api'
import actions from './actions'
import personalDetailsActions from '../serviceusers/actions'
import actionsAttorneys from '../careassessmentAttorneys/actions'
import actionsDomesticSupportActivities from '../careassessmentDomesticSupportActivities/actions'
import actionsEquipment from '../careassessmentEquipment/actions'
import actionsSocialInclusionActivities from '../careassessmentSocialInclusionActivities/actions'
import {defineGetSaga, defineUpdateSaga} from '../../../sagasHelper'
import _ from 'lodash'

// IN THIS SECTION WE MONITOR ALL SUCCESSFUL UPDATES TO THE CAREASSESSMENT AND CAREPLAN
// ON SUCCESS WE REFRESH THE careAssessmentSummary and the careAssessmentSignature
const ignoreActionTypes = []

// Every interaction with the server is implemented as a three action process consisting of these states: REQUEST, SUCCESS, ERROR
function monitorableAction(action) {
  // Action includes the word REQUEST, but is not in the excluded actions list.
  if (
    (action.type.startsWith('CAREASSESSMENT')) &&
    (action.type.includes('UPDATE') ||
      action.type.includes('CREATE') ||
      action.type.includes('DELETE')) &&
    action.type.endsWith('REQUEST') &&
    ignoreActionTypes.every(fragment => !action.type.includes(fragment))
  ) {
    return true
  }
  return false
}

function identifyAction(action) {
  return action.type
    .split('_')
    .slice(0, -1)
    .join('_')
}

function getSuccessType(action) {
  return `${identifyAction(action)}_SUCCESS`
}

function getFailType(action) {
  return `${identifyAction(action)}_ERROR`
}

// For every monitorableAction, refresh the careAssessmentSummary and the careAssessmentSignature
function* refreshCareAssessmentSummaryAndSignature(monitoredAction) {
  const {success} = yield race({
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction))
  })

  if (success) {
    let providerId = monitoredAction.providerId
    let userId = monitoredAction.userId
    if (!providerId) {
      providerId = monitoredAction.payload.data.providerId
    }
    if (!userId) {
      userId = monitoredAction.payload.data.userId
    }

    yield all([
      put(actions.getSummaryRequest(providerId, userId)),
      put(actions.getSignatureRequest(providerId, userId))
    ])
  }
}

// END OF SECTION THAT MONITORS SUCCESSFUL UPDATES TO CAREPLAN AND CAREASSESSMENT

export function* getAll() {
  yield takeEvery(actions.CAREASSESSMENT_GETALL, function* ({
                                                              providerId,
                                                              userId
                                                            }) {
    yield put(actions.getSummaryRequest(providerId, userId))
    yield put(actions.getAboutMeRequest(providerId, userId))
    yield put(actions.getCommunicationMethodRequest(providerId, userId))
    yield put(actions.getPersonalCareRequest(providerId, userId))
    yield put(actions.getMovingAndHandlingRequest(providerId, userId))
    yield put(actions.getMedicationPlanRequest(providerId, userId))
    yield put(
      actions.getNutritionAndHydrationRequest(providerId, userId)
    )
    yield put(actions.getDomesticSupportRequest(providerId, userId))
    yield put(actions.getFinancialSupportRequest(providerId, userId))
    yield put(
      actions.getEnvironmentAssessmentRequest(providerId, userId)
    )
    yield put(actions.getCapacityAndConsentRequest(providerId, userId))
    yield put(actionsSocialInclusionActivities.getList({providerId, userId}))
    yield put(actions.getTreatmentAndCareRequest(providerId, userId))
    yield put(actions.getBodyMapRequest(providerId, userId))
    yield put(actionsAttorneys.getList({providerId, userId}))
    yield put(
      actionsDomesticSupportActivities.getList({providerId, userId})
    )
    yield put(actionsEquipment.getList({providerId, userId}))
    yield put(actions.getSignatureRequest(providerId, userId))
    yield put(actions.getCompletedSignatureRequest({providerId, userId}))
    yield put(actions.getAuditedSignatureRequest({providerId, userId}))
  })
}

export function* getSummaryRequest() {
  yield takeEvery(actions.CAREASSESSMENT_GET_SUMMARY_REQUEST, function* ({
                                                                           providerId,
                                                                           userId
                                                                         }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        Api.CareAssessment.getSummary,
        authToken.token,
        providerId,
        userId
      )
      if (response) {
        yield put(actions.getSummarySuccess(response))
      } else {
        yield put(actions.getSummaryError(error))
      }
    }
  })
}

export function* getAboutMeRequest() {
  yield takeEvery(actions.CAREASSESSMENT_GET_ABOUTME_REQUEST, function* ({
                                                                           providerId,
                                                                           userId
                                                                         }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        Api.CareAssessment.getAboutMe,
        authToken.token,
        providerId,
        userId
      )
      if (response) {
        yield put(actions.getAboutMeSuccess(response))
      } else {
        yield put(actions.getAboutMeError(error))
      }
    }
  })
}

export function* updateAboutMeRequest() {
  yield takeEvery(actions.CAREASSESSMENT_UPDATE_ABOUTME_REQUEST, function* ({
                                                                              providerId,
                                                                              userId,
                                                                              payload
                                                                            }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        Api.CareAssessment.updateAboutMe,
        authToken.token,
        providerId,
        userId,
        payload
      )
      if (response) {
        yield all([
          put(actions.updateAboutMeSuccess(response)),
          // Refresh the personal details (incase the user's religion has been changed)
          put(
            personalDetailsActions.getPersonalDetailsRequest({
              providerId: providerId,
              userId: userId
            })
          )
        ])
      } else {
        yield put(actions.updateAboutMeError(error))
      }
    }
  })
}

export function* getCommunicationMethodRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_GET_COMMUNICATIONMETHOD_REQUEST,
    function* ({providerId, userId}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.getCommunicationMethod,
          authToken.token,
          providerId,
          userId
        )
        if (response) {
          yield put(actions.getCommunicationMethodSuccess(response))
        } else {
          yield put(actions.getCommunicationMethodError(error))
        }
      }
    }
  )
}

export function* updateCommunicationMethodRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updateCommunicationMethod,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updateCommunicationMethodSuccess(response))
        } else {
          yield put(actions.updateCommunicationMethodError(error))
        }
      }
    }
  )
}

export function* getPersonalCareRequest() {
  yield takeEvery(actions.CAREASSESSMENT_GET_PERSONALCARE_REQUEST, function* ({
                                                                                providerId,
                                                                                userId
                                                                              }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        Api.CareAssessment.getPersonalCare,
        authToken.token,
        providerId,
        userId
      )
      if (response) {
        yield put(actions.getPersonalCareSuccess(response))
      } else {
        yield put(actions.getPersonalCareError(error))
      }
    }
  })
}

export function* updatePersonalCareRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_PERSONALCARE_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updatePersonalCare,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updatePersonalCareSuccess(response))
        } else {
          yield put(actions.updatePersonalCareError(error))
        }
      }
    }
  )
}

export function* getMovingAndHandlingRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_GET_MOVINGANDHANDLING_REQUEST,
    function* ({providerId, userId}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.getMovingAndHandling,
          authToken.token,
          providerId,
          userId
        )
        if (response) {
          yield put(actions.getMovingAndHandlingSuccess(response))
        } else {
          yield put(actions.getMovingAndHandlingError(error))
        }
      }
    }
  )
}

export function* updateMovingAndHandlingRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updateMovingAndHandling,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updateMovingAndHandlingSuccess(response))
        } else {
          yield put(actions.updateMovingAndHandlingError(error))
        }
      }
    }
  )
}

export function* getMedicationPlanRequest() {
  yield takeEvery(actions.CAREASSESSMENT_GET_MEDICATIONPLAN_REQUEST, function* ({
                                                                                  providerId,
                                                                                  userId
                                                                                }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        Api.CareAssessment.getMedicationPlan,
        authToken.token,
        providerId,
        userId
      )
      if (response) {
        yield put(actions.getMedicationPlanSuccess(response))
      } else {
        yield put(actions.getMedicationPlanError(error))
      }
    }
  })
}

export function* updateMedicationPlanRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_MEDICATIONPLAN_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updateMedicationPlan,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updateMedicationPlanSuccess(response))
        } else {
          yield put(actions.updateMedicationPlanError(error))
        }
      }
    }
  )
}

export function* getNutritionAndHydrationRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_REQUEST,
    function* ({providerId, userId}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.getNutritionAndHydration,
          authToken.token,
          providerId,
          userId
        )
        if (response) {
          yield put(actions.getNutritionAndHydrationSuccess(response))
        } else {
          yield put(actions.getNutritionAndHydrationError(error))
        }
      }
    }
  )
}

export function* updateNutritionAndHydrationRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updateNutritionAndHydration,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updateNutritionAndHydrationSuccess(response))
        } else {
          yield put(actions.updateNutritionAndHydrationError(error))
        }
      }
    }
  )
}

export function* getDomesticSupportRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_GET_DOMESTICSUPPORT_REQUEST,
    function* ({providerId, userId}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.getDomesticSupport,
          authToken.token,
          providerId,
          userId
        )
        if (response) {
          yield put(actions.getDomesticSupportSuccess(response))
        } else {
          yield put(actions.getDomesticSupportError(error))
        }
      }
    }
  )
}

export function* updateDomesticSupportRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updateDomesticSupport,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updateDomesticSupportSuccess(response))
        } else {
          yield put(actions.updateDomesticSupportError(error))
        }
      }
    }
  )
}

export function* getFinancialSupportRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_GET_FINANCIALSUPPORT_REQUEST,
    function* ({providerId, userId}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.getFinancialSupport,
          authToken.token,
          providerId,
          userId
        )
        if (response) {
          yield put(actions.getFinancialSupportSuccess(response))
        } else {
          yield put(actions.getFinancialSupportError(error))
        }
      }
    }
  )
}

export function* updateFinancialSupportRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updateFinancialSupport,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updateFinancialSupportSuccess(response))
        } else {
          yield put(actions.updateFinancialSupportError(error))
        }
      }
    }
  )
}

export function* getEnvironmentAssessmentRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_REQUEST,
    function* ({providerId, userId}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.getEnvironmentAssessment,
          authToken.token,
          providerId,
          userId
        )
        if (response) {
          yield put(actions.getEnvironmentAssessmentSuccess(response))
        } else {
          yield put(actions.getEnvironmentAssessmentError(error))
        }
      }
    }
  )
}

export function* updateEnvironmentAssessmentRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updateEnvironmentAssessment,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updateEnvironmentAssessmentSuccess(response))
        } else {
          yield put(actions.updateEnvironmentAssessmentError(error))
        }
      }
    }
  )
}

export function* getCapacityAndConsentRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_GET_CAPACITYANDCONSENT_REQUEST,
    function* ({providerId, userId}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.getCapacityAndConsent,
          authToken.token,
          providerId,
          userId
        )
        if (response) {
          yield put(actions.getCapacityAndConsentSuccess(response))
        } else {
          yield put(actions.getCapacityAndConsentError(error))
        }
      }
    }
  )
}

export function* updateCapacityAndConsentRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updateCapacityAndConsent,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updateCapacityAndConsentSuccess(response))
        } else {
          yield put(actions.updateCapacityAndConsentError(error))
        }
      }
    }
  )
}

export function* getTreatmentAndCareRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_GET_TREATMENTANDCARE_REQUEST,
    function* ({providerId, userId}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.getTreatmentAndCare,
          authToken.token,
          providerId,
          userId
        )
        if (response) {
          yield put(actions.getTreatmentAndCareSuccess(response))
        } else {
          yield put(actions.getTreatmentAndCareError(error))
        }
      }
    }
  )
}

export function* updateTreatmentAndCareRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_TREATMENTANDCARE_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updateTreatmentAndCare,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updateTreatmentAndCareSuccess(response))
        } else {
          yield put(actions.updateTreatmentAndCareError(error))
        }
      }
    }
  )
}

export function* getBodyMapRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_GET_BODYMAP_REQUEST,
    function* ({providerId, userId}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.getBodyMap,
          authToken.token,
          providerId,
          userId
        )
        if (response) {
          yield put(actions.getBodyMapSuccess(response))
        } else {
          yield put(actions.getBodyMapError(error))
        }
      }
    }
  )
}

export function* updateBodyMapRequest() {
  yield takeEvery(
    actions.CAREASSESSMENT_UPDATE_BODYMAP_REQUEST,
    function* ({providerId, userId, payload}) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const {response, error} = yield call(
          Api.CareAssessment.updateBodyMap,
          authToken.token,
          providerId,
          userId,
          payload
        )
        if (response) {
          yield put(actions.updateBodyMapSuccess(response))
        } else {
          yield put(actions.updateBodyMapError(error))
        }
      }
    }
  )
}

export function* getSignatureRequest() {
  yield takeEvery(actions.CAREASSESSMENT_GET_SIGNATURE_REQUEST, function* ({
                                                                             providerId,
                                                                             userId
                                                                           }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        Api.CareAssessment.getSignature,
        authToken.token,
        providerId,
        userId
      )
      if (response) {
        yield put(actions.getSignatureSuccess(response, userId))
      } else {
        if (error instanceof TypeError) {
          yield put(actions.getSignatureSuccess(response, userId))
        } else {
          yield put(actions.getSignatureError(error))
        }
      }
    }
  })
}

export function* updateSignatureRequest() {
  yield takeEvery(actions.CAREASSESSMENT_UPDATE_SIGNATURE_REQUEST, function* ({
                                                                                providerId,
                                                                                userId,
                                                                                payload
                                                                              }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        Api.CareAssessment.updateSignature,
        authToken.token,
        providerId,
        userId,
        payload
      )
      if (!error) {
        yield put(actions.updateSignatureSuccess(response))
      } else {
        yield put(actions.updateSignatureError(error))
      }
    }
  })
}

export function* getCareAssessmentPdfRequest() {
  yield takeEvery(actions.CAREASSESSMENT_GET_PDF_REQUEST, function* ({
                                                                       providerId,
                                                                       userId,
                                                                       data,
                                                                       onSuccessCallback
                                                                     }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        Api.CareAssessment.getPdf,
        authToken.token,
        providerId,
        userId,
        data
      )
      if (response) {
        yield put(actions.getPdfSuccess(response, userId))
        _.isFunction(onSuccessCallback) && onSuccessCallback(response, data)
      } else {
        if (error instanceof TypeError) {
          yield put(actions.getPdfSuccess(response, userId))
        } else {
          yield put(actions.getPdfError(error))
        }
      }
    }
  })
}

export function* getCareAssessmentAuditHistoryRequest() {
  yield defineGetSaga(actions.GET_CAREASSESSMENT_AUDIT_HISTORY_REQUEST, Api.CareAssessment.getAuditHistory, actions.getAuditHistorySuccess, actions.getAuditHistoryError)
}

export function* getCareAssessmentCompletedSignatureRequest() {
  yield defineGetSaga(actions.CAREASSESSMENT_GET_COMPLETEDSIGNATURE_REQUEST, Api.CareAssessment.getCompletedSignature, actions.getCompletedSignatureSuccess, actions.getCompletedSignatureError)
}

export function* setCareAssessmentCompletedSignatureRequest() {
  yield defineUpdateSaga(actions.CAREASSESSMENT_SET_COMPLETEDSIGNATURE_REQUEST, Api.CareAssessment.setCompletedSignature, actions.setCompletedSignatureSuccess, actions.setCompletedSignatureError)
}

export function* getCareAssessmentAuditedSignatureRequest() {
  yield defineGetSaga(actions.CAREASSESSMENT_GET_AUDITEDSIGNATURE_REQUEST, Api.CareAssessment.getAuditedSignature, actions.getAuditedSignatureSuccess, actions.getAuditedSignatureError)
}

export function* setCareAssessmentAuditedSignatureRequest() {
  yield defineUpdateSaga(actions.CAREASSESSMENT_SET_AUDITEDSIGNATURE_REQUEST, Api.CareAssessment.setAuditedSignature, actions.setAuditedSignatureSuccess, actions.setAuditedSignatureError)
}

export default function* rootSaga() {
  yield all([
    fork(getAll),
    fork(getSummaryRequest),
    fork(getAboutMeRequest),
    fork(updateAboutMeRequest),
    fork(getCommunicationMethodRequest),
    fork(updateCommunicationMethodRequest),
    fork(getPersonalCareRequest),
    fork(updatePersonalCareRequest),
    fork(getMovingAndHandlingRequest),
    fork(updateMovingAndHandlingRequest),
    fork(getMedicationPlanRequest),
    fork(updateMedicationPlanRequest),
    fork(getNutritionAndHydrationRequest),
    fork(updateNutritionAndHydrationRequest),
    fork(getDomesticSupportRequest),
    fork(updateDomesticSupportRequest),
    fork(getFinancialSupportRequest),
    fork(updateFinancialSupportRequest),
    fork(getEnvironmentAssessmentRequest),
    fork(updateEnvironmentAssessmentRequest),
    fork(getCapacityAndConsentRequest),
    fork(updateCapacityAndConsentRequest),
    fork(getTreatmentAndCareRequest),
    fork(updateTreatmentAndCareRequest),
    fork(getBodyMapRequest),
    fork(updateBodyMapRequest),
    fork(getSignatureRequest),
    fork(updateSignatureRequest),
    fork(getCareAssessmentPdfRequest),
    takeEvery(monitorableAction, refreshCareAssessmentSummaryAndSignature),
    fork(getCareAssessmentAuditHistoryRequest),
    fork(getCareAssessmentCompletedSignatureRequest),
    fork(setCareAssessmentCompletedSignatureRequest),
    fork(getCareAssessmentAuditedSignatureRequest),
    fork(setCareAssessmentAuditedSignatureRequest)
  ])
}
