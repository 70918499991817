import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../../reducersHelper'

const initState = {
  userId: null,
  summary: {},
  summaryLoaded: false,
  aboutMe: {},
  aboutMeLoaded: false,
  communicationMethod: {},
  communicationMethodLoaded: false,
  personalCare: {},
  personalCareLoaded: false,
  movingAndHandling: {},
  movingAndHandlingLoaded: false,
  medicationPlan: {},
  medicationPlanLoaded: false,
  nutritionAndHydration: {},
  nutritionAndHydrationLoaded: false,
  domesticSupport: {},
  domesticSupportLoaded: false,
  financialSupport: {},
  financialSupportLoaded: false,
  environmentAssessment: {},
  environmentAssessmentLoaded: false,
  capacityAndConsent: {},
  capacityAndConsentLoaded: false,
  treatmentAndCare: {},
  treatmentAndCareLoaded: false,
  bodyMap: {},
  bodyMapLoaded: false,
  signature: {},
  signatureLoading: false,
  signatureLoaded: false,
  signatureUpdating: false,
  signatureError: false,
  pdf: {},
  pdfLoading: false,
  pdfLoaded: false,
  pdfError: false,
  updating: false,
  loading: false,
  error: false,
  auditHistory: initialDataState,
  completedSignature: initialDataState,
  auditedSignature: initialDataState
}

const reducers = (state = initState, action) => {
  let newAboutMe,
    newCommunicationMethod,
    newPersonalCare,
    newMovingAndHandling,
    newMedicationPlan,
    newNutritionAndHydration,
    newDomesticSupport,
    newFinancialSupport,
    newEnvironmentAssessment,
    newCapacityAndConsent,
    newTreatmentAndCare,
    newBodyMap

  switch (action.type) {
    case actions.CAREASSESSMENT_GET_SUMMARY_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        summaryLoaded: true,
        error: false,
        summary: action.payload
      }
    case actions.CAREASSESSMENT_GET_SUMMARY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_GET_ABOUTME_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_ABOUTME_SUCCESS:
      return {
        ...state,
        loading: false,
        aboutMeLoaded: true,
        error: false,
        aboutMe: action.payload
      }
    case actions.CAREASSESSMENT_GET_ABOUTME_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_ABOUTME_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_ABOUTME_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        aboutMe: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_ABOUTME_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_ABOUTME:
      newAboutMe = {
        ...state.aboutMe,
        ...action.payload
      }
      return {
        ...state,
        aboutMe: newAboutMe
      }

    case actions.CAREASSESSMENT_GET_COMMUNICATIONMETHOD_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_COMMUNICATIONMETHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        communicationMethodLoaded: true,
        error: false,
        communicationMethod: action.payload
      }
    case actions.CAREASSESSMENT_GET_COMMUNICATIONMETHOD_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        communicationMethod: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_COMMUNICATIONMETHOD:
      newCommunicationMethod = {
        ...state.communicationMethod,
        ...action.payload
      }
      return {
        ...state,
        communicationMethod: newCommunicationMethod
      }

    case actions.CAREASSESSMENT_GET_PERSONALCARE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_PERSONALCARE_SUCCESS:
      return {
        ...state,
        loading: false,
        personalCareLoaded: true,
        error: false,
        personalCare: action.payload
      }
    case actions.CAREASSESSMENT_GET_PERSONALCARE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_PERSONALCARE_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_PERSONALCARE_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        personalCare: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_PERSONALCARE_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_PERSONALCARE:
       newPersonalCare = {
        ...state.personalCare,
        ...action.payload
      }
      return {
        ...state,
        personalCare: newPersonalCare
      }

    case actions.CAREASSESSMENT_GET_MOVINGANDHANDLING_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_MOVINGANDHANDLING_SUCCESS:
      return {
        ...state,
        loading: false,
        movingAndHandlingLoaded: true,
        error: false,
        movingAndHandling: action.payload
      }
    case actions.CAREASSESSMENT_GET_MOVINGANDHANDLING_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        movingAndHandling: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_MOVINGANDHANDLING:
       newMovingAndHandling = {
        ...state.movingAndHandling,
        ...action.payload
      }
      return {
        ...state,
        movingAndHandling: newMovingAndHandling
      }

    case actions.CAREASSESSMENT_GET_MEDICATIONPLAN_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_MEDICATIONPLAN_SUCCESS:
      return {
        ...state,
        loading: false,
        medicationPlanLoaded: true,
        error: false,
        medicationPlan: action.payload
      }
    case actions.CAREASSESSMENT_GET_MEDICATIONPLAN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MEDICATIONPLAN_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_MEDICATIONPLAN_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        medicationPlan: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_MEDICATIONPLAN_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_MEDICATIONPLAN:
      newMedicationPlan = {
        ...state.medicationPlan,
        ...action.payload
      }
      return {
        ...state,
        medicationPlan: newMedicationPlan
      }

    case actions.CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        nutritionAndHydrationLoaded: true,
        error: false,
        nutritionAndHydration: action.payload
      }
    case actions.CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        nutritionAndHydration: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_NUTRITIONANDHYDRATION:
      newNutritionAndHydration = {
        ...state.nutritionAndHydration,
        ...action.payload
      }
      return {
        ...state,
        nutritionAndHydration: newNutritionAndHydration
      }

    case actions.CAREASSESSMENT_GET_DOMESTICSUPPORT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_DOMESTICSUPPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        domesticSupportLoaded: true,
        error: false,
        domesticSupport: action.payload
      }
    case actions.CAREASSESSMENT_GET_DOMESTICSUPPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        domesticSupport: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_DOMESTICSUPPORT:
      newDomesticSupport = {
        ...state.domesticSupport,
        ...action.payload
      }
      return {
        ...state,
        domesticSupport: newDomesticSupport
      }

    case actions.CAREASSESSMENT_GET_FINANCIALSUPPORT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_FINANCIALSUPPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        financialSupportLoaded: true,
        error: false,
        financialSupport: action.payload
      }
    case actions.CAREASSESSMENT_GET_FINANCIALSUPPORT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        financialSupport: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_FINANCIALSUPPORT:
      newFinancialSupport = {
        ...state.financialSupport,
        ...action.payload
      }
      return {
        ...state,
        financialSupport: newFinancialSupport
      }

    case actions.CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        environmentAssessmentLoaded: true,
        error: false,
        environmentAssessment: action.payload
      }
    case actions.CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        environmentAssessment: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_ENVIRONMENTASSESSMENT:
      newEnvironmentAssessment = {
        ...state.environmentAssessment,
        ...action.payload
      }
      return {
        ...state,
        environmentAssessment: newEnvironmentAssessment
      }

    case actions.CAREASSESSMENT_GET_CAPACITYANDCONSENT_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_CAPACITYANDCONSENT_SUCCESS:
      return {
        ...state,
        loading: false,
        capacityAndConsentLoaded: true,
        error: false,
        capacityAndConsent: action.payload
      }
    case actions.CAREASSESSMENT_GET_CAPACITYANDCONSENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        capacityAndConsent: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_CAPACITYANDCONSENT:
      newCapacityAndConsent = {
        ...state.capacityAndConsent,
        ...action.payload
      }
      return {
        ...state,
        capacityAndConsent: newCapacityAndConsent
      }

    case actions.CAREASSESSMENT_GET_TREATMENTANDCARE_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_TREATMENTANDCARE_SUCCESS:
      return {
        ...state,
        loading: false,
        treatmentAndCareLoaded: true,
        error: false,
        treatmentAndCare: action.payload
      }
    case actions.CAREASSESSMENT_GET_TREATMENTANDCARE_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_TREATMENTANDCARE_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_TREATMENTANDCARE_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        treatmentAndCare: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_TREATMENTANDCARE_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_TREATMENTANDCARE:
      newTreatmentAndCare = {
        ...state.treatmentAndCare,
        ...action.payload
      }
      return {
        ...state,
        treatmentAndCare: newTreatmentAndCare
      }

    case actions.CAREASSESSMENT_GET_BODYMAP_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.CAREASSESSMENT_GET_BODYMAP_SUCCESS:
      return {
        ...state,
        loading: false,
        bodyMapLoaded: true,
        error: false,
        bodyMap: action.payload
      }
    case actions.CAREASSESSMENT_GET_BODYMAP_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_BODYMAP_REQUEST:
      return {
        ...state,
        updating: true
      }
    case actions.CAREASSESSMENT_UPDATE_BODYMAP_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false,
        bodyMap: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_BODYMAP_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_MODEL_BODYMAP:
      newBodyMap = {
        ...state.bodyMap,
        ...action.payload
      }
      return {
        ...state,
        bodyMap: newBodyMap
      }

    case actions.CAREASSESSMENT_GET_SIGNATURE_REQUEST:
      return {
        ...state,
        signatureLoading: true,
        signature: null
      }
    case actions.CAREASSESSMENT_GET_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatureLoading: false,
        signatureLoaded: true,
        error: false,
        signature: action.payload
      }
    case actions.CAREASSESSMENT_GET_SIGNATURE_ERROR:
      return {
        ...state,
        signatureLoading: false,
        signatureError: action.error,
        error: action.error
      }
    case actions.CAREASSESSMENT_UPDATE_SIGNATURE_REQUEST:
      return {
        ...state,
        signatureUpdating: true
      }
    case actions.CAREASSESSMENT_UPDATE_SIGNATURE_SUCCESS:
      return {
        ...state,
        signatureUpdating: false,
        error: false,
        signature: action.payload
      }
    case actions.CAREASSESSMENT_UPDATE_SIGNATURE_ERROR:
      return {
        ...state,
        signatureUpdating: false,
        signatureError: action.error,
        error: action.error
      }

    case actions.CAREASSESSMENT_GET_PDF_REQUEST:
      return {
        ...state,
        pdfLoading: true,
        pdf: null
      }
    case actions.CAREASSESSMENT_GET_PDF_SUCCESS:
      return {
        ...state,
        pdfLoading: false,
        pdfLoaded: true,
        error: false,
        pdf: action.payload
      }
    case actions.CAREASSESSMENT_GET_PDF_ERROR:
      return {
        ...state,
        pdfLoading: false,
        pdfError: action.error,
        error: action.error
      }

    case actions.GET_CAREASSESSMENT_AUDIT_HISTORY_REQUEST:
      return reduceDataRequest(state, 'auditHistory')
    case actions.GET_CAREASSESSMENT_AUDIT_HISTORY_SUCCESS:
      return reduceDataSuccess(state, 'auditHistory', action.payload)
    case actions.GET_CAREASSESSMENT_AUDIT_HISTORY_ERROR:
      return reduceDataError(state, 'auditHistory', action.error)

    case actions.CAREASSESSMENT_GET_COMPLETEDSIGNATURE_REQUEST:
      return reduceDataRequest(state, 'completedSignature')
    case actions.CAREASSESSMENT_GET_COMPLETEDSIGNATURE_SUCCESS:
      return reduceDataSuccess(state, 'completedSignature', action.payload)
    case actions.CAREASSESSMENT_GET_COMPLETEDSIGNATURE_ERROR:
      return reduceDataError(state, 'completedSignature', action.error)

    case actions.CAREASSESSMENT_SET_COMPLETEDSIGNATURE_REQUEST:
      return reduceDataRequest(state, 'completedSignature')
    case actions.CAREASSESSMENT_SET_COMPLETEDSIGNATURE_SUCCESS:
      return reduceDataSuccess(state, 'completedSignature', action.payload)
    case actions.CAREASSESSMENT_SET_COMPLETEDSIGNATURE_ERROR:
      return reduceDataError(state, 'completedSignature', action.error)

    case actions.CAREASSESSMENT_GET_AUDITEDSIGNATURE_REQUEST:
      return reduceDataRequest(state, 'auditedSignature')
    case actions.CAREASSESSMENT_GET_AUDITEDSIGNATURE_SUCCESS:
      return reduceDataSuccess(state, 'auditedSignature', action.payload)
    case actions.CAREASSESSMENT_GET_AUDITEDSIGNATURE_ERROR:
      return reduceDataError(state, 'auditedSignature', action.error)

    case actions.CAREASSESSMENT_SET_AUDITEDSIGNATURE_REQUEST:
      return reduceDataRequest(state, 'auditedSignature')
    case actions.CAREASSESSMENT_SET_AUDITEDSIGNATURE_SUCCESS:
      return reduceDataSuccess(state, 'auditedSignature', action.payload)
    case actions.CAREASSESSMENT_SET_AUDITEDSIGNATURE_ERROR:
      return reduceDataError(state, 'auditedSignature', action.error)

    default:
      return state
  }
}

export default reducers
