import config from "./settings";
import { commonGet, commonPatch, commonPut } from "./common";

export default class CareWorkerWebApp {
  static getMySchedule(token, providerId) {
    const url = `${config.url}/auth/providers/${providerId}/visits/myschedule`;
    return commonGet(token, url);
  }

  static getMyAcceptedVisit(token, providerId, data) {
    const { serviceUserId, carePlanVisitId, visitDate } = data;
    const url = `${config.url}/auth/providers/${providerId}/visits/visitid/${carePlanVisitId}/user/${serviceUserId}/date/${visitDate}`;
    return commonGet(token, url);
  }

  static patchVisit(token, providerId, data) {
    const { serviceUserId, carePlanVisitId, visitDate } = data;
    const url = `${config.url}/auth/providers/${providerId}/visits/visitid/${carePlanVisitId}/user/${serviceUserId}/date/${visitDate}`;
    return commonPatch(token, url, { action: data.patchAction });
  }

  static updateAcceptedVisit(token, providerId, data) {
    const { serviceUserId, carePlanVisitId, visitDate, visit } = data;
    const url = `${config.url}/auth/providers/${providerId}/visits/visitid/${carePlanVisitId}/user/${serviceUserId}/date/${visitDate}`;
    return commonPut(token, url, visit);
  }
}
