import actions from './actions'
import {initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess} from '../../reducersHelper'

const initState = {
  entries: initialDataState,
  recentAllServiceUsers: initialDataState
}
const reducers = (state = initState, action) => {
  let entries
  switch (action.type) {
    case actions.CLEAR:
      return initState
    case actions.GET_ENTRIES_REQUEST:
      return reduceDataRequest(state, 'entries')
    case actions.GET_ENTRIES_SUCCESS:
      entries = {
        data: action.payload,
        loading: false,
        loaded: true,
        error: null
      }
      return {...state, entries}
    case actions.GET_ENTRIES_ERROR:
      return reduceDataError(state, 'entries', action.error)

    case actions.GET_ENTRIES_RECENT_UPDATES_ALL_SERVICE_USERS_REQUEST:
      return reduceDataRequest(state, 'recentAllServiceUsers')
    case actions.GET_ENTRIES_RECENT_UPDATES_ALL_SERVICE_USERS_SUCCESS:
      return reduceDataSuccess(state, 'recentAllServiceUsers', action.payload)
    case actions.GET_ENTRIES_RECENT_UPDATES_ALL_SERVICE_USERS_ERROR:
      return reduceDataError(state, 'recentAllServiceUsers', action.error)

    default:
      return state
  }
}

export default reducers
