import actions from './actions'
import crudReducers from '../../crud/reducers'
import { apiPath } from './sagas'

const dataPropName = 'doc'

const initState = {
  list: [],
  loading: false,
  error: false,
  modalActive: false,
  doc: '',
  uploadUrl: apiPath
}

const reducers = crudReducers(actions, dataPropName, initState)

export default reducers
