// import { parseError } from '../../../../helpers/errors'
import {
  defineGetActionsPrSu, defineUpdateActionsPrSu
} from '../../../actionsHelper'

const actions = {
  UPDATE_MODEL_PERSONALDETAILS: 'UPDATE_MODEL_PERSONALDETAILS',
  UPDATE_MODEL_APPEARANCE: 'UPDATE_MODEL_APPEARANCE',
  UPDATE_MODEL_ADDRESS: 'UPDATE_MODEL_ADDRESS',
  UPDATE_MODEL_LEGALCHOICES: 'UPDATE_MODEL_LEGALCHOICES',

  CLEAR_SERVICE_USER_FORM_ERROR: 'CLEAR_SERVICE_USER_FORM_ERROR',

  clearFormError: () => {
    return {
      type: actions.CLEAR_SERVICE_USER_FORM_ERROR
    }
  },

  updateModelLegalChoices: values => {
    return {
      type: actions.UPDATE_MODEL_LEGALCHOICES,
      payload: values
    }
  },

  updateModelAccessDetails: values => {
    return {
      type: actions.UPDATE_MODEL_ACCESS_DETAILS,
      payload: values
    }
  },

  updateModelAdvanceCarePlan: values => {
    return {
      type: actions.UPDATE_MODEL_ADVANCE_CARE_PLAN,
      payload: values
    }
  },

  updateModelAddress: values => {
    return {
      type: actions.UPDATE_MODEL_ADDRESS,
      payload: values
    }
  }

}

defineGetActionsPrSu(actions, 'GET_SERVICE_USER', 'getPersonalDetails')
defineUpdateActionsPrSu(actions, 'UPDATE_SERVICE_USER', 'updatePersonalDetails')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_PRIVATE', 'getPrivateDetails')
defineUpdateActionsPrSu(actions, 'UPDATE_SERVICE_USER_PRIVATE', 'updatePrivateDetails')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_ADDRESS', 'getAddress')
defineUpdateActionsPrSu(actions, 'UPDATE_SERVICE_USER_ADDRESS', 'updateAddress')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_ADDRESS_GEO_FENCING', 'getAddressGeoFencing')
defineUpdateActionsPrSu(actions, 'UPDATE_SERVICE_USER_ADDRESS_GEO_FENCING', 'updateAddressGeoFencing')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_APPEARANCE', 'getAppearance')
defineUpdateActionsPrSu(actions, 'UPDATE_SERVICE_USER_APPEARANCE', 'updateAppearance')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_LEGALCHOICES', 'getLegalChoices')
defineUpdateActionsPrSu(actions, 'UPDATE_SERVICE_USER_LEGALCHOICES', 'updateLegalChoices')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_ACCESS_DETAILS', 'getAccessDetails')
defineUpdateActionsPrSu(actions, 'UPDATE_SERVICE_USER_ACCESS_DETAILS', 'updateAccessDetails')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_ADVANCE_CARE_PLAN', 'getAdvanceCarePlan')
defineUpdateActionsPrSu(actions, 'UPDATE_SERVICE_USER_ADVANCE_CARE_PLAN', 'updateAdvanceCarePlan')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_SERVICE_INFORMATION', 'getServiceInformation')
defineUpdateActionsPrSu(actions, 'UPDATE_SERVICE_USER_SERVICE_INFORMATION', 'updateServiceInformation')
defineGetActionsPrSu(actions, 'DOWNLOAD_SERVICE_USER_SERVICE_INFORMATION_PDF', 'downloadServiceUserServiceInformationPdf')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_BMI', 'getBmi')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_CAREWORKERS_IN_LOCATION', 'getCareWorkersForSingleUser')
defineGetActionsPrSu(actions, 'GET_SERVICE_USER_SERVICELOCATIONS', 'getServiceUserServiceLocations')

defineGetActionsPrSu(actions, 'GET_SERVICE_USER_DOWNLOAD_PDF', 'downloadPdf')

export default actions
