import { defineGetActionsPrSu } from '../../../actionsHelper'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../../reducersHelper'
import { defineGetSaga } from '../../../sagasHelper'
import Api from '../../../../api'
import { all, fork } from 'redux-saga/effects'

const actions = {}
defineGetActionsPrSu(
  actions,
  'GET_HR_HOLIDAYS_UPCOMING',
  'getHrHolidaysUpcoming'
)

const reducer = (state = initialDataState, action) => {
  switch (action.type) {
    case actions.GET_HR_HOLIDAYS_UPCOMING_REQUEST:
      return reduceDataRequest(state)
    case actions.GET_HR_HOLIDAYS_UPCOMING_SUCCESS:
      return reduceDataSuccess(state, null, action.payload)
    case actions.GET_HR_HOLIDAYS_UPCOMING_ERROR:
      return reduceDataError(state, null, action.error)
    default:
      return state
  }
}

function * getUpcomingHolidaysRequest () {
  yield defineGetSaga(actions.GET_HR_HOLIDAYS_UPCOMING_REQUEST, Api.HR.getHolidaysUpcoming, actions.getHrHolidaysUpcomingSuccess, actions.getHrHolidaysUpcomingError)
}

function * saga () {
  yield all([
    fork(getUpcomingHolidaysRequest)
  ])
}

export { reducer, actions, saga }
