import config from './settings'
import { commonGetBlob } from './common'

class CommunicationMessages {
  static downloadCsv (
    token,
    providerId,
    userId,
    data
  ) {
    const { messageType, dateFrom, dateTo } = data
    const qs = `communicationMessageType=${messageType || ''}` +
      `&dateFrom=${dateFrom || ''}` +
      `&dateTo=${dateTo || ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/communicationmessages/csv?${qs}`
    return commonGetBlob(token, url)
  }
}

export default CommunicationMessages
