import crudActions from '../crud/actions'
import { parseError } from '../../../helpers/errors'
import {defineGetActionsPrSu} from "../../actionsHelper";

const actions = {
  ...crudActions('PROVIDER_'),

  GET_PROVIDER_SETTINGS_REQUEST: 'GET_PROVIDER_SETTINGS_REQUEST',
  GET_PROVIDER_SETTINGS_SUCCESS: 'GET_PROVIDER_SETTINGS_SUCCESS',
  GET_PROVIDER_SETTINGS_ERROR: 'GET_PROVIDER_SETTINGS_ERROR',

  getProviderSettingsRequest: (providerId) => {
    return {
      type: actions.GET_PROVIDER_SETTINGS_REQUEST,
      providerId: providerId
    }
  },
  getProviderSettingsSuccess: result => {
    return {
      type: actions.GET_PROVIDER_SETTINGS_SUCCESS,
      payload: result
    }
  },
  getProviderSettingsError: error => {
    return {
      type: actions.GET_PROVIDER_SETTINGS_ERROR,
      error: error
    }
  },

  UPDATE_PROVIDER_SETTINGS_REQUEST: 'UPDATE_PROVIDER_SETTINGS_REQUEST',
  UPDATE_PROVIDER_SETTINGS_SUCCESS: 'UPDATE_PROVIDER_SETTINGS_SUCCESS',
  UPDATE_PROVIDER_SETTINGS_ERROR: 'UPDATE_PROVIDER_SETTINGS_ERROR',

  updateProviderSettingsRequest: (providerId, data) => {
    return {
      type: actions.UPDATE_PROVIDER_SETTINGS_REQUEST,
      providerId: providerId,
      payload: data
    }
  },
  updateProviderSettingsSuccess: result => {
    return {
      type: actions.UPDATE_PROVIDER_SETTINGS_SUCCESS,
      payload: result
    }
  },
  updateProviderSettingsError: error => {
    return {
      type: actions.UPDATE_PROVIDER_SETTINGS_ERROR,
      error: error,
      payload: { error: parseError(error) }
    }
  },

  CLEAR_PROVIDER_LOGO: 'CLEAR_PROVIDER_LOGO',
  GET_PROVIDER_LOGO_REQUEST: 'GET_PROVIDER_LOGO_REQUEST',
  GET_PROVIDER_LOGO_SUCCESS: 'GET_PROVIDER_LOGO_SUCCESS',
  GET_PROVIDER_LOGO_ERROR: 'GET_PROVIDER_LOGO_ERROR',
  UPDATE_PROVIDER_LOGO_REQUEST: 'UPDATE_PROVIDER_LOGO_REQUEST',
  UPDATE_PROVIDER_LOGO_SUCCESS: 'UPDATE_PROVIDER_LOGO_SUCCESS',
  UPDATE_PROVIDER_LOGO_ERROR: 'UPDATE_PROVIDER_LOGO_ERROR',
  DELETE_PROVIDER_LOGO_REQUEST: 'DELETE_PROVIDER_LOGO_REQUEST',
  DELETE_PROVIDER_LOGO_SUCCESS: 'DELETE_PROVIDER_LOGO_SUCCESS',
  DELETE_PROVIDER_LOGO_ERROR: 'DELETE_PROVIDER_LOGO_ERROR',

  clearProviderLogo: () => {
    return {
      type: actions.CLEAR_PROVIDER_LOGO
    }
  },
  getProviderLogoRequest: (providerId) => {
    return {
      type: actions.GET_PROVIDER_LOGO_REQUEST,
      providerId
    }
  },
  getProviderLogoSuccess: (result) => {
    return {
      type: actions.GET_PROVIDER_LOGO_SUCCESS,
      payload: result
    }
  },
  getProviderLogoError: error => {
    return {
      type: actions.GET_PROVIDER_LOGO_ERROR,
      error: error
    }
  },
  updateProviderLogoRequest: (providerId, ProviderLogo) => {
    return {
      type: actions.UPDATE_PROVIDER_LOGO_REQUEST,
      providerId,
      payload: ProviderLogo
    }
  },
  updateProviderLogoSuccess: result => {
    return {
      type: actions.UPDATE_PROVIDER_LOGO_SUCCESS,
      payload: result
    }
  },
  updateProviderLogoError: error => {
    return {
      type: actions.UPDATE_PROVIDER_LOGO_ERROR,
      error: error
    }
  },
  deleteProviderLogoRequest: (providerId) => {
    return {
      type: actions.DELETE_PROVIDER_LOGO_REQUEST,
      providerId
    }
  },
  deleteProviderLogoSuccess: result => {
    return {
      type: actions.DELETE_PROVIDER_LOGO_SUCCESS,
      payload: result
    }
  },
  deleteProviderLogoError: error => {
    return {
      type: actions.DELETE_PROVIDER_LOGO_ERROR,
      error: error
    }
  }

}

defineGetActionsPrSu(actions, 'GET_PROVIDER_ROLES', 'getProviderRoles')

export default actions
