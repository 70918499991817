export const initialDataState = {
  data: null,
  loading: false,
  loaded: false,
  error: false
}

export function reduceDataRequest (state, propName) {
  if (!propName) {
    return {
      ...state,
      loading: true
    }
  }

  const newState = {
    ...state
  }
  newState[propName] = {
    ...state[propName],
    loading: true
  }
  return newState
}

export function reduceDataSuccess (state, propName, payload) {
  if (!propName) {
    return {
      ...state,
      data: payload,
      loading: false,
      loaded: true,
      error: null
    }
  }

  const newState = {
    ...state
  }
  newState[propName] = {
    ...state[propName],
    data: payload,
    loading: false,
    loaded: true,
    error: null
  }
  return newState
}

export function reduceDataError (state, propName, error) {
  if (!propName) {
    return {
      ...state,
      loading: false,
      error: error
    }
  }

  const newState = {
    ...state
  }
  newState[propName] = {
    ...state[propName],
    loading: false,
    error: error
  }
  return newState
}
