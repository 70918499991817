import actions from './actions'
import {initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess} from '../../reducersHelper'

const initState = {
  unifiedVisits: initialDataState,
  assignStaff: initialDataState,
  setCustomTime: initialDataState,
  clearCustomTime: initialDataState,
  setAdjustedActualTimes: initialDataState,
  visitOrPreview: initialDataState,
  exception: initialDataState,
  bulkOperation: initialDataState,
  downloadCSV: initialDataState,
  smartScheduleParams: {
    preferredCareWorker1Weight: 5,
    preferredCareWorker2Weight: 2,
    qualificationsWeight: 5,
    enforceQualifications: true,
    rolloverWeight: 5,
    distributionWeight: 0,
    minimiseClusteringWeight: 5,
    minimiseTravelTimeWeight: 5,
    travelTimeAllowanceWeight: 30,
    travelSpeedMph: 12,
    back2BackMins: 5,
    minimiseIdleTimeWeight: 5,
    rolloverIntervalDays: 7,
  },
  showVisitEditor: {
    visible: false,
    carePlanVisitId: null,
    visitDate: null,
    careWorkerNum: null
  }
}

const reducers = (state = initState, action) => {

  switch (action.type) {
    case actions.CLEAR_SCHEDULING:
      return initState

    case actions.CLEAR_SCHEDULING_BULKOPERATION_RESULT:
      return {
        ...state,
        bulkOperation: initialDataState
      }

    case actions.SET_SMART_SCHEDULE_PARAM:
      return {
        ...state,
        smartScheduleParams: {
          ...state.smartScheduleParams,
          [action.param]: action.value
        },
      }
    case actions.RESET_SMART_SCHEDULE_PARAMS:
      return {
        ...state,
        smartScheduleParams: {...initState.smartScheduleParams}
      }

    case actions.SHOW_VISIT_EDITOR:
      return {
        ...state,
        showVisitEditor: {
          visible: true,
          serviceUserId: action.serviceUserId,
          carePlanVisitId: action.carePlanVisitId,
          visitDate: action.visitDate,
          careWorkerNum: action.careWorkerNum
        },
      }

    case actions.HIDE_VISIT_EDITOR:
      return {
        ...state,
        showVisitEditor: {
          visible: false,
          serviceUserId: null,
          carePlanVisitId: null,
          visitDate: null,
          careWorkerNum: null
        },
      }

    case actions.GET_SCHEDULING_UNIFIED_VISITS_REQUEST:
      return reduceDataRequest(state, 'unifiedVisits')
    case actions.GET_SCHEDULING_UNIFIED_VISITS_SUCCESS:
      return reduceDataSuccess(state, 'unifiedVisits', action.payload)
    case actions.GET_SCHEDULING_UNIFIED_VISITS_ERROR:
      return reduceDataError(state, 'unifiedVisits', action.error)

    case actions.GET_SCHEDULING_SET_CUSTOM_TIME_REQUEST:
      return reduceDataRequest(state, 'setCustomTime')
    case actions.GET_SCHEDULING_SET_CUSTOM_TIME_SUCCESS:
      return reduceDataSuccess(state, 'setCustomTime', action.payload)
    case actions.GET_SCHEDULING_SET_CUSTOM_TIME_ERROR:
      return reduceDataError(state, 'setCustomTime', action.error)

    case actions.GET_SCHEDULING_CLEAR_CUSTOM_TIME_REQUEST:
      return reduceDataRequest(state, 'clearCustomTime')
    case actions.GET_SCHEDULING_CLEAR_CUSTOM_TIME_SUCCESS:
      return reduceDataSuccess(state, 'clearCustomTime', action.payload)
    case actions.GET_SCHEDULING_CLEAR_CUSTOM_TIME_ERROR:
      return reduceDataError(state, 'clearCustomTime', action.error)

    case actions.SET_SCHEDULING_SET_ADJUSTED_ACTUAL_TIMES_REQUEST:
      return reduceDataRequest(state, 'setAdjustedActualTimes')
    case actions.SET_SCHEDULING_SET_ADJUSTED_ACTUAL_TIMES_SUCCESS:
      return reduceDataSuccess(state, 'setAdjustedActualTimes', action.payload)
    case actions.SET_SCHEDULING_SET_ADJUSTED_ACTUAL_TIMES_ERROR:
      return reduceDataError(state, 'setAdjustedActualTimes', action.error)

    case actions.GET_SCHEDULING_ASSIGN_STAFF_REQUEST:
      return reduceDataRequest(state, 'assignStaff')
    case actions.GET_SCHEDULING_ASSIGN_STAFF_SUCCESS:
      return reduceDataSuccess(state, 'assignStaff', action.payload)
    case actions.GET_SCHEDULING_ASSIGN_STAFF_ERROR:
      return reduceDataError(state, 'assignStaff', action.error)

    case actions.SCHEDULING_GET_VISIT_OR_PREVIEW_REQUEST:
      return reduceDataRequest(state, 'visitOrPreview')
    case actions.SCHEDULING_GET_VISIT_OR_PREVIEW_SUCCESS:
      return reduceDataSuccess(state, 'visitOrPreview', action.payload)
    case actions.SCHEDULING_GET_VISIT_OR_PREVIEW_ERROR:
      return reduceDataError(state, 'visitOrPreview', action.error)

    case actions.SCHEDULING_CLEAR_EXCEPTION_REQUEST:
      return reduceDataRequest(state, 'exception')
    case actions.SCHEDULING_CLEAR_EXCEPTION_SUCCESS:
      return reduceDataSuccess(state, 'exception', action.payload)
    case actions.SCHEDULING_CLEAR_EXCEPTION_ERROR:
      return reduceDataError(state, 'exception', action.error)

    case actions.SCHEDULING_SET_EXCEPTION_REQUEST:
      return reduceDataRequest(state, 'exception')
    case actions.SCHEDULING_SET_EXCEPTION_SUCCESS:
      return reduceDataSuccess(state, 'exception', action.payload)
    case actions.SCHEDULING_SET_EXCEPTION_ERROR:
      return reduceDataError(state, 'exception', action.error)

    case actions.SCHEDULING_BULK_ASSIGN_STAFF_REQUEST:
    case actions.SCHEDULING_BULK_ASSIGN_PREFERRED_STAFF_REQUEST:
    case actions.SCHEDULING_BULK_SMART_ALLOCATION_REQUEST:
    case actions.SCHEDULING_BULK_REMOVE_STAFF_REQUEST:
      return reduceDataRequest(state, 'bulkOperation')
    case actions.SCHEDULING_BULK_ASSIGN_STAFF_SUCCESS:
    case actions.SCHEDULING_BULK_ASSIGN_PREFERRED_STAFF_SUCCESS:
    case actions.SCHEDULING_BULK_SMART_ALLOCATION_SUCCESS:
    case actions.SCHEDULING_BULK_REMOVE_STAFF_SUCCESS:
      return reduceDataSuccess(state, 'bulkOperation', action.payload)
    case actions.SCHEDULING_BULK_ASSIGN_STAFF_ERROR:
    case actions.SCHEDULING_BULK_ASSIGN_PREFERRED_STAFF_ERROR:
    case actions.SCHEDULING_BULK_SMART_ALLOCATION_ERROR:
    case actions.SCHEDULING_BULK_REMOVE_STAFF_ERROR:
      return reduceDataError(state, 'bulkOperation', action.error)

    case actions.SCHEDULING_DOWNLOAD_CSV_REQUEST:
      return reduceDataRequest(state, 'downloadCSV')
    case actions.SCHEDULING_DOWNLOAD_CSV_SUCCESS:
      return reduceDataSuccess(state, 'downloadCSV', action.payload)
    case actions.SCHEDULING_DOWNLOAD_CSV_ERROR:
      return reduceDataError(state, 'downloadCSV', action.error)

    default:
      return state
  }
}

export default reducers
