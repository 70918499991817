import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../../reducersHelper'

const initState = {
  // Summary
  summary: initialDataState,

  // Problems
  problem: initialDataState,
  problemCurrent: initialDataState,
  problemPast: initialDataState,
  standardProblem: initialDataState,

  // Diagnosis
  diagnosis: initialDataState,

  // Medications
  medication: initialDataState,
  medicationCurrent: initialDataState,
  medicationPast: initialDataState,
  medicationIssue: initialDataState,

  // Risks and Warnings
  risksWarning: initialDataState,
  allergy: initialDataState,
  contraIndication: initialDataState,

  // Procedures
  procedure: initialDataState,

  // Examinations
  examination: initialDataState,
  operation: initialDataState,
  immunisation: initialDataState,

  // Investigations
  investigation: initialDataState,
  biochemistry: initialDataState,
  eCG: initialDataState,
  haematology: initialDataState,
  imaging: initialDataState,
  microbiology: initialDataState,
  cytology: initialDataState,
  otherInvestigation: initialDataState,
  physiology: initialDataState,
  urinalysis: initialDataState,

  // Events
  event: initialDataState,
  encounterList: initialDataState,
  encounterDetail: initialDataState,
  referral: initialDataState,
  admission: initialDataState,

  // PatientDetails
  patientDetail: initialDataState,
  fullMedicalRecord: initialDataState,

  // Audit History
  auditHistory: initialDataState

}

const reducers = (state = initState, action) => {
  switch (action.type) {
    // Summary
    case actions.GET_SERVICEUSER_EMIS_SUMMARY_REQUEST:
      return reduceDataRequest(state, 'summary')
    case actions.GET_SERVICEUSER_EMIS_SUMMARY_SUCCESS:
      return reduceDataSuccess(state, 'summary', action.payload)
    case actions.GET_SERVICEUSER_EMIS_SUMMARY_ERROR:
      return reduceDataError(state, 'summary', action.error)

    // Problems
    case actions.GET_SERVICEUSER_EMIS_PROBLEM_REQUEST:
      return reduceDataRequest(state, 'problem')
    case actions.GET_SERVICEUSER_EMIS_PROBLEM_SUCCESS:
      return reduceDataSuccess(state, 'problem', action.payload)
    case actions.GET_SERVICEUSER_EMIS_PROBLEM_ERROR:
      return reduceDataError(state, 'problem', action.error)

    case actions.GET_SERVICEUSER_EMIS_PROBLEMCURRENT_REQUEST:
      return reduceDataRequest(state, 'problemCurrent')
    case actions.GET_SERVICEUSER_EMIS_PROBLEMCURRENT_SUCCESS:
      return reduceDataSuccess(state, 'problemCurrent', action.payload)
    case actions.GET_SERVICEUSER_EMIS_PROBLEMCURRENT_ERROR:
      return reduceDataError(state, 'problemCurrent', action.error)

    case actions.GET_SERVICEUSER_EMIS_PROBLEMPAST_REQUEST:
      return reduceDataRequest(state, 'problemPast')
    case actions.GET_SERVICEUSER_EMIS_PROBLEMPAST_SUCCESS:
      return reduceDataSuccess(state, 'problemPast', action.payload)
    case actions.GET_SERVICEUSER_EMIS_PROBLEMPAST_ERROR:
      return reduceDataError(state, 'problemPast', action.error)

    case actions.GET_SERVICEUSER_EMIS_STANDARDPROBLEM_REQUEST:
      return reduceDataRequest(state, 'standardProblem')
    case actions.GET_SERVICEUSER_EMIS_STANDARDPROBLEM_SUCCESS:
      return reduceDataSuccess(state, 'standardProblem', action.payload)
    case actions.GET_SERVICEUSER_EMIS_STANDARDPROBLEM_ERROR:
      return reduceDataError(state, 'standardProblem', action.error)

    // Diagnosis
    case actions.GET_SERVICEUSER_EMIS_DIAGNOSIS_REQUEST:
      return reduceDataRequest(state, 'diagnosis')
    case actions.GET_SERVICEUSER_EMIS_DIAGNOSIS_SUCCESS:
      return reduceDataSuccess(state, 'diagnosis', action.payload)
    case actions.GET_SERVICEUSER_EMIS_DIAGNOSIS_ERROR:
      return reduceDataError(state, 'diagnosis', action.error)

    // Medications
    case actions.GET_SERVICEUSER_EMIS_MEDICATION_REQUEST:
      return reduceDataRequest(state, 'medication')
    case actions.GET_SERVICEUSER_EMIS_MEDICATION_SUCCESS:
      return reduceDataSuccess(state, 'medication', action.payload)
    case actions.GET_SERVICEUSER_EMIS_MEDICATION_ERROR:
      return reduceDataError(state, 'medication', action.error)

    case actions.GET_SERVICEUSER_EMIS_MEDICATIONCURRENT_REQUEST:
      return reduceDataRequest(state, 'medicationCurrent')
    case actions.GET_SERVICEUSER_EMIS_MEDICATIONCURRENT_SUCCESS:
      return reduceDataSuccess(state, 'medicationCurrent', action.payload)
    case actions.GET_SERVICEUSER_EMIS_MEDICATIONCURRENT_ERROR:
      return reduceDataError(state, 'medicationCurrent', action.error)

    case actions.GET_SERVICEUSER_EMIS_MEDICATIONPAST_REQUEST:
      return reduceDataRequest(state, 'medicationPast')
    case actions.GET_SERVICEUSER_EMIS_MEDICATIONPAST_SUCCESS:
      return reduceDataSuccess(state, 'medicationPast', action.payload)
    case actions.GET_SERVICEUSER_EMIS_MEDICATIONPAST_ERROR:
      return reduceDataError(state, 'medicationPast', action.error)

    case actions.GET_SERVICEUSER_EMIS_MEDICATIONISSUE_REQUEST:
      return reduceDataRequest(state, 'medicationIssue')
    case actions.GET_SERVICEUSER_EMIS_MEDICATIONISSUE_SUCCESS:
      return reduceDataSuccess(state, 'medicationIssue', action.payload)
    case actions.GET_SERVICEUSER_EMIS_MEDICATIONISSUE_ERROR:
      return reduceDataError(state, 'medicationIssue', action.error)

    // Risks and Warnings
    case actions.GET_SERVICEUSER_EMIS_RISKSWARNING_REQUEST:
      return reduceDataRequest(state, 'risksWarning')
    case actions.GET_SERVICEUSER_EMIS_RISKSWARNING_SUCCESS:
      return reduceDataSuccess(state, 'risksWarning', action.payload)
    case actions.GET_SERVICEUSER_EMIS_RISKSWARNING_ERROR:
      return reduceDataError(state, 'risksWarning', action.error)

    case actions.GET_SERVICEUSER_EMIS_ALLERGY_REQUEST:
      return reduceDataRequest(state, 'allergy')
    case actions.GET_SERVICEUSER_EMIS_ALLERGY_SUCCESS:
      return reduceDataSuccess(state, 'allergy', action.payload)
    case actions.GET_SERVICEUSER_EMIS_ALLERGY_ERROR:
      return reduceDataError(state, 'allergy', action.error)

    case actions.GET_SERVICEUSER_EMIS_CONTRAINDICATION_REQUEST:
      return reduceDataRequest(state, 'contraIndication')
    case actions.GET_SERVICEUSER_EMIS_CONTRAINDICATION_SUCCESS:
      return reduceDataSuccess(state, 'contraIndication', action.payload)
    case actions.GET_SERVICEUSER_EMIS_CONTRAINDICATION_ERROR:
      return reduceDataError(state, 'contraIndication', action.error)

    // Procedures
    case actions.GET_SERVICEUSER_EMIS_PROCEDURE_REQUEST:
      return reduceDataRequest(state, 'procedure')
    case actions.GET_SERVICEUSER_EMIS_PROCEDURE_SUCCESS:
      return reduceDataSuccess(state, 'procedure', action.payload)
    case actions.GET_SERVICEUSER_EMIS_PROCEDURE_ERROR:
      return reduceDataError(state, 'procedure', action.error)

    // Examinations
    case actions.GET_SERVICEUSER_EMIS_EXAMINATION_REQUEST:
      return reduceDataRequest(state, 'examination')
    case actions.GET_SERVICEUSER_EMIS_EXAMINATION_SUCCESS:
      return reduceDataSuccess(state, 'examination', action.payload)
    case actions.GET_SERVICEUSER_EMIS_EXAMINATION_ERROR:
      return reduceDataError(state, 'examination', action.error)

    case actions.GET_SERVICEUSER_EMIS_OPERATION_REQUEST:
      return reduceDataRequest(state, 'operation')
    case actions.GET_SERVICEUSER_EMIS_OPERATION_SUCCESS:
      return reduceDataSuccess(state, 'operation', action.payload)
    case actions.GET_SERVICEUSER_EMIS_OPERATION_ERROR:
      return reduceDataError(state, 'operation', action.error)

    case actions.GET_SERVICEUSER_EMIS_IMMUNISATION_REQUEST:
      return reduceDataRequest(state, 'immunisation')
    case actions.GET_SERVICEUSER_EMIS_IMMUNISATION_SUCCESS:
      return reduceDataSuccess(state, 'immunisation', action.payload)
    case actions.GET_SERVICEUSER_EMIS_IMMUNISATION_ERROR:
      return reduceDataError(state, 'immunisation', action.error)

    // Investigations
    case actions.GET_SERVICEUSER_EMIS_INVESTIGATION_REQUEST:
      return reduceDataRequest(state, 'investigation')
    case actions.GET_SERVICEUSER_EMIS_INVESTIGATION_SUCCESS:
      return reduceDataSuccess(state, 'investigation', action.payload)
    case actions.GET_SERVICEUSER_EMIS_INVESTIGATION_ERROR:
      return reduceDataError(state, 'investigation', action.error)

    case actions.GET_SERVICEUSER_EMIS_BIOCHEMISTRY_REQUEST:
      return reduceDataRequest(state, 'biochemistry')
    case actions.GET_SERVICEUSER_EMIS_BIOCHEMISTRY_SUCCESS:
      return reduceDataSuccess(state, 'biochemistry', action.payload)
    case actions.GET_SERVICEUSER_EMIS_BIOCHEMISTRY_ERROR:
      return reduceDataError(state, 'biochemistry', action.error)

    case actions.GET_SERVICEUSER_EMIS_ECG_REQUEST:
      return reduceDataRequest(state, 'eCG')
    case actions.GET_SERVICEUSER_EMIS_ECG_SUCCESS:
      return reduceDataSuccess(state, 'eCG', action.payload)
    case actions.GET_SERVICEUSER_EMIS_ECG_ERROR:
      return reduceDataError(state, 'eCG', action.error)

    case actions.GET_SERVICEUSER_EMIS_HAEMATOLOGY_REQUEST:
      return reduceDataRequest(state, 'haematology')
    case actions.GET_SERVICEUSER_EMIS_HAEMATOLOGY_SUCCESS:
      return reduceDataSuccess(state, 'haematology', action.payload)
    case actions.GET_SERVICEUSER_EMIS_HAEMATOLOGY_ERROR:
      return reduceDataError(state, 'haematology', action.error)

    case actions.GET_SERVICEUSER_EMIS_IMAGING_REQUEST:
      return reduceDataRequest(state, 'imaging')
    case actions.GET_SERVICEUSER_EMIS_IMAGING_SUCCESS:
      return reduceDataSuccess(state, 'imaging', action.payload)
    case actions.GET_SERVICEUSER_EMIS_IMAGING_ERROR:
      return reduceDataError(state, 'imaging', action.error)

    case actions.GET_SERVICEUSER_EMIS_MICROBIOLOGY_REQUEST:
      return reduceDataRequest(state, 'microbiology')
    case actions.GET_SERVICEUSER_EMIS_MICROBIOLOGY_SUCCESS:
      return reduceDataSuccess(state, 'microbiology', action.payload)
    case actions.GET_SERVICEUSER_EMIS_MICROBIOLOGY_ERROR:
      return reduceDataError(state, 'microbiology', action.error)

    case actions.GET_SERVICEUSER_EMIS_CYTOLOGY_REQUEST:
      return reduceDataRequest(state, 'cytology')
    case actions.GET_SERVICEUSER_EMIS_CYTOLOGY_SUCCESS:
      return reduceDataSuccess(state, 'cytology', action.payload)
    case actions.GET_SERVICEUSER_EMIS_CYTOLOGY_ERROR:
      return reduceDataError(state, 'cytology', action.error)

    case actions.GET_SERVICEUSER_EMIS_OTHERINVESTIGATION_REQUEST:
      return reduceDataRequest(state, 'otherInvestigation')
    case actions.GET_SERVICEUSER_EMIS_OTHERINVESTIGATION_SUCCESS:
      return reduceDataSuccess(state, 'otherInvestigation', action.payload)
    case actions.GET_SERVICEUSER_EMIS_OTHERINVESTIGATION_ERROR:
      return reduceDataError(state, 'otherInvestigation', action.error)

    case actions.GET_SERVICEUSER_EMIS_PHYSIOLOGY_REQUEST:
      return reduceDataRequest(state, 'physiology')
    case actions.GET_SERVICEUSER_EMIS_PHYSIOLOGY_SUCCESS:
      return reduceDataSuccess(state, 'physiology', action.payload)
    case actions.GET_SERVICEUSER_EMIS_PHYSIOLOGY_ERROR:
      return reduceDataError(state, 'physiology', action.error)

    case actions.GET_SERVICEUSER_EMIS_URINALYSIS_REQUEST:
      return reduceDataRequest(state, 'urinalysis')
    case actions.GET_SERVICEUSER_EMIS_URINALYSIS_SUCCESS:
      return reduceDataSuccess(state, 'urinalysis', action.payload)
    case actions.GET_SERVICEUSER_EMIS_URINALYSIS_ERROR:
      return reduceDataError(state, 'urinalysis', action.error)

    // Events
    case actions.GET_SERVICEUSER_EMIS_EVENT_REQUEST:
      return reduceDataRequest(state, 'event')
    case actions.GET_SERVICEUSER_EMIS_EVENT_SUCCESS:
      return reduceDataSuccess(state, 'event', action.payload)
    case actions.GET_SERVICEUSER_EMIS_EVENT_ERROR:
      return reduceDataError(state, 'event', action.error)

    case actions.GET_SERVICEUSER_EMIS_ENCOUNTERLIST_REQUEST:
      return reduceDataRequest(state, 'encounterList')
    case actions.GET_SERVICEUSER_EMIS_ENCOUNTERLIST_SUCCESS:
      return reduceDataSuccess(state, 'encounterList', action.payload)
    case actions.GET_SERVICEUSER_EMIS_ENCOUNTERLIST_ERROR:
      return reduceDataError(state, 'encounterList', action.error)

    case actions.GET_SERVICEUSER_EMIS_ENCOUNTERDETAIL_REQUEST:
      return reduceDataRequest(state, 'encounterDetail')
    case actions.GET_SERVICEUSER_EMIS_ENCOUNTERDETAIL_SUCCESS:
      return reduceDataSuccess(state, 'encounterDetail', action.payload)
    case actions.GET_SERVICEUSER_EMIS_ENCOUNTERDETAIL_ERROR:
      return reduceDataError(state, 'encounterDetail', action.error)

    case actions.GET_SERVICEUSER_EMIS_REFERRAL_REQUEST:
      return reduceDataRequest(state, 'referral')
    case actions.GET_SERVICEUSER_EMIS_REFERRAL_SUCCESS:
      return reduceDataSuccess(state, 'referral', action.payload)
    case actions.GET_SERVICEUSER_EMIS_REFERRAL_ERROR:
      return reduceDataError(state, 'referral', action.error)

    case actions.GET_SERVICEUSER_EMIS_ADMISSION_REQUEST:
      return reduceDataRequest(state, 'admission')
    case actions.GET_SERVICEUSER_EMIS_ADMISSION_SUCCESS:
      return reduceDataSuccess(state, 'admission', action.payload)
    case actions.GET_SERVICEUSER_EMIS_ADMISSION_ERROR:
      return reduceDataError(state, 'admission', action.error)

    // PatientDetails
    case actions.GET_SERVICEUSER_EMIS_PATIENTDETAIL_REQUEST:
      return reduceDataRequest(state, 'patientDetail')
    case actions.GET_SERVICEUSER_EMIS_PATIENTDETAIL_SUCCESS:
      return reduceDataSuccess(state, 'patientDetail', action.payload)
    case actions.GET_SERVICEUSER_EMIS_PATIENTDETAIL_ERROR:
      return reduceDataError(state, 'patientDetail', action.error)

    case actions.GET_SERVICEUSER_EMIS_FULLMEDICALRECORD_REQUEST:
      return reduceDataRequest(state, 'fullMedicalRecord')
    case actions.GET_SERVICEUSER_EMIS_FULLMEDICALRECORD_SUCCESS:
      return reduceDataSuccess(state, 'fullMedicalRecord', action.payload)
    case actions.GET_SERVICEUSER_EMIS_FULLMEDICALRECORD_ERROR:
      return reduceDataError(state, 'fullMedicalRecord', action.error)

    case actions.GET_SERVICEUSER_EMIS_AUDIT_HISTORY_REQUEST:
      return reduceDataRequest(state, 'auditHistory')
    case actions.GET_SERVICEUSER_EMIS_AUDIT_HISTORY_SUCCESS:
      return reduceDataSuccess(state, 'auditHistory', action.payload)
    case actions.GET_SERVICEUSER_EMIS_AUDIT_HISTORY_ERROR:
      return reduceDataError(state, 'auditHistory', action.error)

    default:
      return state
  }
}

export default reducers
