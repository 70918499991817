import {defineGetActionsPrSu} from "../../../actionsHelper";
import {initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess} from "../../../reducersHelper";
import {defineGetSaga} from "../../../sagasHelper";
import Api from "../../../../api";
import {all, fork} from "redux-saga/effects";

const actions = {}
defineGetActionsPrSu(actions, 'GET_HR_SERVICELOCATIONS', 'getServiceLocations')

const reducer = (state = initialDataState, action) => {
  switch (action.type) {
    case actions.GET_HR_SERVICELOCATIONS_REQUEST:
      return reduceDataRequest(state, null)
    case actions.GET_HR_SERVICELOCATIONS_SUCCESS:
      return reduceDataSuccess(state, null, action.payload)
    case actions.GET_HR_SERVICELOCATIONS_ERROR:
      return reduceDataError(state, null, action.error)
    default:
      return state
  }
}

function * getHrServiceLocationsRequest () {
  yield defineGetSaga(actions.GET_HR_SERVICELOCATIONS_REQUEST, Api.ServicesLocations.getServiceLocationsForSingleUser, actions.getServiceLocationsSuccess, actions.getServiceLocationsError)
}

const saga = function * rootSaga () {
  yield all([
    fork(getHrServiceLocationsRequest)
  ])
}

export { reducer, actions, saga }
