import { defineGetActionsPrSu } from '../../actionsHelper'

const actions = {
  CLEAR_AVAILABILITY: 'CLEAR_AVAILABILITY',

  clear: () => {
    return {
      type: actions.CLEAR_AVAILABILITY
    }
  }

}

defineGetActionsPrSu(actions, 'GET_UNIFIED_AVAILABILITY', 'getUnifiedAvailability')

export default actions
