import actions from '../serviceuser/serviceusers/actions'
import providerUserActions from '../providerusers/actions'
import auditActions from '../audit/actions'
import _ from 'lodash'

const initState = {
  personalDetailsMap: {},
  usersMap: {},
  avatarMap: {},
  avatarRequests: {}
}

const reducers = (state = initState, action) => {
  const personalDetailsMap = { ...state.personalDetailsMap }
  const usersMap = { ...state.usersMap }

  const avatarMap = { ...state.avatarMap }
  let avatarRequests = { ...state.avatarRequests }

  let userId
  switch (action.type) {
    case providerUserActions.GET_LIST_SUCCESS:
      action.payload.forEach(user => {
        usersMap[user.userId] = user
      })
      return {
        ...state,
        usersMap: usersMap
      }
    // case actions.GET_SERVICE_USER_REQUEST:
    //   return reduceDataRequest(state, 'personalDetails')
    case actions.GET_SERVICE_USER_SUCCESS:
      userId = action.payload.user.userId
      personalDetailsMap[userId] = action.payload
      avatarMap[userId] = action.payload.avatarUrl
      return {
        ...state,
        personalDetailsMap: personalDetailsMap
      }
    case actions.GET_USER_AVATAR_REQUEST:
    case providerUserActions.GET_USER_AVATAR_REQUEST:
      avatarRequests[action.userId] = true
      return {
        ...state,
        avatarRequests: avatarRequests
      }
    case actions.GET_USER_AVATAR_SUCCESS:
    case providerUserActions.GET_USER_AVATAR_SUCCESS:
      avatarMap[action.userId] = action.payload
      avatarRequests = _.remove(avatarRequests, function (e) {
        return e === action.userId
      })
      return {
        ...state,
        avatarMap: avatarMap,
        avatarRequests: avatarRequests
      }
    case actions.GET_USER_AVATAR_ERROR:
    case providerUserActions.GET_USER_AVATAR_ERROR:
      avatarRequests = _.remove(avatarRequests, function (e) {
        return e === action.userId
      })
      return {
        ...state,
        avatarRequests: avatarRequests
      }
    case providerUserActions.GET_ALL_USER_AVATARS_SUCCESS:
      return {
        ...state,
        avatarMap: action.payload,
        avatarRequests: avatarRequests
      }
    case auditActions.GET_ENTRIES_SUCCESS:
      return {
        ...state,
        avatarMap: _.assign(avatarMap, action.payload.avatarUrls)
      }
    default:
      return state
  }
}

export default reducers
