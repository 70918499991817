import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../reducersHelper'

const initState = {
  alertTypes: initialDataState,

  providerAlerts: initialDataState,
  acknowledgeAlerts: initialDataState,

  filters: null,
  providerAlertsFiltered: initialDataState
}

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAR_PROVIDER_ALERTS:
      return initState

    case actions.PROVIDER_ALERTS_SET_FILTERS:
      return {
        ...state,
        filters: action.filters
      }

    case actions.GET_ALERT_TYPES_REQUEST:
      return reduceDataRequest(state, 'alertTypes')
    case actions.GET_ALERT_TYPES_SUCCESS:
      return reduceDataSuccess(state, 'alertTypes', action.payload)
    case actions.GET_ALERT_TYPES_ERROR:
      return reduceDataError(state, 'alertTypes', action.error)

    case actions.GET_PROVIDER_ALERTS_REQUEST:
      return reduceDataRequest(state, 'providerAlerts')
    case actions.GET_PROVIDER_ALERTS_SUCCESS:
      return reduceDataSuccess(state, 'providerAlerts', action.payload)
    case actions.GET_PROVIDER_ALERTS_ERROR:
      return reduceDataError(state, 'providerAlerts', action.error)

    case actions.GET_PROVIDER_ALERTS_FILTERED_REQUEST:
      return reduceDataRequest(state, 'providerAlertsFiltered')
    case actions.GET_PROVIDER_ALERTS_FILTERED_SUCCESS:
      return reduceDataSuccess(state, 'providerAlertsFiltered', action.payload)
    case actions.GET_PROVIDER_ALERTS_FILTERED_ERROR:
      return reduceDataError(state, 'providerAlertsFiltered', action.error)

    case actions.ACKNOWLEDGE_ALERT_REQUEST:
      return reduceDataRequest(state, 'acknowledgeAlerts')
    case actions.ACKNOWLEDGE_ALERT_SUCCESS:
      return reduceDataSuccess(state, 'acknowledgeAlerts', action.payload)
    case actions.ACKNOWLEDGE_ALERT_ERROR:
      return reduceDataError(state, 'acknowledgeAlerts', action.error)

    default:
      return state
  }
}

export default reducers
