import actions from './actions'
import crudReducers from '../../crud/reducers'
import {
  initialDataState,
  reduceDataError,
  reduceDataRequest,
  reduceDataSuccess
} from '../../../reducersHelper'

const crud = crudReducers(actions)

const initState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  modalActive: false,
  filterModalActive: false,
  replyModalActive: false,
  repliesActive: {},
  filters: {
    type: ''
  },
  allNotes: {
    data: [],
    loading: false,
    loaded: false,
    error: false
  },
  alerts: {
    data: [],
    loading: false,
    loaded: false,
    error: false
  },
  data: {
    messageType: ''
  },
  downloadCSV: initialDataState
}
const reducers = (state = initState, action) => {
  let crudded, repliesActive
  switch (action.type) {
    case actions.CLEAR:
      crudded = crud(state, action)
      crudded.allNotes = state.allNotes
      crudded.alerts = state.alerts
      return crudded
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: action.payload
      }
    case actions.TOGGLE_FILTER_MODAL:
      state = {
        ...state,
        filterModalActive: !state.filterModalActive
      }
      return state
    case actions.GET_FULL_LIST_REQUEST:
      state = {
        ...state,
        allNotes: {
          ...state.allNotes,
          loading: true,
          loaded: false
        }
      }
      return state
    case actions.GET_FULL_LIST_SUCCESS:
      return {
        ...state,
        allNotes: {
          ...state.allNotes,
          data: action.payload,
          loading: false,
          loaded: true,
          error: false
        }
      }
    case actions.GET_FULL_LIST_ERROR:
      return {
        ...state,
        allNotes: {
          ...state.allNotes,
          loading: false,
          error: action.error
        }
      }
    case actions.GET_ALERTS_LIST_REQUEST:
      state = {
        ...state,
        alerts: {
          ...state.alerts,
          loading: true,
          loaded: false
        }
      }
      return state
    case actions.GET_ALERTS_LIST_SUCCESS:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          data: action.payload,
          loading: false,
          loaded: true,
          error: false
        }
      }
    case actions.GET_ALERTS_LIST_ERROR:
      return {
        ...state,
        alerts: {
          ...state.alerts,
          loading: false,
          error: action.error
        }
      }
    case actions.TOGGLE_REPLY_MODAL:
      state = {
        ...state,
        replyModalActive: !state.replyModalActive
      }
      return state
    case actions.TOGGLE_REPLIES:
      repliesActive = { ...state.repliesActive }
      repliesActive[action.payload] = !repliesActive[action.payload]
      state = {
        ...state,
        repliesActive: repliesActive
      }
      return state

    case actions.COMMUNICATION_MESSAGES_DOWNLOAD_CSV_REQUEST:
      return reduceDataRequest(state, 'downloadCSV')
    case actions.COMMUNICATION_MESSAGES_DOWNLOAD_CSV_SUCCESS:
      return reduceDataSuccess(state, 'downloadCSV', action.payload)
    case actions.COMMUNICATION_MESSAGES_DOWNLOAD_CSV_ERROR:
      return reduceDataError(state, 'downloadCSV', action.error)

    default:
      return crud(state, action)
  }
}

export default reducers
