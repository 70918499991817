import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import React from 'react'
import ReactDOM from 'react-dom'
import BaseWrapper from './BaseWrapper'
import registerServiceWorker from './registerServiceWorker'
import 'antd/dist/antd.css'
import '@formatjs/intl-relativetimeformat/polyfill'
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  // dev: 1.0, prod: 0.1
  tracesSampleRate: 0.1,
});

ReactDOM.render(<Sentry.ErrorBoundary
  fallback={<p>An error has occurred</p>}><BaseWrapper /></Sentry.ErrorBoundary>, document.getElementById('root'))
registerServiceWorker()
