import crudActions from '../../crud/actions'

const prefix = 'SYMPTOMS_'
const actions = {
  ...crudActions(prefix),

  CREATE_NOTE_REQUEST: prefix + 'CREATE_NOTE_REQUEST',
  CREATE_NOTE_SUCCESS: prefix + 'CREATE_NOTE_SUCCESS',
  CREATE_NOTE_ERROR: prefix + 'CREATE_NOTE_ERROR',

  createNoteRequest: (providerId, userId, symptomId, note) => {
    return {
      type: actions.CREATE_NOTE_REQUEST,
      payload: {
        providerId: providerId,
        userId: userId,
        symptomId: symptomId,
        note: note
      }
    }
  },
  createNoteSuccess: result => {
    return {
      type: actions.CREATE_NOTE_SUCCESS,
      payload: result
    }
  },
  createNoteError: error => {
    return {
      type: actions.CREATE_NOTE_ERROR,
      error: error
    }
  },

  GET_COUNT_REQUEST: prefix + 'GET_COUNT_REQUEST',
  GET_COUNT_SUCCESS: prefix + 'GET_COUNT_SUCCESS',
  GET_COUNT_ERROR: prefix + 'GET_COUNT_ERROR',

  getCountRequest: (providerId, userId) => {
    return {
      type: actions.GET_COUNT_REQUEST,
      payload: {
        providerId: providerId,
        userId: userId
      }
    }
  },
  getCountSuccess: result => {
    return {
      type: actions.GET_COUNT_SUCCESS,
      payload: result
    }
  },
  getCountError: error => {
    return {
      type: actions.GET_COUNT_ERROR,
      error: error
    }
  }

}

export default actions
