import crudActions from '../crud/actions'
import { parseError } from '../../../helpers/errors'

const actions = {
  ...crudActions('NEWUSER_'),

  ACTIVATE_REQUEST: 'ACTIVATE_REQUEST',
  ACTIVATE_SUCCESS: 'ACTIVATE_SUCCESS',
  ACTIVATE_ERROR: 'ACTIVATE_ERROR',

  activateRequest: (providerId, newuser) => {
    return {
      type: actions.ACTIVATE_REQUEST,
      payload: {
        email: newuser.email,
        data: { providerId: providerId } // To pass to the crud endpoints
      }
    }
  },
  activateSuccess: result => {
    return {
      type: actions.ACTIVATE_SUCCESS,
      payload: result
    }
  },
  activateError: error => {
    return {
      type: actions.ACTIVATE_ERROR,
      error: error
    }
  },

  CREATE_SERVICEUSER_REQUEST: 'CREATE_SERVICEUSER_REQUEST',
  CREATE_SERVICEUSER_SUCCESS: 'CREATE_SERVICEUSER_SUCCESS',
  CREATE_SERVICEUSER_ERROR: 'CREATE_SERVICEUSER_ERROR',
  createServiceUserRequest: (providerId, serviceuser) => {
    return {
      type: actions.CREATE_SERVICEUSER_REQUEST,
      payload: {
        serviceuser: serviceuser,
        providerId: providerId
      }
    }
  },
  createServiceUserSuccess: result => {
    return {
      type: actions.CREATE_SERVICEUSER_SUCCESS,
      payload: result
    }
  },
  createServiceUserError: error => {
    return {
      type: actions.CREATE_SERVICEUSER_ERROR,
      error: error,
      payload: { error: parseError(error) }
    }
  }

}

export default actions
