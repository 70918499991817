import actions from './actions'
import crudReducers from '../../crud/reducers'

const dataPropName = 'data'

const initState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  count: [],
  countLoading: false,
  modalActive: false,
  data: {}
}

const crud = crudReducers(actions, dataPropName, initState)

const reducers = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.CREATE_NOTE_REQUEST:
      return {
        ...state
      }
    case actions.CREATE_NOTE_SUCCESS:
      return {
        ...state
      }
    case actions.CREATE_NOTE_ERROR:
      return {
        ...state
      }
    case actions.GET_COUNT_REQUEST:
      return {
        ...state,
        countLoading: true
      }
    case actions.GET_COUNT_SUCCESS:
      return {
        ...state,
        count: payload,
        countLoading: false
      }
    case actions.GET_COUNT_ERROR:
      return {
        ...state,
        countLoading: false
      }
    case actions.CLEAR:
      return crud({
        ...state,
        count: [],
        countLoading: false
      }, { type, payload })
    default:
      return crud(state, { type, payload })
  }
}

export default reducers
