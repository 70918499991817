import { all, put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import { getAuthToken } from '../../../../helpers/tokens'
import Api from '../../../../api'

function * getList () {
  yield takeEvery(actions.GET_LIST_REQUEST, function * (action) {
    const { payload } = action
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.HR.getLegalList,
        authToken.token,
        payload
      )
      if (response) {
        yield put(actions.getListSuccess(response))
      } else {
        yield put(actions.getListError(error))
      }
    }
  })
}

export default function * devSaga () {
  yield all([getList()])
}
