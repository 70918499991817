import actions from './actions'
// import crudReducers from '../../crud/reducers'

// NOTE: These reducers are currently non functional. They need to be updated to allow for different listNames without code duplication

const dataPropName = 'model'
const initState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  modalActive: false,
  [dataPropName]: {
    key: '',
    value: ''
  }
}
const reducers = (state = {}, action) => {
  let segment
  switch (action.type) {
    case actions.CLEAR:
      return {}
    case actions.GET_LIST_REQUEST:
      if (!state[action.payload.listName]) {
        segment = initState
      } else {
        segment = state[action.payload.listName]
      }
      segment = {
        ...segment,
        loading: true,
        listLoaded: false
      }
      state = {
        ...state,
        [action.payload.listName]: segment
      }
      return state
    case actions.GET_LIST_SUCCESS:
      if (!state[action.requestPayload.listName]) {
        segment = initState
      } else {
        segment = state[action.requestPayload.listName]
      }
      segment = {
        ...segment,
        loading: false,
        listLoaded: true,
        error: null,
        list: action.payload
      }
      state = {
        ...state,
        [action.requestPayload.listName]: segment
      }
      return state
    case actions.GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case actions.GET_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.GET_SUCCESS:
      state = {
        ...state,
        loading: false,
        loaded: true,
        error: null
      }
      state[dataPropName] = action.payload
      return state
    case actions.GET_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case actions.CREATE_REQUEST:
    case actions.UPDATE_REQUEST:
    case actions.DELETE_REQUEST:
      return {
        ...state,
        updating: true,
        error: null
      }
    case actions.CREATE_SUCCESS:
    case actions.UPDATE_SUCCESS:
    case actions.DELETE_SUCCESS:
      state = {
        ...state,
        updating: false,
        modalActive: false,
        error: null
      }
      state[dataPropName] = action.payload
      return state
    case actions.CREATE_ERROR:
    case actions.UPDATE_ERROR:
    case actions.DELETE_ERROR:
      return {
        ...state,
        updating: false,
        // modalActive: false,
        error: action.error,
        formError: action.payload.error
      }

    case actions.UPDATE_MODEL:
      state = {
        ...state
      }
      state[dataPropName] = action.payload.data
      return state
    case actions.TOGGLE_HANDLE_MODAL:
      state = {
        ...state,
        modalActive: !state.modalActive
      }
      state[dataPropName] =
        action.payload.data == null
          ? initState[dataPropName]
          : action.payload.data
      return state
    case actions.CLEAR_FORM_ERROR:
      return {
        ...state,
        formError: false
      }
    default:
      return state
  }
}

// const reducers = crudReducers(actions, dataPropName, initState)

export default reducers
