import crudActions from '../../crud/actions'

// Actions for the additional documents uploaded
const actions = {
  ...crudActions('HR_DOCUMENT_REPO_DOCS_'),

  HR_DOC_REPO_DOWNLOAD_REQUEST: 'HR_DOC_REPO_DOWNLOAD_REQUEST',

  downloadRequest: (data) => {
    return {
      type: actions.HR_DOC_REPO_DOWNLOAD_REQUEST,
      payload: data
    }
  }
}

export default actions
