const theme = {}

theme.palette = {
  primary: [
    '#2f95a4' // 0: Default
    // Darker / Lighter Shades are autogenerated
  ],
  border: [
    '#e9e9e9', // 0: Border
    '#d8d8d8', // 1: BorderDark
    '#ebebeb', // 2: BorderLight
    '#d3d3d3', // 3:
    'rgba(228, 228, 228, 0.65)' // 4:
  ],
  sidebar: [
    '#f8f8f8', // 0: SidebarBackground
    '#ffffff', // 1: LogoBackground
    '#1890ff', // 2: LogoForeground
    '#efefef', // 3: DefaultText
    '#e3e3e3', // 4: MenuItemBackground
    '#2c2c2c' // 5: HoverText
  ],
  warning: [
    '#ffbf00' // 0: Warning
  ],
  success: [
    '#00b16a' // 0: Success
  ],
  error: [
    '#f64744', // 0: Error
    '#EC3D3A', // 1: Darken 4%
    '#FF5B58', // 2: Lighten 8%
    '#FFcdcd', // 3: Background
    '#FFdddd' // 4: Background Lighter
  ],
  axela: [
    '#071f24', // 0: PineGreen
    '#a3a3a3', // 1: Greyish
    '#2b3259', // 2: Dark Grey Blue
    '#2f95a4', // 3: Sea
    '#bfbfbf', // 4: Pinkish Grey
    '#d0021b', // 5: Scarlet
    '#efefef', // 6: White
    '#ececec', // 7: Keyline
    '#57a5b1', // 8: SeaHover
    '#071e24', // 9: Greyer
    '#071f2422', // 10: PineGreen Transparent
    '#ffbf00', // 11: Warning
    '#808080' // 12: DarkerGreyish
  ],
  servicesLocations: [
    '#a4343a',
    '#a8b50a',
    '#f56600',
    '#2f95a4',
    '#2a3259',
    '#222222',
    '#a4538B',
    '#8dbf1d',
    '#60bbbc',
    '#104232',
    '#33658A',
    '#86BBD8',
    '#758E4F',
    '#F6AE2D',
    '#F28439',
    '#C5F4E0',
    '#C2EABA',
    '#A7C4A0',
    '#DDC8C4',
    '#6B4D57',
    '#F24236',
    '#2E86AB',
    '#BD2D87',
    '#D664BE',
    '#018E42'
  ],
  nationalitiesBarColors: [
    '#52C1D6',
    '#343B5F',
    '#9AC13E',
    '#FDBB4B',
    '#A35488'
  ],
  dependencyLevel: {
    low: '#6bd0e9',
    medium: '#e1a340',
    high: '#d0021b',
    notSet: '#ccf'
  },
  status: {
    active: '#b8e986',
    inactive: '#bfbfbf',
    notinservice: '#d0021b',
    notSet: '#ccf'
  },
  gradients: {}
}

theme.fonts = {
//  primary: 'ProximaNova-Semibold, ProximaNova, Roboto, sans-serif',
  primary: 'Roboto, sans-serif',
  pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace'
}

export default theme
