import { defineGetActionsPrSu } from '../../../actionsHelper'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../../reducersHelper'
import { defineGetSaga } from '../../../sagasHelper'
import Api from '../../../../api'
import { all, fork } from 'redux-saga/effects'

const actions = {}
defineGetActionsPrSu(
  actions,
  'GET_HR_LEARNING_DUE',
  'getHrLearningDue'
)

const reducer = (state = initialDataState, action) => {
  switch (action.type) {
    case actions.GET_HR_LEARNING_DUE_REQUEST:
      return reduceDataRequest(state)
    case actions.GET_HR_LEARNING_DUE_SUCCESS:
      return reduceDataSuccess(state, null, action.payload)
    case actions.GET_HR_LEARNING_DUE_ERROR:
      return reduceDataError(state, null, action.error)
    default:
      return state
  }
}

function * getLearningDueRequest () {
  yield defineGetSaga(actions.GET_HR_LEARNING_DUE_REQUEST, Api.HR.getLearningDue, actions.getHrLearningDueSuccess, actions.getHrLearningDueError)
}

function * saga () {
  yield all([
    fork(getLearningDueRequest)
  ])
}

export { reducer, actions, saga }
