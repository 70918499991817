import actions from './actions'
import crudReducers from '../../crud/reducers'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../../reducersHelper'

const dataPropName = 'data'

const initState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  modalActive: false,
  data: {},
  createFromTemplate: initialDataState,
  downloadPDF: initialDataState
}

const crud = crudReducers(actions, dataPropName, initState)

export default (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case actions.RISKASSESSMENT_CREATEFROMTEMPLATE_REQUEST:
      return reduceDataRequest(state, 'createFromTemplate')
    case actions.RISKASSESSMENT_CREATEFROMTEMPLATE_SUCCESS:
      return reduceDataSuccess(state, 'createFromTemplate', action.payload)
    case actions.RISKASSESSMENT_CREATEFROMTEMPLATE_ERROR:
      return reduceDataError(state, 'createFromTemplate', action.error)
    case actions.RISKASSESSMENT_DOWNLOAD_PDF_REQUEST:
      return reduceDataRequest(state, 'downloadPDF')
    case actions.RISKASSESSMENT_DOWNLOAD_PDF_SUCCESS:
      return reduceDataSuccess(state, 'downloadPDF', action.payload)
    case actions.RISKASSESSMENT_DOWNLOAD_PDF_ERROR:
      return reduceDataError(state, 'downloadPDF', action.error)
    default:
      return crud(state, { type, payload })
  }
}
