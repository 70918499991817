import { all } from 'redux-saga/effects'
import userFormsSagas from './userForms/sagas'
import userFormTemplateSagas from './userFormTemplates/sagas'

export default function * rootSaga () {
  yield all([
    userFormsSagas(),
    userFormTemplateSagas(),
  ])
}
