const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  SET_AUTH_PENDING: 'SET_AUTH_PENDING',
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_O365_REQUEST: 'LOGIN_O365_REQUEST',
  LOGIN_O365_SUCCESS: 'LOGIN_O365_SUCCESS',
  LOGIN_O365_ERROR: 'LOGIN_O365_ERROR',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGOUT: 'LOGOUT',
  PASSWORD_RESET_LINK_REQUEST: 'PASSWORD_RESET_LINK_REQUEST',
  PASSWORD_RESET_LINK_SUCCESS: 'PASSWORD_RESET_LINK_SUCCESS',
  PASSWORD_RESET_LINK_ERROR: 'PASSWORD_RESET_LINK_ERROR',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  INVALID_TOKEN: 'INVALID_TOKEN',
  TOKEN_REFRESH_REQUEST: 'TOKEN_REFRESH_REQUEST',
  TOKEN_REFRESH_SUCCESS: 'TOKEN_REFRESH_SUCCESS',
  TOKEN_REFRESH_ERROR: 'TOKEN_REFRESH_ERROR',
  TOKEN_REFRESH_RESET: 'TOKEN_REFRESH_RESET',
  SAVE_APP_TOKEN: 'SAVE_APP_TOKEN',

  AUTH_EXCHANGE_SAML_TOKEN_REQUEST:
    'AUTH_EXCHANGE_SAML_TOKEN_REQUEST',
  AUTH_EXCHANGE_SAML_TOKEN_SUCCESS:
    'AUTH_EXCHANGE_SAML_TOKEN_SUCCESS',
  AUTH_EXCHANGE_SAML_TOKEN_ERROR:
    'AUTH_EXCHANGE_SAML_TOKEN_ERROR',

  exchangeSamlTokenRequest: (sessionId) => {
    return {
      type: actions.AUTH_EXCHANGE_SAML_TOKEN_REQUEST,
      payload: {sessionId: sessionId}
    }
  },
  exchangeSamlTokenSuccess: result => {
    return {
      type: actions.AUTH_EXCHANGE_SAML_TOKEN_SUCCESS,
      payload: result
    }
  },
  exchangeSamlTokenError: error => ({
    type: actions.AUTH_EXCHANGE_SAML_TOKEN_ERROR,
    error: error
  }),

  checkAuthorization: () => ({
    type: actions.CHECK_AUTHORIZATION
  }),

  setAuthPending: () => ({
    type: actions.SET_AUTH_PENDING
  }),

  login: (email, password, providerCode) => ({
    type: actions.LOGIN_REQUEST,
    email,
    password,
    providerCode
  }),

  loginSuccess: (token) => ({
    type: actions.LOGIN_SUCCESS,
    authToken: token,
    refreshToken: token
  }),

  loginError: (loginError) => ({
    type: actions.LOGIN_ERROR,
    loginError
  }),


  loginO365: () => ({
    type: actions.LOGIN_O365_REQUEST
  }),

  loginO365Success: (token) => ({
    type: actions.LOGIN_O365_SUCCESS,
    authToken: token,
    refreshToken: token
  }),

  loginO365Error: (loginError) => ({
    type: actions.LOGIN_O365_ERROR,
    loginError
  }),


  logout: (url) => ({
    type: actions.LOGOUT,
    redirectTo: url
  }),

  pwResetLinkRequest: (providerCode, email) => {
    return {
      type: actions.PASSWORD_RESET_LINK_REQUEST,
      payload: {
        email: email,
        providerCode: providerCode
      }
    }
  },
  pwResetLinkSuccess: result => {
    return {
      type: actions.PASSWORD_RESET_LINK_SUCCESS,
      payload: result
    }
  },
  pwResetLinkError: error => {
    return {
      type: actions.PASSWORD_RESET_LINK_ERROR,
      error: error
    }
  },

  resetPasswordRequest: (email, password, code) => {
    return {
      type: actions.RESET_PASSWORD_REQUEST,
      payload: {
        email: email,
        password: password,
        code: code
      }
    }
  },
  resetPasswordSuccess: result => {
    return {
      type: actions.RESET_PASSWORD_SUCCESS,
      payload: result
    }
  },
  resetPasswordError: error => {
    return {
      type: actions.RESET_PASSWORD_ERROR,
      error: error
    }
  },

  invalidToken: () => ({
    type: actions.INVALID_TOKEN
  }),

  tokenRefreshRequest: () => ({
    type: actions.TOKEN_REFRESH_REQUEST
  }),

  tokenRefreshSuccess: () => ({
    type: actions.TOKEN_REFRESH_SUCCESS
  }),

  tokenRefreshError: (refreshError) => ({
    type: actions.TOKEN_REFRESH_ERROR,
    refreshError
  }),

  tokenRefreshReset: () => ({
    type: actions.TOKEN_REFRESH_RESET
  }),

  saveAppToken: authToken => ({
    type: actions.SAVE_APP_TOKEN,
    authToken
  })
}
export default actions
