import config from './settings'
import {commonGet, commonGetBlob} from './common'

class Reports {

  static getServiceUsersCovid19Stats(token, providerId, data) {
    const qs = `timeFrom=${data && data.timeFrom ? data.timeFrom.toISOString() : ''}` +
      `&timeTo=${data && data.timeTo ? data.timeTo.toISOString() : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/reports/current/serviceUserCovid19?${qs}`

    return commonGet(token, url)
  }

  static getServiceUsersCovid19Csv(token, providerId, data) {
    const qs = `timeFrom=${data && data.timeFrom ? data.timeFrom.toISOString() : ''}` +
      `&timeTo=${data && data.timeTo ? data.timeTo.toISOString() : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/reports/export/serviceUserCovid19/csv?${qs}`

    return commonGetBlob(token, url)
  }
}

export default Reports
