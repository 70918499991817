import { defineGetActionsPrSu } from '../../../actionsHelper'

const actions = {
  CLEAR_SERVICEUSER_SCHEDULING: 'CLEAR_SERVICEUSER_SCHEDULING',

  clear: () => {
    return {
      type: actions.CLEAR_SERVICEUSER_SCHEDULING
    }
  }
}

defineGetActionsPrSu(actions, 'GET_SERVICEUSER_SCHEDULING_UNIFIED_VISITS', 'getUnifiedVisits')

export default actions
