import config from './settings'
import { commonGet, commonGetBlob } from './common'

class RiskAssessment {
  static downloadFile (
    token,
    providerId,
    userId,
    riskAssessmentId,
    filename
  ) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/riskassessment/${riskAssessmentId}/files/${filename}`
    return commonGetBlob(token, url)
  }

  static createFromTemplate (
    token,
    providerId,
    userId,
    templateId
  ) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/riskassessmenttemplate/${templateId}`
    return commonGet(token, url)
  }

  static downloadPdf (
    token,
    providerId,
    userId,
    data
  ) {
    const { riskAssessmentId } = data
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/riskassessment/${riskAssessmentId}/pdf`
    return commonGetBlob(token, url)
  }
}
export default RiskAssessment
