import {all} from 'redux-saga/effects'
import allergiesSagas from './allergies/sagas'
import conditionDocsSagas from './conditions/docSagas'
import conditionsSagas from './conditions/sagas'
import docRepoSagas from './docRepo/sagas'
import legalDocSagas from './legal/sagas'
import legalListSagas from './legallist/sagas'
import notificationsSagas from './notifications/sagas'
import {saga as absencesSagas} from './absences'
import {saga as employmentHistorySagas} from './employmentHistory'
import {saga as holidaySagas} from './holidays'
import {saga as holidaysUpcomingSagas} from './holidays/upcoming'
import {saga as learningDueSagas} from './learning/due'
import {saga as learningSagas} from './learning'
import {saga as workingHoursSagas} from './workingHours'
import {sagas as staffSagas} from './staff'

export default function* rootSaga() {
  yield all([
    absencesSagas(),
    allergiesSagas(),
    conditionDocsSagas(),
    conditionsSagas(),
    docRepoSagas(),
    employmentHistorySagas(),
    holidaySagas(),
    holidaysUpcomingSagas(),
    learningDueSagas(),
    learningSagas(),
    legalDocSagas(),
    legalListSagas(),
    notificationsSagas(),
    workingHoursSagas(),
    staffSagas()
  ])
}
