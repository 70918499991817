import {Form} from "antd";
import React, {useEffect} from "react";
import {FIELD_DATE_RANGE_PICKER} from "./Filters";
import {RangePicker} from "../../../../components/styled/datepicker";
import _ from 'lodash'
import {useDispatch, useSelector} from "react-redux";
import actions, {getDefaultDateRange} from "../../../../redux/app/rostering/actions";
import moment from "moment-timezone";


const DATE_FORMAT = 'DD.MM.YYYY (ddd)'


export default function DateRangeFilter({form, initialDateRange, onChange, disabled}) {
  const {getFieldDecorator} = form;
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Rostering.filters)

  const setFilter = (dateRange) => {
    // console.log(dateRange)
    // console.log(dateRange.map(d => d.format(dateFormatISO8601)))
    dispatch(actions.setFilters({
      [FIELD_DATE_RANGE_PICKER]: dateRange.map(d => d.clone().utc().startOf('d')),
    }))
  }

  const handleChange = (dateRange) => {
    if (dateRange.length === 0) {
      dateRange = getDefaultDateRange()
    }
    setFilter(dateRange)
    _.isFunction(onChange) && onChange(dateRange)
  }

  useEffect(() => {
    form.setFieldsValue({[FIELD_DATE_RANGE_PICKER]: filters[FIELD_DATE_RANGE_PICKER]})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters[FIELD_DATE_RANGE_PICKER]])

  return <Form.Item className={'rangePicker'}>
    {getFieldDecorator(FIELD_DATE_RANGE_PICKER, {
      initialValue: initialDateRange,
      rules: [{type: 'array', message: 'Please select a date range!'}]
    })(
      <RangePicker format={DATE_FORMAT}
                   style={{width: '100%'}}
                   onChange={handleChange}
                   disabled={disabled}
                   ranges={{
                     Today: [moment(), moment()],
                     'w1': [moment().utc().startOf('week'), moment().utc().endOf('week')],
                     'w2': [moment().utc().startOf('week').add({w:1}), moment().utc().endOf('week').add({w:1})],
                     'w3': [moment().utc().startOf('week').add({w:2}), moment().utc().endOf('week').add({w:2})],
                     'w4': [moment().utc().startOf('week').add({w:3}), moment().utc().endOf('week').add({w:3})],
                     'This Month': [moment().utc().startOf('month'), moment().utc().endOf('month')],
                     'Next Month': [moment().utc().startOf('month').add({month:1}), moment().utc().endOf('month').add({month:1})],
                   }}
      />
    )}
  </Form.Item>
}