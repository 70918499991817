import config from './settings'
import {commonGet, commonPut} from './common'

export default class Alerts {
  static getAlertTypes(token, providerId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/alertTypes`
    return commonGet(token, url)
  }

  static getProviderAlertsFiltered(token, providerId, data) {
    let qs = ''
    if (data) {
      qs = `userIds=${data.userIds ? data.userIds.join() : ''}` +
        `&locationIds=${data.locationIds ? data.locationIds.join() : ''}` +
        `&acknowledgedByUserIds=${data.acknowledgedByUserIds ? data.acknowledgedByUserIds.join() : ''}` +
        `&alertTypes=${data.alertTypes ? data.alertTypes.join() : ''}` +
        `&alertLevels=${data.alertLevels ? data.alertLevels.join() : ''}` +
        `&timeFrom=${data.timeFrom ? data.timeFrom.toISOString() : ''}` +
        `&timeTo=${data.timeTo ? data.timeTo.clone().add({day: 1}).toISOString() : ''}` +
        `${data.showAcknowledged ? '&showAcknowledged=y' : ''}` +
        `&offset=${data.offset ? data.offset : ''}` +
        `&pageSize=${data.pageSize ? data.pageSize : ''}`
    }

    const url = `${
      config.url
    }/auth/providers/${providerId}/alertsFiltered?${qs}`
    return commonGet(token, url)
  }

  static getProviderAlerts(token, providerId) {
    const qs =
      `showAcknowledged=n` +
      `&offset=0` +
      `&pageSize=100`

    const url = `${
      config.url
    }/auth/providers/${providerId}/alertsFiltered?${qs}`
    return commonGet(token, url)
  }

  static getUserAlerts(token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/useralerts/${userId}`
    return commonGet(token, url)
  }

  static acknowledgeAlert(token, providerId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/alerts/${data.alertId}`
    return commonPut(token, url)
  }
}
