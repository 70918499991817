import {buffers} from 'redux-saga'
import {actionChannel, all, call, fork, put, select, take, takeEvery} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {clearTokens, getAuthToken, getRefreshToken, setAuthToken, setRefreshToken} from '../../../helpers/tokens'
import actions from './actions'
import userActions from '../authuser/actions'
import Api from '../../../api'
import {parseError} from '../../../helpers/errors'
import interfaceActions from '../../interface/actions'

const getRefreshCount = state => state.Auth.tokenRefreshCount
const getTokenIsValid = state => state.Auth.tokenIsValid

export function* loginRequest() {
  yield takeEvery(actions.LOGIN_REQUEST, function * (payload) {
    yield put(actions.setAuthPending())
    const { response, error } = yield call(
      Api.Auth.login,
      payload.email,
      payload.password,
      payload.providerCode
    )
    if (response) {
      yield put(actions.loginSuccess(response))
    } else {
      yield put(actions.loginError(parseError(error)))
    }
  })
}

export function* loginO365Request() {
  yield takeEvery(actions.LOGIN_O365_REQUEST, function* () {
    yield put(actions.setAuthPending())
    const {response, error} = yield call(
      Api.Auth.loginO365)
    if (response) {
      console.log(response)
      // window.location.replace(response)
      yield put(actions.loginO365Success(response))
    } else {
      yield put(actions.loginO365Error(parseError(error)))
    }
  })
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function* (action) {
    setAuthToken(action.authToken)
    setRefreshToken(action.refreshToken)
    yield put(userActions.getUser())
    yield put(userActions.getAuthUserPermissionsRequest())
    yield put(userActions.getAuthProviderRequest())
    yield put(userActions.getAuthUserServicesRequest())
    // yield put(push("/app"));
  })
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function () {
  })
}

export function * logout () {
  yield takeEvery(actions.LOGOUT, function * (payload) {
    clearTokens()
    if (payload.redirectTo) {
      yield put(push(payload.redirectTo))
    }
    yield put(interfaceActions.setPrimaryColour())
  })
}

export function * pwResetLinkRequest () {
  yield takeEvery(actions.PASSWORD_RESET_LINK_REQUEST, function * ({ payload }) {
    const { response, error } = yield call(
      Api.Auth.sendPasswordResetLink,
      payload.providerCode,
      payload.email
    )
    if (response) {
      yield put(actions.pwResetLinkSuccess(response))
    } else {
      yield put(actions.pwResetLinkError(error))
    }
  })
}

export function * resetPasswordRequest () {
  yield takeEvery(actions.RESET_PASSWORD_REQUEST, function * ({ payload }) {
    const { response, error } = yield call(
      Api.Auth.resetPassword,
      payload.email,
      payload.password,
      payload.code
    )
    if (response) {
      yield put(actions.resetPasswordSuccess(response))
    } else {
      yield put(actions.resetPasswordError(error))
    }
  })
}

export function * checkAuthorization () {
  yield takeEvery(actions.CHECK_AUTHORIZATION, function * () {
    const token = yield getAuthToken()
    if (token) {
      yield put(actions.loginSuccess(token))
    }
  })
}

export function * tokenRefreshRequest () {
  // This function queues up the token refresh requests and runs them sequentially.
  // The first request tries to refresh the token, the subsequent requests wait for
  // success. If the first request is unsuccessful, the subsequent request retries the
  // refreshToken call.

  // 1- Create a channel for token refresh request actions
  const requestChan = yield actionChannel(actions.TOKEN_REFRESH_REQUEST, buffers.expanding())
  while (true) {
    // Get the current refresh count
    const refreshCount = yield select(getRefreshCount)
    // 2- take from the channel
    const { payload } = yield take(requestChan)
    // 3- Note that we're using a blocking call
    yield call(handleTokenRefreshRequest, payload, refreshCount)
  }
}

function * handleTokenRefreshRequest (payload, refreshCount) {
  const refreshToken = yield getRefreshToken()
  const tokenIsValid = yield select(getTokenIsValid)

  if (
    refreshToken &&
    (refreshCount === 0 || (refreshCount > 0 && !tokenIsValid))
  ) {
    const { response, error } = yield call(Api.Auth.refreshToken, refreshToken)
    if (response) {
      yield put(actions.saveAppToken(response))
      yield setAuthToken(response)
      yield setRefreshToken(response)
      yield put(actions.tokenRefreshSuccess())
    } else {
      yield put(actions.tokenRefreshError(error))
    }
  }
}

export function* exchangeSamlTokenRequest() {
  yield takeEvery(
    actions.AUTH_EXCHANGE_SAML_TOKEN_REQUEST,
    function* (action) {
      console.log(action)
      const {response, error} = yield call(
        Api.Auth.exchangeSamlToken,
        action.payload.sessionId,
      )
      console.log(response, error)
      if (response) {
        console.log("RESPONSE", response.token)
        // yield setAuthToken(response.token)
        // yield setRefreshToken(response.token)
        // yield put(userActions.getUser())
        // yield put(userActions.getAuthUserPermissionsRequest())
        // yield put(userActions.getAuthProviderRequest())
        // yield put(userActions.getAuthUserServicesRequest())
        yield put(actions.loginSuccess(response))
        yield put(actions.exchangeSamlTokenSuccess(response))
      } else {
        console.error("ERROR", error)
        yield put(actions.exchangeSamlTokenError(parseError(error)))
      }
    }
  )
}

export default function* rootSaga() {
  yield all([
    fork(checkAuthorization),
    fork(loginRequest),
    fork(loginO365Request),
    fork(loginSuccess),
    fork(loginError),
    fork(logout),
    fork(pwResetLinkRequest),
    fork(resetPasswordRequest),
    fork(tokenRefreshRequest),
    fork(exchangeSamlTokenRequest)
  ])
}
