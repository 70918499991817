import config from './settings'
import { commonGet, commonPost, commonDelete } from './common'

class HealthTracker {
  static getFitbitAuthUrl (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/healthtracker/fitbit/authurl`
    return commonGet(token, url)
  }

  static exchangeFitbitAuthCode (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/healthtracker/fitbit/authcode`
    return commonPost(token, url, data)
  }

  static getFitbitAuthToken (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/healthtracker/fitbit/token`
    return commonGet(token, url)
  }

  static deleteFitbitAuthToken (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/healthtracker/fitbit/token`
    return commonDelete(token, url)
  }

  static getHealthtrackerCurrent (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/healthtracker/current`
    return commonGet(token, url)
  }
}

export default HealthTracker
