import styled from 'styled-components'
import { font, palette } from 'styled-theme'

const BaseWrapperStyle = styled.div`
  font-family: ${font('primary', 0)};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p,
  li,
  input,
  textarea,
  span,
  div,
  img {
    &::selection {
      background: ${palette('primary', 0)};
      color: #fff;
    }
  }
  
  h1 {
    font-size: 2em;
  }

  .positive {
    color: ${palette('success', 0)};
  }

  .negative {
    color: ${palette('error', 0)};
  }

  .eyecon {
    color: ${palette('primary', 0)};
    height: 18px;
    width: 18px;

    &:hover {
      color: ${palette('axela', 8)};
    }

    &.warn {
      color: ${palette('error', 0)};

      &:hover {
        color: ${palette('error', 2)};
      }
    }
  }
`

export default BaseWrapperStyle
