import config from './settings'
import { commonGet } from './common'

export default class Emar {
  static getUnifiedEmar (token, providerId, userId, data) {
    const qs = `statuses=${data.statuses ? data.statuses.join() : ''}` +
      `&timeFrom=${data.timeFrom ? data.timeFrom.toISOString() : ''}` +
      `&timeTo=${data.timeTo ? data.timeTo.toISOString() : ''}` +
      `&title=${data.title ? data.title : ''}` +
      `&careWorkerIds=${data.careWorkerIds ? data.careWorkerIds.join() : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/emar?${qs}`
    return commonGet(token, url)
  }
}
