import crudActions from '../crud/actions'
import crudReducers from '../crud/reducers'
import crudSagas from '../crud/sagas'
import Api from '../../../api'

// ACTIONS
const actions = crudActions('SETTINGS_LISTS_VISITEXCEPTIONTYPES_')

// REDUCERS
const dataPropName = 'model'
const initState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  modalActive: false,
  [dataPropName]: {}
}
const reducers = crudReducers(actions, dataPropName, initState)

// SAGAS
const sagas = function * devSaga () {
  yield crudSagas(
    actions,
    Api.Crud,
    'providers/:providerId/scheduling/exceptionType',
    'id'
  )
}

export { actions, reducers, sagas }
