import config from './settings'
import { commonGet } from './common'

export default class Audit {
  static getRecentUpdatesAllServiceUsers (token, providerId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/audit/recentUpdatesAllServiceUsers`
    return commonGet(token, url)
  }
}
