import {all, fork} from 'redux-saga/effects'
import Api from '../../../api'
import actions from './actions'
import {defineGetSaga, defineGetSagaProvider} from '../../sagasHelper'

export function* getUnifiedVisitsRequest() {
  yield defineGetSagaProvider(actions.GET_SCHEDULING_UNIFIED_VISITS_REQUEST, Api.Scheduling.getUnifiedVisits, actions.getUnifiedVisitsSuccess, actions.getUnifiedVisitsError)
}

export function* setCustomTimeRequest() {
  yield defineGetSaga(actions.GET_SCHEDULING_SET_CUSTOM_TIME_REQUEST, Api.Rostering.setCustomTime, actions.setCustomTimeSuccess, actions.setCustomTimeError)
}

export function* clearCustomTimeRequest() {
  yield defineGetSaga(actions.GET_SCHEDULING_CLEAR_CUSTOM_TIME_REQUEST, Api.Rostering.clearCustomTime, actions.clearCustomTimeSuccess, actions.clearCustomTimeError)
}

export function* setAdjustedActualTimesRequest() {
  yield defineGetSaga(actions.SET_SCHEDULING_SET_ADJUSTED_ACTUAL_TIMES_REQUEST, Api.Rostering.setAdjustedActualTimes, actions.setAdjustedActualTimesSuccess, actions.setAdjustedActualTimesError)
}

export function* assignStaffRequest() {
  yield defineGetSaga(actions.GET_SCHEDULING_ASSIGN_STAFF_REQUEST, Api.Scheduling.assignStaff, actions.assignStaffSuccess, actions.assignStaffError)
}

export function* getVisitOrPreviewRequest() {
  yield defineGetSaga(actions.SCHEDULING_GET_VISIT_OR_PREVIEW_REQUEST, Api.Scheduling.getVisitOrPreview, actions.getVisitOrPreviewSuccess, actions.getVisitOrPreviewError)
}

export function* setExceptionRequest() {
  yield defineGetSaga(actions.SCHEDULING_SET_EXCEPTION_REQUEST, Api.Scheduling.setException, actions.setExceptionSuccess, actions.setExceptionError)
}

export function * clearExceptionRequest () {
  yield defineGetSaga(actions.SCHEDULING_CLEAR_EXCEPTION_REQUEST, Api.Scheduling.clearException, actions.clearExceptionSuccess, actions.clearExceptionError)
}

export function * bulkAssignStaffRequest () {
  yield defineGetSaga(actions.SCHEDULING_BULK_ASSIGN_STAFF_REQUEST, Api.Scheduling.bulkAssignStaff, actions.bulkAssignStaffSuccess, actions.bulkAssignStaffError)
}

export function * bulkAssignPreferredStaffRequest () {
  yield defineGetSaga(actions.SCHEDULING_BULK_ASSIGN_PREFERRED_STAFF_REQUEST, Api.Scheduling.bulkAssignPreferredStaff, actions.bulkAssignPreferredStaffSuccess, actions.bulkAssignPreferredStaffError)
}

export function * bulkSmartAllocationRequest () {
  yield defineGetSaga(actions.SCHEDULING_BULK_SMART_ALLOCATION_REQUEST, Api.Scheduling.bulkSmartAllocation, actions.bulkSmartAllocationSuccess, actions.bulkSmartAllocationError)
}

export function * bulkRemoveStaffRequest () {
  yield defineGetSaga(actions.SCHEDULING_BULK_REMOVE_STAFF_REQUEST, Api.Scheduling.bulkRemoveStaff, actions.bulkRemoveStaffSuccess, actions.bulkRemoveStaffError)
}

export function * downloadCsvRequest () {
  // NOTE WE MAY NEED TO HANDLE TypeError as success
  //         if (error instanceof TypeError) {
  yield defineGetSagaProvider(actions.SCHEDULING_DOWNLOAD_CSV_REQUEST, Api.Scheduling.downloadCsv, actions.downloadCsvSuccess, actions.downloadCsvError)
}

export default function * rootSaga () {
  yield all([
    fork(getUnifiedVisitsRequest),
    fork(assignStaffRequest),
    fork(getVisitOrPreviewRequest),
    fork(setExceptionRequest),
    fork(clearExceptionRequest),
    fork(bulkAssignStaffRequest),
    fork(bulkAssignPreferredStaffRequest),
    fork(bulkRemoveStaffRequest),
    fork(bulkSmartAllocationRequest),
    fork(downloadCsvRequest),
    fork(setCustomTimeRequest),
    fork(clearCustomTimeRequest),
    fork(setAdjustedActualTimesRequest)
  ])
}
