import { defineGetActionsPrSu } from '../../../actionsHelper'

const actions = {
}

defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_SUMMARY', 'getEmisSummary')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_PROBLEM', 'getEmisProblem')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_DIAGNOSIS', 'getEmisDiagnosis')

// Summary
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_SUMMARY', 'getEmisSummary')

// Problems
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_PROBLEM', 'getEmisProblem')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_PROBLEMCURRENT', 'getEmisProblemCurrent')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_PROBLEMPAST', 'getEmisProblemPast')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_STANDARDPROBLEM', 'getEmisStandardProblem')

// Diagnosis
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_DIAGNOSIS', 'getEmisDiagnosis')

// Medications
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_MEDICATION', 'getEmisMedication')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_MEDICATIONCURRENT', 'getEmisMedicationCurrent')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_MEDICATIONPAST', 'getEmisMedicationPast')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_MEDICATIONISSUE', 'getEmisMedicationIssue')

// Risks and Warnings
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_RISKSWARNING', 'getEmisRisksWarning')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_ALLERGY', 'getEmisAllergy')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_CONTRAINDICATION', 'getEmisContraIndication')

// Procedures
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_PROCEDURE', 'getEmisProcedure')

// Examinations
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_EXAMINATION', 'getEmisExamination')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_OPERATION', 'getEmisOperation')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_IMMUNISATION', 'getEmisImmunisation')

// Investigations
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_INVESTIGATION', 'getEmisInvestigation')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_BIOCHEMISTRY', 'getEmisBiochemistry')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_ECG', 'getEmisECG')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_HAEMATOLOGY', 'getEmisHaematology')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_IMAGING', 'getEmisImaging')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_MICROBIOLOGY', 'getEmisMicrobiology')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_CYTOLOGY', 'getEmisCytology')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_OTHERINVESTIGATION', 'getEmisOtherInvestigation')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_PHYSIOLOGY', 'getEmisPhysiology')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_URINALYSIS', 'getEmisUrinalysis')

// Events
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_EVENT', 'getEmisEvent')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_ENCOUNTERLIST', 'getEmisEncounterList')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_ENCOUNTERDETAIL', 'getEmisEncounterDetail')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_REFERRAL', 'getEmisReferral')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_ADMISSION', 'getEmisAdmission')

// PatientDetails
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_PATIENTDETAIL', 'getEmisPatientDetail')
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_FULLMEDICALRECORD', 'getEmisFullMedicalRecord')

// Audit History
defineGetActionsPrSu(actions, 'GET_SERVICEUSER_EMIS_AUDIT_HISTORY', 'getAuditHistory')

export default actions
