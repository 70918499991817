const actions = {
  GET_USER_REQUEST: 'GET_USER_REQUEST',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
  GET_USER_ERROR: 'GET_USER_ERROR',
  GET_AUTHPROVIDER_REQUEST: 'GET_AUTHPROVIDER_REQUEST',
  GET_AUTHPROVIDER_SUCCESS: 'GET_AUTHPROVIDER_SUCCESS',
  GET_AUTHPROVIDER_ERROR: 'GET_AUTHPROVIDER_ERROR',
  CONFIGURE_SIDEBAR: 'CONFIGURE_SIDEBAR',
  getUser: () => {
    return {
      type: actions.GET_USER_REQUEST
    }
  },
  getUserSuccess: result => {
    return {
      type: actions.GET_USER_SUCCESS,
      user: result
    }
  },
  getUserError: error => ({
    type: actions.GET_USER_ERROR,
    error
  }),
  getAuthProviderRequest: () => {
    return {
      type: actions.GET_AUTHPROVIDER_REQUEST
    }
  },
  getAuthProviderSuccess: result => {
    return {
      type: actions.GET_AUTHPROVIDER_SUCCESS,
      provider: result
    }
  },
  getAuthProviderError: error => ({
    type: actions.GET_AUTHPROVIDER_ERROR,
    error
  }),

  GET_AUTHUSER_SERVICES_REQUEST: 'GET_AUTHUSER_SERVICES_REQUEST',
  GET_AUTHUSER_SERVICES_SUCCESS: 'GET_AUTHUSER_SERVICES_SUCCESS',
  GET_AUTHUSER_SERVICES_ERROR: 'GET_AUTHUSER_SERVICES_ERROR',
  getAuthUserServicesRequest: () => {
    return {
      type: actions.GET_AUTHUSER_SERVICES_REQUEST
    }
  },
  getAuthUserServicesSuccess: result => {
    return {
      type: actions.GET_AUTHUSER_SERVICES_SUCCESS,
      payload: result
    }
  },
  getAuthUserServicesError: error => ({
    type: actions.GET_AUTHUSER_SERVICES_ERROR,
    error
  }),

  GET_AUTHUSER_PERMISSIONS_REQUEST: 'GET_AUTHUSER_PERMISSIONS_REQUEST',
  GET_AUTHUSER_PERMISSIONS_SUCCESS: 'GET_AUTHUSER_PERMISSIONS_SUCCESS',
  GET_AUTHUSER_PERMISSIONS_ERROR: 'GET_AUTHUSER_PERMISSIONS_ERROR',
  getAuthUserPermissionsRequest: () => {
    return {
      type: actions.GET_AUTHUSER_PERMISSIONS_REQUEST
    }
  },
  getAuthUserPermissionsSuccess: result => {
    return {
      type: actions.GET_AUTHUSER_PERMISSIONS_SUCCESS,
      payload: result
    }
  },
  getAuthUserPermissionsError: error => ({
    type: actions.GET_AUTHUSER_PERMISSIONS_ERROR,
    error
  }),

  configureSidebar: () => {
    return {
      type: actions.CONFIGURE_SIDEBAR
    }
  }
}

export default actions
