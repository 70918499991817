import config from './settings'
import { commonGet, commonPut } from './common'

export default class ServicesLocations {
  static getServicesByUserFiltered (token, providerId, data) {
    const qs = `userIds=${data.userIds ? data.userIds.join() : ''}` +
      `&roles=${data.roles ? data.roles.join() : ''}` +
      `&serviceIds=${data.serviceIds ? data.serviceIds.join() : ''}` +
      `&locationIds=${data.locationIds ? data.locationIds.join() : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/servicelocationusers/filtered?${qs}`
    return commonGet(token, url)
  }

  static getServicesForMultipleUsers (token, providerId, data) {
    const qs = `userIds=${data.userIds ? data.userIds.join() : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/servicelocationusers?${qs}`
    return commonGet(token, url)
  }

  static updateServicesForMultipleUsers (token, providerId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/servicelocationusers`
    return commonPut(token, url, data)
  }

  static getServiceLocationsForSingleUser (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/services`
    return commonGet(token, url)
  }

  static getCareWorkersForSingleUser (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/services/careworkers`
    return commonGet(token, url)
  }
}
