import actions from './actions'
import crudReducers from '../../crud/reducers'

const dataPropName = 'attorney'

const initState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  modalActive: false,
  attorney: {}
}

const reducers = crudReducers(actions, dataPropName, initState)

export default reducers
