import { all, fork } from 'redux-saga/effects'
import Api from '../../../api'
import actions from './actions'
import { defineGetSagaProvider } from '../../sagasHelper'

export function * getUnifiedAvailabilityRequest () {
  yield defineGetSagaProvider(actions.GET_UNIFIED_AVAILABILITY_REQUEST, Api.Availability.getUnifiedAvailability, actions.getUnifiedAvailabilitySuccess, actions.getUnifiedAvailabilityError)
}

export default function * rootSaga () {
  yield all([
    fork(getUnifiedAvailabilityRequest)
  ])
}
