import {createBrowserHistory} from 'history'
import {applyMiddleware, compose, createStore} from 'redux'
import {routerMiddleware} from 'connected-react-router'
import createRootReducer from './reducers'
import createSagaMiddleware from 'redux-saga'
import rootSaga from './sagas'
import authActions from './app/auth/actions'
import suActions from './app/serviceuser/actions'
import hrActions from './app/hr/actions'

export const history = createBrowserHistory()

const initState = {
  ServiceUser: {}
}

const resetEnhancer = rootReducer => (state, action) => {
  if (action.type === suActions.SET_SERVICE_USER_ID) {
    console.log('Clearing service user state', action.payload)
    return rootReducer({
      ...state,
      ServiceUser: {Base: {userId: action.payload}},
      CustomForms: {}
    }, action)
  }
  if (action.type === hrActions.SET_HR_USER_ID) {
    console.log('Clearing hr user state', action.payload)
    return rootReducer({
      ...state,
      HR: {Base: {userId: action.payload}},
      CustomForms: {}
    }, action)
  }

  if (action.type !== authActions.LOGOUT) {
    return rootReducer(state, action)
  }

  console.log('Logout: Clearing store')
  const newState = rootReducer(undefined, {})
  newState.router = state.router
  return newState
}

export default function configureStore() {
  const sagaMiddleware = createSagaMiddleware()
  const store = createStore(
    resetEnhancer(createRootReducer(history)), // root reducer with router state
    initState,
    compose(
      applyMiddleware(
        routerMiddleware(history), // for dispatching history actions
        sagaMiddleware
      )
      // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
  )
  // console.log(store.getState())
  sagaMiddleware.run(rootSaga)
  return store
}
