import config from './settings'
import { commonGet } from './common'

export default class Dmd {
  static getDmdAmppList (token, providerId, searchTerm) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/dmd?searchTerm=${searchTerm}`
    return commonGet(token, url)
  }

  static getDmdDoseForms (token, providerId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/dmd/doseForms`
    return commonGet(token, url)
  }

  static getDmdDrugRoutes (token, providerId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/dmd/drugRoutes`
    return commonGet(token, url)
  }

  static getDmdControlledDrugCategories (token, providerId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/dmd/controlledDrugCategories`
    return commonGet(token, url)
  }
}
