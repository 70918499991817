import config from './settings'
import { commonGet, commonPut, commonGetBlob } from './common'

class CarePlan {
  static getCarePlan (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careplan`
    return commonGet(token, url)
  }

  static setCarePreferences (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careplan/preferences`
    return commonPut(token, url, data)
  }

  static setNextReview (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careplan/nextreview`
    return commonPut(token, url, data)
  }

  static getVisitSupportTasks (token, providerId, userId, visitId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careplan/visits/${visitId}/tasks/support`
    return commonGet(token, url)
  }

  static setVisitSupportTasks (token, providerId, userId, visitId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careplan/visits/${visitId}/tasks/support`
    return commonPut(token, url, data)
  }

  static downloadFile (token, providerId, userId, filename) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careplan/files/${filename}`
    return commonGetBlob(token, url)
  }

  static getPdf (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careplan/pdf`
    return commonGetBlob(token, url)
  }

  static getAuditHistory (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careplan/auditHistory`
    return commonGet(token, url)
  }
}
export default CarePlan
