import actions from './actions'
import crudReducers from '../crud/reducers'
import {initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess} from '../../reducersHelper'

const dataPropName = 'provideruser'

const initState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  modalActive: false,
  parent: {
    providerId: null,
    name: '',
    code: ''
  },
  [dataPropName]: {
    userId: null,
    firstName: '',
    lastName: '',
    email: '',
    username: ''
  },
  userSearch: initialDataState,
  avatar: initialDataState,
  allAvatars: initialDataState,
  userPassword: initialDataState,

}

const crud = crudReducers(actions, dataPropName, initState)

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAR_USER_AVATAR:
      return {
        ...state,
        avatar: initialDataState
      }
    case actions.GET_USER_AVATAR_REQUEST:
      return reduceDataRequest(state, 'avatar')
    case actions.GET_USER_AVATAR_SUCCESS:
      return reduceDataSuccess(state, 'avatar', action.payload)
    case actions.GET_USER_AVATAR_ERROR:
      return reduceDataError(state, 'avatar', action.error)
    case actions.UPDATE_USER_AVATAR_REQUEST:
      return reduceDataRequest(state, 'avatar')
    case actions.UPDATE_USER_AVATAR_SUCCESS:
      return reduceDataSuccess(state, 'avatar', action.payload)
    case actions.UPDATE_USER_AVATAR_ERROR:
      return reduceDataError(state, 'avatar', action.error)
    case actions.DELETE_USER_AVATAR_REQUEST:
      return reduceDataRequest(state, 'avatar')
    case actions.DELETE_USER_AVATAR_SUCCESS:
      return reduceDataSuccess(state, 'avatar', null)
    case actions.DELETE_USER_AVATAR_ERROR:
      return reduceDataSuccess(state, 'avatar', action.error)
    case actions.GET_ALL_USER_AVATARS_REQUEST:
      return reduceDataRequest(state, 'allAvatars')
    case actions.GET_ALL_USER_AVATARS_SUCCESS:
      return reduceDataSuccess(state, 'allAvatars', action.payload)
    case actions.GET_ALL_USER_AVATARS_ERROR:
      return reduceDataError(state, 'allAvatars', action.error)
    case actions.SEARCH_PROVIDERUSERS_REQUEST:
      return reduceDataRequest(state, 'userSearch')
    case actions.SEARCH_PROVIDERUSERS_SUCCESS:
      return reduceDataSuccess(state, 'userSearch', action.payload)
    case actions.SEARCH_PROVIDERUSERS_ERROR:
      return reduceDataError(state, 'userSearch', action.error)
    // case actions.GET_USER_PASSWORD_RESET_LINK_REQUEST:
    //   return reduceDataRequest(state, 'passwordResetLink')
    // case actions.GET_USER_PASSWORD_RESET_LINK_SUCCESS:
    //   return reduceDataRequest(state, 'passwordResetLink')
    // case actions.GET_USER_PASSWORD_RESET_LINK_ERROR:
    //   return reduceDataRequest(state, 'passwordResetLink')

    case actions.UPDATE_USER_PASSWORD_REQUEST:
      return reduceDataRequest(state, 'userPassword')
    case actions.UPDATE_USER_PASSWORD_SUCCESS:
      return reduceDataSuccess(state, 'userPassword', action.payload)
    case actions.UPDATE_USER_PASSWORD_ERROR:
      return reduceDataError(state, 'userPassword', action.error)

    default:
      return crud(state, action)
  }
}

export default reducers
