import actions from './actions'
import crudReducers from '../crud/reducers'

const dataPropName = 'newuser'

const initState = {
  list: {},
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  modalActive: false,
  parent: {
    providerId: null,
    name: '',
    code: ''
  },
  newuser: {
    email: null,
    firstName: '',
    lastName: '',
    username: '',
    role: ''
  }
}

const crud = crudReducers(actions, dataPropName, initState)

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.ACTIVATE_REQUEST:
      return {
        ...state
      }
    case actions.ACTIVATE_SUCCESS:
      return {
        ...state
      }
    case actions.ACTIVATE_ERROR:
      return {
        ...state
      }
    case actions.CREATE_SERVICEUSER_REQUEST:
      return {
        ...state,
        updating: true,
        error: false
      }
    case actions.CREATE_SERVICEUSER_SUCCESS:
      return {
        ...state,
        updating: false,
        error: false
      }
    case actions.CREATE_SERVICEUSER_ERROR:
      return {
        ...state,
        updating: false,
        error: action.error,
        formError: action.payload.error
      }
    default:
      return crud(state, action)
  }
}

export default reducers
