import { race, take, put, takeEvery, select } from 'redux-saga/effects'
import authActions from './actions'
import serviceuserActions from '../serviceuser/serviceusers/actions'
import errorActions from '../errors/actions'
import { parseError } from '../../../helpers/errors'

// Some of the technique used here was from: https://blog.bitsrc.io/complex-app-logic-with-redux-and-redux-saga-write-an-authentication-monitor-2f5672303d7d
const getRefreshCount = state => state.Auth.tokenRefreshCount

// Do not monitor these actions
const ignoreActionTypes = [
  authActions.LOGIN_REQUEST,
  authActions.TOKEN_REFRESH_REQUEST,
  authActions.PASSWORD_RESET_LINK_REQUEST,
  authActions.RESET_PASSWORD_REQUEST,
  serviceuserActions.GET_SERVICE_USER_AVATAR_REQUEST
]

// Every interaction with the server is implemented as a three action process consisting of these states: REQUEST, SUCCESS, ERROR
function monitorableAction (action) {
  return (
    action.type.includes('REQUEST') &&
    ignoreActionTypes.every(fragment => !action.type.includes(fragment))
  )
}
function identifyAction (action) {
  return action.type
    .split('_')
    .slice(0, -1)
    .join('_')
}
function getSuccessType (action) {
  return `${identifyAction(action)}_SUCCESS`
}
function getFailType (action) {
  return `${identifyAction(action)}_ERROR`
}

// For every monitorableAction, check to see if there was an "Unauthorized" error response.
// If so, refresh the token, and re-run the action.
function * monitor (monitoredAction) {
  const { success, fail } = yield race({
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction))
  })

  if (success) {
    yield put(authActions.tokenRefreshReset())
  } else if (fail && fail.error) {
    // The error message will be a serialized error
    const error = parseError(fail.error)

    if (undefined === error || undefined === error.status) {
      // This is likely due to failing to contact api server
      yield put(
        errorActions.showError({
          code: 0,
          status: 0,
          message: 'Could not reach server'
        })
      )
      return
    }

    let refreshCount
    let response

    switch (error.status) {
      case -1: // Locally generated error
        if (error.code) {
          yield put(errorActions.showError(error))
        }
        break

      case 400: // APIError
        if (error.code) {
          // Put the APIError to the state, so that it can be shown throughout the app.
          yield put(errorActions.showError(error))
        }
        break

      case 401: // Unauthorized
        refreshCount = yield select(getRefreshCount)
        console.log('refreshCount', refreshCount)

        if (refreshCount >= 30) {
          yield put(authActions.tokenRefreshReset())
          yield put(authActions.logout())
          break
        }

        // UnknownUser - this should typically only be encountered during development
        if (error.code === 20001) {
          yield put(authActions.logout())
          break
        }

        yield put(authActions.tokenRefreshRequest())

         response = yield race({
          success: take(authActions.tokenRefreshSuccess().type),
          fail: take(authActions.tokenRefreshError().type)
        })

        if (response.success) {
          yield put(monitoredAction)
        } else {
          yield put(authActions.logout())
        }

        break
      default:
    }
  }
}

export default function * () {
  yield takeEvery(monitorableAction, monitor)
}
