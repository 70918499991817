import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../reducersHelper'

const initState = {
  user: null,
  provider: null,
  loading: false,
  error: false,
  services: initialDataState,
  permissions: initialDataState
}

export default function reducer (state = initState, action) {
  switch (action.type) {
    case actions.GET_USER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        user: action.user
      }
    case actions.GET_USER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }
    case actions.GET_AUTHPROVIDER_REQUEST:
      return {
        ...state,
        loading: true
      }
    case actions.GET_AUTHPROVIDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        provider: action.provider
      }
    case actions.GET_AUTHPROVIDER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error
      }

    case actions.GET_AUTHUSER_PERMISSIONS_REQUEST:
      return reduceDataRequest(state, 'permissions')
    case actions.GET_AUTHUSER_PERMISSIONS_SUCCESS:
      return reduceDataSuccess(state, 'permissions', action.payload)
    case actions.GET_AUTHUSER_PERMISSIONS_ERROR:
      return reduceDataError(state, 'permissions', action.error)

    case actions.GET_AUTHUSER_SERVICES_REQUEST:
      return reduceDataRequest(state, 'services')
    case actions.GET_AUTHUSER_SERVICES_SUCCESS:
      return reduceDataSuccess(state, 'services', action.payload)
    case actions.GET_AUTHUSER_SERVICES_ERROR:
      return reduceDataError(state, 'services', action.error)

    default:
      return state
  }
}
