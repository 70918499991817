import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
import actions from '../supportTaskTitle/actions'
import { getAuthToken } from '../../../../helpers/tokens'
import Api from '../../../../api'

export function * searchTitleRequest () {
  yield takeEvery(actions.SEARCH_TITLE_REQUEST, function * ({ payload }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.SupportTaskTitle.getTitleList,
        authToken.token,
        payload.providerId,
        payload.searchTerm
      )
      if (response) {
        yield put(actions.searchTitleSuccess(response))
      } else {
        yield put(actions.searchTitleError(error))
      }
    }
  })
}

export default function * devSaga () {
  yield all([
    fork(searchTitleRequest)
  ])
}
