import { all, fork } from 'redux-saga/effects'
import Api from '../../../api'
import actions from './actions'
import { defineGetSagaProvider } from '../../sagasHelper'

export function * getTimeOfDayDataRequest () {
  yield defineGetSagaProvider(actions.GET_UTILITY_TIMEOFDAYDATA_REQUEST, Api.Utility.getTimeOfDayData, actions.getTimeOfDayDataSuccess, actions.getTimeOfDayDataError)
}

export default function * rootSaga () {
  yield all([
    fork(getTimeOfDayDataRequest)
  ])
}
