import styled from 'styled-components'
import { palette } from 'styled-theme'

const DatePickerWrapper = ComponentName => styled(ComponentName)`
  &:hover {
    border-color: ${palette('primary', 0)};
  }

  &.ant-calendar-picker {

    width: 100%;

    .ant-calendar-picker-input.ant-input {
      height: 48px;
      
      &.ant-input-disabled {
        input {
          color: #aaa;
        }
      }
    }
    
    &:hover {
      .ant-calendar-picker-input:not(.ant-input-disabled) {
        border: 1px solid ${palette('primary', 0)};
      }
    }

    .ant-calendar-range-picker-separator {
      line-height: 48px;
      color: ${palette('primary', 0)};
    }

    svg {
      color: ${palette('primary', 0)};
    }
    
    input {
      font-size: 13px;
      height: 40px;
      line-height: 40px;
      font-weight: bold;
      color: ${palette('axela', 2)};
    }

    &.incomplete {
      background-color: ${palette('error', 3)};
      font-weight: normal;
      color: inherit;

      input {
        background-color: inherit;
        color: inherit;
        font-weight: normal;

        &:hover {
          border: 1px solid ${palette('primary', 0)};
        }

        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: inherit;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: inherit;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: inherit;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: inherit;
        }
      }
    }
  }
`

export { DatePickerWrapper }
