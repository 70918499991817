import { all, fork } from 'redux-saga/effects'
import Api from '../../../api'
import actions from './actions'
import { defineGetSagaProvider } from '../../sagasHelper'

export function * getAlertTypesRequest () {
  yield defineGetSagaProvider(actions.GET_ALERT_TYPES_REQUEST, Api.Alerts.getAlertTypes, actions.getAlertTypesSuccess, actions.getAlertTypesError)
}

export function * getProviderAlertsRequest () {
  yield defineGetSagaProvider(actions.GET_PROVIDER_ALERTS_REQUEST, Api.Alerts.getProviderAlerts, actions.getProviderAlertsSuccess, actions.getProviderAlertsError)
}

export function * getProviderAlertsFilteredRequest () {
  yield defineGetSagaProvider(actions.GET_PROVIDER_ALERTS_FILTERED_REQUEST, Api.Alerts.getProviderAlertsFiltered, actions.getProviderAlertsFilteredSuccess, actions.getProviderAlertsFilteredError)
}

export function * acknowledgeAlertRequest () {
  yield defineGetSagaProvider(actions.ACKNOWLEDGE_ALERT_REQUEST, Api.Alerts.acknowledgeAlert, actions.acknowledgeAlertSuccess, actions.acknowledgeAlertError)
}

export default function * rootSaga () {
  yield all([
    fork(getAlertTypesRequest),
    fork(getProviderAlertsRequest),
    fork(acknowledgeAlertRequest),
    fork(getProviderAlertsFilteredRequest)
  ])
}
