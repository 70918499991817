import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../../reducersHelper'

const initState = {
  personalDetails: initialDataState,
  privateDetails: initialDataState,
  appearance: initialDataState,
  address: initialDataState,
  addressGeoFencing: initialDataState,
  accessDetails: initialDataState,
  advanceCarePlan: initialDataState,
  bmi: initialDataState,
  legalChoices: initialDataState,
  careWorkersInLocations: initialDataState,
  serviceInformation: initialDataState,
  downloadServiceUserServiceInformationPdf: initialDataState,
  serviceLocations: initialDataState,
  downloadPDF: initialDataState,
}

const reducers = (state = initState, action) => {
  switch (action.type) {

    case actions.GET_SERVICE_USER_REQUEST:
      return reduceDataRequest(state, 'personalDetails')
    case actions.GET_SERVICE_USER_SUCCESS:
      return reduceDataSuccess(state, 'personalDetails', action.payload)
    case actions.GET_SERVICE_USER_ERROR:
      return reduceDataError(state, 'personalDetails', action.error)
    case actions.UPDATE_SERVICE_USER_REQUEST:
      return reduceDataRequest(state, 'personalDetails')
    case actions.UPDATE_SERVICE_USER_SUCCESS:
      return reduceDataSuccess(state, 'personalDetails', action.payload)
    case actions.UPDATE_SERVICE_USER_ERROR:
      return reduceDataError(state, 'personalDetails', action.error)

    case actions.GET_SERVICE_USER_PRIVATE_REQUEST:
      return reduceDataRequest(state, 'privateDetails')
    case actions.GET_SERVICE_USER_PRIVATE_SUCCESS:
      return reduceDataSuccess(state, 'privateDetails', action.payload)
    case actions.GET_SERVICE_USER_PRIVATE_ERROR:
      return reduceDataError(state, 'privateDetails', action.error)
    case actions.UPDATE_SERVICE_USER_PRIVATE_REQUEST:
      return reduceDataRequest(state, 'privateDetails')
    case actions.UPDATE_SERVICE_USER_PRIVATE_SUCCESS:
      return reduceDataSuccess(state, 'privateDetails', action.payload)
    case actions.UPDATE_SERVICE_USER_PRIVATE_ERROR:
      return reduceDataError(state, 'privateDetails', action.error)

    case actions.GET_SERVICE_USER_ACCESS_DETAILS_REQUEST:
      return reduceDataRequest(state, 'accessDetails')
    case actions.GET_SERVICE_USER_ACCESS_DETAILS_SUCCESS:
      return reduceDataSuccess(state, 'accessDetails', action.payload)
    case actions.GET_SERVICE_USER_ACCESS_DETAILS_ERROR:
      return reduceDataError(state, 'accessDetails', action.error)
    case actions.UPDATE_SERVICE_USER_ACCESS_DETAILS_REQUEST:
      return reduceDataRequest(state, 'accessDetails')
    case actions.UPDATE_SERVICE_USER_ACCESS_DETAILS_SUCCESS:
      return reduceDataSuccess(state, 'accessDetails', action.payload)
    case actions.UPDATE_SERVICE_USER_ACCESS_DETAILS_ERROR:
      return reduceDataError(state, 'accessDetails', action.error)

    case actions.GET_SERVICE_USER_ADVANCE_CARE_PLAN_REQUEST:
      return reduceDataRequest(state, 'advanceCarePlan')
    case actions.GET_SERVICE_USER_ADVANCE_CARE_PLAN_SUCCESS:
      return reduceDataSuccess(state, 'advanceCarePlan', action.payload)
    case actions.GET_SERVICE_USER_ADVANCE_CARE_PLAN_ERROR:
      return reduceDataError(state, 'advanceCarePlan', action.error)
    case actions.UPDATE_SERVICE_USER_ADVANCE_CARE_PLAN_REQUEST:
      return reduceDataRequest(state, 'advanceCarePlan')
    case actions.UPDATE_SERVICE_USER_ADVANCE_CARE_PLAN_SUCCESS:
      return reduceDataSuccess(state, 'advanceCarePlan', action.payload)
    case actions.UPDATE_SERVICE_USER_ADVANCE_CARE_PLAN_ERROR:
      return reduceDataError(state, 'advanceCarePlan', action.error)

    case actions.GET_SERVICE_USER_ADDRESS_REQUEST:
      return reduceDataRequest(state, 'address')
    case actions.GET_SERVICE_USER_ADDRESS_SUCCESS:
      return reduceDataSuccess(state, 'address', action.payload)
    case actions.GET_SERVICE_USER_ADDRESS_ERROR:
      return reduceDataError(state, 'address', action.error)
    case actions.UPDATE_SERVICE_USER_ADDRESS_REQUEST:
      return reduceDataRequest(state, 'address')
    case actions.UPDATE_SERVICE_USER_ADDRESS_SUCCESS:
      return reduceDataSuccess(state, 'address', action.payload)
    case actions.UPDATE_SERVICE_USER_ADDRESS_ERROR:
      return reduceDataError(state, 'address', action.error)

    case actions.GET_SERVICE_USER_ADDRESS_GEO_FENCING_REQUEST:
      return reduceDataRequest(state, 'addressGeoFencing')
    case actions.GET_SERVICE_USER_ADDRESS_GEO_FENCING_SUCCESS:
      return reduceDataSuccess(state, 'addressGeoFencing', action.payload)
    case actions.GET_SERVICE_USER_ADDRESS_GEO_FENCING_ERROR:
      return reduceDataError(state, 'addressGeoFencing', action.error)
    case actions.UPDATE_SERVICE_USER_ADDRESS_GEO_FENCING_REQUEST:
      return reduceDataRequest(state, 'addressGeoFencing')
    case actions.UPDATE_SERVICE_USER_ADDRESS_GEO_FENCING_SUCCESS:
      return reduceDataSuccess(state, 'addressGeoFencing', action.payload)
    case actions.UPDATE_SERVICE_USER_ADDRESS_GEO_FENCING_ERROR:
      return reduceDataError(state, 'addressGeoFencing', action.error)

    case actions.GET_SERVICE_USER_APPEARANCE_REQUEST:
      return reduceDataRequest(state, 'appearance')
    case actions.GET_SERVICE_USER_APPEARANCE_SUCCESS:
      return reduceDataSuccess(state, 'appearance', action.payload)
    case actions.GET_SERVICE_USER_APPEARANCE_ERROR:
      return reduceDataError(state, 'appearance', action.error)
    case actions.UPDATE_SERVICE_USER_APPEARANCE_REQUEST:
      return reduceDataRequest(state, 'appearance')
    case actions.UPDATE_SERVICE_USER_APPEARANCE_SUCCESS:
      return reduceDataSuccess(state, 'appearance', action.payload)
    case actions.UPDATE_SERVICE_USER_APPEARANCE_ERROR:
      return reduceDataError(state, 'appearance', action.error)

    case actions.GET_SERVICE_USER_BMI_REQUEST:
      return reduceDataRequest(state, 'bmi')
    case actions.GET_SERVICE_USER_BMI_SUCCESS:
      return reduceDataSuccess(state, 'bmi', action.payload)
    case actions.GET_SERVICE_USER_BMI_ERROR:
      return reduceDataError(state, 'bmi', action.error)

    case actions.CLEAR_SERVICE_USER_FORM_ERROR:
      return {
        ...state,
        formError: false
      }

    case actions.GET_SERVICE_USER_LEGALCHOICES_REQUEST:
      return reduceDataRequest(state, 'legalChoices')
    case actions.GET_SERVICE_USER_LEGALCHOICES_SUCCESS:
      return reduceDataSuccess(state, 'legalChoices', action.payload)
    case actions.GET_SERVICE_USER_LEGALCHOICES_ERROR:
      return reduceDataError(state, 'legalChoices', action.error)
    case actions.UPDATE_SERVICE_USER_LEGALCHOICES_REQUEST:
      return reduceDataRequest(state, 'legalChoices')
    case actions.UPDATE_SERVICE_USER_LEGALCHOICES_SUCCESS:
      return reduceDataSuccess(state, 'legalChoices', action.payload)
    case actions.UPDATE_SERVICE_USER_LEGALCHOICES_ERROR:
      return reduceDataError(state, 'legalChoices', action.error)

    case actions.GET_SERVICE_USER_SERVICE_INFORMATION_REQUEST:
      return reduceDataRequest(state, 'serviceInformation')
    case actions.GET_SERVICE_USER_SERVICE_INFORMATION_SUCCESS:
      return reduceDataSuccess(state, 'serviceInformation', action.payload)
    case actions.GET_SERVICE_USER_SERVICE_INFORMATION_ERROR:
      return reduceDataError(state, 'serviceInformation', action.error)
    case actions.UPDATE_SERVICE_USER_SERVICE_INFORMATION_REQUEST:
      return reduceDataRequest(state, 'serviceInformation')
    case actions.UPDATE_SERVICE_USER_SERVICE_INFORMATION_SUCCESS:
      return reduceDataSuccess(state, 'serviceInformation', action.payload)
    case actions.UPDATE_SERVICE_USER_SERVICE_INFORMATION_ERROR:
      return reduceDataError(state, 'serviceInformation', action.error)

    case actions.DOWNLOAD_SERVICE_USER_SERVICE_INFORMATION_PDF_REQUEST:
      return reduceDataRequest(state, 'downloadServiceUserServiceInformationPdf')
    case actions.DOWNLOAD_SERVICE_USER_SERVICE_INFORMATION_PDF_SUCCESS:
      return reduceDataSuccess(state, 'downloadServiceUserServiceInformationPdf', action.payload)
    case actions.DOWNLOAD_SERVICE_USER_SERVICE_INFORMATION_PDF_ERROR:
      return reduceDataError(state, 'downloadServiceUserServiceInformationPdf', action.error)

    case actions.GET_SERVICE_USER_CAREWORKERS_IN_LOCATION_REQUEST:
      return reduceDataRequest(state, 'careWorkersInLocations')
    case actions.GET_SERVICE_USER_CAREWORKERS_IN_LOCATION_SUCCESS:
      return reduceDataSuccess(state, 'careWorkersInLocations', action.payload)
    case actions.GET_SERVICE_USER_CAREWORKERS_IN_LOCATION_ERROR:
      return reduceDataError(state, 'careWorkersInLocations', action.error)

    case actions.GET_SERVICE_USER_SERVICELOCATIONS_REQUEST:
      return reduceDataRequest(state, 'serviceLocations')
    case actions.GET_SERVICE_USER_SERVICELOCATIONS_SUCCESS:
      return reduceDataSuccess(state, 'serviceLocations', action.payload)
    case actions.GET_SERVICE_USER_SERVICELOCATIONS_ERROR:
      return reduceDataError(state, 'serviceLocations', action.error)

    case actions.GET_SERVICE_USER_DOWNLOAD_PDF_REQUEST:
      return reduceDataRequest(state, 'downloadPDF')
    case actions.GET_SERVICE_USER_DOWNLOAD_PDF_SUCCESS:
      return reduceDataSuccess(state, 'downloadPDF', action.payload)
    case actions.GET_SERVICE_USER_DOWNLOAD_PDF_ERROR:
      return reduceDataError(state, 'downloadPDF', action.error)


    default:
      return state
  }
}

export default reducers
