import { all, fork } from 'redux-saga/effects'
import Api from '../../../../api'
import actions from './actions'
import { defineGetSaga, defineUpdateSaga } from '../../../sagasHelper'

export function * getCarePlanRequest () {
  yield defineGetSaga(actions.GET_CAREPLAN_REQUEST, Api.CarePlan.getCarePlan, actions.getCarePlanSuccess, actions.getCarePlanError)
}

export function * getCarePlanPdfRequest () {
  // NOTE WE MAY NEED TO HANDLE TypeError as success
  //         if (error instanceof TypeError) {
  yield defineGetSaga(actions.GET_CAREPLAN_PDF_REQUEST, Api.CarePlan.getPdf, actions.getPdfSuccess, actions.getPdfError)
}

export function * setCarePreferencesRequest () {
  yield defineUpdateSaga(actions.SET_CAREPLAN_CARE_PREFERENCES_REQUEST, Api.CarePlan.setCarePreferences, actions.setCarePlanCarePreferencesSuccess, actions.setCarePlanCarePreferencesError)
}

export function * setNextReviewRequest () {
  yield defineUpdateSaga(actions.SET_CAREPLAN_NEXT_REVIEW_REQUEST, Api.CarePlan.setNextReview, actions.setCarePlanNextReviewSuccess, actions.setCarePlanNextReviewError)
}

export function * getCarePlanAuditHistoryRequest () {
  yield defineGetSaga(actions.GET_CAREPLAN_AUDIT_HISTORY_REQUEST, Api.CarePlan.getAuditHistory, actions.getAuditHistorySuccess, actions.getAuditHistoryError)
}

// const ignoreActionTypes = []
//
// Every interaction with the server is implemented as a three action process consisting of these states: REQUEST, SUCCESS, ERROR
// function monitorableAction (action) {
//   // Action includes the word REQUEST, but is not in the excluded actions list.
//   return (
//     ((action.type.startsWith('SET') && action.type.endsWith('SUCCESS'))
//       || action.type.endsWith('CREATE_SUCCESS')
//       || action.type.endsWith('UPDATE_SUCCESS')
//       || action.type.endsWith('DELETE_SUCCESS'))
//     && action.type.includes('CAREPLAN')
//     && ignoreActionTypes.every(fragment => !action.type.includes(fragment))
//   )
// }
//
// function * monitor (monitoredAction) {
//   console.log(monitoredAction)
//   const { providerId, userId } = monitoredAction.requestPayload.data
//   yield put(actions.getAuditHistoryRequest({ providerId, userId }))
// }

export default function * rootSaga () {
  yield all([
    fork(getCarePlanRequest),
    fork(setCarePreferencesRequest),
    fork(setNextReviewRequest),
    fork(getCarePlanPdfRequest),
    fork(getCarePlanAuditHistoryRequest)
    // takeEvery(monitorableAction, monitor)
  ])
}
