import actions from './actions'

const initState = {
  list: [],
  loading: false,
  error: false
}

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_LIST_REQUEST:
      state = {
        ...state,
        loading: true,
        listLoaded: false
      }
      // state[dataPropName] = initState[dataPropName]
      return state
    case actions.GET_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        listLoaded: true,
        error: null,
        list: action.payload
      }
    case actions.GET_LIST_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload
      }

    default:
      return state
  }
}

export default reducers
