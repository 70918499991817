import styled from 'styled-components'
import {palette} from 'styled-theme'
import {borderRadius, transition} from '../../../config/styleHelper'

const AntSelect = ComponentName => styled(ComponentName)`
  &.ant-select {
    min-height: 40px;
    line-height: 40px;
    font-weight: bold;
    color: ${palette('axela', 2)};
        border-radius: 4px;
        background-color: white;

        &.ant-select-disabled {
              background-color: unset;

              .ant-select-selection--multiple .ant-select-selection__choice {
                    background-color: #eaeaea;
                    color: #aaa;
                    border-color: #ccc;
              }
        }

        &.incomplete {
              background-color: ${palette('error', 3)};
              font-weight: normal;
              color: inherit;
        }

        display: inline-block;
        position: relative;
        color: ${palette('axela', 2)};
        font-size: 13px;

        .ant-select-open {
              box-shadow: none;
              --webkit-box-shadow: 0;
        }

        .ant-select-selection.ant-select-selection--single {
              padding-top: 2px;
              padding-bottom: 2px;
              height: auto;
        }

        .ant-select-selection--single .ant-select-selection__rendered {
              margin-top: 2px;
              height: 40px;
              line-height: 38px;
        }


        .ant-select-selection {
              background-color: inherit;
              border-radius: 4px;
              ${transition()};

              &.ant-select-selection--single {
                    position: relative;
                    cursor: pointer;
                    height: ${props => props.height || 'auto'};
              }

              .ant-select-selection__rendered {
                    margin-left: 10px;
                    margin-right: 10px;
                    height: 40px;
                    line-height: 40px;

                    .ant-select-selection-selected-value {
                          float: left;
                          padding: 0 14px 0 0;
                          color: ${palette('axela', 2)};
        }

        .ant-select-search__field {
          color: ${palette('axela', 2)};
        }
      }

      .ant-select-arrow {
        color: ${palette('primary', 0)};
        cursor: pointer;
      }

      &:hover {
        border-color: ${palette('primary', 0)};
        border-radius: 4px;
      }
    }

    &.ant-select-focused {
      .ant-select-selection {
        &:focus,
        &:active {
          border-color: ${palette('primary', 0)};
          border-radius: 4px;
          outline: 0;
        }
      }
    }

    &.ant-select-open {
      .ant-select-selection {
        border-color: ${palette('primary', 0)};
        border-radius: 4px;
        outline: 0;
      }
    }

    .ant-select-selection__placeholder,
    .ant-select-search__field__placeholder {
      color: ${palette('axela', 2)};
    }

    .ant-select-selection--multiple {
      padding-top: 2px;
      padding-bottom: 2px;
    }

    .ant-select-selection--multiple > ul > li,
    .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
      margin-top: 2px;
      height: 40px;
      line-height: 38px;
    }

    .ant-select-selection--multiple .ant-select-selection__choice {
      background-color: ${palette('axela', 6)};
      color: ${palette('axela', 2)};
      font-weight: bold;
      border-color: ${palette('axela', 2)};
      ${borderRadius()};
    }

    .ant-select-selection--multiple .ant-select-arrow {
      top: 24px;
      cursor: pointer;
    }

    .ant-select-tree li a {
      font-size: 13px;
      color: ${palette('primary', 1)};
    }

    &.ant-select-disabled {
      .ant-select-selection {
        border-color: #d9d9d9;
        border-radius: 4px;
        outline: 0;

        &:hover {
          cursor: default;
        }

        .ant-select-selection-selected-value {
          color: ${palette('axela', 1)};
        }

      }
    }
  }

`

const AntSelectOption = ComponentName => styled(ComponentName)`
  &.ant-select-dropdown-menu-item {
    color: green;
    font-weight: bold;
  }
`

const AntSelectOptionGroup = ComponentName => styled(ComponentName)``

export {AntSelect, AntSelectOption, AntSelectOptionGroup}
