import actions from './actions'
import crudReducers from '../../crud/reducers'
import {initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess} from "../../../reducersHelper";

export const initBaseState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  model: {},
  availableTemplates: initialDataState,
  userForm: initialDataState
}

const crud = crudReducers(actions, 'model', initBaseState)

export default (state = initBaseState, action) => {
  const { type, payload } = action

  switch (type) {
    case actions.CUSTOM_FORMS_GET_AVAILABLE_TEMPLATES_REQUEST:
      return reduceDataRequest(state, 'availableTemplates')
    case actions.CUSTOM_FORMS_GET_AVAILABLE_TEMPLATES_SUCCESS:
      return reduceDataSuccess(state, 'availableTemplates', action.payload)
    case actions.CUSTOM_FORMS_GET_AVAILABLE_TEMPLATES_ERROR:
      return reduceDataError(state, 'availableTemplates', action.error)
    case actions.CUSTOM_FORMS_CREATE_USER_FORM_FROM_TEMPLATE_REQUEST:
      return reduceDataRequest(state, 'userForm')
    case actions.CUSTOM_FORMS_CREATE_USER_FORM_FROM_TEMPLATE_SUCCESS:
      return reduceDataSuccess(state, 'userForm', action.payload)
    case actions.CUSTOM_FORMS_CREATE_USER_FORM_FROM_TEMPLATE_ERROR:
      return reduceDataError(state, 'userForm', action.error)

    default:
      return crud(state, { type, payload })
  }
}
