import config from '../config'

export default {
  url: config.apiUrl,
  configHeaders: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  clientId: '8e432a72-e2f6-48b8-be2b-fa415bd2dea8'

}
