import config from './settings'
import {commonGet, commonPost} from './common'

class CustomForms {

  static getAvailableTemplates(
    token,
    providerId,
    userId
  ) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/formtemplates`
    return commonGet(token, url)
  }

  static createUserFormFromTemplate(
    token,
    providerId,
    userId,
    templateId,
    data
  ) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/formtemplates/${templateId}`
    return commonPost(token, url, data)
  }


}

export default CustomForms
