import React, {useEffect} from 'react'
import {Provider} from 'react-redux'
import {StoreContext} from 'redux-react-hook'
import configureStore, {history} from './redux/configureStore'
import BaseRouter from './BaseRouter'
import {ConfigProvider} from 'antd'
import BaseWrapperStyle from './BaseWrapperStyle'

import {IntlProvider} from 'react-intl'
import enGB from 'antd/lib/locale-provider/en_GB'
import englishGlobalTranslations from './translations/en.global.json'
import englishFHIRTranslations from './translations/en.fhir.json'
import englishErrorsTranslations from './translations/en.errors.json'
import englishSymptomsTranslations from './translations/en.symptoms'
import englishHRTranslations from './translations/en.hr'
import 'moment/locale/en-gb'

import authActions from './redux/app/auth/actions'
import interfaceActions from './redux/interface/actions'
import ThemeWrapper from "./ThemeWrapper";
import IconWrapper from "./IconWrapper";

const store = configureStore()
export {store}

export default function BaseWrapper() {
  // Check whether the user is logged in.
  store.dispatch(authActions.checkAuthorization())

  useEffect(() => {
    const handleResize = () => {
      store.dispatch(
        interfaceActions.updateView(window.innerWidth, window.innerHeight)
      )
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  })

  const enTranslations = {
    ...englishGlobalTranslations,
    ...englishErrorsTranslations,
    ...englishFHIRTranslations,
    ...englishSymptomsTranslations,
    ...englishHRTranslations
  }

  return (
    <ConfigProvider locale={enGB}>
      <IntlProvider locale="en-GB" messages={enTranslations}>
        <BaseWrapperStyle>
          <Provider store={store}>
            <StoreContext.Provider value={store}>
              <ThemeWrapper>
                <IconWrapper>
                  <BaseRouter history={history}/>
                </IconWrapper>
              </ThemeWrapper>
            </StoreContext.Provider>
          </Provider>
        </BaseWrapperStyle>
      </IntlProvider>
    </ConfigProvider>
  )
}
