import { all, put, call, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import crudSagas from '../../crud/sagas'
import Api from '../../../../api'
import { getAuthToken } from '../../../../helpers/tokens'

export function * createNoteRequest () {
  yield takeEvery(actions.CREATE_NOTE_REQUEST, function * ({ payload }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Symptoms.createNoteRequest,
        authToken.token,
        payload.providerId,
        payload.userId,
        payload.symptomId,
        payload.note
      )
      if (response) {
        yield put(actions.createNoteSuccess(response))
        yield put(actions.getList({ providerId: payload.providerId, userId: payload.userId }))
      } else {
        yield put(actions.createNoteError(error))
      }
      yield put(actions.toggleModal())
    }
  })
}

export function * getCountRequest () {
  yield takeEvery(actions.GET_COUNT_REQUEST, function * ({ payload }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Symptoms.getSymptomsCountRequest,
        authToken.token,
        payload.providerId,
        payload.userId
      )
      if (response) {
        yield put(actions.getCountSuccess(response))
      } else {
        yield put(actions.getCountError(error))
      }
    }
  })
}

export default function * devSaga () {
  yield all([
    crudSagas(
      actions,
      Api.Crud,
      'providers/:providerId/serviceusers/:userId/symptoms',
      'id'
    ),
    createNoteRequest(),
    getCountRequest()
  ])
}
