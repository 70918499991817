import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../reducersHelper'

const initState = {
  unifiedAvailability: initialDataState
}

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAR_AVAILABILITY:
      return initState

    case actions.GET_UNIFIED_AVAILABILITY_REQUEST:
      return reduceDataRequest(state, 'unifiedAvailability')
    case actions.GET_UNIFIED_AVAILABILITY_SUCCESS:
      return reduceDataSuccess(state, 'unifiedAvailability', action.payload)
    case actions.GET_UNIFIED_AVAILABILITY_ERROR:
      return reduceDataError(state, 'unifiedAvailability', action.error)

    default:
      return state
  }
}

export default reducers
