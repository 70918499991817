// import { getDefaultPath } from '../../helpers/urlSync';
import actions, {
  getView,
  getIsCollapsable,
  VIEW_LAPTOP
} from './actions'
import authActions from '../app/auth/actions'
import { theme } from '../../config'
import { convertHexToRGBA, setExposure } from '../../config/styleHelper'
import _ from 'lodash'

const initState = {
  isCollapsable: getIsCollapsable(window.innerWidth),
  collapsed: (VIEW_LAPTOP !== getView(window.innerWidth)),
  view: getView(window.innerWidth),
  width: window.innerWidth,
  height: window.innerHeight,
  currentLocation: '',
  widescreen: false,
  theme: {}
}
export default function appReducer (state = initState, action) {
  let view
  switch (action.type) {
    case actions.TOGGLE_COLLAPSED:
      return { ...state, collapsed: !state.collapsed }
    case actions.SET_CURRENT_LOCATION:
      return { ...state, currentLocation: action.currentLocation }
    case actions.UPDATE_VIEW:
      view = getView(action.width)
      return {
        ...state,
        view: view,
        width: action.width,
        height: action.height,
        collapsed: (VIEW_LAPTOP !== view),
        isCollapsable: getIsCollapsable(action.width)
      }
    case actions.SET_WIDESCREEN:
      return {
        ...state,
        widescreen: action.isWide
      }
    case actions.SET_PRIMARY_COLOUR:
      return updateTheme(state, action.primaryColour)
    case actions.SET_THEME:
      return {
        ...state,
        theme: action.theme
      }
    case authActions.LOGOUT_SUCCESS:
      return {
        ...state,
        theme: {}
      }
    default:
      return state
  }
}

function updateTheme (state, primaryColour) {
  let colour = theme.palette.primary[0]
  if (primaryColour && primaryColour > '') {
    colour = primaryColour
  }

  const newTheme = _.cloneDeep(theme)

  newTheme.palette.primary[0] = colour
  for (let i = 1; i <= 10; i++) {
    newTheme.palette.primary[i] = setExposure(colour, (-i / 10))
    newTheme.palette.primary[i + 10] = setExposure(colour, i / 11)
  }

  newTheme.palette.gradients = {}
  newTheme.palette.gradients.default = createGradient(newTheme.palette.primary[0], newTheme.palette.primary[3], 1)
  newTheme.palette.gradients.defaultLight = createGradient(newTheme.palette.primary[12], newTheme.palette.primary[18], 0.4)
  newTheme.palette.gradients.defaultLighter = createGradient(newTheme.palette.primary[14], newTheme.palette.primary[20], 0.2)
  newTheme.palette.gradients.reversed = createGradient(newTheme.palette.primary[3], newTheme.palette.primary[0], 1)
  newTheme.palette.gradients.reversedLight = createGradient(newTheme.palette.primary[18], newTheme.palette.primary[12], 0.4)
  newTheme.palette.gradients.reversedLighter = createGradient(newTheme.palette.primary[20], newTheme.palette.primary[14], 0.2)

  return {
    ...state,
    theme: newTheme
  }
}

function createGradient (startColor, endColor, opacity, direction = 'left') {
  const otherDirection = 'right'
  // if (direction === 'right') {
  //   otherDirection = 'left'
  //
  //   // Swap the colors
  //   const tmpColor = startColor
  //   startColor = endColor
  //   endColor = tmpColor
  // }

  const startColorRGBA = convertHexToRGBA(startColor, opacity)
  const endColorRGBA = convertHexToRGBA(endColor, opacity)

  return `
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#2b3259+0,2f95a4+90 */
    background: ${startColor}; /* Old browsers */
    background: -moz-linear-gradient(${direction}, ${startColorRGBA} 0%, ${endColorRGBA} 90%); /* FF3.6-15 */
    background: -webkit-linear-gradient(${direction}, ${startColorRGBA} 0%,${endColorRGBA} 90%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to ${otherDirection}, ${startColorRGBA} 0%,${endColorRGBA} 90%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='${startColor}', endColorstr='${endColor}',GradientType=1 ); /* IE6-9 */
  `
}
