import config from './settings'
import errorService from './error.service'

export function substituteVariables (endpoint, data) {
  if (data == null) {
    return endpoint
  }

  const regex = /:[^/$]*/g
  const matches = endpoint.match(regex)
  if (matches == null) {
    return endpoint
  }
  matches.forEach(match => {
    const propName = match.slice(1)
    endpoint = endpoint.replace(match, data[propName])
  })
  return endpoint
}

function commonCRUD (method, token, url, data) {
  return fetch(url, {
    method: method,
    body: !data ? null : JSON.stringify(data),
    headers: {
      ...config.configHeaders,
      Authorization: 'Bearer ' + token
    }
  })
    .then(response => errorService.handleErrors(response))
    .then(response => ({ response }))
    .catch(error => ({ error }))
}

export function commonGet(token, url) {
  return commonCRUD('GET', token, url)
}

export function commonPut(token, url, data) {
  return commonCRUD('PUT', token, url, data)
}

export function commonPatch(token, url, data) {
  return commonCRUD('PATCH', token, url, data)
}

export function commonPost(token, url, data) {
  return commonCRUD('POST', token, url, data)
}

export function commonDelete(token, url, data) {
  return commonCRUD('DELETE', token, url, data)
}

export function commonGetBlob(token, url) {
  return fetch(url, {
    method: 'GET',
    headers: {
      ...config.configHeaders,
      Authorization: 'Bearer ' + token
    }
  })
    .then(response => {
      if (response.status === 204) {
        return null
      }
      if (response.status !== 200) {
        return response
      }
      return response.blob()
    })
    .then(blob => {
      if (!blob) {
        return {
          response: "#"
        }
      }
      if (window.URL.createObjectURL) {
        return {
          response: window.URL.createObjectURL(blob)
        }
      } else {
        return { response: URL.createObjectURL(blob) }
      }
    })
    .catch(error => {
      console.error(error)
      return { error }
    })
}

export function commonPutBlob (token, url, data) {
  return fetch(url, {
    method: 'PUT',
    body: data,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
    .then(response => errorService.handleErrors(response))
    .then(response => ({ response }))
    .catch(error => ({ error }))
}
