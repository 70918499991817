import config from './settings'
import { commonGet, commonPut } from './common'

export default class Notifications {
  static getUserNotificationOptions (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/notificationOptions`
    return commonGet(token, url)
  }

  static updateUserNotificationOptions (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/notificationOptions`
    return commonPut(token, url, data)
  }

  static getUserNotificationAlertsSelection (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/notificationAlertsSelection`
    return commonGet(token, url)
  }

  static updateUserNotificationAlertsSelection (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/notificationAlertsSelection`
    return commonPut(token, url, data)
  }

  static getUserNotificationEventsSelection (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/notificationEventsSelection`
    return commonGet(token, url)
  }

  static updateUserNotificationEventsSelection (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/notificationEventsSelection`
    return commonPut(token, url, data)
  }
}
