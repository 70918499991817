import { actionChannel, all, call, fork, put, take, takeEvery, debounce } from 'redux-saga/effects'
import actions from './actions'
import crudSagas from '../crud/sagas'
import Api from '../../../api'
import { getAuthToken } from '../../../helpers/tokens'
import {defineGetSaga, defineUpdateSaga} from '../../sagasHelper'
import _ from "lodash";

function * watchAvatarRequests () {
  // 1- Create a channel for request actions
  const requestChan = yield actionChannel(actions.GET_USER_AVATAR_REQUEST)
  while (true) {
    // 2- take from the channel
    const action = yield take(requestChan)
    // 3- Note that we're using a blocking call
    yield call(handleAvatarRequest, action)
  }
}

function * handleAvatarRequest (action) {
  const authToken = yield getAuthToken()
  if (authToken && authToken.token) {
    const { response, error } = yield call(
      Api.Users.getAvatar,
      authToken.token,
      action.providerId,
      action.userId
    )
    if (response) {
      yield put(actions.getAvatarSuccess(response, action.userId))
    } else {
      yield put(actions.getAvatarError(error))
    }
  }
}

export function * getAvatarRequest () {
  yield takeEvery(actions.GET_USER_AVATAR_REQUEST, handleAvatarRequest)
}

export function * updateAvatarRequest () {
  yield takeEvery(actions.UPDATE_USER_AVATAR_REQUEST, function * (
    {
      providerId,
      userId,
      payload
    }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Users.updateAvatar,
        authToken.token,
        providerId,
        userId,
        payload
      )
      if (!error) {
        yield put(actions.updateAvatarSuccess(response))
        yield put(actions.getAvatarRequest(providerId, userId))
      } else {
        yield put(actions.updateAvatarError(error))
      }
    }
  })
}

export function * deleteAvatarRequest () {
  yield takeEvery(actions.DELETE_USER_AVATAR_REQUEST, function * (
    {
      providerId,
      userId
    }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Users.deleteAvatar,
        authToken.token,
        providerId,
        userId
      )
      if (response) {
        yield put(actions.deleteAvatarSuccess(response))
        yield put(actions.getAvatarRequest(providerId, userId))
      } else {
        yield put(actions.deleteAvatarError(error))
      }
    }
  })
}

function* getUserAvatars(action) {
  yield put(actions.getAllUserAvatarsRequest({ providerId: action.payload.providerId }))
}

function * providerUserGetListRequest () {
  yield debounce(1000, actions.GET_LIST_REQUEST, getUserAvatars)
}

function * getAllUserAvatarsRequest () {
  yield defineGetSaga(actions.GET_ALL_USER_AVATARS_REQUEST, Api.Users.getAllUserAvatars, actions.getAllUserAvatarsSuccess, actions.getAllUserAvatarsError)
}

export function * searchUsersRequest () {
  yield takeEvery(actions.SEARCH_PROVIDERUSERS_REQUEST, function * (action) {
    const { payload, successCallback, errorCallback } = action
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Users.searchUsers,
        authToken.token,
        payload.providerId,
        payload)
      if (response) {
        yield put(actions.searchProviderUsersSuccess(response, payload))
        _.isFunction(successCallback) && successCallback(response, payload)
      } else {
        yield put(actions.searchProviderUsersError(error))
        _.isFunction(errorCallback) && errorCallback(error, payload)
      }
    }
  })
}

function * getUserPasswordResetLinkRequest () {
  yield defineGetSaga(actions.GET_USER_PASSWORD_RESET_LINK_REQUEST, Api.Users.getPasswordResetLink, actions.getUserPasswordResetLinkSuccess, actions.getUserPasswordResetLinkError)
}

export function* updateUserPasswordRequest() {
  yield defineUpdateSaga(actions.UPDATE_USER_PASSWORD_REQUEST, Api.Users.resetPasswordForUser, actions.updateUserPasswordSuccess, actions.updateUserPasswordError)
}

export default function * devSaga () {
  yield all([
    crudSagas(actions, Api.Crud, 'providers/:providerId/users', 'userId'),
    fork(watchAvatarRequests),
    fork(updateAvatarRequest),
    fork(deleteAvatarRequest),
    fork(providerUserGetListRequest),
    fork(getAllUserAvatarsRequest),
    fork(searchUsersRequest),
    fork(getUserPasswordResetLinkRequest),
    fork(updateUserPasswordRequest)
  ])
}
