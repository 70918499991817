import { all, fork } from 'redux-saga/effects'
import { sagas as crudServicesSagas } from './crudServices'
import { sagas as crudLocationsSagas } from './crudLocations'
import { defineGetSagaProvider, defineUpdateSagaProvider } from '../../sagasHelper'
import actions from '../serviceslocations/actions'
import Api from '../../../api'

export function * getServicesByUserFilteredRequest () {
  yield defineGetSagaProvider(actions.SERVICESLOCATIONS_GET_SERVICES_BYUSER_FILTERED_REQUEST, Api.ServicesLocations.getServicesByUserFiltered, actions.getServicesByUserFilteredSuccess, actions.getServicesByUserFilteredError)
}

export function * getServicesForMultipleUsersRequest () {
  yield defineGetSagaProvider(actions.SERVICESLOCATIONS_GET_SERVICES_FOR_MULTIPLE_USERS_REQUEST, Api.ServicesLocations.getServicesForMultipleUsers, actions.getServicesForMultipleUsersSuccess, actions.getServicesForMultipleUsersError)
}

export function * updateServicesForMultipleUsersRequest () {
  yield defineUpdateSagaProvider(actions.SERVICESLOCATIONS_UPDATE_SERVICES_FOR_MULTIPLE_USERS_REQUEST, Api.ServicesLocations.updateServicesForMultipleUsers, actions.updateServicesForMultipleUsersSuccess, actions.updateServicesForMultipleUsersError)
}

export default function * rootSaga () {
  yield all([
    fork(getServicesForMultipleUsersRequest),
    fork(updateServicesForMultipleUsersRequest),
    fork(getServicesByUserFilteredRequest),
    crudServicesSagas(),
    crudLocationsSagas()
  ])
}
