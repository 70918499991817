import { actionsDNACPR, actionsDoLS } from './actions'
import crudReducers from '../../crud/reducers'

const dataPropName = 'doc'

const initState = {
  list: [],
  loading: false,
  error: false,
  modalActive: false,
  doc: ''
}

export const reducersDNACPR = crudReducers(actionsDNACPR, dataPropName, { ...initState })
export const reducersDoLS = crudReducers(actionsDoLS, dataPropName, { ...initState })
