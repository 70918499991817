
import config from './settings'
import { commonGet } from './common'

export default class SupportTaskTitle {
  static getTitleList (token, providerId, searchTerm) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/supportTaskTitle?searchTerm=${searchTerm}`
    return commonGet(token, url)
  }
}
