import { parseError } from '../../../helpers/errors'

export default function crudActions (DOCUMENT) {
  const actions = {
    CLEAR: DOCUMENT + 'CLEAR',
    GET_LIST_REQUEST: DOCUMENT + 'GET_LIST_REQUEST',
    GET_LIST_SUCCESS: DOCUMENT + 'GET_LIST_SUCCESS',
    GET_LIST_ERROR: DOCUMENT + 'GET_LIST_ERROR',
    GET_REQUEST: DOCUMENT + 'GET_REQUEST',
    GET_SUCCESS: DOCUMENT + 'GET_SUCCESS',
    GET_ERROR: DOCUMENT + 'GET_ERROR',
    CREATE_REQUEST: DOCUMENT + 'CREATE_REQUEST',
    CREATE_SUCCESS: DOCUMENT + 'CREATE_SUCCESS',
    CREATE_ERROR: DOCUMENT + 'CREATE_ERROR',
    UPDATE_REQUEST: DOCUMENT + 'UPDATE_REQUEST',
    UPDATE_SUCCESS: DOCUMENT + 'UPDATE_SUCCESS',
    UPDATE_ERROR: DOCUMENT + 'UPDATE_ERROR',
    DELETE_REQUEST: DOCUMENT + 'DELETE_REQUEST',
    DELETE_SUCCESS: DOCUMENT + 'DELETE_SUCCESS',
    DELETE_ERROR: DOCUMENT + 'DELETE_ERROR',

    UPDATE_MODEL: DOCUMENT + 'UPDATE_MODEL',
    TOGGLE_HANDLE_MODAL: DOCUMENT + 'TOGGLE_HANDLE_MODAL',

    CLEAR_FORM_ERROR: DOCUMENT + 'CLEAR_FORM_ERROR',

    clear: () => {
      return {
        type: actions.CLEAR
      }
    },
    clearFormError: () => {
      return {
        type: actions.CLEAR_FORM_ERROR
      }
    },
    getList: (parentData, successCallback, errorCallback) => {
      return {
        type: actions.GET_LIST_REQUEST,
        payload: parentData,
        successCallback,
        errorCallback
      }
    },
    getListSuccess: (result, requestPayload) => {
      return {
        type: actions.GET_LIST_SUCCESS,
        payload: result,
        requestPayload
      }
    },
    getListError: (error, requestPayload) => {
      return {
        type: actions.GET_LIST_ERROR,
        error: !error ? {} : error,
        requestPayload
      }
    },
    getRequest: (data, successCallback, errorCallback) => {
      return {
        type: actions.GET_REQUEST,
        payload: data,
        successCallback,
        errorCallback
      }
    },
    getSuccess: (result, requestPayload) => {
      return {
        type: actions.GET_SUCCESS,
        payload: result,
        requestPayload
      }
    },
    getError: (error, requestPayload) => {
      return {
        type: actions.GET_ERROR,
        error: !error ? {} : error,
        requestPayload
      }
    },
    createRequest: (data, successCallback, errorCallback) => ({
      type: actions.CREATE_REQUEST,
      payload: { data },
      successCallback,
      errorCallback
    }),
    createSuccess: (result, requestPayload) => {
      return {
        type: actions.CREATE_SUCCESS,
        payload: result,
        requestPayload
      }
    },
    createError: (error, requestPayload) => {
      return {
        type: actions.CREATE_ERROR,
        error: !error ? {} : error,
        payload: { error: parseError(error) },
        requestPayload
      }
    },
    updateRequest: (data, successCallback, errorCallback) => ({
      type: actions.UPDATE_REQUEST,
      payload: { data },
      successCallback,
      errorCallback
    }),
    updateSuccess: (result, requestPayload) => {
      return {
        type: actions.UPDATE_SUCCESS,
        payload: result,
        requestPayload
      }
    },
    updateError: (error, requestPayload) => {
      return {
        type: actions.UPDATE_ERROR,
        error: !error ? {} : error,
        payload: { error: parseError(error) },
        requestPayload
      }
    },
    deleteRequest: (data, successCallback, errorCallback) => ({
      type: actions.DELETE_REQUEST,
      payload: { data },
      successCallback,
      errorCallback
    }),
    deleteSuccess: (result, requestPayload) => {
      return {
        type: actions.DELETE_SUCCESS,
        payload: result,
        requestPayload
      }
    },
    deleteError: (error, requestPayload) => {
      return {
        type: actions.DELETE_ERROR,
        error: !error ? {} : error,
        payload: { error: parseError(error) },
        requestPayload
      }
    },
    updateModel: data => ({
      type: actions.UPDATE_MODEL,
      payload: { data }
    }),
    toggleModal: (data = null) => ({
      type: actions.TOGGLE_HANDLE_MODAL,
      payload: { data }
    }),
    createReply: (data, successCallback, errorCallback) => ({
      type: actions.CREATE_REPLY,
      payload: { data },
      successCallback,
      errorCallback
    })
  }
  return actions
}
