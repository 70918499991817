import actions from './actions'

const initState = {
  searchTerm: '',
  data: [],
  loading: false,
  error: false,
  doseForms: [],
  drugRoutes: [],
  controlledDrugCategories: []
}
const reducers = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SEARCH_DMDAMPP_REQUEST:
      return {
        ...state,
        searchTerm: payload.searchTerm,
        loading: true
      }
    case actions.SEARCH_DMDAMPP_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      }
    case actions.SEARCH_DMDAMPP_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    case actions.DOSE_FORMS_SUCCESS:
      return {
        ...state,
        doseForms: payload
      }
    case actions.DOSE_FORMS_ERROR:
      return {
        ...state,
        error: payload
      }
    case actions.DRUG_ROUTES_SUCCESS:
      return {
        ...state,
        drugRoutes: payload
      }
    case actions.DRUG_ROUTES_ERROR:
      return {
        ...state,
        error: payload
      }
    case actions.CONTROLLED_DRUG_CATEGORIES_SUCCESS:
      return {
        ...state,
        controlledDrugCategories: payload
      }
    case actions.CONTROLLED_DRUG_CATEGORIES_ERROR:
      return {
        ...state,
        error: payload
      }
    default:
      return state
  }
}
export default reducers
