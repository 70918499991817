import { defineGetActionsPrSu } from '../../actionsHelper'

const DOCUMENT = 'AUDIT_'

const actions = {
  CLEAR: DOCUMENT + '_CLEAR',
  GET_ENTRIES_REQUEST: DOCUMENT + 'GET_ENTRIES_REQUEST',
  GET_ENTRIES_SUCCESS: DOCUMENT + 'GET_ENTRIES_SUCCESS',
  GET_ENTRIES_ERROR: DOCUMENT + 'GET_ENTRIES_ERROR',

  getEntries: (providerId, queryParameters) => {
    return {
      type: actions.GET_ENTRIES_REQUEST,
      providerId,
      queryParameters
    }
  },
  getEntriesSuccess: result => {
    return {
      type: actions.GET_ENTRIES_SUCCESS,
      payload: result
    }
  },
  getEntriesError: error => {
    return {
      type: actions.GET_ENTRIES_ERROR,
      error: error
    }
  }
}

// GetAuditEntriesRecentUpdatesAllServiceUsers
defineGetActionsPrSu(actions, 'GET_ENTRIES_RECENT_UPDATES_ALL_SERVICE_USERS', 'getRecentUpdatesAllServiceUsers')

export default actions
