import crudActions from '../crud/actions'
import {defineGetActionsPrSu, defineUpdateActionsPrSu} from '../../actionsHelper'

const actions = crudActions('PROVIDERUSER_')

const userActions = {
  SEARCH_PROVIDERUSERS_REQUEST: 'SEARCH_PROVIDERUSERS_REQUEST',
  SEARCH_PROVIDERUSERS_SUCCESS: 'SEARCH_PROVIDERUSERS_SUCCESS',
  SEARCH_PROVIDERUSERS_ERROR: 'SEARCH_PROVIDERUSERS_ERROR',
  searchProviderUsersRequest: (data, successCallback, errorCallback) => {
    return {
      type: userActions.SEARCH_PROVIDERUSERS_REQUEST,
      payload: data,
      successCallback,
      errorCallback
    }
  },
  searchProviderUsersSuccess: (result, requestPayload) => {
    return {
      type: userActions.SEARCH_PROVIDERUSERS_SUCCESS,
      payload: result,
      requestPayload
    }
  },
  searchProviderUsersError: (error, requestPayload) => {
    return {
      type: userActions.SEARCH_PROVIDERUSERS_ERROR,
      error: !error ? {} : error,
      requestPayload
    }
  },
}

defineGetActionsPrSu(
  userActions,
  'GET_USER_PASSWORD_RESET_LINK',
  'getUserPasswordResetLink'
)


defineUpdateActionsPrSu(
  userActions,
  'UPDATE_USER_PASSWORD',
  'updateUserPassword'
)

const avatarActions = {

  CLEAR_USER_AVATAR: 'CLEAR_USER_AVATAR',
  GET_USER_AVATAR_REQUEST: 'GET_USER_AVATAR_REQUEST',
  GET_USER_AVATAR_SUCCESS: 'GET_USER_AVATAR_SUCCESS',
  GET_USER_AVATAR_ERROR: 'GET_USER_AVATAR_ERROR',
  UPDATE_USER_AVATAR_REQUEST: 'UPDATE_USER_AVATAR_REQUEST',
  UPDATE_USER_AVATAR_SUCCESS: 'UPDATE_USER_AVATAR_SUCCESS',
  UPDATE_USER_AVATAR_ERROR: 'UPDATE_USER_AVATAR_ERROR',
  DELETE_USER_AVATAR_REQUEST: 'DELETE_USER_AVATAR_REQUEST',
  DELETE_USER_AVATAR_SUCCESS: 'DELETE_USER_AVATAR_SUCCESS',
  DELETE_USER_AVATAR_ERROR: 'DELETE_USER_AVATAR_ERROR',

  clearUserAvatar: () => {
    return {
      type: avatarActions.CLEAR_USER_AVATAR
    }
  },
  getAvatarRequest: (providerId, userId) => {
    return {
      type: avatarActions.GET_USER_AVATAR_REQUEST,
      providerId,
      userId
    }
  },
  getAvatarSuccess: (result, userId) => {
    return {
      type: avatarActions.GET_USER_AVATAR_SUCCESS,
      payload: result,
      userId
    }
  },
  getAvatarError: error => {
    return {
      type: avatarActions.GET_USER_AVATAR_ERROR,
      error: error
    }
  },
  updateAvatarRequest: (providerId, userId, avatar) => {
    return {
      type: avatarActions.UPDATE_USER_AVATAR_REQUEST,
      providerId,
      userId,
      payload: avatar
    }
  },
  updateAvatarSuccess: result => {
    return {
      type: avatarActions.UPDATE_USER_AVATAR_SUCCESS,
      payload: result
    }
  },
  updateAvatarError: error => {
    return {
      type: avatarActions.UPDATE_USER_AVATAR_ERROR,
      error: error
    }
  },
  deleteAvatarRequest: (providerId, userId) => {
    return {
      type: avatarActions.DELETE_USER_AVATAR_REQUEST,
      providerId,
      userId
    }
  },
  deleteAvatarSuccess: result => {
    return {
      type: avatarActions.DELETE_USER_AVATAR_SUCCESS,
      payload: result
    }
  },
  deleteAvatarError: error => {
    return {
      type: avatarActions.DELETE_USER_AVATAR_ERROR,
      error: error
    }
  }
}

defineGetActionsPrSu(
  avatarActions,
  'GET_ALL_USER_AVATARS',
  'getAllUserAvatars'
)

export default {...actions, ...avatarActions, ...userActions}
