import { defineGetActionsPrSu } from '../../actionsHelper'

const actions = {
  CLEAR_PROVIDER_ALERTS: 'CLEAR_PROVIDER_ALERTS',

  clear: () => {
    return {
      type: actions.CLEAR_PROVIDER_ALERTS
    }
  },

  PROVIDER_ALERTS_SET_FILTERS: 'PROVIDER_ALERTS_SET_FILTERS',
  setFilters: (filters) => {
    return {
      type: actions.PROVIDER_ALERTS_SET_FILTERS,
      filters: filters
    }
  }

}

defineGetActionsPrSu(actions, 'GET_ALERT_TYPES', 'getAlertTypes')
defineGetActionsPrSu(actions, 'GET_PROVIDER_ALERTS', 'getProviderAlerts')
defineGetActionsPrSu(actions, 'GET_PROVIDER_ALERTS_FILTERED', 'getProviderAlertsFiltered')
defineGetActionsPrSu(actions, 'ACKNOWLEDGE_ALERT', 'acknowledgeAlert')

export default actions
