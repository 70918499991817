const actions = {
  CLEAR_HEALTHTRACKER: 'CLEAR_HEALTHTRACKER',
  clear: () => {
    return {
      type: actions.CLEAR_HEALTHTRACKER
    }
  },

  HEALTHTRACKER_GET_FITBIT_AUTHURL_REQUEST:
    'HEALTHTRACKER_GET_FITBIT_AUTHURL_REQUEST',
  HEALTHTRACKER_GET_FITBIT_AUTHURL_SUCCESS:
    'HEALTHTRACKER_GET_FITBIT_AUTHURL_SUCCESS',
  HEALTHTRACKER_GET_FITBIT_AUTHURL_ERROR:
    'HEALTHTRACKER_GET_FITBIT_AUTHURL_ERROR',

  HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_REQUEST:
    'HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_REQUEST',
  HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_SUCCESS:
    'HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_SUCCESS',
  HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_ERROR:
    'HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_ERROR',

  HEALTHTRACKER_GET_FITBIT_TOKEN_REQUEST:
    'HEALTHTRACKER_GET_FITBIT_TOKEN_REQUEST',
  HEALTHTRACKER_GET_FITBIT_TOKEN_SUCCESS:
    'HEALTHTRACKER_GET_FITBIT_TOKEN_SUCCESS',
  HEALTHTRACKER_GET_FITBIT_TOKEN_ERROR: 'HEALTHTRACKER_GET_FITBIT_TOKEN_ERROR',

  HEALTHTRACKER_DELETE_FITBIT_TOKEN_REQUEST:
    'HEALTHTRACKER_DELETE_FITBIT_TOKEN_REQUEST',
  HEALTHTRACKER_DELETE_FITBIT_TOKEN_SUCCESS:
    'HEALTHTRACKER_DELETE_FITBIT_TOKEN_SUCCESS',
  HEALTHTRACKER_DELETE_FITBIT_TOKEN_ERROR:
    'HEALTHTRACKER_DELETE_FITBIT_TOKEN_ERROR',

  HEALTHTRACKER_GET_CURRENT_REQUEST:
    'HEALTHTRACKER_GET_CURRENT_REQUEST',
  HEALTHTRACKER_GET_CURRENT_SUCCESS:
    'HEALTHTRACKER_GET_CURRENT_SUCCESS',
  HEALTHTRACKER_GET_CURRENT_ERROR:
    'HEALTHTRACKER_GET_CURRENT_ERROR',

  getFitbitAuthUrlRequest: (providerId, userId) => {
    return {
      type: actions.HEALTHTRACKER_GET_FITBIT_AUTHURL_REQUEST,
      providerId,
      userId
    }
  },
  getFitbitAuthUrlSuccess: result => {
    return {
      type: actions.HEALTHTRACKER_GET_FITBIT_AUTHURL_SUCCESS,
      payload: result
    }
  },
  getFitbitAuthUrlError: error => ({
    type: actions.HEALTHTRACKER_GET_FITBIT_AUTHURL_ERROR,
    error: error
  }),

  exchangeFitbitAuthCodeRequest: (providerId, userId, code) => {
    return {
      type: actions.HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_REQUEST,
      providerId,
      userId,
      data: { code: code }
    }
  },
  exchangeFitbitAuthCodeSuccess: result => {
    return {
      type: actions.HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_SUCCESS,
      payload: result
    }
  },
  exchangeFitbitAuthCodeError: error => ({
    type: actions.HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_ERROR,
    error: error
  }),

  getFitbitTokenRequest: (providerId, userId) => {
    return {
      type: actions.HEALTHTRACKER_GET_FITBIT_TOKEN_REQUEST,
      providerId,
      userId
    }
  },
  getFitbitTokenSuccess: result => {
    return {
      type: actions.HEALTHTRACKER_GET_FITBIT_TOKEN_SUCCESS,
      payload: result
    }
  },
  getFitbitTokenError: error => ({
    type: actions.HEALTHTRACKER_GET_FITBIT_TOKEN_ERROR,
    error: error
  }),

  deleteFitbitTokenRequest: (providerId, userId) => {
    return {
      type: actions.HEALTHTRACKER_DELETE_FITBIT_TOKEN_REQUEST,
      providerId,
      userId
    }
  },
  deleteFitbitTokenSuccess: result => {
    return {
      type: actions.HEALTHTRACKER_DELETE_FITBIT_TOKEN_SUCCESS,
      payload: result
    }
  },
  deleteFitbitTokenError: error => ({
    type: actions.HEALTHTRACKER_DELETE_FITBIT_TOKEN_ERROR,
    error: error
  }),

  getHealthtrackerCurrentRequest: (providerId, userId) => {
    return {
      type: actions.HEALTHTRACKER_GET_CURRENT_REQUEST,
      providerId,
      userId
    }
  },
  getHealthtrackerCurrentSuccess: result => {
    return {
      type: actions.HEALTHTRACKER_GET_CURRENT_SUCCESS,
      payload: result
    }
  },
  getHealthtrackerCurrentError: error => ({
    type: actions.HEALTHTRACKER_GET_CURRENT_ERROR,
    error: error
  })

}
export default actions
