import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../reducersHelper'

const initState = {
  timeOfDayData: initialDataState
}

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_UTILITY_TIMEOFDAYDATA_REQUEST:
      return reduceDataRequest(state, 'timeOfDayData')
    case actions.GET_UTILITY_TIMEOFDAYDATA_SUCCESS:
      return reduceDataSuccess(state, 'timeOfDayData', action.payload)
    case actions.GET_UTILITY_TIMEOFDAYDATA_ERROR:
      return reduceDataError(state, 'timeOfDayData', action.error)
    default:
      return state
  }
}

export default reducers
