import actions from './actions'
import crudReducers from '../crud/reducers'
import {initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess} from "../../reducersHelper";

const dataPropName = 'data'

const initState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  modalActive: false,
  data: {},
  matchingVisits: initialDataState,
  unifiedRounds: initialDataState,
}

const crud = crudReducers(actions, dataPropName, initState)

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.ROUNDS_GET_VISITS_MATCHING_ROUND_REQUEST:
      return reduceDataRequest(state, 'matchingVisits')
    case actions.ROUNDS_GET_VISITS_MATCHING_ROUND_SUCCESS:
      return reduceDataSuccess(state, 'matchingVisits', action.payload)
    case actions.ROUNDS_GET_VISITS_MATCHING_ROUND_ERROR:
      return reduceDataError(state, 'matchingVisits', action.error)


    case actions.ROUNDS_GET_UNIFIED_ROUNDS_REQUEST:
      return reduceDataRequest(state, 'unifiedRounds')
    case actions.ROUNDS_GET_UNIFIED_ROUNDS_SUCCESS:
      return reduceDataSuccess(state, 'unifiedRounds', action.payload)
    case actions.ROUNDS_GET_UNIFIED_ROUNDS_ERROR:
      return reduceDataError(state, 'unifiedRounds', action.error)

    case actions.ROUNDS_ASSIGN_STAFF_REQUEST:
      return reduceDataRequest(state, 'assignStaff')
    case actions.ROUNDS_ASSIGN_STAFF_SUCCESS:
      return reduceDataSuccess(state, 'assignStaff', action.payload)
    case actions.ROUNDS_ASSIGN_STAFF_ERROR:
      return reduceDataError(state, 'assignStaff', action.error)

    case actions.CLEAR_MATCHING_VISIT_LIST:
      return {
        ...state,
        matchingVisits: initialDataState
      }

    default:
      return crud(state, action)
  }
}


export default reducers
