import actions from './actions'
import crudReducers from '../crud/reducers'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../reducersHelper'
import defaultLogo from '../../../images/logo/cairid-logo.jpg'

const dataPropName = 'provider'

const initState = {
  list: {},
  loading: false,
  error: false,
  modalActive: false,
  provider: {
    providerId: null,
    name: '',
    code: ''
  },
  settings: {
    loading: false,
    updating: false,
    error: false,
    data: null
  },
  logo: {
    ...initialDataState,
    data: null
  },
  roles: initialDataState
}

const crud = crudReducers(actions, dataPropName, initState)

const reducers = (state = initState, action) => {
  const { type, payload } = action

  let settings = {}
  let tmp = {}

  switch (type) {
    case actions.GET_PROVIDER_SETTINGS_REQUEST:
      settings = {
        ...state.settings,
        loading: true
      }
      return {
        ...state,
        settings: settings
      }
    case actions.GET_PROVIDER_SETTINGS_SUCCESS:
      settings = {
        ...state.settings,
        loading: false,
        loaded: true,
        error: false,
        data: payload
      }
      return {
        ...state,
        settings: settings
      }
    case actions.GET_PROVIDER_SETTINGS_ERROR:
      settings = {
        ...state.settings,
        loading: false,
        error: payload
      }
      return {
        ...state,
        settings: settings
      }
    case actions.UPDATE_PROVIDER_SETTINGS_REQUEST:
      settings = {
        ...state.settings,
        updating: true,
        error: false
      }
      return {
        ...state,
        settings: settings
      }
    case actions.UPDATE_PROVIDER_SETTINGS_SUCCESS:
      settings = {
        ...state.settings,
        updating: false,
        updated: true,
        error: false,
        data: payload
      }
      return {
        ...state,
        settings: settings
      }
    case actions.UPDATE_PROVIDER_SETTINGS_ERROR:
      settings = {
        ...state.settings,
        updating: false,
        error: action.error
      }
      return {
        ...state,
        settings: settings
      }

    case actions.GET_PROVIDER_LOGO_REQUEST:
      return reduceDataRequest(state, 'logo')
    case actions.GET_PROVIDER_LOGO_SUCCESS:
      return reduceDataSuccess(state, 'logo', action.payload)
    case actions.GET_PROVIDER_LOGO_ERROR:
      tmp = reduceDataError(state, 'logo', action.error)
      tmp.logo.data = defaultLogo
      return tmp
    case actions.UPDATE_PROVIDER_LOGO_REQUEST:
      return reduceDataRequest(state, 'logo')
    case actions.UPDATE_PROVIDER_LOGO_SUCCESS:
      return reduceDataSuccess(state, 'logo', action.payload)
    case actions.UPDATE_PROVIDER_LOGO_ERROR:
      return reduceDataError(state, 'logo', action.error)
    case actions.DELETE_PROVIDER_LOGO_REQUEST:
      return reduceDataRequest(state, 'logo')
    case actions.DELETE_PROVIDER_LOGO_SUCCESS:
      tmp = reduceDataSuccess(state, 'logo', action.payload)
      tmp.logo.data = defaultLogo
      return tmp
    case actions.DELETE_PROVIDER_LOGO_ERROR:
      return reduceDataError(state, 'logo', action.error)

    case actions.GET_PROVIDER_ROLES_REQUEST:
      return reduceDataRequest(state, 'roles')
    case actions.GET_PROVIDER_ROLES_SUCCESS:
      return reduceDataSuccess(state, 'roles', action.payload)
    case actions.GET_PROVIDER_ROLES_ERROR:
      return reduceDataError(state, 'roles', action.error)

    default:
      return crud(state, { type, payload })
  }
}

export default reducers
