import crudActions from '../../crud/actions'

export const actionsDNACPR = {
  ...crudActions('SERVICEUSER_LEGALCHOICE_DOCS_DNACPR'),

  SERVICEUSER_LEGALCHOICE_DOC_DOWNLOAD_REQUEST:
    'SERVICEUSER_LEGALCHOICE_DOC_DNACPR_DOWNLOAD_REQUEST',

  downloadRequest: data => {
    return {
      type: actionsDNACPR.SERVICEUSER_LEGALCHOICE_DOC_DOWNLOAD_REQUEST,
      payload: data
    }
  }
}

export const actionsDoLS = {
  ...crudActions('SERVICEUSER_LEGALCHOICE_DOCS_DOLS'),

  SERVICEUSER_LEGALCHOICE_DOC_DOWNLOAD_REQUEST:
    'SERVICEUSER_LEGALCHOICE_DOC_DOLS_DOWNLOAD_REQUEST',

  downloadRequest: data => {
    return {
      type: actionsDoLS.SERVICEUSER_LEGALCHOICE_DOC_DOWNLOAD_REQUEST,
      payload: data
    }
  }
}
