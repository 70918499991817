import config from "./settings";
import {commonDelete, commonGet, commonPut} from "./common";

class Rounds {
  static getVisitsMatchingRoundFilters(token, providerId, data) {
    const qs = `serviceUserIds=${data.serviceUserIds ? data.serviceUserIds.join() : ''}` +
      // `&careWorkerIds=${data.careWorkerIds ? data.careWorkerIds.join() : ''}` +
      `&locationIds=${data.locationIds ? data.locationIds.join() : ''}` +
      `&visitTypes=${data.visitTypes ? data.visitTypes.join() : ''}` +
      // `&statuses=${data.statuses ? data.statuses.join() : ''}` +
      `&dateFrom=${data.dateFrom ? data.dateFrom : ''}` +
      `&dateTo=${data.dateTo ? data.dateTo : ''}` +
      `&timeFrom=${data.timeFrom ? data.timeFrom.toISOString() : ''}` +
      `&timeTo=${data.timeTo ? data.timeTo.toISOString() : ''}` +
      `&daysOfWeekByWeek=${data.daysOfWeekByWeek ? data.daysOfWeekByWeek : ''}`
    // + `&title=${data.title ? data.title : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/roundsFilterVisits?${qs}`
    return commonGet(token, url)
  }


  static getUnifiedRounds (token, providerId, data) {
    const qs = `serviceUserIds=${data.serviceUserIds ? data.serviceUserIds.join() : ''}` +
      `&careWorkerIds=${data.careWorkerIds ? data.careWorkerIds.join() : ''}` +
      `&locationIds=${data.locationIds ? data.locationIds.join() : ''}` +
      `&visitTypes=${data.visitTypes ? data.visitTypes.join() : ''}` +
      `&statuses=${data.statuses ? data.statuses.join() : ''}` +
      `&timeFrom=${data.timeFrom ? data.timeFrom.toISOString() : ''}` +
      `&timeTo=${data.timeTo ? data.timeTo.toISOString() : ''}` +
      `&dateFrom=${data.dateFrom ? data.dateFrom.format("YYYY-MM-DD") : ''}` +
      `&dateTo=${data.dateTo ? data.dateTo.format("YYYY-MM-DD") : ''}` +
      `&title=${data.title ? data.title : ''}` +
      `&offset=${data.offset ? data.offset : ''}` +
      `&pageSize=${data.pageSize ? data.pageSize : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/rounds?${qs}${data.withAvailability ? '&availability=y' : ''}`
    return commonGet(token, url)
  }



  static assignStaff (token, providerId, data) {
    const {
      roundId,
      date,
      careWorkerId,
      assignedRoundId
    } = data

    if (!careWorkerId && assignedRoundId > 0) {
      // REMOVE
      const url = `${
        config.url
      }/auth/providers/${providerId}/scheduling/rounds/assign/${assignedRoundId}`
      return commonDelete(token, url)
    } else if (careWorkerId && !assignedRoundId) {
      // ASSIGN
        const url = `${
          config.url
        }/auth/providers/${providerId}/scheduling/rounds/assign/${roundId}/${date}/${careWorkerId}`
        return commonPut(token, url)
    }
  }

}



export default Rounds
