import { race, take, put, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import authActions from '../auth/actions'
import serviceuserActions from '../serviceuser/serviceusers/actions'
import { parseError } from '../../../helpers/errors'

const ignoreActionTypes = [
  authActions.LOGIN_REQUEST,
  authActions.TOKEN_REFRESH_REQUEST,
  serviceuserActions.GET_SERVICE_USER_AVATAR_REQUEST
]

// Every interaction with the server is implemented as a three action process consisting of these states: REQUEST, SUCCESS, ERROR
function monitorableAction (action) {
  // Action includes the word REQUEST, but is not in the excluded actions list.
  return (
    action.type.includes('REQUEST') &&
    ignoreActionTypes.every(fragment => !action.type.includes(fragment))
  )
}

function identifyAction (action) {
  return action.type
    .split('_')
    .slice(0, -1)
    .join('_')
}
function getSuccessType (action) {
  return `${identifyAction(action)}_SUCCESS`
}
function getFailType (action) {
  return `${identifyAction(action)}_ERROR`
}

// For every monitorableAction, check to see if there was an "Forbidden" error response.
// If so, refresh the token, and re-run the action.
function * monitor (monitoredAction) {
  const { fail } = yield race({
    success: take(getSuccessType(monitoredAction)),
    fail: take(getFailType(monitoredAction))
  })

  if (fail && fail.error) {
    // The error message will be a serialized error
    const parsedError = parseError(fail.error)

    if (undefined === parsedError || undefined === parsedError.status) {
      // This is likely due to failing to contact api server
      yield put(
        actions.showError({
          code: 0,
          status: 0,
          message: 'Could not reach server'
        })
      )
      return
    }

    switch (parsedError.status) {
      case -1: // Locally Generated Error
        yield put(actions.showError(parsedError))
        break
      case 400: // Server Error
        yield put(actions.showError(parsedError))
        break
      case 403: // Forbidden
        yield put(actions.showError(parsedError))
        break
      case 429: // Too Many Requests
        yield put(actions.showError(parsedError))
        break
      default:
        if (parsedError.status !== 401 && parsedError.code !== 30003) {
          // Not a token refresh
          yield put(actions.showError(parsedError))
        }
        break
    }
  }
}

export default function * () {
  yield takeEvery(monitorableAction, monitor)
}
