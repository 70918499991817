import crudActions from '../../crud/actions'
import { defineGetActionsPrSu } from '../../../actionsHelper'

const DOCUMENT = 'COMMUNICATION_MESSAGES_'

const actions = {
  ...crudActions(DOCUMENT),

  CLEAR: DOCUMENT + '_CLEAR',
  SET_FILTERS: 'SET_FILTERS',
  TOGGLE_FILTER_MODAL: 'TOGGLE_FILTER_MODAL',
  GET_FULL_LIST_REQUEST: DOCUMENT + 'GET_FULL_LIST_REQUEST',
  GET_FULL_LIST_SUCCESS: DOCUMENT + 'GET_FULL_LIST_SUCCESS',
  GET_FULL_LIST_ERROR: DOCUMENT + 'GET_FULL_LIST_ERROR',
  GET_ALERTS_LIST_REQUEST: DOCUMENT + 'GET_ALERTS_LIST_REQUEST',
  GET_ALERTS_LIST_SUCCESS: DOCUMENT + 'GET_ALERTS_LIST_SUCCESS',
  GET_ALERTS_LIST_ERROR: DOCUMENT + 'GET_ALERTS_LIST_ERROR',
  TOGGLE_REPLY_MODAL: 'TOGGLE_REPLY_MODAL',
  TOGGLE_REPLIES: 'TOGGLE_REPLIES',

  setFilters: (type, from, to) => {
    return {
      type: actions.SET_FILTERS,
      payload: { type: type, from: from, to: to }
    }
  },
  toggleFilterModal: () => ({
    type: actions.TOGGLE_FILTER_MODAL
  }),

  getFullList: (providerId, userId, queryParameters) => {
    return {
      type: actions.GET_FULL_LIST_REQUEST,
      providerId,
      userId,
      queryParameters
    }
  },
  getFullListSuccess: result => {
    return {
      type: actions.GET_FULL_LIST_SUCCESS,
      payload: result
    }
  },
  getFullListError: error => {
    return {
      type: actions.GET_FULL_LIST_ERROR,
      error: error
    }
  },
  getAlertsList: (providerId, userId, queryParameters) => {
    return {
      type: actions.GET_ALERTS_LIST_REQUEST,
      providerId,
      userId,
      queryParameters
    }
  },
  getAlertsListSuccess: result => {
    return {
      type: actions.GET_ALERTS_LIST_SUCCESS,
      payload: result
    }
  },
  getAlertsListError: error => {
    return {
      type: actions.GET_ALERTS_LIST_ERROR,
      error: error
    }
  },
  toggleReplyModal: () => ({
    type: actions.TOGGLE_REPLY_MODAL
  }),
  toggleReplies: (commNoteId) => ({
    type: actions.TOGGLE_REPLIES,
    payload: commNoteId
  })
}

defineGetActionsPrSu(actions, 'COMMUNICATION_MESSAGES_DOWNLOAD_CSV', 'downloadCsv')

export default actions
