import {Form} from "antd";
import React, {useEffect, useMemo, useState} from "react";
import Select from "../../../../components/styled/select"
import _ from 'lodash'
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../redux/app/rostering/actions";
import Spin from "../../../../components/styled/spin";

const {Option} = Select


export default function UsersFilter({form, onChange, roles, field, placeholderText, disabled}) {
  const {getFieldDecorator} = form;
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Rostering.filters)
  const { usersMap } = useSelector(state => state.Maps)
  const [searchableServiceUsers, setSearchableServiceUsers] = useState([])
  const [serviceUserResults, setServiceUserResults] = useState([])

  const compareName = (a, b) => {
    if (a.text > b.text) return 1
    if (a.text < b.text) return -1
    return 0
  }

  useEffect(() => {
    let tmpUsers = _.values(usersMap)
      .filter(user => _.intersection(roles, user.role).length > 0)
      .map(user => {
        return {
          code: user.userId,
          text: user.firstName + ' ' + user.lastName
        }
      }).sort(compareName)
    setSearchableServiceUsers(tmpUsers)
    setServiceUserResults(tmpUsers)
  }, [usersMap, roles])

  const setFilter = (serviceUserIds) => {
    dispatch(actions.setFilters({
      [field]: serviceUserIds
    }))
  }

  const handleSearchServiceUsers = (value) => {
    setServiceUserResults(searchFunc(searchableServiceUsers, value))
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchServiceUsersDebounced = useMemo(()=>_.debounce(handleSearchServiceUsers, 250),[searchableServiceUsers])


  const searchFunc = (values, term) => {
    const lcTerm = term.toLowerCase()
    return values.reduce(function (filtered, item) {
      if (item.text.toLowerCase().includes(lcTerm)) {
        filtered.push(item)
      }
      return filtered
    }, [])
  }

  const handleChange = (serviceUsers) => {
    const serviceUserIds = serviceUsers.filter(s => !!s.key).map(s => s.key)
    setFilter(serviceUserIds)
    _.isFunction(onChange) && onChange(serviceUserIds)
  }

  useEffect(() => {
    if (!filters[field] || !_.isArray(filters[field]) || !usersMap || _.isEmpty(usersMap)) {
      return
    }
    const selected = (filters[field] && _.isArray(filters[field])) ? filters[field].map(s => {
      return {
        key: s,
        label: usersMap[s].firstName + ' ' + usersMap[s].lastName
      }
    }) : []

    form.setFieldsValue({[field]: selected})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersMap, filters[field]])

  return <Form.Item className={'multiSelect'}>
    {getFieldDecorator(field, {
      // initialValue: selected,
      rules: [{type: 'array', message: 'Select users!'}]
    })(
      <Select
        mode='multiple'
        labelInValue
        placeholder={placeholderText}
         notFoundContent={!usersMap ? <Spin size='small'/> : null}
        filterOption={false}
        onSearch={handleSearchServiceUsersDebounced}
        // onSelect={handleSelect}
        onChange={handleChange}
        // onDeselect={updateFilters}
        style={{width: '100%'}}
        disabled={disabled}
      >
        {serviceUserResults.map(d => (
          <Option key={d.code}>{d.text}</Option>
        ))}
      </Select>
    )}
  </Form.Item>


}