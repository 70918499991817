import {all, fork} from 'redux-saga/effects'
import Api from '../../../../api'
import actions from './actions'
import {defineGetSaga, defineUpdateSaga} from '../../../sagasHelper'

export function* getPersonalDetailsRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_REQUEST, Api.ServiceUser.getPersonalDetails, actions.getPersonalDetailsSuccess, actions.getPersonalDetailsError)
}

export function* updatePersonalDetailsRequest() {
  yield defineUpdateSaga(actions.UPDATE_SERVICE_USER_REQUEST, Api.ServiceUser.updatePersonalDetails, actions.updatePersonalDetailsSuccess, actions.updatePersonalDetailsError)
}

export function* getPrivateDetailsRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_PRIVATE_REQUEST, Api.ServiceUser.getPrivateDetails, actions.getPrivateDetailsSuccess, actions.getPrivateDetailsError)
}

export function* updatePrivateDetailsRequest() {
  yield defineUpdateSaga(actions.UPDATE_SERVICE_USER_PRIVATE_REQUEST, Api.ServiceUser.updatePrivateDetails, actions.updatePrivateDetailsSuccess, actions.updatePrivateDetailsError)
}

export function* getAppearanceRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_APPEARANCE_REQUEST, Api.ServiceUser.getAppearance, actions.getAppearanceSuccess, actions.getAppearanceError)
}

export function* updateAppearanceRequest() {
  yield defineUpdateSaga(actions.UPDATE_SERVICE_USER_APPEARANCE_REQUEST, Api.ServiceUser.updateAppearance, [actions.updateAppearanceSuccess, actions.getBmiRequest], actions.updateAppearanceError)
}

export function* getAddressRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_ADDRESS_REQUEST, Api.ServiceUser.getAddress, actions.getAddressSuccess, actions.getAddressError)
}

export function* updateAddressRequest() {
  yield defineUpdateSaga(actions.UPDATE_SERVICE_USER_ADDRESS_REQUEST, Api.ServiceUser.updateAddress, actions.updateAddressSuccess, actions.updateAddressError)
}

export function* getAddressGeoFencingRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_ADDRESS_GEO_FENCING_REQUEST, Api.ServiceUser.getAddressGeoFencing, actions.getAddressGeoFencingSuccess, actions.getAddressGeoFencingError)
}

export function* updateAddressGeoFencingRequest() {
  yield defineUpdateSaga(actions.UPDATE_SERVICE_USER_ADDRESS_GEO_FENCING_REQUEST, Api.ServiceUser.updateAddressGeoFencing, actions.updateAddressGeoFencingSuccess, actions.updateAddressGeoFencingError)
}

export function* getAccessDetailsRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_ACCESS_DETAILS_REQUEST, Api.ServiceUser.getAccessDetails, actions.getAccessDetailsSuccess, actions.getAccessDetailsError)
}

export function* updateAccessDetailsRequest() {
  yield defineUpdateSaga(actions.UPDATE_SERVICE_USER_ACCESS_DETAILS_REQUEST, Api.ServiceUser.updateAccessDetails, actions.updateAccessDetailsSuccess, actions.updateAccessDetailsError)
}

export function* getAdvanceCarePlanRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_ADVANCE_CARE_PLAN_REQUEST, Api.ServiceUser.getAdvanceCarePlan, actions.getAdvanceCarePlanSuccess, actions.getAdvanceCarePlanError)
}

export function* updateAdvanceCarePlanRequest() {
  yield defineUpdateSaga(actions.UPDATE_SERVICE_USER_ADVANCE_CARE_PLAN_REQUEST, Api.ServiceUser.updateAdvanceCarePlan, actions.updateAdvanceCarePlanSuccess, actions.updateAdvanceCarePlanError)
}

export function* getLegalChoicesRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_LEGALCHOICES_REQUEST, Api.ServiceUser.getLegalChoices, actions.getLegalChoicesSuccess, actions.getLegalChoicesError)
}

export function* updateLegalChoicesRequest() {
  yield defineUpdateSaga(actions.UPDATE_SERVICE_USER_LEGALCHOICES_REQUEST, Api.ServiceUser.updateLegalChoices, actions.updateLegalChoicesSuccess, actions.updateLegalChoicesError)
}

export function* getServiceInformationRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_SERVICE_INFORMATION_REQUEST, Api.ServiceUser.getServiceInformation, actions.getServiceInformationSuccess, actions.getServiceInformationError)
}

export function* updateServiceInformationRequest() {
  yield defineUpdateSaga(actions.UPDATE_SERVICE_USER_SERVICE_INFORMATION_REQUEST, Api.ServiceUser.updateServiceInformation, actions.updateServiceInformationSuccess, actions.updateServiceInformationError)
}

export function* downloadServiceUserServiceInformationPdfRequest() {
  yield defineGetSaga(actions.DOWNLOAD_SERVICE_USER_SERVICE_INFORMATION_PDF_REQUEST, Api.ServiceUser.downloadServiceUserServiceInformationPdf, actions.downloadServiceUserServiceInformationPdfSuccess, actions.downloadServiceUserServiceInformationPdfError)
}

export function* getBmiRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_BMI_REQUEST, Api.ServiceUser.getBmi, actions.getBmiSuccess, actions.getBmiError)
}

export function* getCareWorkersForSingleUserRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USER_CAREWORKERS_IN_LOCATION_REQUEST, Api.ServicesLocations.getCareWorkersForSingleUser, actions.getCareWorkersForSingleUserSuccess, actions.getCareWorkersForSingleUserError)
}

export function* getServices() {
  yield defineGetSaga(actions.GET_SERVICE_USER_SERVICELOCATIONS_REQUEST, Api.ServicesLocations.getServiceLocationsForSingleUser, actions.getServiceUserServiceLocationsSuccess, actions.getServiceUserServiceLocationsError)
}

export function * downloadPDFRequest () {
  // NOTE WE MAY NEED TO HANDLE TypeError as success
  //         if (error instanceof TypeError) {
  yield defineGetSaga(actions.GET_SERVICE_USER_DOWNLOAD_PDF_REQUEST, Api.ServiceUser.downloadPdf, actions.downloadPdfSuccess, actions.downloadPdfError)
}


export default function* rootSaga() {
  yield all([
    fork(getPersonalDetailsRequest),
    fork(updatePersonalDetailsRequest),
    fork(getPrivateDetailsRequest),
    fork(updatePrivateDetailsRequest),
    fork(getAppearanceRequest),
    fork(updateAppearanceRequest),
    fork(getAddressRequest),
    fork(updateAddressRequest),
    fork(getAddressGeoFencingRequest),
    fork(updateAddressGeoFencingRequest),
    fork(getAccessDetailsRequest),
    fork(updateAccessDetailsRequest),
    fork(getAdvanceCarePlanRequest),
    fork(updateAdvanceCarePlanRequest),
    fork(getBmiRequest),
    fork(getLegalChoicesRequest),
    fork(updateLegalChoicesRequest),
    fork(getCareWorkersForSingleUserRequest),
    fork(getServices),
    fork(getServiceInformationRequest),
    fork(updateServiceInformationRequest),
    fork(downloadServiceUserServiceInformationPdfRequest),
    fork(downloadPDFRequest)
  ])
}
