import { all, call, fork, put, takeEvery } from 'redux-saga/effects'
import actions from './actions'
import interfaceActions from '../../interface/actions'
import crudSagas from '../crud/sagas'
import Api from '../../../api'
import { getAuthToken } from '../../../helpers/tokens'
import {defineGetSagaProvider} from "../../sagasHelper";

export function * getProviderSettingsRequest () {
  yield takeEvery(actions.GET_PROVIDER_SETTINGS_REQUEST, function * ({ providerId }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Provider.getProviderSettings,
        authToken.token,
        providerId)
      if (response) {
        yield put(actions.getProviderSettingsSuccess(response))
        yield put(interfaceActions.setPrimaryColour(response.themePrimaryColour))
      } else {
        yield put(actions.getProviderSettingsError(error))
      }
    }
  })
}

export function * updateProviderSettingsRequest () {
  yield takeEvery(actions.UPDATE_PROVIDER_SETTINGS_REQUEST, function * ({ providerId, payload }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Provider.updateProviderSettings,
        authToken.token,
        providerId,
        payload)
      if (response) {
        yield put(actions.updateProviderSettingsSuccess(response))
      } else {
        yield put(actions.updateProviderSettingsError(error))
      }
    }
  })
}

function * handleLogoRequest (action) {
  const authToken = yield getAuthToken()
  if (authToken && authToken.token) {
    const { response, error } = yield call(
      Api.Provider.getProviderLogo,
      authToken.token,
      action.providerId
    )
    if (response) {
      yield put(actions.getProviderLogoSuccess(response))
    } else {
      yield put(actions.getProviderLogoError(error))
    }
  }
}

export function * getLogoRequest () {
  yield takeEvery(actions.GET_PROVIDER_LOGO_REQUEST, handleLogoRequest)
}

export function * updateLogoRequest () {
  yield takeEvery(actions.UPDATE_PROVIDER_LOGO_REQUEST, function * (
    {
      providerId,
      payload
    }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Provider.updateProviderLogo,
        authToken.token,
        providerId,
        payload
      )
      if (!error) {
        yield put(actions.updateProviderLogoSuccess(response))
        yield put(actions.getProviderLogoRequest(providerId))
      } else {
        yield put(actions.updateProviderLogoError(error))
      }
    }
  })
}

export function * deleteLogoRequest () {
  yield takeEvery(actions.DELETE_PROVIDER_LOGO_REQUEST, function * (
    {
      providerId
    }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Provider.deleteProviderLogo,
        authToken.token,
        providerId
      )
      if (response) {
        yield put(actions.deleteProviderLogoSuccess(response))
        yield put(actions.getProviderLogoRequest(providerId))
      } else {
        yield put(actions.deleteProviderLogoError(error))
      }
    }
  })
}



export function * getProviderRolesRequest () {
  yield defineGetSagaProvider(actions.GET_PROVIDER_ROLES_REQUEST, Api.Provider.getProviderRoles, actions.getProviderRolesSuccess, actions.getProviderRolesError)
}


export default function * devSaga () {
  yield all([
    crudSagas(actions, Api.Crud, 'providers', 'providerId'),
    fork(getProviderSettingsRequest),
    fork(updateProviderSettingsRequest),
    fork(getLogoRequest),
    fork(updateLogoRequest),
    fork(deleteLogoRequest),
    fork(getProviderRolesRequest)
  ])
}
