import actions from './actions'
import crudSagas from '../crud/sagas'
import Api from '../../../api'
import {all} from "redux-saga/effects";
import {defineGetSagaProvider} from "../../sagasHelper";

export function* getVisitsMatchingRoundRequest() {
  yield defineGetSagaProvider(actions.ROUNDS_GET_VISITS_MATCHING_ROUND_REQUEST, Api.Rounds.getVisitsMatchingRoundFilters, actions.getVisitsMatchingRoundSuccess, actions.getVisitsMatchingRoundError)
}

export function* getUnifiedRoundsRequest() {
  yield defineGetSagaProvider(actions.ROUNDS_GET_UNIFIED_ROUNDS_REQUEST, Api.Rounds.getUnifiedRounds, actions.getUnifiedRoundsSuccess, actions.getUnifiedRoundsError)
}

export function* assignStaffRequest() {
  yield defineGetSagaProvider(actions.ROUNDS_ASSIGN_STAFF_REQUEST, Api.Rounds.assignStaff, actions.assignStaffSuccess, actions.assignStaffError)
}


export default function* devSaga() {
  yield all([
    crudSagas(
      actions,
      Api.Crud,
      'providers/:providerId/rounds',
      'id'
    ),
    getVisitsMatchingRoundRequest(),
    getUnifiedRoundsRequest(),
    assignStaffRequest()
  ])
}
