import crudActions from '../../crud/actions'

const actions = {
  ...crudActions('HR_CONDITION_DOCS_'),

  HR_CONDITION_DOC_DOWNLOAD_REQUEST: 'HR_CONDITION_DOC_DOWNLOAD_REQUEST',

  downloadRequest: (data) => {
    return {
      type: actions.HR_CONDITION_DOC_DOWNLOAD_REQUEST,
      payload: data
    }
  }
}

export default actions
