import BasicLists from './basicLists/reducers'
import CareVisitTypes from './careVisitTypes/reducers'
import { reducers as SchedulingExceptionTypes } from './schedulingExceptionTypes'
import Foods from './foods/reducers'
import Hydration from './hydration/reducers'
import SupportTaskList from './supportTaskList/reducers'
import { combineReducers } from 'redux'

const initBaseState = {}

const BaseReducer = (state = initBaseState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export default combineReducers({
  Base: BaseReducer,
  BasicLists,
  CareVisitTypes,
  SchedulingExceptionTypes,
  Foods,
  Hydration,
  SupportTaskList
})
