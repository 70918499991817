import actions from './actions'
import crudSagas from '../../crud/sagas'
import Api from '../../../../api'

export default function * devSaga () {
  yield crudSagas(
    actions,
    Api.Crud,
    'providers/:providerId/settings/food',
    'key'
  )
}
