import config from './settings'
import { commonGet } from './common'

export default class Availability {
  static getUnifiedAvailability (token, providerId, data) {
    const qs = `staffUserIds=${data.staffUserIds ? data.staffUserIds.join() : ''}` +
      `&serviceUserIds=${data.serviceUserIds ? data.serviceUserIds.join() : ''}` +
      `&locationIds=${data.locationIds ? data.locationIds.join() : ''}` +
      `&roles=${data.roles ? data.roles.join() : ''}` +
      `&statuses=${data.statuses ? data.statuses.join() : ''}` +
      `&title=${data.title ? data.title : ''}` +
      `&timeFrom=${data.timeFrom ? data.timeFrom.toISOString() : ''}` +
      `&timeTo=${data.timeTo ? data.timeTo.toISOString() : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/availability/unified?${qs}`
    return commonGet(token, url)
  }
}
