const actions = {
  NOOP: 'NOOP',
  CONNECTION_ERROR: 'CONNECTION_ERROR',
  SHOW_ERROR: 'SHOW_ERROR',
  REMOVE_ERROR: 'REMOVE_ERROR',

  connectionError: error => ({
    type: actions.CONNECTION_ERROR,
    error
  }),

  showError: error => {
    // Don't show the error for HealthTracker Authcode not found
    if (error.code === 20038) {
      return { type: actions.NOOP }
    }

    // Don't show the error for HealthTracker Fitbit if there is insufficient_scope
    if (
      error.status === 0 &&
      error.code === 0 &&
      error.message === 'insufficient_scope'
    ) {
      return { type: actions.NOOP }
    }

    return {
      type: actions.SHOW_ERROR,
      error
    }
  },

  removeError: () => ({
    type: actions.REMOVE_ERROR
  })
}
export default actions
