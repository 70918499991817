import { all, put, call, takeEvery, fork } from 'redux-saga/effects'
import actions from './actions'
import crudSagas from '../crud/sagas'
import usersActions from '../providerusers/actions'
import { getAuthToken } from '../../../helpers/tokens'
import Api from '../../../api'

export function * activateRequest () {
  yield takeEvery(actions.ACTIVATE_REQUEST, function * ({ payload }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Users.activateRequest,
        authToken.token,
        payload.data.providerId,
        payload.email
      )
      if (response) {
        yield put(actions.activateSuccess(response))
      } else {
        yield put(actions.activateError(error))
      }
      yield put(usersActions.getList(payload.data))
      yield put(actions.getList(payload.data))
    }
  })
}

export function * createServiceUserRequest () {
  yield takeEvery(actions.CREATE_SERVICEUSER_REQUEST, function * ({ payload }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.ServiceUser.createServiceUser,
        authToken.token,
        payload.providerId,
        payload.serviceuser
      )
      if (response) {
        yield put(actions.createServiceUserSuccess(response))
        yield put(usersActions.getList({ providerId: payload.providerId }))
        yield put(actions.getList({ providerId: payload.providerId }))
        yield put(actions.toggleModal(null))
      } else {
        yield put(actions.createServiceUserError(error))
      }
    }
  })
}
export default function * devSaga () {
  yield all([
    crudSagas(actions, Api.Crud, 'providers/:providerId/newusers', 'email'),
    fork(createServiceUserRequest),
    fork(activateRequest)
  ])
}
