const actions = {
  SEARCH_DMDAMPP_REQUEST: 'SEARCH_DMDAMPP_REQUEST',
  SEARCH_DMDAMPP_SUCCESS: 'SEARCH_DMDAMPP_SUCCESS',
  SEARCH_DMDAMPP_ERROR: 'SEARCH_DMDAMPP_ERROR',

  searchDmdAmppRequest: (providerId, searchTerm) => {
    return {
      type: actions.SEARCH_DMDAMPP_REQUEST,
      payload: {
        providerId: providerId,
        searchTerm: searchTerm
      }
    }
  },
  searchDmdAmppSuccess: result => {
    return {
      type: actions.SEARCH_DMDAMPP_SUCCESS,
      payload: result
    }
  },
  searchDmdAmppError: error => {
    return {
      type: actions.SEARCH_DMDAMPP_ERROR,
      error: error
    }
  },

  DOSE_FORMS_REQUEST: 'DOSE_FORMS_REQUEST',
  DOSE_FORMS_SUCCESS: 'DOSE_FORMS_SUCCESS',
  DOSE_FORMS_ERROR: 'DOSE_FORMS_ERROR',

  doseFormRequest: (providerId) => {
    return {
      type: actions.DOSE_FORMS_REQUEST,
      payload: {
        providerId: providerId
      }
    }
  },
  doseFormSuccess: result => {
    return {
      type: actions.DOSE_FORMS_SUCCESS,
      payload: result
    }
  },
  doseFormError: error => {
    return {
      type: actions.DOSE_FORMS_ERROR,
      error: error
    }
  },

  DRUG_ROUTES_REQUEST: 'DRUG_ROUTES_REQUEST',
  DRUG_ROUTES_SUCCESS: 'DRUG_ROUTES_SUCCESS',
  DRUG_ROUTES_ERROR: 'DRUG_ROUTES_ERROR',

  drugRoutesRequest: (providerId) => {
    return {
      type: actions.DRUG_ROUTES_REQUEST,
      payload: {
        providerId: providerId
      }
    }
  },
  drugRoutesSuccess: result => {
    return {
      type: actions.DRUG_ROUTES_SUCCESS,
      payload: result
    }
  },
  drugRoutesError: error => {
    return {
      type: actions.DRUG_ROUTES_ERROR,
      error: error
    }
  },

  CONTROLLED_DRUG_CATEGORIES_REQUEST: 'CONTROLLED_DRUG_CATEGORIES_REQUEST',
  CONTROLLED_DRUG_CATEGORIES_SUCCESS: 'CONTROLLED_DRUG_CATEGORIES_SUCCESS',
  CONTROLLED_DRUG_CATEGORIES_ERROR: 'CONTROLLED_DRUG_CATEGORIES_ERROR',

  controlledDrugCategoryRequest: (providerId) => {
    return {
      type: actions.CONTROLLED_DRUG_CATEGORIES_REQUEST,
      payload: {
        providerId: providerId
      }
    }
  },
  controlledDrugCategorySuccess: result => {
    return {
      type: actions.CONTROLLED_DRUG_CATEGORIES_SUCCESS,
      payload: result
    }
  },
  controlledDrugCategoryError: error => {
    return {
      type: actions.CONTROLLED_DRUG_CATEGORIES_ERROR,
      error: error
    }
  }
}

export default actions
