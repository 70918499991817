import _ from 'lodash'

/*
  // THIS FUNCTION DEFINES ACTIONS WITH THE FOLLOWING FORMAT
  //
  // ACTION_PREFIX_REQUEST: 'ACTION_PREFIX_REQUEST',
  // ACTION_PREFIX_SUCCESS: 'ACTION_PREFIX_SUCCESS',
  // ACTION_PREFIX_ERROR: 'ACTION_PREFIX_ERROR',
  // funcPrefixRequest: (providerId, userId) => getRequestAction(actions.ACTION_PREFIX_REQUEST, {
  //   providerId,
  //   userId
  // }),
  // funcPrefixSuccess: result => getSuccessAction(actions.ACTION_PREFIX_SUCCESS, result),
  // funcPrefixError: error => getFailureAction(actions.ACTION_PREFIX_ERROR, error)
  //
 */
export function defineGetActionsPrSu (actions, actionPrefix, funcPrefix) {
  const actionRequest = actionPrefix + '_REQUEST'
  const actionSuccess = actionPrefix + '_SUCCESS'
  const actionFailure = actionPrefix + '_ERROR'

  const functionRequest = funcPrefix + 'Request'
  const functionSuccess = funcPrefix + 'Success'
  const functionFailure = funcPrefix + 'Error'

  actions[actionRequest] = actionRequest
  actions[actionSuccess] = actionSuccess
  actions[actionFailure] = actionFailure

  actions[functionRequest] = (ids, data, onSuccessCallback, onFailureCallback) => getRequestAction(actionRequest, ids, data, onSuccessCallback, onFailureCallback)
  actions[functionSuccess] = (ids, result) => getSuccessAction(actionSuccess, ids, result)
  actions[functionFailure] = (ids, error) => getFailureAction(actionFailure, ids, error)
}

/*
  // UPDATE_SERVICE_USER_REQUEST: 'UPDATE_SERVICE_USER_REQUEST',
  // UPDATE_SERVICE_USER_SUCCESS: 'UPDATE_SERVICE_USER_SUCCESS',
  // UPDATE_SERVICE_USER_ERROR: 'UPDATE_SERVICE_USER_ERROR',
  // updatePersonalDetailsRequest: (providerId, userId, serviceUser) => updateRequestAction(actions.UPDATE_SERVICE_USER_REQUEST, {
  //   providerId,
  //   userId
  // }, serviceUser),
  // updatePersonalDetailsSuccess: result => updateSuccessAction(actions.UPDATE_SERVICE_USER_SUCCESS, result),
  // updatePersonalDetailsError: error => updateFailureAction(actions.UPDATE_SERVICE_USER_ERROR, error),
  //
  // updateModelPersonalDetails: values => {
  //   return {
  //     type: actions.UPDATE_MODEL_PERSONALDETAILS,
  //     payload: values
  //   }
  // },
 */
export function defineUpdateActionsPrSu (actions, actionPrefix, funcPrefix) {
  const actionRequest = actionPrefix + '_REQUEST'
  const actionSuccess = actionPrefix + '_SUCCESS'
  const actionFailure = actionPrefix + '_ERROR'

  const functionRequest = funcPrefix + 'Request'
  const functionSuccess = funcPrefix + 'Success'
  const functionFailure = funcPrefix + 'Error'

  actions[actionRequest] = actionRequest
  actions[actionSuccess] = actionSuccess
  actions[actionFailure] = actionFailure

  actions[functionRequest] = (ids, payload, onSuccessCallback, onFailureCallback) => updateRequestAction(actionRequest, ids, payload, onSuccessCallback, onFailureCallback)
  actions[functionSuccess] = (ids, result) => updateSuccessAction(actionSuccess, ids, result)
  actions[functionFailure] = (ids, error) => updateFailureAction(actionFailure, ids, error)
}

export function getRequestAction (type, ids, data, onSuccessCallback, onFailureCallback) {
  if (!_.isObject(ids)) {
    console.trace('Not an object', ids)
    throw new Error('Not an object')
  }
  return {
    type: type,
    ids: ids,
    data: data,
    onSuccessCallback,
    onFailureCallback
  }
}

export function getSuccessAction (type, ids, payload) {
  return {
    type: type,
    payload: payload,
    ids: ids
  }
}

export function getFailureAction (type, ids, error) {
  return {
    type: type,
    error: error,
    ids: ids
  }
}

export function updateRequestAction (type, ids, payload, onSuccessCallback, onFailureCallback) {
  if (!_.isObject(ids)) {
    console.trace('Not an object', ids)
    throw new Error('Not an object')
  }
  return {
    type: type,
    payload: payload,
    ids: ids,
    onSuccessCallback,
    onFailureCallback
  }
}

export function updateSuccessAction (type, ids, payload) {
  return {
    type: type,
    payload: payload,
    ids: ids
  }
}

export function updateFailureAction (type, ids, error) {
  return {
    type: type,
    error: error,
    ids: ids
  }
}
