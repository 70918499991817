import {useCallback, useEffect, useRef, useState} from 'react'
import _ from 'lodash'
import {useSelector} from "react-redux";

// useHover Hook
export function useHover() {
  const [value, setValue] = useState(false)

  const ref = useRef(null)

  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(
    () => {
      const node = ref.current
      if (node) {
        node.addEventListener('mouseover', handleMouseOver)
        node.addEventListener('mouseout', handleMouseOut)

        return () => {
          node.removeEventListener('mouseover', handleMouseOver)
          node.removeEventListener('mouseout', handleMouseOut)
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current] // Recall only if ref changes
  )

  return [ref, value]
}

const check = (e) => {
  if (Array.isArray(e)) {
    return e.length === 0
  } else if (e && e === '0001-01-01T00:00:00Z') {
    return true
  } else if (e && e.target) {
    return !e.target.value
  } else {
    return !e
  }
}

const getValue = (e) => {
  if (Array.isArray(e)) {
    return e
  } else if (e && e.target) {
    return e.target.value
  } else {
    return e
  }
}

export function usePermissions() {
  const {loading, loaded, data, error} = useSelector((state) => state.AuthUser.permissions)
  const authUser = useSelector((state) => state.AuthUser.user)
  const [permissions, setPermissions] = useState({})

  useEffect(() => {
    if (loading || !loaded || error) {
      return
    }
    if (!_.isEmpty(permissions) && !loading && loaded && !error) {
      return
    }
    setPermissions(process(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, loaded, error, data])

  const _hasPermission = (permissions, perm) => {
    if (!permissions) {
      return false
    }
    return (!!permissions[perm])
  }

  const hasPermission = useCallback(function (perm) {
    return _hasPermission(permissions, perm)
  }, [permissions]);

  const hasAll = useCallback(function (perms) {
    if (!perms || perms.length === 0) {
      return false
    }
    for (let i = 0; i < perms.length; i++) {
      if (!permissions[perms[i]]) return false
    }
    return true
  }, [permissions])

  const hasAny = useCallback(function (perms) {
    if (!perms || perms.length === 0) {
      return false
    }
    for (let i = 0; i < perms.length; i++) {
      if (permissions[perms[i]]) return true
    }
    return false
  }, [permissions])

  const hasRole = useCallback(function (roles) {
    if (!authUser) {
      return false
    }
    return roles.includes(authUser.role)
  }, [authUser]);

  const process = (data) =>
    _.reduce(data, (result, value,) => {
      result[value] = true
      // const [, obj, act] = value
      // if (!result[obj]) {
      //   result[obj] = []
      // }
      // result[obj].push(act)
      return result
    }, {})

  if (_.isEmpty(permissions))
    return {
      hasPermission: () => false,
      hasAll: null,
      hasAny: null,
      hasRole
    }

  return {hasPermission, hasAll, hasAny, hasRole, ready: !_.isEmpty(permissions)}

}


export function useIncomplete(fields, func) {
  const [incomplete, setIncomplete] = useState(
    Object.fromEntries(fields.map((f) => [f, false]))
  )
  const handlers = Object.fromEntries(
    fields.map((f) => [
      f,
      (e, form) => {
        if (_.isFunction(func)) {
          setIncomplete(func(_.set(form, f, getValue(e))))
        } else {
          setIncomplete({...incomplete, [f]: check(e)})
        }
      }
    ])
  )
  const resetIncompleteness = (form) => {
    if (_.isFunction(func)) {
      setIncomplete(func(form))
    } else {
      setIncomplete(Object.fromEntries(fields.map((f) => [f, check(form[f])])))
    }
  }
  return [incomplete, handlers, resetIncompleteness]
}

// https://stackoverflow.com/a/53446665
export function usePrevious(value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
