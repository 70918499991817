import {Form} from "antd";
import React, {useEffect, useMemo} from "react";
import Input from "../../../../components/styled/input"
import _ from 'lodash'
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../redux/app/rostering/actions";
import {FIELD_TITLE} from "./Filters";


export default function TitleFilter({form, onChange, placeholderText, disabled}) {
  const {getFieldDecorator} = form;
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Rostering.filters)

  useEffect(() => {
    form.setFieldsValue({[FIELD_TITLE]: filters.title})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters])

  const setFilter = (title) => {
    dispatch(actions.setFilters({
      [FIELD_TITLE]: title
    }))
  }

  const handleChange = (title) => {
    setFilter(title)
    _.isFunction(onChange) && onChange(title)
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeDebounced = useMemo(() => _.debounce(handleChange, 500), [])


  return <Form.Item className={'visitName'}>
    {getFieldDecorator(FIELD_TITLE, {
      // initialValue: filters[FIELD_TITLE] ? filters[FIELD_TITLE] : '' ,
      rules: [{type: 'string', message: 'placeholderText'}]
    })(
      <Input placeholder={placeholderText}
             onChange={(e) => handleChangeDebounced(e.target.value)}
             disabled={disabled}
      />
    )}
  </Form.Item>


}