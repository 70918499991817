import { defineGetActionsPrSu, defineUpdateActionsPrSu } from '../../../actionsHelper'

const actions = {
  CLEAR_CAREPLAN_PDF: 'CLEAR_CAREPLAN_PDF',
  clearPdf: () => {
    return {
      type: actions.CLEAR_CAREPLAN_PDF
    }
  }
}

defineGetActionsPrSu(actions, 'GET_CAREPLAN', 'getCarePlan')
defineGetActionsPrSu(actions, 'GET_CAREPLAN_PDF', 'getPdf')
defineUpdateActionsPrSu(actions, 'SET_CAREPLAN_CARE_PREFERENCES', 'setCarePlanCarePreferences')
defineUpdateActionsPrSu(actions, 'SET_CAREPLAN_NEXT_REVIEW', 'setCarePlanNextReview')

defineGetActionsPrSu(actions, 'GET_CAREPLAN_AUDIT_HISTORY', 'getAuditHistory')

export default actions
