import actions from './actions'
import crudSagas from '../../crud/sagas'
import Api from '../../../../api'
import {all, fork} from "redux-saga/effects";
import {call, put, takeEvery} from "@redux-saga/core/effects";
import {getAuthToken} from "../../../../helpers/tokens";
import _ from "lodash";

export function * getAvailableTemplatesRequest () {
  yield takeEvery(actions.CUSTOM_FORMS_GET_AVAILABLE_TEMPLATES_REQUEST, function * ({ ids, onSuccessCallback, onFailureCallback }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.CustomForms.getAvailableTemplates,
        authToken.token,
        ids.providerId,
        ids.userId
      )
      if (response) {
        yield put(actions.getAvailableTemplatesSuccess(ids, response))
        _.isFunction(onSuccessCallback) && onSuccessCallback(response)
      } else {
        yield put(actions.getAvailableTemplatesError(ids, error))
        _.isFunction(onFailureCallback) && onFailureCallback(error)
      }
    }
  })
}


export function * createUserFormFromTemplateRequest () {
  yield takeEvery(actions.CUSTOM_FORMS_CREATE_USER_FORM_FROM_TEMPLATE_REQUEST, function * ({ ids, payload, onSuccessCallback, onFailureCallback }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.CustomForms.createUserFormFromTemplate,
        authToken.token,
        ids.providerId,
        ids.userId,
        ids.templateId,
        payload,
      )
      if (response) {
        yield put(actions.createUserFormFromTemplateSuccess(ids, response))
        _.isFunction(onSuccessCallback) && onSuccessCallback(response)
      } else {
        yield put(actions.createUserFormFromTemplateError(ids, error))
        _.isFunction(onFailureCallback) && onFailureCallback(error)
      }
    }
  })
}


export default function * devSaga () {
  yield all([
    crudSagas(actions, Api.Crud, 'providers/:providerId/users/:userId/forms', 'formId'),
    fork(getAvailableTemplatesRequest),
    fork(createUserFormFromTemplateRequest)
  ])
}
