import { all, fork } from "redux-saga/effects";
import actions from "./actions";
import {
  defineGetSagaProvider,
  defineUpdateSagaProvider,
} from "../../sagasHelper";
import Api from "../../../api";
import { put, takeEvery } from "@redux-saga/core/effects";
import moment from "moment-timezone";

export function* getMyScheduleRequest() {
  yield defineGetSagaProvider(
    actions.GET_MY_SCHEDULE + "_REQUEST",
    Api.CareWorker.getMySchedule,
    actions.getMyScheduleSuccess,
    actions.getMyScheduleError
  );
}

export function* getMyAcceptedVisitRequest() {
  yield defineGetSagaProvider(
    actions.GET_MY_ACCEPTED_VISIT + "_REQUEST",
    Api.CareWorker.getMyAcceptedVisit,
    actions.getMyAcceptedVisitSuccess,
    actions.getMyAcceptedVisitError
  );
}

export function* acceptVisitRequest() {
  yield defineUpdateSagaProvider(
    actions.ACCEPT_VISIT + "_REQUEST",
    Api.CareWorker.patchVisit,
    actions.acceptVisitSuccess,
    actions.acceptVisitError
  );
}

export function* acceptVisitSuccess() {
  yield takeEvery(
    actions.ACCEPT_VISIT + "_SUCCESS",
    function* ({ ids, payload }) {
      yield put(actions.setVisitData(payload));
      yield put(
        actions.startVisitRequest(ids, {
          serviceUserId: payload.serviceUserId,
          carePlanVisitId: payload.carePlanVisitId,
          visitDate: moment(payload.dateOfVisit).format("YYYY-MM-DD"),
          patchAction: "start",
        })
      );
    }
  );
}

export function* startVisitRequest() {
  yield defineUpdateSagaProvider(
    actions.START_VISIT + "_REQUEST",
    Api.CareWorker.patchVisit,
    actions.startVisitSuccess,
    actions.startVisitError
  );
}

export function* startVisitSuccess() {
  yield takeEvery(actions.START_VISIT + "_SUCCESS", function* ({ payload }) {
    yield put(actions.setVisitData(payload));
  });
}

export function* updateAcceptedVisitRequest() {
  yield defineUpdateSagaProvider(
    actions.UPDATE_ACCEPTED_VISIT + "_REQUEST",
    Api.CareWorker.updateAcceptedVisit,
    actions.updateAcceptedVisitSuccess,
    actions.updateAcceptedVisitError
  );
}

export function* updateAcceptedVisitSuccess() {
  yield takeEvery(
    actions.UPDATE_ACCEPTED_VISIT + "_SUCCESS",
    function* ({ ids, payload }) {
      yield put(actions.setVisitData(payload));
      yield put(actions.getMyScheduleRequest(ids));
    }
  );
}

export default function* rootSaga() {
  yield all([
    fork(getMyScheduleRequest),
    fork(getMyAcceptedVisitRequest),
    fork(acceptVisitRequest),
    fork(acceptVisitSuccess),
    fork(startVisitRequest),
    fork(startVisitSuccess),
    fork(updateAcceptedVisitRequest),
    fork(updateAcceptedVisitSuccess),
  ]);
}
