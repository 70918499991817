import config from "./settings";
import {commonDelete, commonGet, commonPut} from "./common";


export default class Rostering {

  static getUnifiedRoster(token, providerId, data) {
    const qs = `serviceUserIds=${data.serviceUserIds ? data.serviceUserIds.join() : ''}` +
      `&staffUserIds=${data.staffUserIds ? data.staffUserIds.join() : ''}` +
      `&locationIds=${data.locationIds ? data.locationIds.join() : ''}` +
      `&visitTypes=${data.visitTypes ? data.visitTypes.join() : ''}` +
      `&visitStatuses=${data.visitStatuses ? data.visitStatuses.join() : ''}` +
      `&availabilityStatuses=${data.availabilityStatuses ? data.availabilityStatuses.join() : ''}` +
      `&roundStatuses=${data.roundStatuses ? data.roundStatuses.join() : ''}` +
      `&timeFrom=${data.timeFrom ? data.timeFrom.toISOString() : ''}` +
      `&timeTo=${data.timeTo ? data.timeTo.toISOString() : ''}` +
      `&dateFrom=${data.dateFrom ? data.dateFrom.toISOString() : ''}` +
      `&dateTo=${data.dateTo ? data.dateTo.toISOString() : ''}` +
      `&title=${data.title ? data.title : ''}` +
      `&offset=${data.offset ? data.offset : 0}` +
      `&pageSize=${data.pageSize ? data.pageSize : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/roster?${qs}`
    return commonGet(token, url)
  }

  static assignStaff(token, providerId, userId, data) {
    const {
      visitId,
      date,
      careWorkerNum,
      careWorkerId,
      scheduledVisitId,
    } = data

    if (!careWorkerId && scheduledVisitId) {
      // REMOVE
      const url = `${
        config.url
      }/auth/providers/${providerId}/scheduling/visits/assign/${userId}/${visitId}/${date}/${scheduledVisitId}`
      return commonDelete(token, url)
    } else if (careWorkerId && !scheduledVisitId) {
      // ASSIGN
      const url = `${
        config.url
      }/auth/providers/${providerId}/scheduling/visits/assign/${userId}/${visitId}/${date}/${careWorkerNum}/${careWorkerId}`
      return commonPut(token, url)
    }
  }

  static setCustomTime(token, providerId, userId, data) {
    const {
      visitId,
      date,
      careWorkerNum,
      startTime,
      endTime
    } = data

    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/visits/customTime/${userId}/${visitId}/${date}/${careWorkerNum}/${startTime}/${endTime}`
    return commonPut(token, url)
  }

  static clearCustomTime(token, providerId, userId, data) {
    const {
      visitId,
      date,
      careWorkerNum,
    } = data

    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/visits/customTime/${userId}/${visitId}/${date}/${careWorkerNum}`
    return commonDelete(token, url)
  }

  static setAdjustedActualTimes(token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/visits/persisted/${data.persistedVisitId}/adjustTimes`
    return commonPut(token, url, data)
  }


}