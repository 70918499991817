import {Form} from "antd";
import React, {useContext, useEffect, useState} from "react";
import Select from "../../../../components/styled/select"
import _ from 'lodash'
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../redux/app/rostering/actions";
import Spin from "../../../../components/styled/spin";
import {AuthContext} from "../../AuthContextProvider";
import {FIELD_LOCATIONS} from "./Filters";
import {usePermissions} from "../../../../helpers/hooks";
import ao from "../../../../config/permissions";

const {Option, OptGroup} = Select


export default function LocationsFilter({form, onChange, disabled, multiple = 'multiple', includeNoLocation = false}) {
  const {getFieldDecorator} = form;
  const dispatch = useDispatch()
  const {authServices: authProviderServices} = useContext(AuthContext)
  const filters = useSelector(state => state.Rostering.filters)
  const [locationOptions, setLocationOptions] = useState([])
  const providerServices = useSelector(state => state.ServicesLocations.services)
  const {hasPermission} = usePermissions()


  const compareName = (a, b) => {
    if (a.text > b.text) return 1
    if (a.text < b.text) return -1
    return 0
  }

  const processServices = (data) => {
    return _.values(data).sort(compareName).map(s => ({...s, locations: _.values(s.locations).sort(compareName)}))
  }

  useEffect(() => {
    // if (!providerServices.loading && !providerServices.listLoaded) {
    //   dispatch(actions.getList({
    //     providerId: authUser.providerId
    //   }))
    // }

    if (hasPermission(ao.perm.UserUnconstrainedByServiceLocationAllow)) {
      if (providerServices.list && providerServices.listLoaded) {
        const processed = processServices(providerServices.list)
        setLocationOptions(processed)
      }
    } else {
      if (authProviderServices.data && authProviderServices.loaded) {
        const processed = processServices(authProviderServices.data)
        setLocationOptions(processed)
      }
    }


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProviderServices, providerServices, hasPermission])


  useEffect(() => {

    if (!locationOptions) {
      return
    }

    if (locationOptions.length === 1 && locationOptions[0].locations.length === 1) {
      setFilter([locationOptions[0].locations[0].id])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationOptions])

  const setFilter = (locationIds) => {
    dispatch(actions.setFilters({
      [FIELD_LOCATIONS]: locationIds
    }))
  }

  const handleChange = (locations) => {
    let locationIds
    if (multiple) {
      locationIds = locations.filter(s => !!s.key).map(s => s.key)
    } else {
      locationIds = [locations.key]
    }
    if (locationIds.length > 0) {
      setFilter(locationIds)
    }
    _.isFunction(onChange) && onChange(locationIds)
  }

  useEffect(() => {
    if (!filters[FIELD_LOCATIONS] || !_.isArray(filters[FIELD_LOCATIONS]) || !authProviderServices) {
      return
    }
    const selected = (filters[FIELD_LOCATIONS] && _.isArray(filters[FIELD_LOCATIONS])) ? filters[FIELD_LOCATIONS].map(s => {
      return {
        key: s,
        label: locationOptions[s]
      }
    }) : []

    form.setFieldsValue({[FIELD_LOCATIONS]: selected})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authProviderServices, filters[FIELD_LOCATIONS]])

  return <Form.Item className={'multiSelect'}>
    {getFieldDecorator(FIELD_LOCATIONS, {
      // initialValue: [],
      rules: [{type: 'array', message: multiple ? 'Select locations!' : 'Select location!'}]
    })(
      <Select
        disabled={disabled}
        mode={multiple}
        labelInValue
        placeholder={multiple ? 'Select locations...' : 'Select location...'}
        showSearch
        notFoundContent={authProviderServices.loading ? <Spin size='small'/> : null}
        filterOption={(input, option) => {
          if (!input) {
            return false
          }
          if (!option.props.label) {
            return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          } else {
            return option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        }
        }
        onChange={handleChange}
        style={{width: '100%'}}
      >
        {locationOptions.map(s => (
          <OptGroup key={s.id} label={s.name}>{s.locations.map(l => (
            <Option key={l.id} value={l.id}>{l.name}</Option>))}
          </OptGroup>
        ))}
        {includeNoLocation &&
          <OptGroup key={'other'} label={'Other'}><Option key={'none'} value={''}>{'No Location'}</Option></OptGroup>}
      </Select>
    )}
  </Form.Item>


}