import { all } from 'redux-saga/effects'
import basicListsSagas from './basicLists/sagas'
import careVisitTypesSagas from './careVisitTypes/sagas'
import { sagas as schedulingExceptionTypesSagas } from './schedulingExceptionTypes'
import foodsSagas from './foods/sagas'
import hydrationSagas from './hydration/sagas'
import SupportTaskListSagas from './supportTaskList/sagas'

export default function * settingsSagas () {
  yield all([
    basicListsSagas(),
    careVisitTypesSagas(),
    schedulingExceptionTypesSagas(),
    foodsSagas(),
    hydrationSagas(),
    SupportTaskListSagas()
  ])
}
