import crudActions from '../../crud/actions'

// Actions for the additional documents uploaded to the serviceuser condition
const actions = {
  ...crudActions('SERVICEUSER_DOCUMENT_DOCS_'),

  SERVICEUSER_DOCUMENT_DOC_DOWNLOAD_REQUEST:
    'SERVICEUSER_DOCUMENT_DOC_DOWNLOAD_REQUEST',

  downloadRequest: data => {
    return {
      type: actions.SERVICEUSER_DOCUMENT_DOC_DOWNLOAD_REQUEST,
      payload: data
    }
  }
}

export default actions
