import { all, fork } from 'redux-saga/effects'
import Api from '../../../../api'
import actions from './actions'
import { defineGetSaga } from '../../../sagasHelper'

// Summary
export function * getEmisSummaryRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_SUMMARY_REQUEST, Api.Emis.getSummary, actions.getEmisSummarySuccess, actions.getEmisSummaryError)
}

// Problems
export function * getEmisProblemRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_PROBLEM_REQUEST, Api.Emis.getProblem, actions.getEmisProblemSuccess, actions.getEmisProblemError)
}

export function * getEmisProblemCurrentRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_PROBLEMCURRENT_REQUEST, Api.Emis.getProblemCurrent, actions.getEmisProblemCurrentSuccess, actions.getEmisProblemCurrentError)
}

export function * getEmisProblemPastRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_PROBLEMPAST_REQUEST, Api.Emis.getProblemPast, actions.getEmisProblemPastSuccess, actions.getEmisProblemPastError)
}

export function * getEmisStandardProblemRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_STANDARDPROBLEM_REQUEST, Api.Emis.getStandardProblem, actions.getEmisStandardProblemSuccess, actions.getEmisStandardProblemError)
}

// Diagnosis
export function * getEmisDiagnosisRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_DIAGNOSIS_REQUEST, Api.Emis.getDiagnosis, actions.getEmisDiagnosisSuccess, actions.getEmisDiagnosisError)
}

// Medications
export function * getEmisMedicationRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_MEDICATION_REQUEST, Api.Emis.getMedication, actions.getEmisMedicationSuccess, actions.getEmisMedicationError)
}

export function * getEmisMedicationCurrentRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_MEDICATIONCURRENT_REQUEST, Api.Emis.getMedicationCurrent, actions.getEmisMedicationCurrentSuccess, actions.getEmisMedicationCurrentError)
}

export function * getEmisMedicationPastRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_MEDICATIONPAST_REQUEST, Api.Emis.getMedicationPast, actions.getEmisMedicationPastSuccess, actions.getEmisMedicationPastError)
}

export function * getEmisMedicationIssueRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_MEDICATIONISSUE_REQUEST, Api.Emis.getMedicationIssue, actions.getEmisMedicationIssueSuccess, actions.getEmisMedicationIssueError)
}

// Risks and Warnings
export function * getEmisRisksWarningRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_RISKSWARNING_REQUEST, Api.Emis.getRisksWarning, actions.getEmisRisksWarningSuccess, actions.getEmisRisksWarningError)
}

export function * getEmisAllergyRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_ALLERGY_REQUEST, Api.Emis.getAllergy, actions.getEmisAllergySuccess, actions.getEmisAllergyError)
}

export function * getEmisContraIndicationRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_CONTRAINDICATION_REQUEST, Api.Emis.getContraIndication, actions.getEmisContraIndicationSuccess, actions.getEmisContraIndicationError)
}

// Procedures
export function * getEmisProcedureRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_PROCEDURE_REQUEST, Api.Emis.getProcedure, actions.getEmisProcedureSuccess, actions.getEmisProcedureError)
}

// Examinations
export function * getEmisExaminationRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_EXAMINATION_REQUEST, Api.Emis.getExamination, actions.getEmisExaminationSuccess, actions.getEmisExaminationError)
}

export function * getEmisOperationRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_OPERATION_REQUEST, Api.Emis.getOperation, actions.getEmisOperationSuccess, actions.getEmisOperationError)
}

export function * getEmisImmunisationRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_IMMUNISATION_REQUEST, Api.Emis.getImmunisation, actions.getEmisImmunisationSuccess, actions.getEmisImmunisationError)
}

// Investigations
export function * getEmisInvestigationRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_INVESTIGATION_REQUEST, Api.Emis.getInvestigation, actions.getEmisInvestigationSuccess, actions.getEmisInvestigationError)
}

export function * getEmisBiochemistryRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_BIOCHEMISTRY_REQUEST, Api.Emis.getBiochemistry, actions.getEmisBiochemistrySuccess, actions.getEmisBiochemistryError)
}

export function * getEmisECGRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_ECG_REQUEST, Api.Emis.getECG, actions.getEmisECGSuccess, actions.getEmisECGError)
}

export function * getEmisHaematologyRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_HAEMATOLOGY_REQUEST, Api.Emis.getHaematology, actions.getEmisHaematologySuccess, actions.getEmisHaematologyError)
}

export function * getEmisImagingRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_IMAGING_REQUEST, Api.Emis.getImaging, actions.getEmisImagingSuccess, actions.getEmisImagingError)
}

export function * getEmisMicrobiologyRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_MICROBIOLOGY_REQUEST, Api.Emis.getMicrobiology, actions.getEmisMicrobiologySuccess, actions.getEmisMicrobiologyError)
}

export function * getEmisCytologyRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_CYTOLOGY_REQUEST, Api.Emis.getCytology, actions.getEmisCytologySuccess, actions.getEmisCytologyError)
}

export function * getEmisOtherInvestigationRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_OTHERINVESTIGATION_REQUEST, Api.Emis.getOtherInvestigation, actions.getEmisOtherInvestigationSuccess, actions.getEmisOtherInvestigationError)
}

export function * getEmisPhysiologyRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_PHYSIOLOGY_REQUEST, Api.Emis.getPhysiology, actions.getEmisPhysiologySuccess, actions.getEmisPhysiologyError)
}

export function * getEmisUrinalysisRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_URINALYSIS_REQUEST, Api.Emis.getUrinalysis, actions.getEmisUrinalysisSuccess, actions.getEmisUrinalysisError)
}

// Events
export function * getEmisEventRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_EVENT_REQUEST, Api.Emis.getEvent, actions.getEmisEventSuccess, actions.getEmisEventError)
}

export function * getEmisEncounterListRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_ENCOUNTERLIST_REQUEST, Api.Emis.getEncounterList, actions.getEmisEncounterListSuccess, actions.getEmisEncounterListError)
}

export function * getEmisEncounterDetailRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_ENCOUNTERDETAIL_REQUEST, Api.Emis.getEncounterDetail, actions.getEmisEncounterDetailSuccess, actions.getEmisEncounterDetailError)
}

export function * getEmisReferralRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_REFERRAL_REQUEST, Api.Emis.getReferral, actions.getEmisReferralSuccess, actions.getEmisReferralError)
}

export function * getEmisAdmissionRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_ADMISSION_REQUEST, Api.Emis.getAdmission, actions.getEmisAdmissionSuccess, actions.getEmisAdmissionError)
}

// PatientDetails
export function * getEmisPatientDetailRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_PATIENTDETAIL_REQUEST, Api.Emis.getPatientDetail, actions.getEmisPatientDetailSuccess, actions.getEmisPatientDetailError)
}

export function * getEmisFullMedicalRecordRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_FULLMEDICALRECORD_REQUEST, Api.Emis.getFullMedicalRecord, actions.getEmisFullMedicalRecordSuccess, actions.getEmisFullMedicalRecordError)
}

// Audit History
export function * getEmisAuditHistoryRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_EMIS_AUDIT_HISTORY_REQUEST, Api.Emis.getAuditHistory, actions.getAuditHistorySuccess, actions.getAuditHistoryError)
}

export default function * rootSaga () {
  yield all([
    // Summary
    fork(getEmisSummaryRequest),

    // Problems
    fork(getEmisProblemRequest),
    fork(getEmisProblemCurrentRequest),
    fork(getEmisProblemPastRequest),
    fork(getEmisStandardProblemRequest),

    // Diagnosis
    fork(getEmisDiagnosisRequest),

    // Medications
    fork(getEmisMedicationRequest),
    fork(getEmisMedicationCurrentRequest),
    fork(getEmisMedicationPastRequest),
    fork(getEmisMedicationIssueRequest),

    // Risks and Warnings
    fork(getEmisRisksWarningRequest),
    fork(getEmisAllergyRequest),
    fork(getEmisContraIndicationRequest),

    // Procedures
    fork(getEmisProcedureRequest),

    // Examinations
    fork(getEmisExaminationRequest),
    fork(getEmisOperationRequest),
    fork(getEmisImmunisationRequest),

    // Investigations
    fork(getEmisInvestigationRequest),
    fork(getEmisBiochemistryRequest),
    fork(getEmisECGRequest),
    fork(getEmisHaematologyRequest),
    fork(getEmisImagingRequest),
    fork(getEmisMicrobiologyRequest),
    fork(getEmisCytologyRequest),
    fork(getEmisOtherInvestigationRequest),
    fork(getEmisPhysiologyRequest),
    fork(getEmisUrinalysisRequest),

    // Events
    fork(getEmisEventRequest),
    fork(getEmisEncounterListRequest),
    fork(getEmisEncounterDetailRequest),
    fork(getEmisReferralRequest),
    fork(getEmisAdmissionRequest),

    // PatientDetails
    fork(getEmisPatientDetailRequest),
    fork(getEmisFullMedicalRecordRequest),

    // Audit History
    fork(getEmisAuditHistoryRequest)
  ])
}
