import {all} from 'redux-saga/effects'
import alertsSagas from './app/alerts/sagas'
import auditSagas from './app/audit/sagas'
import authSagas from './app/auth/sagas'
import authuserSagas from './app/authuser/sagas'
import availabilitySagas from './app/availability/sagas'
import careWorkerWebAppSagas from './app/careWorkerWebApp/sagas'
import customMedicationsSagas from './app/customMedications/sagas'
import dmdSagas from './app/dmd/sagas'
import errorSagas from './app/errors/sagas'
import customFormSagas from './app/customForms/sagas'
import hrSagas from './app/hr/sagas'
import newUsersSagas from './app/newusers/sagas'
import providersSagas from './app/providers/sagas'
import reportingDashboardSagas from './app/reportingdashboard/sagas'
import riskAssessmentTemplateSagas from './app/riskAssessmentTemplate/sagas'
import roundsSagas from './app/rounds/sagas'
import schedulingSagas from './app/scheduling/sagas'
import schedulingNewSagas from './app/rostering/sagas'
import serviceUserSagas from './app/serviceuser/sagas'
import serviceUserDashboardSagas from './app/serviceuserDashboard/sagas'
import servicesLocationsSagas from './app/serviceslocations/sagas'
import settingsSagas from './app/settings/sagas'
import tokenRefreshSagas from './app/auth/token.refresh.sagas'
import usersSagas from './app/providerusers/sagas'
import utilitySagas from './app/utility/sagas'

export default function * rootSaga () {
  yield all([
    alertsSagas(),
    auditSagas(),
    authSagas(),
    authuserSagas(),
    availabilitySagas(),
    careWorkerWebAppSagas(),
    customFormSagas(),
    customMedicationsSagas(),
    dmdSagas(),
    errorSagas(),
    hrSagas(),
    newUsersSagas(),
    providersSagas(),
    reportingDashboardSagas(),
    riskAssessmentTemplateSagas(),
    roundsSagas(),
    schedulingSagas(),
    schedulingNewSagas(),
    serviceUserSagas(),
    serviceUserDashboardSagas(),
    servicesLocationsSagas(),
    settingsSagas(),
    tokenRefreshSagas(),
    usersSagas(),
    utilitySagas()
  ])
}
