import React, {useEffect, useState} from 'react'
import {IconContext} from "react-icons";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {theme as defaultTheme} from './config'

const IconColourWrapper = styled.div`
  .eyecon {
    color: ${props => props.theme.palette.primary[0]};
    
    &.warn {
      color: red;
    }

    &.success {
      color: green;
    }
  }
`

export default function IconWrapper({children, ...otherProps}) {
  const interfaceTheme = useSelector(state => state.Interface.theme)
  const [currentTheme, setCurrentTheme] = useState(defaultTheme)
  useEffect(() => {
    if (interfaceTheme && interfaceTheme.palette) {
      setCurrentTheme(interfaceTheme)
    }
  }, [interfaceTheme])
  return (
    <IconContext.Provider value={{className: 'eyecon', ...otherProps}}>
      <IconColourWrapper theme={currentTheme}>
        {children}
      </IconColourWrapper>
    </IconContext.Provider>
  )

}