// import { parseError } from '../../../../helpers/errors'
import {defineGetActionsPrSu} from '../../actionsHelper'

const actions = {}

defineGetActionsPrSu(actions, 'GET_SERVICE_USERS_STATS', 'getStats')
defineGetActionsPrSu(actions, 'GET_SERVICE_USERS_CARE_ASSESSMENT_COMPLETENESS_STATS', 'getCareAssessmentCompletenessStats')
defineGetActionsPrSu(actions, 'GET_SERVICE_USERS_CARE_ASSESSMENT_COMPLETENESS_CSV', 'getCareAssessmentCompletenessCsv')

defineGetActionsPrSu(actions, 'GET_SERVICE_USERS_COVID_19_STATS', 'getServiceUsersCovid19Stats')
defineGetActionsPrSu(actions, 'GET_SERVICE_USERS_COVID_19_CSV', 'getServiceUsersCovid19Csv')


export default actions
