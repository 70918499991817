import crudActions from "../crud/actions";
import { defineGetActionsPrSu } from "../../actionsHelper";

const DOCUMENT = "REPORTING_DASHBOARD";

const actions = {
  ...crudActions(DOCUMENT),

  GET_REPORTING_DASHBOARD_REQUEST: DOCUMENT + "_REQUEST",
  GET_REPORTING_DASHBOARD_SUCCESS: DOCUMENT + "_SUCCESS",
  GET_REPORTING_DASHBOARD_FAILURE: DOCUMENT + "_FAILURE",

  getReportingDashboardRequest: (providerId, refresh = true) => {
    return {
      type: actions.GET_REPORTING_DASHBOARD_REQUEST,
      payload: {
        providerId: providerId,
        refresh: refresh,
      },
    };
  },
  getReportingDashboardSuccess: (result) => {
    return {
      type: actions.GET_REPORTING_DASHBOARD_SUCCESS,
      payload: result,
    };
  },
  getReportingDashboardError: (error) => {
    return {
      type: actions.GET_REPORTING_DASHBOARD_FAILURE,
      error: error,
    };
  },
};

defineGetActionsPrSu(
  actions,
  "DOWNLOAD_USER_REPORTING_CSV",
  "downloadUserReportingCsv"
);
defineGetActionsPrSu(actions, "DOWNLOAD_REPORT_FILE", "downloadReportFile");

export default actions;
