import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'

import Alerts from './app/alerts/reducers'
import Audit from './app/audit/reducers'
import Auth from './app/auth/reducers'
import AuthUser from './app/authuser/reducers'
import Availability from './app/availability/reducers'
import CareWorkerWebApp from './app/careWorkerWebApp/reducers'
import CustomMedications from './app/customMedications/reducers'
import Dmd from './app/dmd/reducers'
import Errors from './app/errors/reducers'
import CustomForms from './app/customForms/reducers'
import HR from './app/hr/reducers'
import Interface from './interface/reducers'
import Maps from './app/maps/reducers'
import NewUsers from './app/newusers/reducers'
import Providers from './app/providers/reducers'
import ReportingDashboard from './app/reportingdashboard/reducers'
import RiskAssessmentTemplate from './app/riskAssessmentTemplate/reducers'
import Rounds from './app/rounds/reducers'
import Scheduling from './app/scheduling/reducers'
import Rostering from './app/rostering/reducers'
import ServiceUser from './app/serviceuser/reducers'
import ServiceUserDashboard from './app/serviceuserDashboard/reducers'
import ServicesLocations from './app/serviceslocations/reducers'
import Settings from './app/settings/reducers'
import Users from './app/providerusers/reducers'
import Utility from './app/utility/reducers'

const reducers = {
  Alerts,
  Audit,
  Auth,
  AuthUser,
  Availability,
  CareWorkerWebApp,
  CustomMedications,
  Dmd,
  Errors,
  CustomForms,
  HR,
  Interface,
  Maps,
  NewUsers,
  Providers,
  ReportingDashboard,
  RiskAssessmentTemplate,
  Rounds,
  Scheduling,
  Rostering,
  ServiceUser,
  ServiceUserDashboard,
  ServicesLocations,
  Settings,
  Users,
  Utility
}

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    ...reducers
  })
