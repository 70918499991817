import actions from './actions'
import crudReducers from '../crud/reducers'
import {
  initialDataState,
  reduceDataError,
  reduceDataRequest,
  reduceDataSuccess
} from '../../reducersHelper'

const dataPropName = 'data'

const initState = {
  reportsLoaded: false,
  error: false,
  reports: {
    data: [],
    loading: false,
    loaded: false
  },
  downloadUserCsv: initialDataState,
  downloadReportFile: initialDataState
}

const crud = crudReducers(actions, dataPropName, initState)

const reducers = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case actions.GET_REPORTING_DASHBOARD_REQUEST:
      state = {
        ...state,
        reports: {
          loading: true,
          loaded: false
        }
      }
      return state
    case actions.GET_REPORTING_DASHBOARD_SUCCESS:
      return {
        ...state,
        error: false,
        reports: {
          ...state.reports,
          data: payload,
          loading: false,
          loaded: true
        }
      }
    case actions.GET_REPORTING_DASHBOARD_ERROR:
      return {
        ...state,
        error: payload,
        reports: {
          ...state.reports,
          loading: false
        }
      }
    case actions.DOWNLOAD_USER_REPORTING_CSV_REQUEST:
      return reduceDataRequest(state, 'downloadUserReportingCsv')
    case actions.DOWNLOAD_USER_REPORTING_CSV_SUCCESS:
      return reduceDataSuccess(state, 'downloadUserReportingCsv', action.payload)
    case actions.DOWNLOAD_USER_REPORTING_CSV_ERROR:
      return reduceDataError(state, 'downloadUserReportingCsv', action.error)
    case actions.DOWNLOAD_REPORT_FILE_REQUEST:
      return reduceDataRequest(state, 'downloadReportFile')
    case actions.DOWNLOAD_REPORT_FILE_SUCCESS:
      return reduceDataSuccess(state, 'downloadReportFile', action.payload)
    case actions.DOWNLOAD_REPORT_FILE_ERROR:
      return reduceDataError(state, 'downloadReportFile', action.error)
    default:
      return crud(state, { type, payload })
  }
}

export default reducers
