import { all, fork } from 'redux-saga/effects'
import Api from '../../../../api'
import actions from './actions'
import { defineGetSaga } from '../../../sagasHelper'

export function * getUnifiedEmarRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_UNIFIED_EMAR_REQUEST, Api.Emar.getUnifiedEmar, actions.getUnifiedEmarSuccess, actions.getUnifiedEmarError)
}

export default function * rootSaga () {
  yield all([
    fork(getUnifiedEmarRequest)
  ])
}
