import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../reducersHelper'

const initState = {
  serviceUsersStats: initialDataState,
  careAssessmentCompletenessStats: initialDataState,
  careAssessmentCompletenessCsv: initialDataState,
  serviceUsersCovid19Stats: initialDataState,
  serviceUsersCovid19Csv: initialDataState
}

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_SERVICE_USERS_STATS_REQUEST:
      return reduceDataRequest(state, 'serviceUsersStats')
    case actions.GET_SERVICE_USERS_STATS_SUCCESS:
      return reduceDataSuccess(state, 'serviceUsersStats', action.payload)
    case actions.GET_SERVICE_USERS_STATS_ERROR:
      return reduceDataError(state, 'serviceUsersStats', action.error)

    case actions.GET_SERVICE_USERS_CARE_ASSESSMENT_COMPLETENESS_STATS_REQUEST:
      return reduceDataRequest(state, 'careAssessmentCompletenessStats')
    case actions.GET_SERVICE_USERS_CARE_ASSESSMENT_COMPLETENESS_STATS_SUCCESS:
      return reduceDataSuccess(state, 'careAssessmentCompletenessStats', action.payload)
    case actions.GET_SERVICE_USERS_CARE_ASSESSMENT_COMPLETENESS_STATS_ERROR:
      return reduceDataError(state, 'careAssessmentCompletenessStats', action.error)

    case actions.GET_SERVICE_USERS_CARE_ASSESSMENT_COMPLETENESS_CSV_REQUEST:
      return reduceDataRequest(state, 'careAssessmentCompletenessCsv')
    case actions.GET_SERVICE_USERS_CARE_ASSESSMENT_COMPLETENESS_CSV_SUCCESS:
      return reduceDataSuccess(state, 'careAssessmentCompletenessCsv', action.payload)
    case actions.GET_SERVICE_USERS_CARE_ASSESSMENT_COMPLETENESS_CSV_ERROR:
      return reduceDataError(state, 'careAssessmentCompletenessCsv', action.error)


    case actions.GET_SERVICE_USERS_COVID_19_STATS_REQUEST:
      return reduceDataRequest(state, 'serviceUsersCovid19Stats')
    case actions.GET_SERVICE_USERS_COVID_19_STATS_SUCCESS:
      return reduceDataSuccess(state, 'serviceUsersCovid19Stats', action.payload)
    case actions.GET_SERVICE_USERS_COVID_19_STATS_ERROR:
      return reduceDataError(state, 'serviceUsersCovid19Stats', action.error)

    case actions.GET_SERVICE_USERS_COVID_19_CSV_REQUEST:
      return reduceDataRequest(state, 'serviceUsersCovid19Csv')
    case actions.GET_SERVICE_USERS_COVID_19_CSV_SUCCESS:
      return reduceDataSuccess(state, 'serviceUsersCovid19Csv', action.payload)
    case actions.GET_SERVICE_USERS_COVID_19_CSV_ERROR:
      return reduceDataError(state, 'serviceUsersCovid19Csv', action.error)
    
    default:
      return state
  }
}

export default reducers
