import { capitalize } from 'lodash'
import {
  defineGetActionsPrSu,
  defineUpdateActionsPrSu
} from './actionsHelper'
import crudActions from './app/crud/actions'
import crudReducers from './app/crud/reducers'
import crudSagas from './app/crud/sagas'

import { all, fork } from 'redux-saga/effects'
import Api from '../api'
import { defineGetSaga, defineUpdateSaga } from './sagasHelper'
const initialDataState = {
  data: null,
  loading: false,
  loaded: false,
  error: false
}
export const reduxMultiple = new Proxy(
  {},
  {
    get (_, modulePrefix) {
      return new Proxy(
        {},
        {
          get (_t, property) {
            return () => {
              const PascalCased = property
                .toLowerCase()
                .split('_')
                .map((s) => capitalize(s))
                .join('')
              const camelCase =
                PascalCased[0].toLowerCase() + PascalCased.slice(1)
              const actions = crudActions(`${modulePrefix}_${property}_`)

              return {
                reducer: crudReducers(actions, camelCase),
                actions: crudActions(`${modulePrefix}_${property}_`),
                saga: function * () {
                  yield crudSagas(
                    actions,
                    Api.Crud,
                    `providers/:providerId/${modulePrefix.toLowerCase()}/:userId/${camelCase}`,
                    'id'
                  )
                }
              }
            }
          }
        }
      )
    }
  }
)

export const reduxField = new Proxy(
  {},
  {
    get (_, modulePrefix) {
      return new Proxy(
        {},
        {
          get (_t, property) {
            return () => {
              const PascalCased = property
                .toLowerCase()
                .split('_')
                .map((s) => capitalize(s))
                .join('')

              const actions = {}
              defineGetActionsPrSu(
                actions,
                `GET_${modulePrefix}_${property}`,
                `get${PascalCased}`
              )
              defineUpdateActionsPrSu(
                actions,
                `UPDATE_${modulePrefix}_${property}`,
                `update${PascalCased}`
              )
              const reducer = (state = initialDataState, action) => {
                switch (action.type) {
                  case actions[`GET_${modulePrefix}_${property}_REQUEST`]:
                    return { ...state, loading: true }
                  case actions[`GET_${modulePrefix}_${property}_SUCCESS`]:
                    return {
                      ...state,
                      data: action.payload,
                      loading: false,
                      loaded: true,
                      error: null
                    }
                  case actions[`GET_${modulePrefix}_${property}_ERROR`]:
                    return { ...state, loading: false, error: action.error }
                  case actions[`UPDATE_${modulePrefix}_${property}_REQUEST`]:
                    return { ...state, loading: true }
                  case actions[`UPDATE_${modulePrefix}_${property}_SUCCESS`]:
                    return {
                      ...state,
                      data: action.payload,
                      loading: false,
                      loaded: true,
                      error: null
                    }
                  case actions[`UPDATE_${modulePrefix}_${property}_ERROR`]:
                    return { ...state, loading: false, error: action.error }
                  default:
                    return state
                }
              }
              const sagas = {
                [`get${PascalCased}Request`]: function * () {
                  yield defineGetSaga(
                    actions[`GET_${modulePrefix}_${property}_REQUEST`],
                    Api[modulePrefix][`get${PascalCased}`],
                    actions[`get${PascalCased}Success`],
                    actions[`get${PascalCased}Error`]
                  )
                },
                [`update${PascalCased}Request`]: function * () {
                  yield defineUpdateSaga(
                    actions[`UPDATE_${modulePrefix}_${property}_REQUEST`],
                    Api[modulePrefix][`update${PascalCased}`],
                    actions[`update${PascalCased}Success`],
                    actions[`update${PascalCased}Error`]
                  )
                }
              }
              return {
                reducer,
                actions,
                saga: function * () {
                  yield all([
                    fork(sagas[`get${PascalCased}Request`]),
                    fork(sagas[`update${PascalCased}Request`])
                  ])
                }
              }
            }
          }
        }
      )
    }
  }
)
