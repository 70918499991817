import crudActions from '../../crud/actions'
import { defineGetActionsPrSu } from '../../../actionsHelper'

const actions = {
  ...crudActions('RISKASSESSMENT_')
}

defineGetActionsPrSu(actions, 'RISKASSESSMENT_CREATEFROMTEMPLATE', 'createFromTemplate')
defineGetActionsPrSu(actions, 'RISKASSESSMENT_DOWNLOAD_PDF', 'downloadPdf')

export default actions
