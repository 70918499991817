import config from './settings'
import {
  commonGet,
  commonPut,
  commonPost,
  commonGetBlob
} from './common'

class ServiceUser {
  static getServiceUsersStats (token, providerId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusersstats`
    return commonGet(token, url)
  }

  static createServiceUser (token, providerId, serviceuser) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers`
    return commonPost(token, url, serviceuser)
  }

  static getPersonalDetails (token, providerId, userId) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}`
    return commonGet(token, url)
  }

  static updatePersonalDetails (token, providerId, userId, data) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}`
    return commonPut(token, url, data)
  }

  static getPrivateDetails (token, providerId, userId) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/private`
    return commonGet(token, url)
  }

  static updatePrivateDetails (token, providerId, userId, data) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/private`
    return commonPut(token, url, data)
  }

  static getAppearance (token, providerId, userId) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/appearance`
    return commonGet(token, url)
  }

  static updateAppearance (token, providerId, userId, data) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/appearance`
    return commonPut(token, url, data)
  }

  static getAddress (token, providerId, userId) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/address`
    return commonGet(token, url)
  }

  static updateAddress (token, providerId, userId, data) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/address`
    return commonPut(token, url, data)
  }

  static getAddressGeoFencing (token, providerId, userId) {
    const url = `${
      config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/location`
    return commonGet(token, url)
  }

  static updateAddressGeoFencing (token, providerId, userId, data) {
    const url = `${
      config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/location`
    return commonPut(token, url, data)
  }

  static getAccessDetails (token, providerId, userId) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/accessdetails`
    return commonGet(token, url)
  }

  static updateAccessDetails (token, providerId, userId, data) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/accessdetails`
    return commonPut(token, url, data)
  }

  static getAdvanceCarePlan (token, providerId, userId) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/advancecareplan`
    return commonGet(token, url)
  }

  static updateAdvanceCarePlan (token, providerId, userId, data) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/advancecareplan`
    return commonPut(token, url, data)
  }

  static getBmi (token, providerId, userId) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/bmi`
    return commonGet(token, url)
  }

  static downloadConditionFile (
    token,
    providerId,
    userId,
    conditionId,
    filename
  ) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/conditions/${conditionId}/files/${filename}`
    return commonGetBlob(token, url)
  }

  static getLegalChoices (token, providerId, userId) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/legalChoices`
    return commonGet(token, url)
  }

  static updateLegalChoices (token, providerId, userId, data) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/legalChoices`
    return commonPut(token, url, data)
  }

  static downloadLegalChoiceFile (token, providerId, userId, legalChoiceType, filename) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/legalChoicesFiles/${legalChoiceType}/${filename}`
    return commonGetBlob(token, url)
  }

  static getServiceInformation (token, providerId, userId) {
    const url = `${config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/serviceinfo`
    return commonGet(token, url)
  }

  static updateServiceInformation (token, providerId, userId, data) {
    const url = `${config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/serviceinfo`
    return commonPut(token, url, data)
  }

  static createServiceInformation (token, providerId, userId, data) {
    const url = `${config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/serviceinfo`
    return commonPost(token, url, data)
  }

  static downloadServiceUserServiceInformationPdf (token, providerId, userId, data) {
    const url = `${config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/serviceinfo/${data.serviceInfoId}/pdf`
    return commonGetBlob(token, url, data)
  }

  static downloadServiceUserDocsFile (
    token,
    providerId,
    userId,
    filename
  ) {
    const url = `${config.url
      }/auth/providers/${providerId}/serviceusers/${userId}/documents/files/${filename}`
    return commonGetBlob(token, url)
  }

  static downloadPdf (
    token,
    providerId,
    userId
  ) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/pdf`
    return commonGetBlob(token, url)
  }
}

export default ServiceUser
