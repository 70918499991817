const ao = {
  perm: {
    AcknowledgeAlertBulkUpdate: 'acknowledgeAlertBulk::update',
    AcknowledgeAlertUpdate: 'acknowledgeAlert::update',
    AlertList: 'alert::list',
    AlertTypeList: 'alertType::list',
    AuditEntryList: 'auditEntry::list',
    AvailabilityUnifiedRead: 'availabilityUnified::read',
    BulkActionAssignCareworkerToVisitUpdate: 'bulkActionAssignCareworkerToVisit::update',
    BulkActionRemoveCareWorkerFromVisitUpdate: 'bulkActionRemoveCareWorkerFromVisit::update',
    BulkActionSmartAllocateVisitsUpdate: 'bulkActionSmartAllocateVisits::update',
    CareAssessmentAboutMeRead: 'careAssessmentAboutMe::read',
    CareAssessmentAboutMeUpdate: 'careAssessmentAboutMe::update',
    CareAssessmentArchivedPDFDownload: 'careAssessmentArchivedPDF::download',
    CareAssessmentAuditHistoryRead: 'careAssessmentAuditHistory::read',
    CareAssessmentAuditedSignatureRead: 'careAssessmentAuditedSignature::read',
    CareAssessmentAuditedSignatureUpdate: 'careAssessmentAuditedSignature::update',
    CareAssessmentBodyMapRead: 'careAssessmentBodyMap::read',
    CareAssessmentBodyMapUpdate: 'careAssessmentBodyMap::update',
    CareAssessmentCapacityAndConsentRead: 'careAssessmentCapacityAndConsent::read',
    CareAssessmentCapacityAndConsentUpdate: 'careAssessmentCapacityAndConsent::update',
    CareAssessmentCommunicationRead: 'careAssessmentCommunication::read',
    CareAssessmentCommunicationUpdate: 'careAssessmentCommunication::update',
    CareAssessmentCompletedSignatureRead: 'careAssessmentCompletedSignature::read',
    CareAssessmentCompletedSignatureUpdate: 'careAssessmentCompletedSignature::update',
    CareAssessmentDomesticSupportActivityCreate: 'careAssessmentDomesticSupportActivity::create',
    CareAssessmentDomesticSupportActivityDelete: 'careAssessmentDomesticSupportActivity::delete',
    CareAssessmentDomesticSupportActivityList: 'careAssessmentDomesticSupportActivity::list',
    CareAssessmentDomesticSupportActivityUpdate: 'careAssessmentDomesticSupportActivity::update',
    CareAssessmentDomesticSupportRead: 'careAssessmentDomesticSupport::read',
    CareAssessmentDomesticSupportUpdate: 'careAssessmentDomesticSupport::update',
    CareAssessmentEnvironmentAssessmentRead: 'careAssessmentEnvironmentAssessment::read',
    CareAssessmentEnvironmentAssessmentUpdate: 'careAssessmentEnvironmentAssessment::update',
    CareAssessmentFinancialSupportRead: 'careAssessmentFinancialSupport::read',
    CareAssessmentFinancialSupportUpdate: 'careAssessmentFinancialSupport::update',
    CareAssessmentMedicationPlanRead: 'careAssessmentMedicationPlan::read',
    CareAssessmentMedicationPlanUpdate: 'careAssessmentMedicationPlan::update',
    CareAssessmentMovingAndHandlingEquipmentCreate: 'careAssessmentMovingAndHandlingEquipment::create',
    CareAssessmentMovingAndHandlingEquipmentDelete: 'careAssessmentMovingAndHandlingEquipment::delete',
    CareAssessmentMovingAndHandlingEquipmentList: 'careAssessmentMovingAndHandlingEquipment::list',
    CareAssessmentMovingAndHandlingEquipmentUpdate: 'careAssessmentMovingAndHandlingEquipment::update',
    CareAssessmentMovingAndHandlingFileDelete: 'careAssessmentMovingAndHandlingFile::delete',
    CareAssessmentMovingAndHandlingFileDownload: 'careAssessmentMovingAndHandlingFile::download',
    CareAssessmentMovingAndHandlingFileList: 'careAssessmentMovingAndHandlingFile::list',
    CareAssessmentMovingAndHandlingFileUpload: 'careAssessmentMovingAndHandlingFile::upload',
    CareAssessmentMovingAndHandlingRead: 'careAssessmentMovingAndHandling::read',
    CareAssessmentMovingAndHandlingUpdate: 'careAssessmentMovingAndHandling::update',
    CareAssessmentNutritionAndHydrationRead: 'careAssessmentNutritionAndHydration::read',
    CareAssessmentNutritionAndHydrationUpdate: 'careAssessmentNutritionAndHydration::update',
    CareAssessmentPDFDownload: 'careAssessmentPDF::download',
    CareAssessmentPersonalCareRead: 'careAssessmentPersonalCare::read',
    CareAssessmentPersonalCareUpdate: 'careAssessmentPersonalCare::update',
    CareAssessmentSignatureRead: 'careAssessmentSignature::read',
    CareAssessmentSignatureUpdate: 'careAssessmentSignature::update',
    CareAssessmentSocialActivityCreate: 'careAssessmentSocialActivity::create',
    CareAssessmentSocialActivityDelete: 'careAssessmentSocialActivity::delete',
    CareAssessmentSocialActivityList: 'careAssessmentSocialActivity::list',
    CareAssessmentSocialActivityUpdate: 'careAssessmentSocialActivity::update',
    CareAssessmentSummaryRead: 'careAssessmentSummary::read',
    CareAssessmentSupportNetworkRead: 'careAssessmentSupportNetwork::read',
    CareAssessmentSupportNetworkUpdate: 'careAssessmentSupportNetwork::update',
    CareAssessmentTreatmentAndCarePreferencesRead: 'careAssessmentTreatmentAndCarePreferences::read',
    CareAssessmentTreatmentAndCarePreferencesUpdate: 'careAssessmentTreatmentAndCarePreferences::update',
    CarePlanAuditHistoryRead: 'carePlanAuditHistory::read',
    CarePlanCareVisitCreate: 'carePlanCareVisit::create',
    CarePlanCareVisitDelete: 'carePlanCareVisit::delete',
    CarePlanCareVisitList: 'carePlanCareVisit::list',
    CarePlanCareVisitMedicationTaskRead: 'carePlanCareVisitMedicationTask::read',
    CarePlanCareVisitMedicationTaskUpdate: 'carePlanCareVisitMedicationTask::update',
    CarePlanCareVisitRead: 'carePlanCareVisit::read',
    CarePlanCareVisitSupportTaskRead: 'carePlanCareVisitSupportTask::read',
    CarePlanCareVisitSupportTaskUpdate: 'carePlanCareVisitSupportTask::update',
    CarePlanCareVisitTypeCreate: 'carePlanCareVisitType::create',
    CarePlanCareVisitTypeDelete: 'carePlanCareVisitType::delete',
    CarePlanCareVisitTypeRead: 'carePlanCareVisitType::read',
    CarePlanCareVisitTypeUpdate: 'carePlanCareVisitType::update',
    CarePlanCareVisitUpdate: 'carePlanCareVisit::update',
    CarePlanDownload: 'carePlan::download',
    CarePlanFileDelete: 'carePlanFile::delete',
    CarePlanFileDownload: 'carePlanFile::download',
    CarePlanFileList: 'carePlanFile::list',
    CarePlanFileUpload: 'carePlanFile::upload',
    CarePlanMedicationTaskCreate: 'carePlanMedicationTask::create',
    CarePlanMedicationTaskDelete: 'carePlanMedicationTask::delete',
    CarePlanMedicationTaskList: 'carePlanMedicationTask::list',
    CarePlanMedicationTaskUpdate: 'carePlanMedicationTask::update',
    CarePlanNextReviewUpdate: 'carePlanNextReview::update',
    CarePlanOutcomeCreate: 'carePlanOutcome::create',
    CarePlanOutcomeDelete: 'carePlanOutcome::delete',
    CarePlanOutcomeList: 'carePlanOutcome::list',
    CarePlanOutcomeMedicationTaskRead: 'carePlanOutcomeMedicationTask::read',
    CarePlanOutcomeMedicationTaskUpdate: 'carePlanOutcomeMedicationTask::update',
    CarePlanOutcomeRead: 'carePlanOutcome::read',
    CarePlanOutcomeSupportTaskRead: 'carePlanOutcomeSupportTask::read',
    CarePlanOutcomeSupportTaskUpdate: 'carePlanOutcomeSupportTask::update',
    CarePlanOutcomeUpdate: 'carePlanOutcome::update',
    CarePlanPreferencesUpdate: 'carePlanPreferences::update',
    CarePlanSummaryRead: 'carePlanSummary::read',
    CarePlanSupportTaskCreate: 'carePlanSupportTask::create',
    CarePlanSupportTaskDelete: 'carePlanSupportTask::delete',
    CarePlanSupportTaskList: 'carePlanSupportTask::list',
    CarePlanSupportTaskTitleList: 'carePlanSupportTaskTitle::list',
    CarePlanSupportTaskUpdate: 'carePlanSupportTask::update',
    CommunicationMessageForServiceUserCreate: 'communicationMessageForServiceUser::create',
    CommunicationMessageForServiceUserDownload: 'communicationMessageForServiceUser::download',
    CommunicationMessageForServiceUserRead: 'communicationMessageForServiceUser::read',
    CommunicationMessagesRead: 'communicationMessages::read',
    CommunicationMessagesUpdate: 'communicationMessages::update',
    CustomFormsUserFormAvailableTemplateList: 'customFormsUserFormAvailableTemplate::list',
    CustomFormsUserFormCreate: 'customFormsUserForm::create',
    CustomFormsUserFormDelete: 'customFormsUserForm::delete',
    CustomFormsUserFormList: 'customFormsUserForm::list',
    CustomFormsUserFormRead: 'customFormsUserForm::read',
    CustomFormsUserFormTemplateCreate: 'customFormsUserFormTemplate::create',
    CustomFormsUserFormTemplateDelete: 'customFormsUserFormTemplate::delete',
    CustomFormsUserFormTemplateList: 'customFormsUserFormTemplate::list',
    CustomFormsUserFormTemplateRead: 'customFormsUserFormTemplate::read',
    CustomFormsUserFormTemplateUpdate: 'customFormsUserFormTemplate::update',
    CustomFormsUserFormUpdate: 'customFormsUserForm::update',
    CustomMedicationCreate: 'customMedication::create',
    CustomMedicationDelete: 'customMedication::delete',
    CustomMedicationList: 'customMedication::list',
    CustomMedicationRead: 'customMedication::read',
    CustomMedicationUpdate: 'customMedication::update',
    DashboardCareAssessmentCompletenessDownload: 'dashboardCareAssessmentCompleteness::download',
    DashboardCareAssessmentCompletenessRead: 'dashboardCareAssessmentCompleteness::read',
    DmdRead: 'dmd::read',
    EmarRead: 'emar::read',
    EmisAuditHistoryRead: 'emisAuditHistory::read',
    EmisRead: 'emis::read',
    HealthTrackerFitbitRead: 'healthTrackerFitbit::read',
    HrStatsHolidaysRead: 'hrStatsHolidays::read',
    HrStatsLearningRead: 'hrStatsLearning::read',
    HrStatsLegalRead: 'hrStatsLegal::read',
    NewUserActivate: 'newUser::activate',
    NewUserCreate: 'newUser::create',
    NewUserDelete: 'newUser::delete',
    NewUserList: 'newUser::list',
    NotificationAlertSelectionRead: 'notificationAlertSelection::read',
    NotificationAlertSelectionUpdate: 'notificationAlertSelection::update',
    NotificationEventSelectionRead: 'notificationEventSelection::read',
    NotificationEventSelectionUpdate: 'notificationEventSelection::update',
    NotificationOptionRead: 'notificationOption::read',
    NotificationOptionUpdate: 'notificationOption::update',
    PasswordUpdate: 'password::update',
    ProviderCreate: 'provider::create',
    ProviderDelete: 'provider::delete',
    ProviderList: 'provider::list',
    ProviderLogoDelete: 'providerLogo::delete',
    ProviderLogoRead: 'providerLogo::read',
    ProviderLogoUpdate: 'providerLogo::update',
    ProviderRead: 'provider::read',
    ProviderSettingsRead: 'providerSettings::read',
    ProviderSettingsUpdate: 'providerSettings::update',
    ProviderUpdate: 'provider::update',
    ProviderselfRead: 'provider(self)::read',
    ReportDownload: 'report::download',
    ReportRead: 'report::read',
    RiskAssessmentFromTemplateCreate: 'riskAssessmentFromTemplate::create',
    RiskAssessmentTemplateCreate: 'riskAssessmentTemplate::create',
    RiskAssessmentTemplateDelete: 'riskAssessmentTemplate::delete',
    RiskAssessmentTemplateList: 'riskAssessmentTemplate::list',
    RiskAssessmentTemplateRead: 'riskAssessmentTemplate::read',
    RiskAssessmentTemplateUpdate: 'riskAssessmentTemplate::update',
    RoundAssignCareWorkerUpdate: 'roundAssignCareWorker::update',
    RoundCreate: 'round::create',
    RoundDelete: 'round::delete',
    RoundList: 'round::list',
    RoundRead: 'round::read',
    RoundRemoveCareWorkerUpdate: 'roundRemoveCareWorker::update',
    RoundUnifiedRead: 'roundUnified::read',
    RoundUpdate: 'round::update',
    ServiceUserCareOutcomeCreate: 'serviceUserCareOutcome::create',
    ServiceUserCareOutcomeDelete: 'serviceUserCareOutcome::delete',
    ServiceUserCareOutcomeList: 'serviceUserCareOutcome::list',
    ServiceUserCareOutcomeRead: 'serviceUserCareOutcome::read',
    ServiceUserCareOutcomeUpdate: 'serviceUserCareOutcome::update',
    ServiceUserCreate: 'serviceUser::create',
    ServiceUserDetailsAccessDetailsRead: 'serviceUserDetailsAccessDetails::read',
    ServiceUserDetailsAccessDetailsUpdate: 'serviceUserDetailsAccessDetails::update',
    ServiceUserDetailsAddressRead: 'serviceUserDetailsAddress::read',
    ServiceUserDetailsAddressUpdate: 'serviceUserDetailsAddress::update',
    ServiceUserDetailsAdvanceCarePlanRead: 'serviceUserDetailsAdvanceCarePlan::read',
    ServiceUserDetailsAdvanceCarePlanUpdate: 'serviceUserDetailsAdvanceCarePlan::update',
    ServiceUserDetailsAllRead: 'serviceUserDetailsAll::read',
    ServiceUserDetailsAllergyCreate: 'serviceUserDetailsAllergy::create',
    ServiceUserDetailsAllergyDelete: 'serviceUserDetailsAllergy::delete',
    ServiceUserDetailsAllergyList: 'serviceUserDetailsAllergy::list',
    ServiceUserDetailsAllergyRead: 'serviceUserDetailsAllergy::read',
    ServiceUserDetailsAllergyUpdate: 'serviceUserDetailsAllergy::update',
    ServiceUserDetailsAppearanceRead: 'serviceUserDetailsAppearance::read',
    ServiceUserDetailsAppearanceUpdate: 'serviceUserDetailsAppearance::update',
    ServiceUserDetailsBMIRead: 'serviceUserDetailsBMI::read',
    ServiceUserDetailsConditionCreate: 'serviceUserDetailsCondition::create',
    ServiceUserDetailsConditionDelete: 'serviceUserDetailsCondition::delete',
    ServiceUserDetailsConditionFileDelete: 'serviceUserDetailsConditionFile::delete',
    ServiceUserDetailsConditionFileDownload: 'serviceUserDetailsConditionFile::download',
    ServiceUserDetailsConditionFileList: 'serviceUserDetailsConditionFile::list',
    ServiceUserDetailsConditionFileUpload: 'serviceUserDetailsConditionFile::upload',
    ServiceUserDetailsConditionList: 'serviceUserDetailsCondition::list',
    ServiceUserDetailsConditionRead: 'serviceUserDetailsCondition::read',
    ServiceUserDetailsConditionUpdate: 'serviceUserDetailsCondition::update',
    ServiceUserDetailsContactCreate: 'serviceUserDetailsContact::create',
    ServiceUserDetailsContactDelete: 'serviceUserDetailsContact::delete',
    ServiceUserDetailsContactList: 'serviceUserDetailsContact::list',
    ServiceUserDetailsContactRead: 'serviceUserDetailsContact::read',
    ServiceUserDetailsContactUpdate: 'serviceUserDetailsContact::update',
    ServiceUserDetailsLegalChoiceFileDelete: 'serviceUserDetailsLegalChoiceFile::delete',
    ServiceUserDetailsLegalChoiceFileDownload: 'serviceUserDetailsLegalChoiceFile::download',
    ServiceUserDetailsLegalChoiceFileList: 'serviceUserDetailsLegalChoiceFile::list',
    ServiceUserDetailsLegalChoiceFileUpload: 'serviceUserDetailsLegalChoiceFile::upload',
    ServiceUserDetailsLegalChoicesRead: 'serviceUserDetailsLegalChoices::read',
    ServiceUserDetailsLegalChoicesUpdate: 'serviceUserDetailsLegalChoices::update',
    ServiceUserDetailsLocationRead: 'serviceUserDetailsLocation::read',
    ServiceUserDetailsLocationUpdate: 'serviceUserDetailsLocation::update',
    ServiceUserDetailsPDFDownload: 'serviceUserDetailsPDF::download',
    ServiceUserDetailsPersonalRead: 'serviceUserDetailsPersonal::read',
    ServiceUserDetailsPersonalUpdate: 'serviceUserDetailsPersonal::update',
    ServiceUserDetailsPrivateRead: 'serviceUserDetailsPrivate::read',
    ServiceUserDetailsPrivateUpdate: 'serviceUserDetailsPrivate::update',
    ServiceUserDetailsSupportingDocumentDelete: 'serviceUserDetailsSupportingDocument::delete',
    ServiceUserDetailsSupportingDocumentDownload: 'serviceUserDetailsSupportingDocument::download',
    ServiceUserDetailsSupportingDocumentList: 'serviceUserDetailsSupportingDocument::list',
    ServiceUserDetailsSupportingDocumentUpload: 'serviceUserDetailsSupportingDocument::upload',
    ServiceUserList: 'serviceUser::list',
    ServiceUserRiskAssessmentCreate: 'serviceUserRiskAssessment::create',
    ServiceUserRiskAssessmentDelete: 'serviceUserRiskAssessment::delete',
    ServiceUserRiskAssessmentDownload: 'serviceUserRiskAssessment::download',
    ServiceUserRiskAssessmentFileDelete: 'serviceUserRiskAssessmentFile::delete',
    ServiceUserRiskAssessmentFileDownload: 'serviceUserRiskAssessmentFile::download',
    ServiceUserRiskAssessmentFileList: 'serviceUserRiskAssessmentFile::list',
    ServiceUserRiskAssessmentFileUpload: 'serviceUserRiskAssessmentFile::upload',
    ServiceUserRiskAssessmentList: 'serviceUserRiskAssessment::list',
    ServiceUserRiskAssessmentRead: 'serviceUserRiskAssessment::read',
    ServiceUserRiskAssessmentUpdate: 'serviceUserRiskAssessment::update',
    ServiceUserServiceInformationCreate: 'serviceUserServiceInformation::create',
    ServiceUserServiceInformationDelete: 'serviceUserServiceInformation::delete',
    ServiceUserServiceInformationPDFDownload: 'serviceUserServiceInformationPDF::download',
    ServiceUserServiceInformationRead: 'serviceUserServiceInformation::read',
    ServiceUserServiceInformationUpdate: 'serviceUserServiceInformation::update',
    ServiceUserStatsRead: 'serviceUserStats::read',
    ServiceUserSymptomCreate: 'serviceUserSymptom::create',
    ServiceUserSymptomDelete: 'serviceUserSymptom::delete',
    ServiceUserSymptomList: 'serviceUserSymptom::list',
    ServiceUserSymptomNoteCreate: 'serviceUserSymptomNote::create',
    ServiceUserSymptomNoteDelete: 'serviceUserSymptomNote::delete',
    ServiceUserSymptomRead: 'serviceUserSymptom::read',
    ServiceUserSymptomUpdate: 'serviceUserSymptom::update',
    ServicesAndLocationsLocationCreate: 'servicesAndLocationsLocation::create',
    ServicesAndLocationsLocationDelete: 'servicesAndLocationsLocation::delete',
    ServicesAndLocationsLocationList: 'servicesAndLocationsLocation::list',
    ServicesAndLocationsLocationRead: 'servicesAndLocationsLocation::read',
    ServicesAndLocationsLocationUpdate: 'servicesAndLocationsLocation::update',
    ServicesAndLocationsMultipleUsersRead: 'servicesAndLocationsMultipleUsers::read',
    ServicesAndLocationsMultipleUsersUpdate: 'servicesAndLocationsMultipleUsers::update',
    ServicesAndLocationsMyServicesRead: 'servicesAndLocationsMyServices::read',
    ServicesAndLocationsServiceCreate: 'servicesAndLocationsService::create',
    ServicesAndLocationsServiceDelete: 'servicesAndLocationsService::delete',
    ServicesAndLocationsServiceList: 'servicesAndLocationsService::list',
    ServicesAndLocationsServiceRead: 'servicesAndLocationsService::read',
    ServicesAndLocationsServiceUpdate: 'servicesAndLocationsService::update',
    ServicesAndLocationsSingleUserCareWorkersRead: 'servicesAndLocationsSingleUserCareWorkers::read',
    ServicesAndLocationsSingleUserRead: 'servicesAndLocationsSingleUser::read',
    ServicesAndLocationsSingleUserUpdate: 'servicesAndLocationsSingleUser::update',
    SettingsListCreate: 'settingsList::create',
    SettingsListDelete: 'settingsList::delete',
    SettingsListList: 'settingsList::list',
    SettingsListUpdate: 'settingsList::update',
    StaffUserDetailsAbsenceCreate: 'staffUserDetailsAbsence::create',
    StaffUserDetailsAbsenceDelete: 'staffUserDetailsAbsence::delete',
    StaffUserDetailsAbsenceList: 'staffUserDetailsAbsence::list',
    StaffUserDetailsAbsenceRead: 'staffUserDetailsAbsence::read',
    StaffUserDetailsAbsenceUpdate: 'staffUserDetailsAbsence::update',
    StaffUserDetailsAddressRead: 'staffUserDetailsAddress::read',
    StaffUserDetailsAddressUpdate: 'staffUserDetailsAddress::update',
    StaffUserDetailsAllergyCreate: 'staffUserDetailsAllergy::create',
    StaffUserDetailsAllergyDelete: 'staffUserDetailsAllergy::delete',
    StaffUserDetailsAllergyList: 'staffUserDetailsAllergy::list',
    StaffUserDetailsAllergyRead: 'staffUserDetailsAllergy::read',
    StaffUserDetailsAllergyUpdate: 'staffUserDetailsAllergy::update',
    StaffUserDetailsBankDetailsRead: 'staffUserDetailsBankDetails::read',
    StaffUserDetailsBankDetailsUpdate: 'staffUserDetailsBankDetails::update',
    StaffUserDetailsConditionCreate: 'staffUserDetailsCondition::create',
    StaffUserDetailsConditionDelete: 'staffUserDetailsCondition::delete',
    StaffUserDetailsConditionFileDelete: 'staffUserDetailsConditionFile::delete',
    StaffUserDetailsConditionFileDownload: 'staffUserDetailsConditionFile::download',
    StaffUserDetailsConditionFileList: 'staffUserDetailsConditionFile::list',
    StaffUserDetailsConditionFileUpload: 'staffUserDetailsConditionFile::upload',
    StaffUserDetailsConditionList: 'staffUserDetailsCondition::list',
    StaffUserDetailsConditionRead: 'staffUserDetailsCondition::read',
    StaffUserDetailsConditionUpdate: 'staffUserDetailsCondition::update',
    StaffUserDetailsEmploymentCreate: 'staffUserDetailsEmployment::create',
    StaffUserDetailsEmploymentDelete: 'staffUserDetailsEmployment::delete',
    StaffUserDetailsEmploymentList: 'staffUserDetailsEmployment::list',
    StaffUserDetailsEmploymentRead: 'staffUserDetailsEmployment::read',
    StaffUserDetailsEmploymentUpdate: 'staffUserDetailsEmployment::update',
    StaffUserDetailsFileDelete: 'staffUserDetailsFile::delete',
    StaffUserDetailsFileDownload: 'staffUserDetailsFile::download',
    StaffUserDetailsFileList: 'staffUserDetailsFile::list',
    StaffUserDetailsFileUpload: 'staffUserDetailsFile::upload',
    StaffUserDetailsHolidayCreate: 'staffUserDetailsHoliday::create',
    StaffUserDetailsHolidayDelete: 'staffUserDetailsHoliday::delete',
    StaffUserDetailsHolidayList: 'staffUserDetailsHoliday::list',
    StaffUserDetailsHolidayRead: 'staffUserDetailsHoliday::read',
    StaffUserDetailsHolidayRequestCreate: 'staffUserDetailsHolidayRequest::create',
    StaffUserDetailsHolidayRequestDelete: 'staffUserDetailsHolidayRequest::delete',
    StaffUserDetailsHolidayRequestRead: 'staffUserDetailsHolidayRequest::read',
    StaffUserDetailsHolidaySummaryRead: 'staffUserDetailsHolidaySummary::read',
    StaffUserDetailsHolidayUpdate: 'staffUserDetailsHoliday::update',
    StaffUserDetailsLearningCreate: 'staffUserDetailsLearning::create',
    StaffUserDetailsLearningDelete: 'staffUserDetailsLearning::delete',
    StaffUserDetailsLearningList: 'staffUserDetailsLearning::list',
    StaffUserDetailsLearningRead: 'staffUserDetailsLearning::read',
    StaffUserDetailsLearningUpdate: 'staffUserDetailsLearning::update',
    StaffUserDetailsLegalFileDelete: 'staffUserDetailsLegalFile::delete',
    StaffUserDetailsLegalFileDownload: 'staffUserDetailsLegalFile::download',
    StaffUserDetailsLegalFileList: 'staffUserDetailsLegalFile::list',
    StaffUserDetailsLegalFileUpload: 'staffUserDetailsLegalFile::upload',
    StaffUserDetailsLegalRead: 'staffUserDetailsLegal::read',
    StaffUserDetailsLegalUpdate: 'staffUserDetailsLegal::update',
    StaffUserDetailsNextOfKinRead: 'staffUserDetailsNextOfKin::read',
    StaffUserDetailsNextOfKinUpdate: 'staffUserDetailsNextOfKin::update',
    StaffUserDetailsPersonalRead: 'staffUserDetailsPersonal::read',
    StaffUserDetailsPersonalUpdate: 'staffUserDetailsPersonal::update',
    StaffUserDetailsWorkingHoursRead: 'staffUserDetailsWorkingHours::read',
    StaffUserDetailsWorkingHoursUpdate: 'staffUserDetailsWorkingHours::update',
    UdmsBulkAssignCareWorkerUpdate: 'udmsBulkAssignCareWorker::update',
    UserAlertList: 'userAlert::list',
    UserAvatarDelete: 'userAvatar::delete',
    UserAvatarList: 'userAvatar::list',
    UserAvatarRead: 'userAvatar::read',
    UserAvatarUpdate: 'userAvatar::update',
    UserCSVDownload: 'userCSV::download',
    UserDelete: 'user::delete',
    UserSearchAllow: 'userSearch::allow',
    UserUnconstrainedByServiceLocationAllow: 'userUnconstrainedByServiceLocation::allow',
    UserUpdate: 'user::update',
    UserotherList: 'user(other)::list',
    UserotherRead: 'user(other)::read',
    UserselfList: 'user(self)::list',
    UserselfRead: 'user(self)::read',
    UserserviceUserList: 'user(serviceUser)::list',
    UserserviceUserRead: 'user(serviceUser)::read',
    UserstaffList: 'user(staff)::list',
    UserstaffRead: 'user(staff)::read',
    UtilityTimeOfDayRead: 'utilityTimeOfDay::read',
    ViewPasswordResetLinkRead: 'viewPasswordResetLink::read',
    VisitsAcceptedVisitRead: 'visitsAcceptedVisit::read',
    VisitsAssignCareWorkerUpdate: 'visitsAssignCareWorker::update',
    VisitsCustomTimeDelete: 'visitsCustomTime::delete',
    VisitsCustomTimeUpdate: 'visitsCustomTime::update',
    VisitsExceptionTypeCreate: 'visitsExceptionType::create',
    VisitsExceptionTypeDelete: 'visitsExceptionType::delete',
    VisitsExceptionTypeList: 'visitsExceptionType::list',
    VisitsExceptionTypeUpdate: 'visitsExceptionType::update',
    VisitsMissScheduledVisitAllow: 'visitsMissScheduledVisit::allow',
    VisitsMyAcceptedVisitRead: 'visitsMyAcceptedVisit::read',
    VisitsMyAcceptedVisitUpdate: 'visitsMyAcceptedVisit::update',
    VisitsMyScheduleRead: 'visitsMySchedule::read',
    VisitsPersistedVisitModifyTimesAllow: 'visitsPersistedVisitModifyTimes::allow',
    VisitsRemoveCareWorkerUpdate: 'visitsRemoveCareWorker::update',
    VisitsScheduledVisitAccept: 'visitsScheduledVisit::accept',
    VisitsSetExceptionDelete: 'visitsSetException::delete',
    VisitsSetExceptionUpdate: 'visitsSetException::update',
    VisitsUnifiedVisitDownload: 'visitsUnifiedVisit::download',
    VisitsUnifiedVisitRead: 'visitsUnifiedVisit::read',
    WebShowCareAssessmentPDFDownloadButtonAllow: 'webShowCareAssessmentPDFDownloadButton::allow',
    WebShowCarePlanPDFDownloadButtonAllow: 'webShowCarePlanPDFDownloadButton::allow',
    WebShowRiskAssessmentPDFDownloadButtonAllow: 'webShowRiskAssessmentPDFDownloadButton::allow',
    WebShowServiceUserDetailsPDFDownloadButtonAllow: 'webShowServiceUserDetailsPDFDownloadButton::allow',
  },
  insufficientPermissions: "Insufficient Permissions"
}
export default ao
