const actions = {
  SEARCH_TITLE_REQUEST: 'SEARCH_TITLE_REQUEST',
  SEARCH_TITLE_SUCCESS: 'SEARCH_TITLE_SUCCESS',
  SEARCH_TITLE_ERROR: 'SEARCH_TITLE_ERROR',

  searchTitleRequest: (providerId, searchTerm) => {
    return {
      type: actions.SEARCH_TITLE_REQUEST,
      payload: {
        providerId: providerId,
        searchTerm: searchTerm
      }
    }
  },
  searchTitleSuccess: result => {
    return {
      type: actions.SEARCH_TITLE_SUCCESS,
      payload: result
    }
  },
  searchTitleError: error => {
    return {
      type: actions.SEARCH_TITLE_ERROR,
      error: error
    }
  }
}

export default actions
