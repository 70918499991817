import crudActions from '../../crud/actions'

// Actions for the additional documents uploaded to the serviceuser condition
const actions = {
  ...crudActions('SERVICEUSER_CONDITION_DOCS_'),

  SERVICEUSER_CONDITION_DOC_DOWNLOAD_REQUEST:
    'SERVICEUSER_CONDITION_DOC_DOWNLOAD_REQUEST',

  downloadRequest: data => {
    return {
      type: actions.SERVICEUSER_CONDITION_DOC_DOWNLOAD_REQUEST,
      payload: data
    }
  }
}

export default actions
