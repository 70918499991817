import actions from './actions'
import { reducers as crudServicesReducers } from './crudServices.js'
import { reducers as crudLocationsReducers } from './crudLocations.js'
import { combineReducers } from 'redux'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../reducersHelper'

const initState = {
  filters: {},
  servicesByUserFiltered: initialDataState,
  servicesForMultipleUsers: initialDataState
}

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.SERVICESLOCATIONS_CLEAR:
      return initState

    case actions.SERVICESLOCATIONS_SET_FILTERS:
      return {
        ...state,
        filters: action.filters
      }

    case actions.SERVICESLOCATIONS_GET_SERVICES_BYUSER_FILTERED_REQUEST:
      return reduceDataRequest(state, 'servicesByUserFiltered')
    case actions.SERVICESLOCATIONS_GET_SERVICES_BYUSER_FILTERED_SUCCESS:
      return reduceDataSuccess(state, 'servicesByUserFiltered', action.payload)
    case actions.SERVICESLOCATIONS_GET_SERVICES_BYUSER_FILTERED_ERROR:
      return reduceDataError(state, 'servicesByUserFiltered', action.error)

    case actions.SERVICESLOCATIONS_GET_SERVICES_FOR_MULTIPLE_USERS_REQUEST:
      return reduceDataRequest(state, 'servicesForMultipleUsers')
    case actions.SERVICESLOCATIONS_GET_SERVICES_FOR_MULTIPLE_USERS_SUCCESS:
      return reduceDataSuccess(state, 'servicesForMultipleUsers', action.payload)
    case actions.SERVICESLOCATIONS_GET_SERVICES_FOR_MULTIPLE_USERS_ERROR:
      return reduceDataError(state, 'servicesForMultipleUsers', action.error)

    case actions.SERVICESLOCATIONS_UPDATE_SERVICES_FOR_MULTIPLE_USERS_REQUEST:
      return reduceDataRequest(state, 'servicesForMultipleUsers')
    case actions.SERVICESLOCATIONS_UPDATE_SERVICES_FOR_MULTIPLE_USERS_SUCCESS:
      return reduceDataSuccess(state, 'servicesForMultipleUsers', action.payload)
    case actions.SERVICESLOCATIONS_UPDATE_SERVICES_FOR_MULTIPLE_USERS_ERROR:
      return reduceDataError(state, 'servicesForMultipleUsers', action.error)

    default:
      return state
  }
}

export default combineReducers(
  {
    general: reducers,
    services: crudServicesReducers,
    locations: crudLocationsReducers
  })
