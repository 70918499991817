import { all, fork } from 'redux-saga/effects'
import Api from '../../../../api'
import actions from './actions'
import { defineGetSagaProvider } from '../../../sagasHelper'

export function * getUnifiedVisitsRequest () {
  yield defineGetSagaProvider(actions.GET_SERVICEUSER_SCHEDULING_UNIFIED_VISITS_REQUEST, Api.Scheduling.getUnifiedVisits, actions.getUnifiedVisitsSuccess, actions.getUnifiedVisitsError)
}

export default function * rootSaga () {
  yield all([
    fork(getUnifiedVisitsRequest)
  ])
}
