import Alerts from './alerts'
import Audit from './audit'
import Auth from './auth'
import AuthUser from './authuser'
import Availability from './availability'
import CareAssessment from './careassessment'
import CareOutcomes from './careOutcomes'
import CarePlan from './careplan'
import CareWorkerWebApp from './careWorkerWebApp'
import CommunicationMessages from './communicationmessages'
import Crud from './crud'
import CustomForms from "./customforms";
import Dmd from './dmd'
import Emar from './emar'
import Emis from './emis'
import HealthTracker from './healthtracker'
import Notifications from './notifications'
import Provider from './provider'
import ReportingDashboard from './reportingDashboard'
import Reports from './reports'
import RiskAssessment from './riskassessment'
import Rounds from './rounds'
import Rostering from './rostering'
import Scheduling from './scheduling'
import ServiceInformation from './serviceinformation'
import ServicesLocations from './servicesLocations'
import ServiceUser from './serviceuser'
import SupportTaskTitle from './supportTaskTitle'
import Symptoms from './symptoms'
import Users from './users'
import Utility from './utility'
import HR from './hr'

export const FauxSuperUserId = '102239ab-ad63-461e-bdf6-37a007deb912'

export default {
  Alerts,
  Audit,
  Auth,
  AuthUser,
  Availability,
  CareAssessment,
  CareOutcomes,
  CareWorker: CareWorkerWebApp,
  CommunicationMessages,
  CarePlan,
  Crud,
  CustomForms,
  Dmd,
  Emar,
  Emis,
  HealthTracker,
  Notifications,
  Provider,
  ReportingDashboard,
  Reports,
  RiskAssessment,
  Rounds,
  Rostering,
  Scheduling,
  ServiceInformation,
  ServicesLocations,
  ServiceUser,
  SupportTaskTitle,
  Symptoms,
  Users,
  Utility,
  HR
}
