import { defineGetActionsPrSu } from '../../../actionsHelper'

const actions = {
  CLEAR_SERVICEUSER_EMAR: 'CLEAR_SERVICEUSER_EMAR',

  clear: () => {
    return {
      type: actions.CLEAR_SERVICEUSER_EMAR
    }
  }
}

defineGetActionsPrSu(actions, 'GET_SERVICEUSER_UNIFIED_EMAR', 'getUnifiedEmar')

export default actions
