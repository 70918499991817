import { defineGetActionsPrSu, defineUpdateActionsPrSu } from '../../../actionsHelper'

const actions = {}

defineGetActionsPrSu(actions, 'NOTIFICATIONS_GET_NOTIFICATION_OPTIONS', 'getUserNotificationOptions')
defineUpdateActionsPrSu(actions, 'NOTIFICATIONS_UPDATE_NOTIFICATION_OPTIONS', 'updateUserNotificationOptions')

defineGetActionsPrSu(actions, 'NOTIFICATIONS_GET_ALERTS_SELECTION', 'getUserAlertsSelection')
defineUpdateActionsPrSu(actions, 'NOTIFICATIONS_UPDATE_ALERTS_SELECTION', 'updateUserAlertsSelection')

defineGetActionsPrSu(actions, 'NOTIFICATIONS_GET_EVENTS_SELECTION', 'getUserEventsSelection')
defineUpdateActionsPrSu(actions, 'NOTIFICATIONS_UPDATE_EVENTS_SELECTION', 'updateUserEventsSelection')

export default actions
