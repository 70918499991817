import { all, fork } from 'redux-saga/effects'
import Api from '../../../../api'
import actions from './actions'
import { defineGetSaga } from '../../../sagasHelper'

export function * getServiceUserAlertsRequest () {
  yield defineGetSaga(actions.GET_SERVICEUSER_ALERTS_REQUEST, Api.Alerts.getUserAlerts, actions.getServiceUserAlertsSuccess, actions.getServiceUserAlertsError)
}
export default function * rootSaga () {
  yield all([
    fork(getServiceUserAlertsRequest)
  ])
}
