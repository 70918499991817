import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../../reducersHelper'

const initState = {
  carePlanInfo: initialDataState,
  pdf: initialDataState,
  auditHistory: initialDataState
}

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAR_CAREPLAN_PDF:
      return {
        ...state,
        pdf: initialDataState
      }

    case actions.GET_CAREPLAN_REQUEST:
    case actions.SET_CAREPLAN_CARE_PREFERENCES_REQUEST:
    case actions.SET_CAREPLAN_NEXT_REVIEW_REQUEST:
      return reduceDataRequest(state, 'carePlanInfo')
    case actions.GET_CAREPLAN_SUCCESS:
    case actions.SET_CAREPLAN_CARE_PREFERENCES_SUCCESS:
    case actions.SET_CAREPLAN_NEXT_REVIEW_SUCCESS:
      return reduceDataSuccess(state, 'carePlanInfo', action.payload)
    case actions.GET_CAREPLAN_ERROR:
    case actions.SET_CAREPLAN_CARE_PREFERENCES_ERROR:
    case actions.SET_CAREPLAN_NEXT_REVIEW_ERROR:
      return reduceDataError(state, 'carePlanInfo', action.error)

    case actions.GET_CAREPLAN_PDF_REQUEST:
      return reduceDataRequest(state, 'pdf')
    case actions.GET_CAREPLAN_PDF_SUCCESS:
      return reduceDataSuccess(state, 'pdf', action.payload)
    case actions.GET_CAREPLAN_PDF_ERROR:
      return reduceDataError(state, 'pdf', action.error)

    case actions.GET_CAREPLAN_AUDIT_HISTORY_REQUEST:
      return reduceDataRequest(state, 'auditHistory')
    case actions.GET_CAREPLAN_AUDIT_HISTORY_SUCCESS:
      return reduceDataSuccess(state, 'auditHistory', action.payload)
    case actions.GET_CAREPLAN_AUDIT_HISTORY_ERROR:
      return reduceDataError(state, 'auditHistory', action.error)

    default:
      return state
  }
}

export default reducers
