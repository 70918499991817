import config from './settings'
import { commonGet } from './common'

export default class Emis {
  // Summary
  static getSummary (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/summary`
    return commonGet(token, url)
  }

  // Problems
  static getProblem (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/problem`
    return commonGet(token, url)
  }

  static getProblemCurrent (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/problemCurrent`
    return commonGet(token, url)
  }

  static getProblemPast (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/problemPast`
    return commonGet(token, url)
  }

  static getStandardProblem (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/standardProblem`
    return commonGet(token, url)
  }

  // Diagnosis
  static getDiagnosis (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/diagnosis`
    return commonGet(token, url)
  }

  // Medications
  static getMedication (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/medication`
    return commonGet(token, url)
  }

  static getMedicationCurrent (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/medicationCurrent`
    return commonGet(token, url)
  }

  static getMedicationPast (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/medicationPast`
    return commonGet(token, url)
  }

  static getMedicationIssue (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/medicationIssue`
    return commonGet(token, url)
  }

  // Risks and Warnings
  static getRisksWarning (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/risksWarning`
    return commonGet(token, url)
  }

  static getAllergy (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/allergy`
    return commonGet(token, url)
  }

  static getContraIndication (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/contraIndication`
    return commonGet(token, url)
  }

  // Procedures
  static getProcedure (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/procedure`
    return commonGet(token, url)
  }

  // Examinations
  static getExamination (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/examination`
    return commonGet(token, url)
  }

  static getOperation (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/operation`
    return commonGet(token, url)
  }

  static getImmunisation (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/immunisation`
    return commonGet(token, url)
  }

  // Investigations
  static getInvestigation (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/investigation`
    return commonGet(token, url)
  }

  static getBiochemistry (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/biochemistry`
    return commonGet(token, url)
  }

  static getECG (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/eCG`
    return commonGet(token, url)
  }

  static getHaematology (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/haematology`
    return commonGet(token, url)
  }

  static getImaging (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/imaging`
    return commonGet(token, url)
  }

  static getMicrobiology (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/microbiology`
    return commonGet(token, url)
  }

  static getCytology (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/cytology`
    return commonGet(token, url)
  }

  static getOtherInvestigation (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/otherInvestigation`
    return commonGet(token, url)
  }

  static getPhysiology (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/physiology`
    return commonGet(token, url)
  }

  static getUrinalysis (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/urinalysis`
    return commonGet(token, url)
  }

  // Events
  static getEvent (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/event`
    return commonGet(token, url)
  }

  static getEncounterList (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/encounterList`
    return commonGet(token, url)
  }

  static getEncounterDetail (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/encounterDetail`
    return commonGet(token, url)
  }

  static getReferral (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/referral`
    return commonGet(token, url)
  }

  static getAdmission (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/admission`
    return commonGet(token, url)
  }

  // PatientDetails
  static getPatientDetail (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/patientDetail`
    return commonGet(token, url)
  }

  static getFullMedicalRecord (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/emis/dataExtract/fullMedicalRecord`
    return commonGet(token, url)
  }

  // Audit History
  static getAuditHistory (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/emis/auditHistory`
    return commonGet(token, url)
  }
}
