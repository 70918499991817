import { all, fork } from 'redux-saga/effects'
import actions from './actions'
import crudSagas from '../../crud/sagas'
import Api from '../../../../api'
import { call, put, takeEvery } from '@redux-saga/core/effects'
import { getAuthToken } from '../../../../helpers/tokens'
import _ from 'lodash'
import { defineGetSaga } from '../../../sagasHelper'

export function * createRiskAssessmentFromTemplateRequest () {
  yield takeEvery(actions.RISKASSESSMENT_CREATEFROMTEMPLATE_REQUEST, function * ({ ids, onSuccessCallback, onFailureCallback }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.RiskAssessment.createFromTemplate,
        authToken.token,
        ids.providerId,
        ids.userId,
        ids.templateId
      )
      if (response) {
        yield put(actions.createFromTemplateSuccess(ids, response))
        _.isFunction(onSuccessCallback) && onSuccessCallback(response)
      } else {
        yield put(actions.createFromTemplateError(ids, error))
        _.isFunction(onFailureCallback) && onFailureCallback(error)
      }
    }
  })
}

export function * downloadPDFRequest () {
  // NOTE WE MAY NEED TO HANDLE TypeError as success
  //         if (error instanceof TypeError) {
  yield defineGetSaga(actions.RISKASSESSMENT_DOWNLOAD_PDF_REQUEST, Api.RiskAssessment.downloadPdf, actions.downloadPdfSuccess, actions.downloadPdfError)
}

export default function * devSaga () {
  yield all([
    crudSagas(actions, Api.Crud, 'providers/:providerId/serviceusers/:userId/riskassessment', 'id'),
    fork(createRiskAssessmentFromTemplateRequest),
    downloadPDFRequest()
  ])
}
