import actions from "./actions";
import {
  initialDataState,
  reduceDataError,
  reduceDataRequest,
  reduceDataSuccess,
} from "../../reducersHelper";

const initState = {
  myAcceptedVisit: initialDataState,
  mySchedule: initialDataState,
  myScheduleProcessed: null,
  visitData: {},
  patchVisit: initialDataState,
  updateAcceptedVisit: initialDataState,
};

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAR:
      return { ...initState };

    case actions.SET_MY_SCHEDULE_PROCESSED:
      return {
        ...state,
        myScheduleProcessed: action.payload,
      };

    case actions.SET_VISIT_DATA:
      if (!action.visit.scheduledVisitId) {
        return { ...state };
      }

      return {
        ...state,
        visitData: {
          ...state.visitData,
          [action.visit.scheduledVisitId]: action.visit,
        },
      };

    case actions.GET_MY_SCHEDULE + "_REQUEST":
      return reduceDataRequest(state, "mySchedule");
    case actions.GET_MY_SCHEDULE + "_SUCCESS":
      return reduceDataSuccess(state, "mySchedule", action.payload);
    case actions.GET_MY_SCHEDULE + "_ERROR":
      return reduceDataError(state, "mySchedule", action.error);

    case actions.GET_MY_ACCEPTED_VISIT + "_REQUEST":
      return reduceDataRequest(state, "myAcceptedVisit");
    case actions.GET_MY_ACCEPTED_VISIT + "_SUCCESS":
      return reduceDataSuccess(state, "myAcceptedVisit", action.payload);
    case actions.GET_MY_ACCEPTED_VISIT + "_ERROR":
      return reduceDataError(state, "myAcceptedVisit", action.error);

    case actions.ACCEPT_VISIT + "_REQUEST":
      return reduceDataRequest(state, "patchVisit");
    case actions.ACCEPT_VISIT + "_SUCCESS":
      return reduceDataSuccess(state, "patchVisit", action.payload);
    case actions.ACCEPT_VISIT + "_ERROR":
      return reduceDataError(state, "patchVisit", action.error);

    case actions.START_VISIT + "_REQUEST":
      return reduceDataRequest(state, "patchVisit");
    case actions.START_VISIT + "_SUCCESS":
      return reduceDataSuccess(state, "patchVisit", action.payload);
    case actions.START_VISIT + "_ERROR":
      return reduceDataError(state, "patchVisit", action.error);

    case actions.UPDATE_ACCEPTED_VISIT + "_REQUEST":
      return reduceDataRequest(state, "updateAcceptedVisit");
    case actions.UPDATE_ACCEPTED_VISIT + "_SUCCESS":
      return reduceDataSuccess(state, "updateAcceptedVisit", action.payload);
    case actions.UPDATE_ACCEPTED_VISIT + "_ERROR":
      return reduceDataError(state, "updateAcceptedVisit", action.error);

    default:
      return state;
  }
};

export default reducers;
