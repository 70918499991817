export function clearTokens () {
  localStorage.removeItem('authToken')
  localStorage.removeItem('refreshToken')
}

export function getAuthToken () {
  try {
    const authToken = JSON.parse(localStorage.getItem('authToken'))
    return authToken
  } catch (err) {
    clearTokens()
    return null
  }
}

export function getRefreshToken () {
  try {
    const refreshToken = JSON.parse(localStorage.getItem('refreshToken'))
    return refreshToken
  } catch (err) {
    // clearTokens();
    return null
  }
}

export function setAuthToken (token) {
  try {
    localStorage.setItem('authToken', JSON.stringify(token))
  } catch (err) {
    clearTokens()
  }
}

export function setRefreshToken (token) {
  try {
    localStorage.setItem('refreshToken', JSON.stringify(token))
  } catch (err) {
    clearTokens()
  }
}
