import actions from './actions'
import crudReducers from '../../crud/reducers'

const dataPropName = 'doc'

const initState = {
  list: [],
  loading: false,
  error: false,
  modalActive: false,
  doc: ''
}

const reducers = crudReducers(actions, dataPropName, initState)

export default reducers
