import crudActions from '../../crud/actions'

const actions = {
  ...crudActions('CAREPLAN_DOCS_'),

  CAREPLAN_DOC_DOWNLOAD_REQUEST: 'CAREPLAN_DOC_DOWNLOAD_REQUEST',

  downloadRequest: data => {
    return {
      type: actions.CAREPLAN_DOC_DOWNLOAD_REQUEST,
      payload: data
    }
  }
}

export default actions
