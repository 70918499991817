const actions = {
  GET_LIST_REQUEST: 'HR_LEGAL_LIST_GET_LIST_REQUEST',
  GET_LIST_SUCCESS: 'HR_LEGAL_LIST_GET_LIST_SUCCESS',
  GET_LIST_ERROR: 'HR_LEGAL_LIST_GET_LIST_ERROR',

  getList: (providerID) => {
    return {
      type: actions.GET_LIST_REQUEST,
      payload: providerID
    }
  },
  getListSuccess: (result) => {
    return {
      type: actions.GET_LIST_SUCCESS,
      payload: result
    }
  },
  getListError: (error) => {
    return {
      type: actions.GET_LIST_ERROR,
      error: error
    }
  }
}

export default actions
