
class Errors {
  static isAPIError (response) {
    return (
      response.code !== undefined &&
      response.message !== undefined &&
      response.status !== undefined
    )
  }

  static handleErrors (response) {
    // Check to see if response is an API error and throw accordingly
    if (response.status === 204) {
      return {error: response}
    }

    if (response.status === 404) {
      return {error: response}
    }

    if (response.status === 0) {
      return response
    }

    if (response.status === 429) {
      throw new Error(
        JSON.stringify({
          code: 30021,
          status: response.status,
          message: "Too many requests",
        })
      )
    }

    if (response.status === 401) {
      throw new Error(
        JSON.stringify({
          status: response.status,
          message: response.statusText
        })
      )
    }

    const responseObject = response.json()

    return responseObject.then(body => {
      if (this.isAPIError(body)) {
        // Throw the ApiError as a json string (for browser compatibility)
        if (body === '') {
          throw new Error(
            JSON.stringify({
              status: response.status,
              message: response.statusText
            })
          )
        }
        throw new Error(JSON.stringify(body))
      } else {
        // Otherwise, just return the parsed body
        if (response.ok) {
          if (body === '') {
            return true
          }
          return body
        } else {
          throw new Error(
            JSON.stringify({
              status: response.status,
              message: response.statusText
            })
          )
        }
      }
    })
      .catch(error => {

        // Re-throw the error generated inside the promise.
        console.log(error)
        throw error

        // try {
        // } catch (error) {
        //   console.log(error)
        //   let status = error.status
        //   let message = error.message
        //   if (error instanceof TypeError) {
        //     if (message === 'Failed to fetch') {
        //       status = 0
        //       message = 'The browser could not reach the server.\nPlease try the following:\n\n  1: Check your internet connection by checking another site\n  2. Reload this page\n\nIf the problem persists, then:\n\n  3: Contact us on help@axela.co.uk or if urgent, call your account manager\n\nThank you for your understanding.'
        //       yield put(actions.showError({ status: status, message: message }))
        //     }
        //   }
        // }
      })
  }

  static handleConnectionError (error) {
    let status = error.message
    let message = error.message
    if (error instanceof TypeError) {
      if (message === 'Failed to fetch') {
        status = 0
        message = 'The browser could not reach the server.\nPlease try the following:\n\n  1: Check your internet connection by checking another site\n  2. Reload this page\n\nIf the problem persists, then:\n\n  3: Contact us on help@axela.co.uk or if urgent, call your account manager\n\nThank you for your understanding.'
        throw new Error(
          JSON.stringify({
            status: status,
            message: message
          })
        )
      }
    }
  }
}

export default Errors
