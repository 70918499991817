import actions from './actions'

const initState = {
  searchTerm: '',
  data: [],
  loading: false,
  error: false
}
const reducers = (state = initState, { type, payload }) => {
  switch (type) {
    case actions.SEARCH_TITLE_REQUEST:
      return {
        ...state,
        searchTerm: payload.searchTerm,
        loading: true
      }
    case actions.SEARCH_TITLE_SUCCESS:
      return {
        ...state,
        loading: false,
        data: payload
      }
    case actions.SEARCH_TITLE_ERROR:
      return {
        ...state,
        loading: false,
        error: payload
      }
    default:
      return state
  }
}
export default reducers
