import { all, takeEvery, put, call, fork } from 'redux-saga/effects'
import actions from '../dmd/actions'
import { getAuthToken } from '../../../helpers/tokens'
import Api from '../../../api'

export function * searchDmdAmppRequest () {
  yield takeEvery(actions.SEARCH_DMDAMPP_REQUEST, function * ({ payload }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Dmd.getDmdAmppList,
        authToken.token,
        payload.providerId,
        payload.searchTerm
      )
      if (response) {
        yield put(actions.searchDmdAmppSuccess(response))
      } else {
        yield put(actions.searchDmdAmppError(error))
      }
    }
  })
}

export function * getDoseForms () {
  yield takeEvery(actions.DOSE_FORMS_REQUEST, function * ({ payload }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Dmd.getDmdDoseForms,
        authToken.token,
        payload.providerId
      )
      if (response) {
        yield put(actions.doseFormSuccess(response))
      } else {
        yield put(actions.doseFormError(error))
      }
    }
  })
}

export function * getDrugRoutes () {
  yield takeEvery(actions.DRUG_ROUTES_REQUEST, function * ({ payload }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Dmd.getDmdDrugRoutes,
        authToken.token,
        payload.providerId
      )
      if (response) {
        yield put(actions.drugRoutesSuccess(response))
      } else {
        yield put(actions.drugRoutesError(error))
      }
    }
  })
}

export function * getControlledDrugCategories () {
  yield takeEvery(actions.CONTROLLED_DRUG_CATEGORIES_REQUEST, function * ({ payload }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Dmd.getDmdControlledDrugCategories,
        authToken.token,
        payload.providerId
      )
      if (response) {
        yield put(actions.controlledDrugCategorySuccess(response))
      } else {
        yield put(actions.controlledDrugCategoryError(error))
      }
    }
  })
}

export default function * devSaga () {
  yield all([
    fork(searchDmdAmppRequest),
    fork(getDoseForms),
    fork(getDrugRoutes),
    fork(getControlledDrugCategories)
  ])
}
