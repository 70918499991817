import React, {useState, useEffect} from 'react'
import {ThemeProvider} from "styled-components";
import {theme as defaultTheme} from "./config";
import {useSelector} from "react-redux";
import _ from 'lodash'

export default function ThemeWrapper(props) {
    const interfaceTheme = useSelector((state) => state.Interface.theme)
    const [theme, setTheme] = useState(defaultTheme)
    useEffect(() => {
        if (!_.isEmpty(interfaceTheme)) {
            setTheme(interfaceTheme)
        } else {
            setTheme(defaultTheme)
        }
    }, [interfaceTheme])

    return (
      <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )

}