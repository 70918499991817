import config from './settings'
import { commonGet } from './common'

export default class Utility {
  static getTimeOfDayData (token, providerId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/timeOfDay`
    return commonGet(token, url)
  }
}
