import {store} from "../../../BaseWrapper";
import qs from "qs";
import moment from "moment-timezone";
import {
  FIELD_AVAILABILITY_STATUS,
  FIELD_DATE_RANGE_PICKER,
  FIELD_LOCATIONS,
  FIELD_SERVICEUSERS,
  FIELD_STAFFUSERS,
  FIELD_TITLE,
  FIELD_TYPE,
  FIELD_VISIT_STATUS
} from "../../../containers/App/Rostering/Filters/Filters";
import {defineGetActionsPrSu} from "../../actionsHelper";

export const getDefaultDateRange = () => {
  return [
    moment().utc().startOf('day'),
    moment().utc().startOf('day').add({day: 0})
  ]
}

const actions = {
  CLEAR: 'ROSTERING_CLEAR_FILTERS',
  SET_FILTERS: 'ROSTERING_SET_FILTERS',
  SET_SCHEDULE_PAGE: 'ROSTERING_SET_SCHEDULE_PAGE',
  LOAD_FILTERS_FROM_QUERY_STRING: 'ROSTERING_LOAD_FILTERS_FROM_QUERY_STRING',
  LOAD: 'ROSTERING_LOAD',
  SET_SHOW_STAFF: 'ROSTERING_SET_SHOW_STAFF',
  CLEAR_SHOW_STAFF: 'ROSTERING_CLEAR_SHOW_STAFF',
  DOWNLOAD_CSV_REQUEST: 'ROSTERING_DOWNLOAD_CSV_REQUEST',
  DOWNLOAD_CSV_SUCCESS: 'ROSTERING_DOWNLOAD_CSV_SUCCESS',
  DOWNLOAD_CSV_ERROR: 'ROSTERING_DOWNLOAD_CSV_ERROR',

  clear: () => {
    return {
      type: actions.CLEAR
    }
  },

  setFilters: (newFilters) => {
    return {
      type: actions.SET_FILTERS,
      filters: newFilters
    }
  },

  setSchedulePage: (page, pageSize = 50) => {
    return {
      type: actions.SET_SCHEDULE_PAGE,
      pageNo: page,
      pageSize: pageSize
    }
  },

  load: () => {
    return {
      type: actions.LOAD,
    }
  },

  loadFiltersFromQueryString: () => {
    const state = store.getState()
    const currentLocation = state.Interface.currentLocation
    const qsparams = qs.parse(currentLocation.search.slice(1))

    let filters = {}

    // Date Range
    const qsTimeFrom = qsparams.timeFrom && moment(qsparams.timeFrom, 'YYYY-MM-DDTHH:mm:ssZ').utc()
    const qsTimeTo = qsparams.timeTo && moment(qsparams.timeTo, 'YYYY-MM-DDTHH:mm:ssZ').utc()
    let dateRange
    if (qsTimeFrom && qsTimeTo) {
      dateRange = [qsTimeFrom, qsTimeTo]
    } else {
      dateRange = getDefaultDateRange()
    }
    filters[FIELD_DATE_RANGE_PICKER] = dateRange.map(d => d.clone().utc().startOf('d'))

    // ServiceUserIds
    if (qsparams.serviceUserIds) {
      filters[FIELD_SERVICEUSERS] = qsparams.serviceUserIds.split(',')
    }

    // StaffUserIds
    if (qsparams.staffUserIds) {
      filters[FIELD_STAFFUSERS] = qsparams.staffUserIds.split(',')
    }

    // LocationIds
    if (qsparams.locationIds) {
      filters[FIELD_LOCATIONS] = qsparams.locationIds.split(',')
    }

    // Title
    if (qsparams.title) {
      filters[FIELD_TITLE] = qsparams.title
    }

    // VisitTypes
    if (qsparams.visitTypes) {
      filters[FIELD_TYPE] = qsparams.visitTypes.split(',')
    }

    // AvailabilityStatuses
    if (qsparams.availabilityStatuses) {
      filters[FIELD_AVAILABILITY_STATUS] = qsparams.availabilityStatuses.split(',')
    }

    // VisitStatuses
    if (qsparams.visitStatuses) {
      filters[FIELD_VISIT_STATUS] = qsparams.visitStatuses.split(',')
    }

    let scheduleOffset = 0

    // ScheduleOffset
    if (qsparams.sOffset) {
      scheduleOffset = qsparams.sOffset
    }

    return {
      type: actions.LOAD_FILTERS_FROM_QUERY_STRING,
      filters: filters,
      scheduleOffset: scheduleOffset
    }

  },

  setShowStaff:(staffIdArr, hide) => {
    return {
      type: actions.SET_SHOW_STAFF,
      payload: staffIdArr,
      hide: hide
    }
  },
  clearShowStaff:() => {
    return {
      type: actions.CLEAR_SHOW_STAFF,
    }
  },

  downloadCSV: (mode,successCallback) => {
    return {
      type: actions.DOWNLOAD_CSV_REQUEST,
      mode: mode,
      successCallback: successCallback
    }
  },


}

defineGetActionsPrSu(actions, 'ROSTERING_GET_PLANBOARD', 'getPlanboard')
defineGetActionsPrSu(actions, 'ROSTERING_GET_UNIFIED_ROSTER', 'getUnifiedRoster')

export default actions
