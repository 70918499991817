import actions from './actions'
import _ from 'lodash'
import {initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess} from "../../reducersHelper";

const initState = {
  filters: {},
  schedule: {
    offset: 0,
    pageSize: 50
  },
  allowDropStaffIds: undefined,
  hideStaff: false,
  planboard: initialDataState,
  unifiedRoster: initialDataState,
}

const reducers = (state = initState, action) => {

  switch (action.type) {
    case actions.CLEAR:
      return {...initState}

    case actions.LOAD_FILTERS_FROM_QUERY_STRING:
    case actions.SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters
        },
        schedule: {
          ...state.schedule,
          offset: action.scheduleOffset ? action.scheduleOffset : 0
        }
      }

    case actions.SET_SCHEDULE_PAGE:
      return {
        ...state,
        schedule: {
          ...state.schedule,
          offset: (action.pageNo - 1) * action.pageSize,
          pageSize: action.pageSize
        }
      }

    case actions.SET_SHOW_STAFF:
      return {
        ...state,
        allowDropStaffIds: _.keyBy(action.payload, o => o),
        hideStaff: action.hide
      }

    case actions.CLEAR_SHOW_STAFF:
      return {
        ...state,
        allowDropStaffIds: undefined
      }

    case actions.ROSTERING_GET_PLANBOARD_REQUEST:
      return reduceDataRequest(state, 'planboard')
    case actions.ROSTERING_GET_PLANBOARD_SUCCESS:
      return reduceDataSuccess(state, 'planboard', action.payload)
    case actions.GROSTERING_GET_PLANBOARD_ERROR:
      return reduceDataError(state, 'planboard', action.error)

    case actions.ROSTERING_GET_UNIFIED_ROSTER_REQUEST:
      return reduceDataRequest(state, 'unifiedRoster')
    case actions.ROSTERING_GET_UNIFIED_ROSTER_SUCCESS:
      return reduceDataSuccess(state, 'unifiedRoster', action.payload)
    case actions.GROSTERING_GET_UNIFIED_ROSTER_ERROR:
      return reduceDataError(state, 'unifiedRoster', action.error)

    default:
      return state
  }
}

export default reducers
