import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../../reducersHelper'

const initState = {
  unifiedVisits: initialDataState
}

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.CLEAR_SERVICEUSER_SCHEDULING:
      return initState

    case actions.GET_SERVICEUSER_SCHEDULING_UNIFIED_VISITS_REQUEST:
      return reduceDataRequest(state, 'unifiedVisits')
    case actions.GET_SERVICEUSER_SCHEDULING_UNIFIED_VISITS_SUCCESS:
      return reduceDataSuccess(state, 'unifiedVisits', action.payload)
    case actions.GET_SERVICEUSER_SCHEDULING_UNIFIED_VISITS_ERROR:
      return reduceDataError(state, 'unifiedVisits', action.error)

    default:
      return state
  }
}

export default reducers
