import config from './settings'
import {commonDelete, commonGet, commonGetBlob, commonPut} from './common'

export default class Scheduling {
  static getUnifiedVisits (token, providerId, data) {
    const qs = `serviceUserIds=${data.serviceUserIds ? data.serviceUserIds.join() : ''}` +
      `&careWorkerIds=${data.careWorkerIds ? data.careWorkerIds.join() : ''}` +
      `&locationIds=${data.locationIds ? data.locationIds.join() : ''}` +
      `&visitTypes=${data.visitTypes ? data.visitTypes.join() : ''}` +
      `&statuses=${data.statuses ? data.statuses.join() : ''}` +
      `&timeFrom=${data.timeFrom ? data.timeFrom.toISOString() : ''}` +
      `&timeTo=${data.timeTo ? data.timeTo.toISOString() : ''}` +
      `&dateFrom=${data.dateFrom ? data.dateFrom.toISOString() : ''}` +
      `&dateTo=${data.dateTo ? data.dateTo.toISOString() : ''}` +
      `&title=${data.title ? data.title : ''}` +
      `&offset=${data.offset ? data.offset : ''}` +
      `&pageSize=${data.pageSize ? data.pageSize : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/visits?${qs}${data.withAvailability ? '&availability=y' : ''}`
    return commonGet(token, url)
  }

  static assignStaff (token, providerId, userId, data) {
    const {
      visitId,
      date,
      careWorkerNum,
      careWorkerId,
      scheduledVisitId,
      startTime,
      endTime
    } = data

    if (!careWorkerId && scheduledVisitId) {
      // REMOVE
      const url = `${
        config.url
      }/auth/providers/${providerId}/scheduling/visits/assign/${userId}/${visitId}/${date}/${scheduledVisitId}`
      return commonDelete(token, url)
    } else if (careWorkerId && !scheduledVisitId) {
      // ASSIGN
      if (startTime) {
        const url = `${
          config.url
        }/auth/providers/${providerId}/scheduling/visits/assignAdhoc/${userId}/${visitId}/${date}/${careWorkerNum}/${careWorkerId}/${startTime}/${endTime}`
        return commonPut(token, url)
      } else {
        const url = `${
          config.url
        }/auth/providers/${providerId}/scheduling/visits/assign/${userId}/${visitId}/${date}/${careWorkerNum}/${careWorkerId}`
        return commonPut(token, url)
      }
    }
  }

  static setException (token, providerId, userId, data) {
    const {
      visitId,
      date,
      careWorkerNum
    } = data
    const url = `${
        config.url
      }/auth/providers/${providerId}/scheduling/visits/exceptions/${userId}/${visitId}/${date}/${careWorkerNum}`
    return commonPut(token, url, data)
  }

  static clearException (token, providerId, userId, data) {
    const {
      visitId,
      date,
      careWorkerNum
    } = data
    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/visits/exceptions/${userId}/${visitId}/${date}/${careWorkerNum}`
    return commonDelete(token, url, data)
  }

  static getVisitOrPreview (token, providerId, userId, data) {
    const {
      visitId,
      date,
      careWorkerNum
    } = data
    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/visits/view/${userId}/${visitId}/${date}/${careWorkerNum}`
    return commonGet(token, url, data)
  }

  static bulkAssignStaff (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/bulk/assignCareWorker`
    return commonPut(token, url, data)
  }

  static bulkAssignPreferredStaff (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/bulk/assignPreferredCareWorker`
    return commonPut(token, url, data)
  }

  static bulkRemoveStaff (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/bulk/removeCareWorker`
    return commonPut(token, url, data)
  }

  static bulkSmartAllocation (token, providerId, userId, data) {
    const url = `${
        config.url
    }/auth/providers/${providerId}/scheduling/bulk/smartAllocation`
    return commonPut(token, url, data)
  }

  static downloadCsv (token, providerId, data) {
    const qs = `serviceUserIds=${data.serviceUserIds ? data.serviceUserIds.join() : ''}` +
      `&careWorkerIds=${data.careWorkerIds ? data.careWorkerIds.join() : ''}` +
      `&locationIds=${data.locationIds ? data.locationIds.join() : ''}` +
      `&visitTypes=${data.visitTypes ? data.visitTypes.join() : ''}` +
      `&statuses=${data.statuses ? data.statuses.join() : ''}` +
      `&timeFrom=${data.timeFrom ? data.timeFrom.toISOString() : ''}` +
      `&timeTo=${data.timeTo ? data.timeTo.toISOString() : ''}` +
      `&title=${data.title ? data.title : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/scheduling/visits/csv?${qs}`
    return commonGetBlob(token, url)
  }
}
