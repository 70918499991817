import config from './settings'
import { commonDelete, commonGet, commonPut, commonPutBlob } from './common'

class Provider {
  static getProviderSettings (token, providerId) {
    const url = `${config.url}/auth/providers/${providerId}/settings`
    return commonGet(token, url)
  }

  static updateProviderSettings (token, providerId, data) {
    const url = `${config.url}/auth/providers/${providerId}/settings`
    return commonPut(token, url, data)
  }

  static getProviderRoles (token, providerId) {
    const url = `${config.url}/auth/providers/${providerId}/roles`
    return commonGet(token, url)
  }

  static getProviderLogo (token, providerId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/logo`
    return fetch(url, {
      method: 'GET',
      headers: {
        ...config.configHeaders,
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        if (response.status === 204) {
          return null
        }
        if (response.status !== 200) {
          return response
        }

        return response.text()
      }).then(text => {
        return { response: text }
      })
      .catch(error => ({ error }))
  }

  static updateProviderLogo (token, providerId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/logo.png`
    return commonPutBlob(token, url, data)
  }

  static deleteProviderLogo (token, providerId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/logo.png`
    return commonDelete(token, url)
  }
}

export default Provider
