import crudActions from '../../crud/actions'

// Actions for the additional documents uploaded to the serviceuser condition
const actions = {
  ...crudActions('HR_LEGAL_DOCUMENT_DOCS_'),

  HR_LEGAL_DOC_DOWNLOAD_REQUEST: 'HR_LEGAL_DOC_DOWNLOAD_REQUEST',

  downloadRequest: (data) => {
    return {
      type: actions.HR_LEGAL_DOC_DOWNLOAD_REQUEST,
      payload: data
    }
  }
}

export default actions
