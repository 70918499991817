import crudActions from '../../crud/actions'

const actions = {
  ...crudActions('RISKASSESSMENT_DOCS_'),

  RISKASSESSMENT_DOC_DOWNLOAD_REQUEST: 'RISKASSESSMENT_DOC_DOWNLOAD_REQUEST',

  downloadRequest: data => {
    return {
      type: actions.RISKASSESSMENT_DOC_DOWNLOAD_REQUEST,
      payload: data
    }
  }
}

export default actions
