import {all, fork} from 'redux-saga/effects'
import Api from '../../../api'
import actions from './actions'
import {defineGetSaga, defineGetSagaProvider} from '../../sagasHelper'

export function* getStatsRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USERS_STATS_REQUEST, Api.ServiceUser.getServiceUsersStats, actions.getStatsSuccess, actions.getStatsError)
}

export function* getCareAssessmentCompletenessStatsRequest() {
  yield defineGetSaga(actions.GET_SERVICE_USERS_CARE_ASSESSMENT_COMPLETENESS_STATS_REQUEST, Api.CareAssessment.getCompletenessStats, actions.getCareAssessmentCompletenessStatsSuccess, actions.getCareAssessmentCompletenessStatsError)
}

export function * getCareAssessmentCompletenessCsvRequest () {
  yield defineGetSagaProvider(actions.GET_SERVICE_USERS_CARE_ASSESSMENT_COMPLETENESS_CSV_REQUEST, Api.CareAssessment.getCompletenessCsv, actions.getCareAssessmentCompletenessCsvSuccess, actions.getCareAssessmentCompletenessCsvError)
}

export function* getServiceUsersCovid19StatsRequest() {
  yield defineGetSagaProvider(actions.GET_SERVICE_USERS_COVID_19_STATS_REQUEST, Api.Reports.getServiceUsersCovid19Stats, actions.getServiceUsersCovid19StatsSuccess, actions.getServiceUsersCovid19StatsError)
}

export function * getServiceUsersCovid19CsvRequest () {
  yield defineGetSagaProvider(actions.GET_SERVICE_USERS_COVID_19_CSV_REQUEST, Api.Reports.getServiceUsersCovid19Csv, actions.getServiceUsersCovid19CsvSuccess, actions.getServiceUsersCovid19CsvError)
}


export default function* rootSaga() {
  yield all([
    fork(getStatsRequest),
    fork(getCareAssessmentCompletenessStatsRequest),
    fork(getCareAssessmentCompletenessCsvRequest),
    fork(getServiceUsersCovid19StatsRequest),
    fork(getServiceUsersCovid19CsvRequest),
  ])
}
