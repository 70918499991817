import {all} from 'redux-saga/effects'
import alertsSagas from './alerts/sagas'
import careAssessmentAttorneysSagas from './careassessmentAttorneys/sagas'
import careAssessmentDomesticSupportActivitiesSagas from './careassessmentDomesticSupportActivities/sagas'
import careAssessmentEquipmentSagas from './careassessmentEquipment/sagas'
import careAssessmentMovingAndHandlingDocsSagas from './careAssessmentMovingAndHandlingDocs/sagas'
import careAssessmentSagas from './careassessment/sagas'
import careAssessmentSocialInclusionActivitiesSagas from './careassessmentSocialInclusionActivities/sagas'
import carePlanDocsSagas from './careplanDocs/sagas'
import carePlanMedicationTasksSagas from './careplanMedicationTasks/sagas'
import careOutcomesSagas from './careOutcomes/sagas'
import carePlanSagas from './careplan/sagas'
import carePlanSupportTasksSagas from './careplanSupportTasks/sagas'
import carePlanVisitsSagas from './careplanVisits/sagas'
import communicationMessagesSagas from './communicationMessages/sagas'
import emarSagas from './emar/sagas'
import emisSagas from './emis/sagas'
import healthtrackerSagas from './healthtracker/sagas'
import riskAssessmentDocsSagas from './riskAssessmentDocs/sagas'
import riskAssessmentSagas from './riskAssessment/sagas'
import schedulingSagas from './scheduling/sagas'
import serviceUserAllergiesSagas from './serviceuserAllergies/sagas'
import serviceUserConditionsDocsSagas from './serviceuserConditions/docSagas'
import serviceUserConditionsSagas from './serviceuserConditions/sagas'
import serviceUserLegalChoicesDocsSagas from './serviceuserLegalChoicesDocs/sagas'
import serviceUserContactsSagas from './serviceuserContacts/sagas'
import serviceUserDocumentsSagas from './serviceuserDocuments/sagas'
import serviceUserInformationSagas from './serviceuserInformation/sagas'
import serviceUserSagas from './serviceusers/sagas'
import supportTaskTitleSagas from './supportTaskTitle/sagas'
import symptomsSagas from './symptoms/sagas'

export default function* rootSaga() {
  yield all([
    alertsSagas(),
    careAssessmentAttorneysSagas(),
    careAssessmentDomesticSupportActivitiesSagas(),
    careAssessmentMovingAndHandlingDocsSagas(),
    careAssessmentEquipmentSagas(),
    careAssessmentSagas(),
    careAssessmentSocialInclusionActivitiesSagas(),
    carePlanDocsSagas(),
    carePlanMedicationTasksSagas(),
    careOutcomesSagas(),
    carePlanSagas(),
    carePlanSupportTasksSagas(),
    carePlanVisitsSagas(),
    communicationMessagesSagas(),
    emarSagas(),
    emisSagas(),
    healthtrackerSagas(),
    riskAssessmentDocsSagas(),
    riskAssessmentSagas(),
    schedulingSagas(),
    serviceUserAllergiesSagas(),
    serviceUserConditionsDocsSagas(),
    serviceUserConditionsSagas(),
    serviceUserLegalChoicesDocsSagas(),
    serviceUserContactsSagas(),
    serviceUserDocumentsSagas(),
    serviceUserInformationSagas(),
    serviceUserSagas(),
    supportTaskTitleSagas(),
    symptomsSagas()
  ])
}
