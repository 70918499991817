import config from './settings'
import {commonDelete, commonGet, commonPut, commonPutBlob} from './common'

class Users {
  static activateRequest (token, providerId, email) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/newusers/${email}/activate`
    return commonGet(token, url)
  }


  static searchUsers (token, providerId, data) {
    const qs = `userIds=${data.userIds ? data.userIds.join() : ''}` +
      `&locationIds=${data.locationIds ? data.locationIds.join() : ''}` +
      `&serviceUserStatus=${data.serviceUserStatus ? data.serviceUserStatus.join() : ''}` +
      `&role=${data.role ? data.role.join() : ''}` +
      `&enabled=${data.enabled ? data.enabled.join() : ''}` +
      `&searchString=${data.searchString ? data.searchString : ''}` +
      `&offset=${data.offset ? data.offset : ''}` +
      `&pageSize=${data.pageSize ? data.pageSize : ''}`

    const url = `${
      config.url
    }/auth/providers/${providerId}/users?${qs}`
    return commonGet(token, url)
  }

  static getAvatar (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/avatar`
    return fetch(url, {
      method: 'GET',
      headers: {
        ...config.configHeaders,
        Authorization: 'Bearer ' + token
      }
    })
      .then(response => {
        if (response.status === 204) {
          return null
        }
        if (response.status !== 200) {
          return response
        }

        return response.text()
      }).then(text => {
        return { response: text }
      })
      .catch(error => ({ error }))
  }

  static updateAvatar (token, providerId, userId, data) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/avatar.jpg`
    return commonPutBlob(token, url, data)
  }

  static deleteAvatar (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/avatar.jpg`
    return commonDelete(token, url)
  }

  static getAllUserAvatars (token, providerId) {
    const url = `${config.url}/auth/providers/${providerId}/userAvatars`
    return commonGet(token, url)
  }

  static getPasswordResetLink (token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/users/${userId}/passwordResetLink`
    return commonGet(token, url)
  }

  static resetPasswordForUser (token, providerId, userId, payload) {
  const url = `${config.url}/auth/providers/${providerId}/users/${userId}/password`
    return commonPut(token, url, payload)
  }

}

export default Users
