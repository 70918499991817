import config from "./settings";
import { commonGet, commonGetBlob } from "./common";

export default class ReportingDashboard {
  static getDashboard(token, providerId, refresh) {
    const refreshQS = refresh ? "?refresh=y" : "";

    const url = `${config.url}/auth/providers/${providerId}/reports/dashboard${refreshQS}`;
    return commonGet(token, url);
  }

  static downloadUserReportCsv(token, providerId, data) {
    const { reportType } = data;
    const url = `${config.url}/auth/providers/${providerId}/usersExport/${reportType}/csv`;
    return commonGetBlob(token, url);
  }

  static downloadReportFile(token, providerId, data) {
    const { reportName } = data;
    const url = `${config.url}/auth/providers/${providerId}/reports/export/${reportName}/csv`;
    return commonGetBlob(token, url);
  }
}
