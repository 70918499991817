import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getAuthToken } from '../../../helpers/tokens'
import actions from './actions'
import Api from '../../../api'
import { defineGetSagaProvider } from '../../sagasHelper'

function makeUrl (queryParameters) {
  if (!queryParameters) {
    queryParameters = {}
  }
  if (!queryParameters.pageSize) {
    queryParameters.pageSize = 100
  }
  const qs = Object.keys(queryParameters).map(key => key + '=' + queryParameters[key]).join('&')
  return `providers/:providerId/audit?${qs}`
}

export function * getEntries () {
  yield takeEvery(actions.GET_ENTRIES_REQUEST, function * ({ providerId, queryParameters }) {
    const url = makeUrl(queryParameters)
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Crud.getList,
        authToken.token,
        { providerId },
        url
      )
      if (response) {
        yield put(actions.getEntriesSuccess(response))
      } else {
        yield put(actions.getEntriesError(error))
      }
    }
  })
}

export function * getRecentUpdatesAllServiceUsersRequest () {
  yield defineGetSagaProvider(actions.GET_ENTRIES_RECENT_UPDATES_ALL_SERVICE_USERS_REQUEST, Api.Audit.getRecentUpdatesAllServiceUsers, actions.getRecentUpdatesAllServiceUsersSuccess, actions.getRecentUpdatesAllServiceUsersError)
}

export default function * devSaga () {
  yield all([
    getEntries(),
    getRecentUpdatesAllServiceUsersRequest()
  ])
}
