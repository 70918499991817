import crudActions from '../crud/actions'
import crudReducers from '../crud/reducers'
import crudSagas from '../crud/sagas'
import {initialDataState} from '../../reducersHelper'
import Api from '../../../api'

const actions = crudActions('PROVIDERSERVICESLOCATIONS_SERVICES_CRUD_')
const reducers = crudReducers(actions, 'service', initialDataState)
function * sagas () {
  yield crudSagas(
    actions,
    Api.Crud,
    'providers/:providerId/services',
    'id'
  )
}

export { actions, reducers, sagas }
