import { Input, InputNumber } from 'antd'
import {
  InputWrapper,
  InputGroupWrapper,
  InputSearchWrapper,
  InputNumberWrapper,
  TextAreaWrapper
} from './styles/input.style'

const { Search, TextArea, Group } = Input

const StyledInput = InputWrapper(Input)

const InputGroup = InputGroupWrapper(Group)

const InputSearch = InputSearchWrapper(Search)

const TextAreaWrapped = TextAreaWrapper(TextArea)

const StyledInputNumber = InputNumberWrapper(InputNumber)

export default StyledInput
export { InputSearch, InputGroup, TextAreaWrapped, StyledInputNumber }
