import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../../reducersHelper'
import { defineGetActionsPrSu } from '../../../actionsHelper'
import { defineGetSaga } from '../../../sagasHelper'
import Api from '../../../../api'
import { all, fork } from 'redux-saga/effects'

const actions = {}
defineGetActionsPrSu(actions, 'GET_HR_HOLIDAY_SUMMARY', 'getHolidaySummary')

const reducer = (state = initialDataState, action) => {
  switch (action.type) {
    case actions.GET_HR_HOLIDAY_SUMMARY_REQUEST:
      return reduceDataRequest(state, null)
    case actions.GET_HR_HOLIDAY_SUMMARY_SUCCESS:
      return reduceDataSuccess(state, null, action.payload)
    case actions.GET_HR_HOLIDAY_SUMMARY_ERROR:
      return reduceDataError(state, null, action.error)
    default:
      return state
  }
}

function * getHrHolidaySummaryRequest () {
  yield defineGetSaga(actions.GET_HR_HOLIDAY_SUMMARY_REQUEST, Api.HR.getHolidaySummary, actions.getHolidaySummarySuccess, actions.getHolidaySummaryError)
}

const saga = function * rootSaga () {
  yield all([
    fork(getHrHolidaySummaryRequest)
  ])
}

export { reducer, actions, saga }
