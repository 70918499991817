import {combineReducers} from 'redux'
import actions from './actions'

import Allergies from './allergies/reducers'
import Conditions from './conditions/reducers'
import ConditionsDocs from './conditions/docReducers'
import DocRepo from './docRepo/reducers'
import LegalDoc from './legal/reducers'
import LegalList from './legallist/reducers'
import Notifications from './notifications/reducers'
import {reducer as Absences} from './absences'
import {reducer as EmploymentHistory} from './employmentHistory'
import {reducer as Holidays} from './holidays'
import {reducer as HolidaysUpcoming} from './holidays/upcoming'
import {reducer as Learning} from './learning'
import {reducer as LearningDue} from './learning/due'
import {reducer as WorkingHours} from './workingHours'
import {reducers as Staff} from './staff'

const initBaseState = {
  userId: null,
  showHeader: 'small',
  personalDetailsMap: {}
}

const BaseReducer = (state = initBaseState, action) => {
  const header = []
  switch (action.type) {
    case actions.SET_HR_USER_ID:
      if (state.userId !== action.payload.userId) {
        return {
          ...initBaseState,
          userId: action.payload.userId
        }
      }
      return state
    case actions.PUSH_HR_USER_HEADER:
      header.push(action.payload.show)
      return {
        ...state,
        showHeader: action.payload.show
      }
    case actions.POP_HR_USER_HEADER:
      return {
        ...state,
        showHeader: header.pop()
      }

    default:
      return state
  }
}

export default combineReducers({
  Base: BaseReducer,
  Absences,
  Allergies,
  Conditions,
  ConditionsDocs,
  DocRepo,
  EmploymentHistory,
  Holidays,
  HolidaysUpcoming,
  Learning,
  LearningDue,
  LegalDoc,
  LegalList,
  Notifications,
  WorkingHours,
  Staff
})
