import crudActions from '../crud/actions'
import {defineGetActionsPrSu} from "../../actionsHelper";

const actions = {
  ...crudActions('ROUNDS'),

  CLEAR_MATCHING_VISIT_LIST: 'ROUNDS_CLEAR_MATCHING_VISIT_LIST',

  clearMatchingVisitList: () => {
    return {
      type: actions.CLEAR_MATCHING_VISIT_LIST
    }
  }

}

defineGetActionsPrSu(actions, 'ROUNDS_GET_VISITS_MATCHING_ROUND', 'getVisitsMatchingRound')

defineGetActionsPrSu(actions, 'ROUNDS_GET_UNIFIED_ROUNDS', 'getUnifiedRounds')

defineGetActionsPrSu(actions, 'ROUNDS_ASSIGN_STAFF', 'assignStaff')

export default actions
