import actions from './actions'
import crudReducers from '../../crud/reducers'

const dataPropName = 'equipment'

const initState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  modalActive: false,
  equipment: {
    equipment: '',
    location: '',
    use: ''
  }
}

const reducers = crudReducers(actions, dataPropName, initState)

export default reducers
