import crudActions from '../../crud/actions'
import {defineGetActionsPrSu, defineUpdateActionsPrSu} from "../../../actionsHelper";


const actions = {
  ...crudActions('CUSTOM_FORMS_')
}

defineGetActionsPrSu(actions, 'CUSTOM_FORMS_GET_AVAILABLE_TEMPLATES', 'getAvailableTemplates')
defineUpdateActionsPrSu(actions, 'CUSTOM_FORMS_CREATE_USER_FORM_FROM_TEMPLATE', 'createUserFormFromTemplate')

export default actions
