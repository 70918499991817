import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { getAuthToken } from "../../../helpers/tokens";
import actions from "./actions";
import Api from "../../../api";
import { defineGetSagaProvider } from "../../sagasHelper";

export function* getReportingDashboard() {
  yield takeEvery(
    actions.GET_REPORTING_DASHBOARD_REQUEST,
    function* ({ payload }) {
      const authToken = yield getAuthToken();
      if (authToken && authToken.token) {
        const { response, error } = yield call(
          Api.ReportingDashboard.getDashboard,
          authToken.token,
          payload.providerId,
          payload.refresh
        );

        if (response) {
          yield put(actions.getReportingDashboardSuccess(response));
        } else {
          yield put(actions.getReportingDashboardError(error));
        }
      }
    }
  );
}

export function* downloadUserReportingCsvRequest() {
  yield defineGetSagaProvider(
    actions.DOWNLOAD_USER_REPORTING_CSV_REQUEST,
    Api.ReportingDashboard.downloadUserReportCsv,
    actions.downloadUserReportingCsvSuccess,
    actions.downloadUserReportingCsvError
  );
}

export function* downloadReportFileRequest() {
  yield defineGetSagaProvider(
    actions.DOWNLOAD_REPORT_FILE_REQUEST,
    Api.ReportingDashboard.downloadReportFile,
    actions.downloadReportFileSuccess,
    actions.downloadReportFileError
  );
}

export default function* devSaga() {
  yield all([
    fork(getReportingDashboard),
    downloadUserReportingCsvRequest(),
    downloadReportFileRequest(),
  ]);
}
