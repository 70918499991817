import { defineGetActionsPrSu } from '../../../actionsHelper'

const actions = {
  CLEAR_PROVIDER_ALERTS: 'CLEAR_SERVICEUSER_ALERTS',

  clear: () => {
    return {
      type: actions.CLEAR_PROVIDER_ALERTS
    }
  }
}

defineGetActionsPrSu(actions, 'GET_SERVICEUSER_ALERTS', 'getServiceUserAlerts')

export default actions
