import {Form} from "antd";
import React, {useEffect} from "react";
import Select from "../../../../components/styled/select"
import _ from 'lodash'
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../redux/app/rostering/actions";
import Spin from "../../../../components/styled/spin";
import {FIELD_TYPE} from "./Filters";

const {Option} = Select


export default function VisitTypeFilter({form, onChange, disabled}) {
  const {getFieldDecorator} = form;
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Rostering.filters)
  const {list: careVisitTypes, loading} = useSelector(state => state.Settings.CareVisitTypes)


  const setFilter = (types) => {
    dispatch(actions.setFilters({
      [FIELD_TYPE]: types
    }))
  }

  const handleChange = (types) => {
    const typeIds = types.filter(s => !!s.key).map(s => s.key)
    setFilter(typeIds)
    _.isFunction(onChange) && onChange(typeIds)
  }

  useEffect(() => {
    if (!filters[FIELD_TYPE] || !_.isArray(filters[FIELD_TYPE]) || loading) {
      return
    }
    const selected = (filters[FIELD_TYPE] && _.isArray(filters[FIELD_TYPE])) ? filters[FIELD_TYPE].map(s => careVisitTypes.find(cv => cv.key===s)) : []

    form.setFieldsValue({[FIELD_TYPE]: selected})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careVisitTypes, loading, filters[FIELD_TYPE]])

  return <Form.Item className={'multiSelect'}>
    {getFieldDecorator(FIELD_TYPE, {
      initialValue: [],
      rules: [{type: 'array', message: 'Select visit type!'}]
    })(
      <Select
        mode='multiple'
        labelInValue
        placeholder='Select visit type'
        disabled={disabled}
        notFoundContent={loading ? <Spin size='small'/> : null}
        filterOption={true}
        // onSelect={updateFilters}
        onChange={handleChange}
        style={{width: '100%'}}
      >
        {careVisitTypes.map(d => (
          <Option key={d.key}
                  value={d.key}>
            {d.value}
          </Option>
        ))}
      </Select>
    )}
  </Form.Item>


}