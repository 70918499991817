import actions from './actions'
import { initialDataState, reduceDataError, reduceDataRequest, reduceDataSuccess } from '../../../reducersHelper'

const initState = {
  userNotificationOptions: initialDataState,
  userAlertsSelection: initialDataState,
  userEventsSelection: initialDataState
}

const reducers = (state = initState, action) => {
  switch (action.type) {
    case actions.NOTIFICATIONS_GET_NOTIFICATION_OPTIONS_REQUEST:
      return reduceDataRequest(state, 'userNotificationOptions')
    case actions.NOTIFICATIONS_GET_NOTIFICATION_OPTIONS_SUCCESS:
      return reduceDataSuccess(state, 'userNotificationOptions', action.payload)
    case actions.NOTIFICATIONS_GET_NOTIFICATION_OPTIONS_ERROR:
      return reduceDataError(state, 'userNotificationOptions', action.error)

    case actions.NOTIFICATIONS_UPDATE_NOTIFICATION_OPTIONS_REQUEST:
      return reduceDataRequest(state, 'userNotificationOptions')
    case actions.NOTIFICATIONS_UPDATE_NOTIFICATION_OPTIONS_SUCCESS:
      return reduceDataSuccess(state, 'userNotificationOptions', action.payload)
    case actions.NOTIFICATIONS_UPDATE_NOTIFICATION_OPTIONS_ERROR:
      return reduceDataError(state, 'userNotificationOptions', action.error)

    case actions.NOTIFICATIONS_GET_ALERTS_SELECTION_REQUEST:
      return reduceDataRequest(state, 'userAlertsSelection')
    case actions.NOTIFICATIONS_GET_ALERTS_SELECTION_SUCCESS:
      return reduceDataSuccess(state, 'userAlertsSelection', action.payload)
    case actions.NOTIFICATIONS_GET_ALERTS_SELECTION_ERROR:
      return reduceDataError(state, 'userAlertsSelection', action.error)

    case actions.NOTIFICATIONS_UPDATE_ALERTS_SELECTION_REQUEST:
      return reduceDataRequest(state, 'userAlertsSelection')
    case actions.NOTIFICATIONS_UPDATE_ALERTS_SELECTION_SUCCESS:
      return reduceDataSuccess(state, 'userAlertsSelection', action.payload)
    case actions.NOTIFICATIONS_UPDATE_ALERTS_SELECTION_ERROR:
      return reduceDataError(state, 'userAlertsSelection', action.error)

    case actions.NOTIFICATIONS_GET_EVENTS_SELECTION_REQUEST:
      return reduceDataRequest(state, 'userEventsSelection')
    case actions.NOTIFICATIONS_GET_EVENTS_SELECTION_SUCCESS:
      return reduceDataSuccess(state, 'userEventsSelection', action.payload)
    case actions.NOTIFICATIONS_GET_EVENTS_SELECTION_ERROR:
      return reduceDataError(state, 'userEventsSelection', action.error)

    case actions.NOTIFICATIONS_UPDATE_EVENTS_SELECTION_REQUEST:
      return reduceDataRequest(state, 'userEventsSelection')
    case actions.NOTIFICATIONS_UPDATE_EVENTS_SELECTION_SUCCESS:
      return reduceDataSuccess(state, 'userEventsSelection', action.payload)
    case actions.NOTIFICATIONS_UPDATE_EVENTS_SELECTION_ERROR:
      return reduceDataError(state, 'userEventsSelection', action.error)

    default:
      return state
  }
}

export default reducers
