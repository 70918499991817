import {
  all,
  call,
  fork,
  put,
  takeEvery
} from 'redux-saga/effects'
import { getAuthToken } from '../../../../helpers/tokens'
import Api from '../../../../api'
import actions from './actions'

export function * getFitbitAuthUrlRequest () {
  yield takeEvery(actions.HEALTHTRACKER_GET_FITBIT_AUTHURL_REQUEST, function * ({
    providerId,
    userId
  }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.HealthTracker.getFitbitAuthUrl,
        authToken.token,
        providerId,
        userId
      )
      if (response) {
        window.location.replace(response.url)
        // yield put(actions.getFitbitAuthUrlSuccess(response))
      } else {
        yield put(actions.getFitbitAuthUrlError(error))
      }
    }
  })
}

export function * exchangeFitbitAuthCodeRequest () {
  yield takeEvery(
    actions.HEALTHTRACKER_EXCHANGE_FITBIT_AUTHCODE_REQUEST,
    function * ({ providerId, userId, data }) {
      const authToken = yield getAuthToken()
      if (authToken && authToken.token) {
        const { response, error } = yield call(
          Api.HealthTracker.exchangeFitbitAuthCode,
          authToken.token,
          providerId,
          userId,
          data
        )
        if (response) {
          yield put(actions.exchangeFitbitAuthCodeSuccess(response))
        } else {
          yield put(actions.exchangeFitbitAuthCodeError(error))
        }
      }
    }
  )
}

export function * getFitbitTokenRequest () {
  yield takeEvery(actions.HEALTHTRACKER_GET_FITBIT_TOKEN_REQUEST, function * ({
    providerId,
    userId
  }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.HealthTracker.getFitbitAuthToken,
        authToken.token,
        providerId,
        userId
      )
      if (response) {
        yield put(actions.getFitbitTokenSuccess(response))
      } else {
        yield put(actions.getFitbitTokenError(error))
      }
    }
  })
}

export function * deleteFitbitTokenRequest () {
  yield takeEvery(actions.HEALTHTRACKER_DELETE_FITBIT_TOKEN_REQUEST, function * ({
    providerId,
    userId
  }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.HealthTracker.deleteFitbitAuthToken,
        authToken.token,
        providerId,
        userId
      )
      if (response) {
        yield put(actions.deleteFitbitTokenSuccess(response))
      } else {
        yield put(actions.deleteFitbitTokenError(error))
      }
    }
  })
}

export function * getCurrentRequest () {
  yield takeEvery(actions.HEALTHTRACKER_GET_CURRENT_REQUEST, function * ({
    providerId,
    userId
  }) {
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.HealthTracker.getHealthtrackerCurrent,
        authToken.token,
        providerId,
        userId
      )
      if (response) {
        yield put(actions.getHealthtrackerCurrentSuccess(response))
      } else {
        yield put(actions.getHealthtrackerCurrentError(error))
      }
    }
  })
}

// Keeping this code (commented out) for reference temporarily while serverside equivalent error handling is implemented.
//
// function * getRequest (action, ApiFunc, SuccessFunc, ErrorFunc) {
//   yield takeEvery(action, function * (payload) {
//     const response = yield call(ApiFunc, payload.token)
//     // const response = { error: { code: 401, message: 'expired_token' } }
//     const state = yield select()
//     const userId = state.ServiceUsers.userId
//     const providerId = state.AuthUser.user
//       ? state.AuthUser.user.providerId
//       : null
//
//     if (!response) {
//       yield put(
//         ErrorFunc({
//           status: 400,
//           message: 'No Response from FitBit API'
//         })
//       )
//     } else if (response.status === 401) {
//       console.log(response.status, response.errors, response.error)
//       yield put(actions.refreshFitbitTokenRequest(providerId, userId))
//     } else if (response.success !== undefined && !response.success) {
//       if (response.errors && Array.isArray(response.errors)) {
//         console.log(response.status, response.errors)
//         yield put(
//           ErrorFunc({
//             // Take first error in array only. Consider joining errors
//             message: response.errors[0].errorType
//           })
//         )
//       } else {
//         console.log(response.status, response.error)
//         yield put(
//           ErrorFunc({
//             message: 'Unknown error from FitBit API'
//           })
//         )
//       }
//     } else if (response.error !== undefined && response.error.message) {
//       console.log(response.status, response.error)
//
//       yield put(
//         ErrorFunc({
//           message: response.error.message
//         })
//       )
//     } else {
//       yield put(SuccessFunc(response))
//     }
//   })
// }

export default function * rootSaga () {
  yield all([
    fork(getFitbitAuthUrlRequest),
    fork(exchangeFitbitAuthCodeRequest),
    fork(getFitbitTokenRequest),
    fork(deleteFitbitTokenRequest),
    fork(getCurrentRequest)
  ])
}
