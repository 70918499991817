import styled from 'styled-components'
import { palette } from 'styled-theme'

const InputWrapper = (ComponentName) => styled(ComponentName)`
  &.ant-input,
  &.ant-input-group-wrapper input {
    font-size: 13px;
    height: 48px;
    line-height: 48px;
    color: ${palette('axela', 2)};
    font-weight: bold;
    box-shadow: none;

    &:hover,
    input:hover {
      border: 1px solid ${palette('primary', 0)};
    }
    &:focus,
    input:focus {
      border: 1px solid ${palette('primary', 0)};
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &::-webkit-input-placeholder,
    input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: inherit;
      font-weight: normal;
    }
    &::-moz-placeholder,
    input::-moz-placeholder {
      /* Firefox 19+ */
      color: inherit;
      font-weight: normal;
    }
    &:-ms-input-placeholder,
    input:-ms-input-placeholder {
      /* IE 10+ */
      color: inherit;
      font-weight: normal;
    }
    &:-moz-placeholder,
    input:-moz-placeholder {
      /* Firefox 18- */
      color: inherit;
      font-weight: normal;
    }
  }

  &.incomplete,
  &.incomplete input {
    background-color: ${palette('error', 3)};
    font-weight: normal;
    color: inherit;
  }
  
  &.ant-input-group-wrapper.incomplete {
    .ant-input {
      background-color: ${palette('error', 3)};
      font-weight: normal;
      color: inherit;
    }
  }
`

const InputNumberWrapper = (ComponentName) => styled(ComponentName)`
  &.ant-input-number:hover {
    border-color: ${palette('primary', 0)};
  }

  &.ant-input-number:focus {
    border-color: ${palette('primary', 0)};
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  &.ant-input-number {
    font-size: 13px;
    height: 48px;
    line-height: 48px;
    color: ${palette('axela', 2)};
    font-weight: bold;
    box-shadow: none;

    input {
      font-weight: bold;
      box-shadow: none;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: inherit;
        font-weight: normal;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: inherit;
        font-weight: normal;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: inherit;
        font-weight: normal;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: inherit;
        font-weight: normal;
      }
    }
  }

  &.incomplete {
    background-color: ${palette('error', 3)};
    font-weight: normal;
    color: inherit;
  }
`

const InputGroupWrapper = (ComponentName) => styled(ComponentName)``
const TextAreaWrapper = (ComponentName) => styled(ComponentName)`
  &.ant-input {
    &:hover {
      border: 1px solid ${palette('primary', 0)};
    }
    &:focus {
      border: 1px solid ${palette('primary', 0)};
      -webkit-box-shadow: none;
      box-shadow: none;
    }

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: inherit;
      font-weight: normal;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: inherit;
      font-weight: normal;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: inherit;
      font-weight: normal;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: inherit;
      font-weight: normal;
    }
  }

  &.incomplete {
    background-color: ${palette('error', 3)};

    color: ${palette('axela', 2)};
    box-shadow: none;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: ${palette('axela', 2)};
      font-weight: normal;
    }
    &::-moz-placeholder {
      /* Firefox 19+ */
      color: ${palette('axela', 2)};
      font-weight: normal;
    }
    &:-ms-input-placeholder {
      /* IE 10+ */
      color: ${palette('axela', 2)};
      font-weight: normal;
    }
    &:-moz-placeholder {
      /* Firefox 18- */
      color: ${palette('axela', 2)};
      font-weight: normal;
    }
  }
`
const InputSearchWrapper = (ComponentName) => styled(ComponentName)``

export {
  InputWrapper,
  InputGroupWrapper,
  InputSearchWrapper,
  TextAreaWrapper,
  InputNumberWrapper
}
