import UserForms, {initBaseState as userFormsInitBaseState} from './userForms/reducers'
import UserFormTemplates, {initBaseState as userFormsTemplatesInitBaseState} from './userFormTemplates/reducers'

import { combineReducers } from 'redux'

const initBaseState = {
  UserForms: userFormsInitBaseState,
  UserFormTemplates: userFormsTemplatesInitBaseState
}

const BaseReducer = (state = initBaseState) => state

export default combineReducers({
  Base: BaseReducer,
  UserForms,
  UserFormTemplates
})
