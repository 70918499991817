import {defineGetActionsPrSu, defineUpdateActionsPrSu} from '../../../actionsHelper'

const actions = {
  CAREASSESSMENT_GETALL: 'CAREASSESSMENT_GETALL',
  getAll: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GETALL,
      providerId,
      userId
    }
  },

  CAREASSESSMENT_UPDATE_MODEL_ABOUTME: 'CAREASSESSMENT_UPDATE_MODEL_ABOUTME',
  CAREASSESSMENT_UPDATE_MODEL_COMMUNICATIONMETHOD:
    'CAREASSESSMENT_UPDATE_MODEL_COMMUNICATIONMETHOD',

  CAREASSESSMENT_GET_SUMMARY_REQUEST: 'CAREASSESSMENT_GET_SUMMARY_REQUEST',
  CAREASSESSMENT_GET_SUMMARY_SUCCESS: 'CAREASSESSMENT_GET_SUMMARY_SUCCESS',
  CAREASSESSMENT_GET_SUMMARY_ERROR: 'CAREASSESSMENT_GET_SUMMARY_ERROR',

  CAREASSESSMENT_GET_PDF_REQUEST: 'CAREASSESSMENT_GET_PDF_REQUEST',
  CAREASSESSMENT_GET_PDF_SUCCESS: 'CAREASSESSMENT_GET_PDF_SUCCESS',
  CAREASSESSMENT_GET_PDF_ERROR: 'CAREASSESSMENT_GET_PDF_ERROR',

  CAREASSESSMENT_GET_ABOUTME_REQUEST: 'CAREASSESSMENT_GET_ABOUTME_REQUEST',
  CAREASSESSMENT_GET_ABOUTME_SUCCESS: 'CAREASSESSMENT_GET_ABOUTME_SUCCESS',
  CAREASSESSMENT_GET_ABOUTME_ERROR: 'CAREASSESSMENT_GET_ABOUTME_ERROR',
  CAREASSESSMENT_UPDATE_ABOUTME_REQUEST:
    'CAREASSESSMENT_UPDATE_ABOUTME_REQUEST',
  CAREASSESSMENT_UPDATE_ABOUTME_SUCCESS:
    'CAREASSESSMENT_UPDATE_ABOUTME_SUCCESS',
  CAREASSESSMENT_UPDATE_ABOUTME_ERROR: 'CAREASSESSMENT_UPDATE_ABOUTME_ERROR',

  CAREASSESSMENT_GET_COMMUNICATIONMETHOD_REQUEST:
    'CAREASSESSMENT_GET_COMMUNICATIONMETHOD_REQUEST',
  CAREASSESSMENT_GET_COMMUNICATIONMETHOD_SUCCESS:
    'CAREASSESSMENT_GET_COMMUNICATIONMETHOD_SUCCESS',
  CAREASSESSMENT_GET_COMMUNICATIONMETHOD_ERROR:
    'CAREASSESSMENT_GET_COMMUNICATIONMETHOD_ERROR',
  CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_REQUEST:
    'CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_REQUEST',
  CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_SUCCESS:
    'CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_SUCCESS',
  CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_ERROR:
    'CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_ERROR',

  CAREASSESSMENT_GET_PERSONALCARE_REQUEST:
    'CAREASSESSMENT_GET_PERSONALCARE_REQUEST',
  CAREASSESSMENT_GET_PERSONALCARE_SUCCESS:
    'CAREASSESSMENT_GET_PERSONALCARE_SUCCESS',
  CAREASSESSMENT_GET_PERSONALCARE_ERROR:
    'CAREASSESSMENT_GET_PERSONALCARE_ERROR',
  CAREASSESSMENT_UPDATE_PERSONALCARE_REQUEST:
    'CAREASSESSMENT_UPDATE_PERSONALCARE_REQUEST',
  CAREASSESSMENT_UPDATE_PERSONALCARE_SUCCESS:
    'CAREASSESSMENT_UPDATE_PERSONALCARE_SUCCESS',
  CAREASSESSMENT_UPDATE_PERSONALCARE_ERROR:
    'CAREASSESSMENT_UPDATE_PERSONALCARE_ERROR',

  CAREASSESSMENT_GET_MOVINGANDHANDLING_REQUEST:
    'CAREASSESSMENT_GET_MOVINGANDHANDLING_REQUEST',
  CAREASSESSMENT_GET_MOVINGANDHANDLING_SUCCESS:
    'CAREASSESSMENT_GET_MOVINGANDHANDLING_SUCCESS',
  CAREASSESSMENT_GET_MOVINGANDHANDLING_ERROR:
    'CAREASSESSMENT_GET_MOVINGANDHANDLING_ERROR',
  CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_REQUEST:
    'CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_REQUEST',
  CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_SUCCESS:
    'CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_SUCCESS',
  CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_ERROR:
    'CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_ERROR',

  CAREASSESSMENT_GET_MEDICATIONPLAN_REQUEST:
    'CAREASSESSMENT_GET_MEDICATIONPLAN_REQUEST',
  CAREASSESSMENT_GET_MEDICATIONPLAN_SUCCESS:
    'CAREASSESSMENT_GET_MEDICATIONPLAN_SUCCESS',
  CAREASSESSMENT_GET_MEDICATIONPLAN_ERROR:
    'CAREASSESSMENT_GET_MEDICATIONPLAN_ERROR',
  CAREASSESSMENT_UPDATE_MEDICATIONPLAN_REQUEST:
    'CAREASSESSMENT_UPDATE_MEDICATIONPLAN_REQUEST',
  CAREASSESSMENT_UPDATE_MEDICATIONPLAN_SUCCESS:
    'CAREASSESSMENT_UPDATE_MEDICATIONPLAN_SUCCESS',
  CAREASSESSMENT_UPDATE_MEDICATIONPLAN_ERROR:
    'CAREASSESSMENT_UPDATE_MEDICATIONPLAN_ERROR',

  CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_REQUEST:
    'CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_REQUEST',
  CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_SUCCESS:
    'CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_SUCCESS',
  CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_ERROR:
    'CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_ERROR',
  CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_REQUEST:
    'CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_REQUEST',
  CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_SUCCESS:
    'CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_SUCCESS',
  CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_ERROR:
    'CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_ERROR',

  CAREASSESSMENT_GET_DOMESTICSUPPORT_REQUEST:
    'CAREASSESSMENT_GET_DOMESTICSUPPORT_REQUEST',
  CAREASSESSMENT_GET_DOMESTICSUPPORT_SUCCESS:
    'CAREASSESSMENT_GET_DOMESTICSUPPORT_SUCCESS',
  CAREASSESSMENT_GET_DOMESTICSUPPORT_ERROR:
    'CAREASSESSMENT_GET_DOMESTICSUPPORT_ERROR',
  CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_REQUEST:
    'CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_REQUEST',
  CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_SUCCESS:
    'CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_SUCCESS',
  CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_ERROR:
    'CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_ERROR',

  CAREASSESSMENT_GET_FINANCIALSUPPORT_REQUEST:
    'CAREASSESSMENT_GET_FINANCIALSUPPORT_REQUEST',
  CAREASSESSMENT_GET_FINANCIALSUPPORT_SUCCESS:
    'CAREASSESSMENT_GET_FINANCIALSUPPORT_SUCCESS',
  CAREASSESSMENT_GET_FINANCIALSUPPORT_ERROR:
    'CAREASSESSMENT_GET_FINANCIALSUPPORT_ERROR',
  CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_REQUEST:
    'CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_REQUEST',
  CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_SUCCESS:
    'CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_SUCCESS',
  CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_ERROR:
    'CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_ERROR',

  CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_REQUEST:
    'CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_REQUEST',
  CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_SUCCESS:
    'CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_SUCCESS',
  CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_ERROR:
    'CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_ERROR',
  CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_REQUEST:
    'CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_REQUEST',
  CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_SUCCESS:
    'CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_SUCCESS',
  CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_ERROR:
    'CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_ERROR',

  CAREASSESSMENT_GET_CAPACITYANDCONSENT_REQUEST:
    'CAREASSESSMENT_GET_CAPACITYANDCONSENT_REQUEST',
  CAREASSESSMENT_GET_CAPACITYANDCONSENT_SUCCESS:
    'CAREASSESSMENT_GET_CAPACITYANDCONSENT_SUCCESS',
  CAREASSESSMENT_GET_CAPACITYANDCONSENT_ERROR:
    'CAREASSESSMENT_GET_CAPACITYANDCONSENT_ERROR',
  CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_REQUEST:
    'CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_REQUEST',
  CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_SUCCESS:
    'CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_SUCCESS',
  CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_ERROR:
    'CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_ERROR',

  CAREASSESSMENT_GET_TREATMENTANDCARE_REQUEST:
    'CAREASSESSMENT_GET_TREATMENTANDCARE_REQUEST',
  CAREASSESSMENT_GET_TREATMENTANDCARE_SUCCESS:
    'CAREASSESSMENT_GET_TREATMENTANDCARE_SUCCESS',
  CAREASSESSMENT_GET_TREATMENTANDCARE_ERROR:
    'CAREASSESSMENT_GET_TREATMENTANDCARE_ERROR',
  CAREASSESSMENT_UPDATE_TREATMENTANDCARE_REQUEST:
    'CAREASSESSMENT_UPDATE_TREATMENTANDCARE_REQUEST',
  CAREASSESSMENT_UPDATE_TREATMENTANDCARE_SUCCESS:
    'CAREASSESSMENT_UPDATE_TREATMENTANDCARE_SUCCESS',
  CAREASSESSMENT_UPDATE_TREATMENTANDCARE_ERROR:
    'CAREASSESSMENT_UPDATE_TREATMENTANDCARE_ERROR',

  CAREASSESSMENT_GET_BODYMAP_REQUEST:
    'CAREASSESSMENT_GET_BODYMAP_REQUEST',
  CAREASSESSMENT_GET_BODYMAP_SUCCESS:
    'CAREASSESSMENT_GET_BODYMAP_SUCCESS',
  CAREASSESSMENT_GET_BODYMAP_ERROR:
    'CAREASSESSMENT_GET_BODYMAP_ERROR',
  CAREASSESSMENT_UPDATE_BODYMAP_REQUEST:
    'CAREASSESSMENT_UPDATE_BODYMAP_REQUEST',
  CAREASSESSMENT_UPDATE_BODYMAP_SUCCESS:
    'CAREASSESSMENT_UPDATE_BODYMAP_SUCCESS',
  CAREASSESSMENT_UPDATE_BODYMAP_ERROR:
    'CAREASSESSMENT_UPDATE_BODYMAP_ERROR',

  CAREASSESSMENT_GET_SIGNATURE_REQUEST: 'CAREASSESSMENT_GET_SIGNATURE_REQUEST',
  CAREASSESSMENT_GET_SIGNATURE_SUCCESS: 'CAREASSESSMENT_GET_SIGNATURE_SUCCESS',
  CAREASSESSMENT_GET_SIGNATURE_ERROR: 'CAREASSESSMENT_GET_SIGNATURE_ERROR',
  CAREASSESSMENT_UPDATE_SIGNATURE_REQUEST:
    'CAREASSESSMENT_UPDATE_SIGNATURE_REQUEST',
  CAREASSESSMENT_UPDATE_SIGNATURE_SUCCESS:
    'CAREASSESSMENT_UPDATE_SIGNATURE_SUCCESS',
  CAREASSESSMENT_UPDATE_SIGNATURE_ERROR:
    'CAREASSESSMENT_UPDATE_SIGNATURE_ERROR',

  getSummaryRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_SUMMARY_REQUEST,
      providerId,
      userId
    }
  },
  getSummarySuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_SUMMARY_SUCCESS,
      payload: result
    }
  },
  getSummaryError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_SUMMARY_ERROR,
      error: error
    }
  },

  getPdfRequest: (providerId, userId, data, onSuccessCallback) => {
    return {
      type: actions.CAREASSESSMENT_GET_PDF_REQUEST,
      providerId,
      userId,
      data,
      onSuccessCallback
    }
  },
  getPdfSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_PDF_SUCCESS,
      payload: result
    }
  },
  getPdfError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_PDF_ERROR,
      error: error
    }
  },

  getAboutMeRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_ABOUTME_REQUEST,
      providerId,
      userId
    }
  },
  getAboutMeSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_ABOUTME_SUCCESS,
      payload: result
    }
  },
  getAboutMeError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_ABOUTME_ERROR,
      error: error
    }
  },
  updateAboutMeRequest: (providerId, userId, aboutMe) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_ABOUTME_REQUEST,
      providerId,
      userId,
      payload: aboutMe
    }
  },
  updateAboutMeSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_ABOUTME_SUCCESS,
      payload: result
    }
  },
  updateAboutMeError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_ABOUTME_ERROR,
      error: error
    }
  },
  updateModelAboutMe: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_ABOUTME,
      payload: values
    }
  },

  getCommunicationMethodRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_COMMUNICATIONMETHOD_REQUEST,
      providerId,
      userId
    }
  },
  getCommunicationMethodSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_COMMUNICATIONMETHOD_SUCCESS,
      payload: result
    }
  },
  getCommunicationMethodError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_COMMUNICATIONMETHOD_ERROR,
      error: error
    }
  },
  updateCommunicationMethodRequest: (
    providerId,
    userId,
    communicationMethod
  ) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_REQUEST,
      providerId,
      userId,
      payload: communicationMethod
    }
  },
  updateCommunicationMethodSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_SUCCESS,
      payload: result
    }
  },
  updateCommunicationMethodError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_COMMUNICATIONMETHOD_ERROR,
      error: error
    }
  },
  updateModelCommunicationMethod: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_COMMUNICATIONMETHOD,
      payload: values
    }
  },

  getPersonalCareRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_PERSONALCARE_REQUEST,
      providerId,
      userId
    }
  },
  getPersonalCareSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_PERSONALCARE_SUCCESS,
      payload: result
    }
  },
  getPersonalCareError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_PERSONALCARE_ERROR,
      error: error
    }
  },
  updatePersonalCareRequest: (providerId, userId, personalCare) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_PERSONALCARE_REQUEST,
      providerId,
      userId,
      payload: personalCare
    }
  },
  updatePersonalCareSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_PERSONALCARE_SUCCESS,
      payload: result
    }
  },
  updatePersonalCareError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_PERSONALCARE_ERROR,
      error: error
    }
  },
  updateModelPersonalCare: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_PERSONALCARE,
      payload: values
    }
  },

  getMovingAndHandlingRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_MOVINGANDHANDLING_REQUEST,
      providerId,
      userId
    }
  },
  getMovingAndHandlingSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_MOVINGANDHANDLING_SUCCESS,
      payload: result
    }
  },
  getMovingAndHandlingError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_MOVINGANDHANDLING_ERROR,
      error: error
    }
  },
  updateMovingAndHandlingRequest: (
    providerId,
    userId,
    movingAndHandling
  ) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_REQUEST,
      providerId,
      userId,
      payload: movingAndHandling
    }
  },
  updateMovingAndHandlingSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_SUCCESS,
      payload: result
    }
  },
  updateMovingAndHandlingError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MOVINGANDHANDLING_ERROR,
      error: error
    }
  },
  updateModelMovingAndHandling: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_MOVINGANDHANDLING,
      payload: values
    }
  },

  getMedicationPlanRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_MEDICATIONPLAN_REQUEST,
      providerId,
      userId
    }
  },
  getMedicationPlanSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_MEDICATIONPLAN_SUCCESS,
      payload: result
    }
  },
  getMedicationPlanError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_MEDICATIONPLAN_ERROR,
      error: error
    }
  },
  updateMedicationPlanRequest: (providerId, userId, medicationPlan) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MEDICATIONPLAN_REQUEST,
      providerId,
      userId,
      payload: medicationPlan
    }
  },
  updateMedicationPlanSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MEDICATIONPLAN_SUCCESS,
      payload: result
    }
  },
  updateMedicationPlanError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MEDICATIONPLAN_ERROR,
      error: error
    }
  },
  updateModelMedicationPlan: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_MEDICATIONPLAN,
      payload: values
    }
  },

  getNutritionAndHydrationRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_REQUEST,
      providerId,
      userId
    }
  },
  getNutritionAndHydrationSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_SUCCESS,
      payload: result
    }
  },
  getNutritionAndHydrationError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_NUTRITIONANDHYDRATION_ERROR,
      error: error
    }
  },
  updateNutritionAndHydrationRequest: (
    providerId,
    userId,
    nutritionAndHydration
  ) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_REQUEST,
      providerId,
      userId,
      payload: nutritionAndHydration
    }
  },
  updateNutritionAndHydrationSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_SUCCESS,
      payload: result
    }
  },
  updateNutritionAndHydrationError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_NUTRITIONANDHYDRATION_ERROR,
      error: error
    }
  },
  updateModelNutritionAndHydration: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_NUTRITIONANDHYDRATION,
      payload: values
    }
  },

  getDomesticSupportRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_DOMESTICSUPPORT_REQUEST,
      providerId,
      userId
    }
  },
  getDomesticSupportSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_DOMESTICSUPPORT_SUCCESS,
      payload: result
    }
  },
  getDomesticSupportError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_DOMESTICSUPPORT_ERROR,
      error: error
    }
  },
  updateDomesticSupportRequest: (
    providerId,
    userId,
    domesticSupport
  ) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_REQUEST,
      providerId,
      userId,
      payload: domesticSupport
    }
  },
  updateDomesticSupportSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_SUCCESS,
      payload: result
    }
  },
  updateDomesticSupportError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_DOMESTICSUPPORT_ERROR,
      error: error
    }
  },
  updateModelDomesticSupport: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_DOMESTICSUPPORT,
      payload: values
    }
  },

  getFinancialSupportRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_FINANCIALSUPPORT_REQUEST,
      providerId,
      userId
    }
  },
  getFinancialSupportSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_FINANCIALSUPPORT_SUCCESS,
      payload: result
    }
  },
  getFinancialSupportError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_FINANCIALSUPPORT_ERROR,
      error: error
    }
  },
  updateFinancialSupportRequest: (
    providerId,
    userId,
    financialSupport
  ) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_REQUEST,
      providerId,
      userId,
      payload: financialSupport
    }
  },
  updateFinancialSupportSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_SUCCESS,
      payload: result
    }
  },
  updateFinancialSupportError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_FINANCIALSUPPORT_ERROR,
      error: error
    }
  },
  updateModelFinancialSupport: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_FINANCIALSUPPORT,
      payload: values
    }
  },

  getEnvironmentAssessmentRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_REQUEST,
      providerId,
      userId
    }
  },
  getEnvironmentAssessmentSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_SUCCESS,
      payload: result
    }
  },
  getEnvironmentAssessmentError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_ENVIRONMENTASSESSMENT_ERROR,
      error: error
    }
  },
  updateEnvironmentAssessmentRequest: (
    providerId,
    userId,
    environmentAssessment
  ) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_REQUEST,
      providerId,
      userId,
      payload: environmentAssessment
    }
  },
  updateEnvironmentAssessmentSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_SUCCESS,
      payload: result
    }
  },
  updateEnvironmentAssessmentError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_ENVIRONMENTASSESSMENT_ERROR,
      error: error
    }
  },
  updateModelEnvironmentAssessment: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_ENVIRONMENTASSESSMENT,
      payload: values
    }
  },

  getCapacityAndConsentRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_CAPACITYANDCONSENT_REQUEST,
      providerId,
      userId
    }
  },
  getCapacityAndConsentSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_CAPACITYANDCONSENT_SUCCESS,
      payload: result
    }
  },
  getCapacityAndConsentError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_CAPACITYANDCONSENT_ERROR,
      error: error
    }
  },
  updateCapacityAndConsentRequest: (
    providerId,
    userId,
    capacityAndConsent
  ) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_REQUEST,
      providerId,
      userId,
      payload: capacityAndConsent
    }
  },
  updateCapacityAndConsentSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_SUCCESS,
      payload: result
    }
  },
  updateCapacityAndConsentError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_CAPACITYANDCONSENT_ERROR,
      error: error
    }
  },
  updateModelCapacityAndConsent: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_CAPACITYANDCONSENT,
      payload: values
    }
  },
  getTreatmentAndCareRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_TREATMENTANDCARE_REQUEST,
      providerId,
      userId
    }
  },
  getTreatmentAndCareSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_TREATMENTANDCARE_SUCCESS,
      payload: result
    }
  },
  getTreatmentAndCareError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_TREATMENTANDCARE_ERROR,
      error: error
    }
  },
  updateTreatmentAndCareRequest: (
    providerId,
    userId,
    treatmentAndCare
  ) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_TREATMENTANDCARE_REQUEST,
      providerId,
      userId,
      payload: treatmentAndCare
    }
  },
  updateTreatmentAndCareSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_TREATMENTANDCARE_SUCCESS,
      payload: result
    }
  },
  updateTreatmentAndCareError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_TREATMENTANDCARE_ERROR,
      error: error
    }
  },
  updateModelTreatmentAndCare: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_TREATMENTANDCARE,
      payload: values
    }
  },

  getBodyMapRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_BODYMAP_REQUEST,
      providerId,
      userId
    }
  },
  getBodyMapSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_GET_BODYMAP_SUCCESS,
      payload: result
    }
  },
  getBodyMapError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_BODYMAP_ERROR,
      error: error
    }
  },
  updateBodyMapRequest: (
    providerId,
    userId,
    bodyMap
  ) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_BODYMAP_REQUEST,
      providerId,
      userId,
      payload: bodyMap
    }
  },
  updateBodyMapSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_BODYMAP_SUCCESS,
      payload: result
    }
  },
  updateBodyMapError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_BODYMAP_ERROR,
      error: error
    }
  },
  updateModelBodyMap: values => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_MODEL_BODYMAP,
      payload: values
    }
  },

  getSignatureRequest: (providerId, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_SIGNATURE_REQUEST,
      providerId,
      userId
    }
  },
  getSignatureSuccess: (result, userId) => {
    return {
      type: actions.CAREASSESSMENT_GET_SIGNATURE_SUCCESS,
      payload: result,
      userId
    }
  },
  getSignatureError: error => {
    return {
      type: actions.CAREASSESSMENT_GET_SIGNATURE_ERROR,
      error: error
    }
  },
  updateSignatureRequest: (providerId, userId, signature) => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_SIGNATURE_REQUEST,
      providerId,
      userId,
      payload: signature
    }
  },
  updateSignatureSuccess: result => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_SIGNATURE_SUCCESS,
      payload: result
    }
  },
  updateSignatureError: error => {
    return {
      type: actions.CAREASSESSMENT_UPDATE_SIGNATURE_ERROR,
      error: error
    }
  }
}

defineGetActionsPrSu(actions, 'CAREASSESSMENT_GET_COMPLETEDSIGNATURE', 'getCompletedSignature')
defineUpdateActionsPrSu(actions, 'CAREASSESSMENT_SET_COMPLETEDSIGNATURE', 'setCompletedSignature')

defineGetActionsPrSu(actions, 'CAREASSESSMENT_GET_AUDITEDSIGNATURE', 'getAuditedSignature')
defineUpdateActionsPrSu(actions, 'CAREASSESSMENT_SET_AUDITEDSIGNATURE', 'setAuditedSignature')

defineGetActionsPrSu(actions, 'GET_CAREASSESSMENT_AUDIT_HISTORY', 'getAuditHistory')

export default actions
