import { all, fork } from 'redux-saga/effects'
import actions from './actions'
import Api from '../../../../api'
import { defineGetSaga, defineUpdateSaga } from '../../../sagasHelper'

export function * getUserNotificationOptionsRequest () {
  yield defineGetSaga(actions.NOTIFICATIONS_GET_NOTIFICATION_OPTIONS_REQUEST, Api.Notifications.getUserNotificationOptions, actions.getUserNotificationOptionsSuccess, actions.getUserNotificationOptionsError)
}

export function * updateUserNotificationOptionsRequest () {
  yield defineUpdateSaga(actions.NOTIFICATIONS_UPDATE_NOTIFICATION_OPTIONS_REQUEST, Api.Notifications.updateUserNotificationOptions, actions.updateUserNotificationOptionsSuccess, actions.updateUserNotificationOptionsError)
}

export function * getUserAlertsSelectionRequest () {
  yield defineGetSaga(actions.NOTIFICATIONS_GET_ALERTS_SELECTION_REQUEST, Api.Notifications.getUserNotificationAlertsSelection, actions.getUserAlertsSelectionSuccess, actions.getUserAlertsSelectionError)
}

export function * updateUserAlertsSelectionRequest () {
  yield defineUpdateSaga(actions.NOTIFICATIONS_UPDATE_ALERTS_SELECTION_REQUEST, Api.Notifications.updateUserNotificationAlertsSelection, actions.updateUserAlertsSelectionSuccess, actions.updateUserAlertsSelectionError)
}

export function * getUserEventsSelectionRequest () {
  yield defineGetSaga(actions.NOTIFICATIONS_GET_EVENTS_SELECTION_REQUEST, Api.Notifications.getUserNotificationEventsSelection, actions.getUserEventsSelectionSuccess, actions.getUserEventsSelectionError)
}

export function * updateUserEventsSelectionRequest () {
  yield defineUpdateSaga(actions.NOTIFICATIONS_UPDATE_EVENTS_SELECTION_REQUEST, Api.Notifications.updateUserNotificationEventsSelection, actions.updateUserEventsSelectionSuccess, actions.updateUserEventsSelectionError)
}

export default function * rootSaga () {
  yield all([
    fork(getUserNotificationOptionsRequest),
    fork(updateUserNotificationOptionsRequest),
    fork(getUserAlertsSelectionRequest),
    fork(updateUserAlertsSelectionRequest),
    fork(getUserEventsSelectionRequest),
    fork(updateUserEventsSelectionRequest)
  ])
}
