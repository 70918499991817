import Alerts from './alerts/reducers'
import CareAssessment from './careassessment/reducers'
import CareAssessmentAttorneys from './careassessmentAttorneys/reducers'
import CareAssessmentDomesticSupportActivities from './careassessmentDomesticSupportActivities/reducers'
import CareAssessmentEquipment from './careassessmentEquipment/reducers'
import CareAssessmentMovingAndHandlingDocs from './careAssessmentMovingAndHandlingDocs/reducers'
import CareAssessmentSocialInclusion from './careassessmentSocialInclusionActivities/reducers'
import CarePlan from './careplan/reducers'
import CarePlanDocs from './careplanDocs/reducers'
import CarePlanMedicationTasks from './careplanMedicationTasks/reducers'
import CareOutcomes from './careOutcomes/reducers'
import CarePlanSupportTasks from './careplanSupportTasks/reducers'
import CarePlanVisits from './careplanVisits/reducers'
import CommunicationMessages from './communicationMessages/reducers'
import Emar from './emar/reducers'
import Emis from './emis/reducers'
import HealthTracker from './healthtracker/reducers'
import RiskAssessment from './riskAssessment/reducers'
import RiskAssessmentDocs from './riskAssessmentDocs/reducers'
import Scheduling from './scheduling/reducers'
import ServiceUserAllergies from './serviceuserAllergies/reducers'
import ServiceUserConditions from './serviceuserConditions/reducers'
import ServiceUserConditionsDocs from './serviceuserConditions/docReducers'
import ServiceUserInformation from './serviceuserInformation/reducers'
import {reducersDNACPR, reducersDoLS} from './serviceuserLegalChoicesDocs/reducers'
import ServiceUserContacts from './serviceuserContacts/reducers'
import ServiceUserDocumentsDocs from './serviceuserDocuments/reducers'
import ServiceUsers from './serviceusers/reducers'
import SupportTaskTitles from './supportTaskTitle/reducers'
import Symptoms from './symptoms/reducers'
import actions from './actions'
import {combineReducers} from 'redux'

const initBaseState = {
  userId: null,
  showServiceUserHeader: 'small',
  personalDetailsMap: {}
}

const BaseReducer = (state = initBaseState, action) => {
  const serviceUserHeader = []
  switch (action.type) {
    case actions.SET_SERVICE_USER_ID:
      return {
        ...state,
        userId: action.payload.userId
      }
    case actions.PUSH_SERVICE_USER_HEADER:
      serviceUserHeader.push(action.payload.show)
      return {
        ...state,
        showServiceUserHeader: action.payload.show
      }
    case actions.POP_SERVICE_USER_HEADER:
      return {
        ...state,
        showServiceUserHeader: serviceUserHeader.pop()
      }

    default:
      return state
  }
}

export default combineReducers({
  Base: BaseReducer,
  Alerts,
  CareAssessment,
  CareAssessmentAttorneys,
  CareAssessmentDomesticSupportActivities,
  CareAssessmentEquipment,
  CareAssessmentMovingAndHandlingDocs,
  CareAssessmentSocialInclusion,
  CarePlan,
  CarePlanDocs,
  CarePlanMedicationTasks,
  CareOutcomes,
  CarePlanSupportTasks,
  CarePlanVisits,
  CommunicationMessages,
  Emar,
  Emis,
  HealthTracker,
  RiskAssessment,
  RiskAssessmentDocs,
  Scheduling,
  ServiceUserAllergies,
  ServiceUserConditions,
  ServiceUserConditionsDocs,
  ServiceUserInformation,
  ServiceUserlegalChoicesDocsDNACPR: reducersDNACPR,
  ServiceUserLegalChoicesDocsDoLS: reducersDoLS,
  ServiceUserContacts,
  ServiceUserDocumentsDocs,
  ServiceUsers,
  SupportTaskTitles,
  Symptoms
})
