export const VIEW_NARROW = 'narrow'
export const VIEW_MOBILE = 'mobile'
export const VIEW_TABLET = 'tablet'
export const VIEW_LAPTOP = 'laptop'

export const LAPTOP_WIDTH = 1066
export const TABLET_WIDTH = 767
export const MOBILE_WIDTH = 480

export function getView (width) {
  let newView = VIEW_NARROW
  if (width > LAPTOP_WIDTH) {
    newView = VIEW_LAPTOP
  } else if (width > TABLET_WIDTH) {
    newView = VIEW_TABLET
  } else if (width > MOBILE_WIDTH) {
    newView = VIEW_MOBILE
  }
  return newView
}

export function getIsCollapsable (width) {
  return width <= LAPTOP_WIDTH;
}

const actions = {
  TOGGLE_COLLAPSED: 'TOGGLE_COLLAPSED',
  toggleCollapsed: () => ({
    type: actions.TOGGLE_COLLAPSED
  }),

  SET_CURRENT_LOCATION: 'SET_CURRENT_LOCATION',
  setCurrentLocation: currentLocation => {
    // window.Intercom('update')
    return {
      type: actions.SET_CURRENT_LOCATION,
      currentLocation
    }
  },

  UPDATE_VIEW: 'UPDATE_VIEW',
  updateView: (newWidth, newHeight) => {
    return {
      type: actions.UPDATE_VIEW,
      width: newWidth,
      height: newHeight
    }
  },

  SET_WIDESCREEN: 'SET_WIDESCREEN',
  setWidescreen: isWide => ({
    type: actions.SET_WIDESCREEN,
    isWide
  }),

  SET_PRIMARY_COLOUR: 'SET_PRIMARY_COLOUR',
  setPrimaryColour: primaryColour => ({
    type: actions.SET_PRIMARY_COLOUR,
    primaryColour
  }),

  SET_THEME: 'SET_THEME',
  setTheme: theme => ({
    type: actions.SET_THEME,
    theme
  })

}
export default actions
