import actions from './actions'
import crudReducers from '../../crud/reducers'

export const initBaseState = {
  list: [],
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  model: {}
}

const reducers = crudReducers(actions, 'model', initBaseState)

export default reducers
