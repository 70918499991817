import {all, call, put, takeEvery} from '@redux-saga/core/effects'
import {getAuthToken} from '../helpers/tokens'
import _ from 'lodash'

export function defineGetSaga(type, apiCall, successAction, failureAction) {
  return takeEvery(type, function* (action) {
    const {ids, data, onSuccessCallback, onFailureCallback} = action
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        apiCall,
        authToken.token,
        ids.providerId,
        ids.userId,
        data // other data
      )
      if (response) {
        yield put(successAction(ids, response))
        _.isFunction(onSuccessCallback) && onSuccessCallback(response)
      } else {
        yield put(failureAction(ids, error))
        _.isFunction(onFailureCallback) && onFailureCallback(error)
      }
    }
  })
}

export function defineGetSagaProvider(
  type,
  apiCall,
  successAction,
  failureAction
) {
  return takeEvery(type, function* (action) {
    const {ids, data, onSuccessCallback, onFailureCallback} = action
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        apiCall,
        authToken.token,
        ids.providerId,
        data // other data
      )
      if (response) {
        yield put(successAction(ids, response))
        _.isFunction(onSuccessCallback) && onSuccessCallback(response)
      } else {
        yield put(failureAction(ids, error))
        _.isFunction(onFailureCallback) && onFailureCallback(error)
      }
    }
  })
}

export function defineUpdateSaga(type, apiCall, successAction, failureAction) {
  return takeEvery(type, function* (action) {
    const {ids, payload, onSuccessCallback, onFailureCallback} = action
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        apiCall,
        authToken.token,
        ids.providerId,
        ids.userId,
        payload
      )
      if (response) {
        if (Array.isArray(successAction)) {
          const puts = []
          successAction.forEach((action) => {
            puts.push(put(action(ids, response)))
          })
          yield all(puts)
        } else {
          yield put(successAction(ids, response))
        }
        _.isFunction(onSuccessCallback) && onSuccessCallback(response)
      } else {
        yield put(failureAction(ids, error))
        _.isFunction(onFailureCallback) && onFailureCallback(error)
      }
    }
  })
}

export function defineUpdateSagaProvider(type, apiCall, successAction, failureAction) {
  return takeEvery(type, function* (action) {
    const {ids, payload, onSuccessCallback, onFailureCallback} = action
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const {response, error} = yield call(
        apiCall,
        authToken.token,
        ids.providerId,
        payload
      )
      if (response) {
        if (Array.isArray(successAction)) {
          const puts = []
          successAction.forEach((action) => {
            puts.push(put(action(ids, response)))
          })
          yield all(puts)
        } else {
          yield put(successAction(ids, response))
        }
        _.isFunction(onSuccessCallback) && onSuccessCallback(response)
      } else {
        yield put(failureAction(ids, error))
        _.isFunction(onFailureCallback) && onFailureCallback(error)
      }
    }
  })
}
