import {defineGetActionsPrSu} from '../../actionsHelper'

const actions = {
  CLEAR_SCHEDULING: 'CLEAR_SCHEDULING',

  clear: () => {
    return {
      type: actions.CLEAR_SCHEDULING
    }
  },

  CLEAR_SCHEDULING_BULKOPERATION_RESULT: 'CLEAR_SCHEDULING_BULKOPERATION_RESULT',

  clearSchedulingBulkOperationResult: () => {
    return {
      type: actions.CLEAR_SCHEDULING_BULKOPERATION_RESULT
    }
  },

  SET_SMART_SCHEDULE_PARAM: 'SET_SMART_SCHEDULE_PARAM',
  setSmartScheduleParam: (param, value) => {
    return {
      type: actions.SET_SMART_SCHEDULE_PARAM,
      param: param,
      value: value
    }
  },

  RESET_SMART_SCHEDULE_PARAMS: 'RESET_SMART_SCHEDULE_PARAMS',
  resetSmartScheduleParams: () => {
    return {
      type: actions.RESET_SMART_SCHEDULE_PARAMS,
    }
  },

  SHOW_VISIT_EDITOR: 'SHOW_VISIT_EDITOR',
  showVisitEditor: (serviceUserId, carePlanVisitId, visitDate, careWorkerNum) => {
    return {
      type: actions.SHOW_VISIT_EDITOR,
      serviceUserId: serviceUserId,
      carePlanVisitId: carePlanVisitId,
      visitDate: visitDate,
      careWorkerNum: careWorkerNum
    }
  },
  HIDE_VISIT_EDITOR: 'HIDE_VISIT_EDITOR',
  hideVisitEditor: () => {
    return {
      type: actions.HIDE_VISIT_EDITOR,
    }
  },

}

defineGetActionsPrSu(actions, 'GET_SCHEDULING_SET_CUSTOM_TIME', 'setCustomTime')

defineGetActionsPrSu(actions, 'GET_SCHEDULING_CLEAR_CUSTOM_TIME', 'clearCustomTime')

defineGetActionsPrSu(actions, 'SET_SCHEDULING_SET_ADJUSTED_ACTUAL_TIMES', 'setAdjustedActualTimes')

defineGetActionsPrSu(actions, 'GET_SCHEDULING_ASSIGN_STAFF', 'assignStaff')

defineGetActionsPrSu(actions, 'GET_SCHEDULING_UNIFIED_VISITS', 'getUnifiedVisits')

defineGetActionsPrSu(actions, 'SCHEDULING_GET_VISIT_OR_PREVIEW', 'getVisitOrPreview')

defineGetActionsPrSu(actions, 'SCHEDULING_SET_EXCEPTION', 'setException')

defineGetActionsPrSu(actions, 'SCHEDULING_CLEAR_EXCEPTION', 'clearException')

defineGetActionsPrSu(actions, 'SCHEDULING_BULK_ASSIGN_STAFF', 'bulkAssignStaff')

defineGetActionsPrSu(actions, 'SCHEDULING_BULK_ASSIGN_PREFERRED_STAFF', 'bulkAssignPreferredStaff')

defineGetActionsPrSu(actions, 'SCHEDULING_BULK_REMOVE_STAFF', 'bulkRemoveStaff')

defineGetActionsPrSu(actions, 'SCHEDULING_BULK_SMART_ALLOCATION', 'bulkSmartAllocation')

defineGetActionsPrSu(actions, 'SCHEDULING_DOWNLOAD_CSV', 'downloadCsv')

export default actions
