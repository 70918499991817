import config from "./settings";
import {commonGetBlob} from "./common";

class CareOutcomes {
  static getPdf(token, providerId, userId) {
    const url = `${
      config.url
    }/auth/providers/${providerId}/serviceusers/${userId}/careoutcomes/pdf`
    return commonGetBlob(token, url)
  }
}

export default CareOutcomes
