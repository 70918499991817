import { DatePicker } from 'antd'
import { DatePickerWrapper } from './styles/datepicker.style'
export default DatePickerWrapper(DatePicker)

const { RangePicker } = DatePicker

const RangePickerWrapped = DatePickerWrapper(RangePicker)
export { RangePickerWrapped as RangePicker }

const PopupStyle = { paddingTop: '18px', paddingLeft: '4px' }
export { PopupStyle }
