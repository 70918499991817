import { defineGetActionsPrSu, defineUpdateActionsPrSu } from '../../actionsHelper'

const actions = {
  SERVICESLOCATIONS_CLEAR: 'SERVICESLOCATIONS_CLEAR',
  clear: () => {
    return {
      type: actions.SERVICESLOCATIONS_CLEAR
    }
  },

  SERVICESLOCATIONS_SET_FILTERS: 'SERVICESLOCATIONS_SET_FILTERS',
  setFilters: (filters) => {
    return {
      type: actions.SERVICESLOCATIONS_SET_FILTERS,
      filters: filters
    }
  }

}

defineGetActionsPrSu(actions, 'SERVICESLOCATIONS_GET_SERVICES_BYUSER_FILTERED', 'getServicesByUserFiltered')
defineGetActionsPrSu(actions, 'SERVICESLOCATIONS_GET_SERVICES_FOR_MULTIPLE_USERS', 'getServicesForMultipleUsers')
defineUpdateActionsPrSu(actions, 'SERVICESLOCATIONS_UPDATE_SERVICES_FOR_MULTIPLE_USERS', 'updateServicesForMultipleUsers')

export default actions
