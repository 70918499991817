import config from './settings'
import { commonGet } from './common'

class AuthUser {
  static getUser (token) {
    const url = `${config.url}/auth/user`
    return commonGet(token, url)
  }

  static getProvider (token) {
    const url = `${config.url}/auth/provider`
    return commonGet(token, url)
  }

  static getServices (token) {
    const url = `${config.url}/auth/services`
    return commonGet(token, url)
  }

  static getPermissions (token) {
    const url = `${config.url}/auth/permissions`
    return commonGet(token, url)
  }

}
export default AuthUser
