import { all, takeEvery, put, call } from 'redux-saga/effects'
import { getAuthToken } from '../../../../helpers/tokens'
import actions from './actions'
import crudSagas from '../../crud/sagas'
import Api from '../../../../api'
import { defineGetSaga } from '../../../sagasHelper'

function makeUrl (userId, queryParameters) {
  if (!queryParameters.pageSize) {
    queryParameters.pageSize = 100
  }
  const qs = Object.keys(queryParameters).map(key => key + '=' + queryParameters[key]).join('&')
  const su = !userId ? '' : `serviceusers/${userId}/`
  return `providers/:providerId/${su}communicationmessages?${qs}`
}

export function * getFullList () {
  yield takeEvery(actions.GET_FULL_LIST_REQUEST, function * ({ providerId, userId, queryParameters }) {
    const url = makeUrl(userId, queryParameters)
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Crud.getList,
        authToken.token,
        { providerId },
        url
      )
      if (response) {
        yield put(actions.getFullListSuccess(response))
      } else {
        yield put(actions.getFullListError(error))
      }
    }
  })
}

export function * getAlertsList () {
  yield takeEvery(actions.GET_ALERTS_LIST_REQUEST, function * ({ providerId, userId, queryParameters }) {
    const url = makeUrl(userId, queryParameters)
    const authToken = yield getAuthToken()
    if (authToken && authToken.token) {
      const { response, error } = yield call(
        Api.Crud.getList,
        authToken.token,
        { providerId },
        url
      )
      if (response) {
        yield put(actions.getAlertsListSuccess(response))
      } else {
        yield put(actions.getAlertsListError(error))
      }
    }
  })
}

export function * downloadCsvRequest () {
  // NOTE WE MAY NEED TO HANDLE TypeError as success
  //         if (error instanceof TypeError) {
  yield defineGetSaga(actions.COMMUNICATION_MESSAGES_DOWNLOAD_CSV_REQUEST, Api.CommunicationMessages.downloadCsv, actions.downloadCsvSuccess, actions.downloadCsvError)
}

export default function * devSaga () {
  yield all([
    crudSagas(
      actions,
      Api.Crud,
      'providers/:providerId/serviceusers/:userId/communicationmessages',
      'id' // single get is not supported at the moment
    ),
    getFullList(),
    getAlertsList(),
    downloadCsvRequest()
  ])
}
