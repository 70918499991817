import crudActions from '../../crud/actions'

const actions = {
  ...crudActions('CAREASSESSMENT_MOVINGANDHANDLING_DOCS_'),

  CAREASSESSMENT_MOVINGANDHANDLING_DOC_DOWNLOAD_REQUEST: 'CAREASSESSMENT_MOVINGANDHANDLING_DOC_DOWNLOAD_REQUEST',

  downloadRequest: data => {
    return {
      type: actions.CAREASSESSMENT_MOVINGANDHANDLING_DOC_DOWNLOAD_REQUEST,
      payload: data
    }
  }
}

export default actions
