import _ from 'lodash'

export function parseError (error) {
  // The error message will be a serialized error
  let parsedError
  try {
    parsedError = JSON.parse(error.message)
  } catch (err) {
    let message = _.isObject(error) ? error.message : error
    if (error instanceof TypeError) {
      if (message === 'Failed to fetch') {
        message = 'The browser could not reach the server.\nPlease try the following:\n\n  1: Check your internet connection by checking another site\n  2. Reload this page\n\nIf the problem persists, then:\n\n  3: Contact us on help@axela.co.uk or if urgent, call your account manager'
      }
    }

    parsedError = {
      code: 0,
      status: 0,
      message: message
    }
  }
  return parsedError
}
