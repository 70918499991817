import {
  defineGetActionsPrSu,
  defineUpdateActionsPrSu,
} from "../../actionsHelper";
import moment from "moment-timezone";

const actions = {
  CLEAR: "CAREWORKERWEBAPP_CLEAR",
  clear: () => {
    return {
      type: actions.CLEAR,
    };
  },

  SET_MY_SCHEDULE_PROCESSED: "CAREWORKERWEBAPP_SET_MY_SCHEDULE_PROCESSED",
  setMyScheduleProcessed: (processed) => {
    return {
      type: actions.SET_MY_SCHEDULE_PROCESSED,
      payload: processed,
    };
  },

  SET_VISIT_DATA: "CAREWORKERWEBAPP_SET_VISIT_DATA",
  setVisitData: (visit) => {
    return {
      type: actions.SET_VISIT_DATA,
      visit: visit,
    };
  },

  // START_VISIT: 'CAREWORKERWEBAPP_START_VISIT',
  // startVisit: (ids, visit) => {
  //   return actions.updateAcceptedVisitRequest(ids, {
  //     serviceUserId: visit.serviceUserId,
  //     carePlanVisitId: visit.carePlanVisitId,
  //     visitDate: moment(visit.dateOfVisit).format('YYYY-MM-DD'),
  //     visit: {
  //       ...visit,
  //       startedTime: moment().toISOString()
  //     }
  //   })
  // },

  END_VISIT: "CAREWORKERWEBAPP_END_VISIT",
  endVisit: (ids, visit) => {
    return actions.updateAcceptedVisitRequest(ids, {
      serviceUserId: visit.serviceUserId,
      carePlanVisitId: visit.carePlanVisitId,
      visitDate: moment(visit.dateOfVisit).format("YYYY-MM-DD"),
      visit: {
        ...visit,
        endedTime: moment().toISOString(),
      },
    });
  },

  UPDATE_SUPPORT_TASK: "CAREWORKERWEBAPP_UPDATE_SUPPORT_TASK",
  updateSupportTask: (
    ids,
    visit,
    taskId,
    taskCompleted,
    completed,
    note,
    taskNotCompletedReason
  ) => {
    //find the support task
    const supportTasks = visit.supportTasks.map((t) => {
      if (t.id !== taskId) {
        return t;
      }
      return {
        ...t,
        taskCompleted: taskCompleted,
        completed: completed,
        completedNote: note,
        completedTime: moment().toISOString(),
        taskNotCompletedReason: taskNotCompletedReason,
      };
    });

    return actions.updateAcceptedVisitRequest(ids, {
      serviceUserId: visit.serviceUserId,
      carePlanVisitId: visit.carePlanVisitId,
      visitDate: moment(visit.dateOfVisit).format("YYYY-MM-DD"),
      visit: {
        ...visit,
        supportTasks: supportTasks,
      },
    });
  },

  UPDATE_MEDICATION_TASK: "CAREWORKERWEBAPP_UPDATE_MEDICATION_TASK",
  updateMedicationTask: (
    ids,
    visit,
    taskId,
    status,
    amount,
    completed,
    note
  ) => {
    //find the medication task
    const medicationTasks = visit.medicationTasks.map((t) => {
      if (t.id !== taskId) {
        return t;
      }
      return {
        ...t,
        status: status,
        amount: amount,
        completed: completed,
        completedNote: note,
        completedTime: moment().toISOString(),
      };
    });

    return actions.updateAcceptedVisitRequest(ids, {
      serviceUserId: visit.serviceUserId,
      carePlanVisitId: visit.carePlanVisitId,
      visitDate: moment(visit.dateOfVisit).format("YYYY-MM-DD"),
      visit: {
        ...visit,
        medicationTasks: medicationTasks,
      },
    });
  },

  GET_MY_SCHEDULE: "CAREWORKERWEBAPP_GET_MY_SCHEDULE",
  GET_MY_ACCEPTED_VISIT: "CAREWORKERWEBAPP_GET_MY_ACCEPTED_VISIT",
  ACCEPT_VISIT: "CAREWORKERWEBAPP_ACCEPT_VISIT",
  START_VISIT: "CAREWORKERWEBAPP_START_VISIT",
  UPDATE_ACCEPTED_VISIT: "CAREWORKERWEBAPP_UPDATE_ACCEPTED_VISIT",
};

defineGetActionsPrSu(actions, actions.GET_MY_SCHEDULE, "getMySchedule");
defineGetActionsPrSu(
  actions,
  actions.GET_MY_ACCEPTED_VISIT,
  "getMyAcceptedVisit"
);
defineUpdateActionsPrSu(actions, actions.ACCEPT_VISIT, "acceptVisit");
defineUpdateActionsPrSu(actions, actions.START_VISIT, "startVisit");
defineUpdateActionsPrSu(
  actions,
  actions.UPDATE_ACCEPTED_VISIT,
  "updateAcceptedVisit"
);

export default actions;
