import config from './settings'
import { commonGet, commonPost } from './common'

class ServiceInformation {
  static get (token, payload) {
    const url = `${
      config.url
    }/auth/providers/${payload.providerId}/serviceusers/${payload.userId}/serviceinfo`

    return commonGet(token, url)
  }

  static post (token, payload) {
    const url = `${
      config.url
    }/auth/providers/${payload.providerId}/serviceusers/${payload.userId}/serviceinfo`
    return commonPost(token, url, payload.payload)
  }
}

export default ServiceInformation
