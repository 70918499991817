import actions from './actions'

const initState = {
  error: null
}

export default function reducer (state = initState, action) {
  switch (action.type) {
    case actions.CONNECTION_ERROR:
      return {
        ...state,
        error: action.error
      }
    case actions.SHOW_ERROR:
      return {
        ...state,
        error: action.error
      }
    case actions.REMOVE_ERROR:
      return {
        ...state,
        error: null
      }
    default:
      return state
  }
}
