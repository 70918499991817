import config from './settings'
import {
  commonGet,
  commonPost
} from './common'

class Symptoms {
  static createNoteRequest (token, providerId, userId, symptomId, note) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/symptoms/${symptomId}/notes`
    return commonPost(token, url, note)
  }

  static getSymptomsCountRequest (token, providerId, userId) {
    const url = `${config.url}/auth/providers/${providerId}/serviceusers/${userId}/symptomscount`
    return commonGet(token, url)
  }
}

export default Symptoms
