import actions from './actions'
import crudReducers from '../../crud/reducers'

const dataPropName = 'condition'

const initState = {
  list: {},
  listLoaded: false,
  loading: false,
  loaded: false,
  error: false,
  modalActive: false,
  condition: {}
}

const reducers = crudReducers(actions, dataPropName, initState)

export default reducers
