import {Form} from "antd";
import React, {useContext, useEffect} from "react";
import Select from "../../../../components/styled/select"
import _ from 'lodash'
import {useDispatch, useSelector} from "react-redux";
import actions from "../../../../redux/app/rostering/actions";
import Spin from "../../../../components/styled/spin";
import {IntlContext} from "react-intl";

const {Option} = Select

export default function StatusFilter({form, onChange, statuses,langPrefix, field, disabled}) {
  const {getFieldDecorator} = form;
  const dispatch = useDispatch()
  const filters = useSelector(state => state.Rostering.filters)
  const {list: careVisitTypes, loading} = useSelector(state => state.Settings.CareVisitTypes)
  const intl = useContext(IntlContext)


  const setFilter = (types) => {
    dispatch(actions.setFilters({
      [field]: types
    }))
  }

  const handleChange = (types) => {
    const typeIds = types.filter(s => !!s.key).map(s => s.key)
    setFilter(typeIds)
    _.isFunction(onChange) && onChange(typeIds)
  }

  useEffect(() => {
    if (!filters[field] || !_.isArray(filters[field]) || loading) {
      return
    }
    const selected = (filters[field] && _.isArray(filters[field])) ? filters[field]
      .filter(s => statuses.includes(s))
      .map(s => {
      return {
        key: s,
        label: intl.formatMessage({
          id: `${langPrefix}.${s}`
        })
      }
    }) : []

    form.setFieldsValue({[field]: selected})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [careVisitTypes, loading, filters[field]])

  return <Form.Item className={'multiSelect'}>
    {getFieldDecorator(field, {
      initialValue: [],
      rules: [{type: 'array', message: 'Select status!'}]
    })(
      <Select
        mode='multiple'
        labelInValue
        placeholder='Select status'
        disabled={disabled}
        notFoundContent={loading ? <Spin size='small'/> : null}
        filterOption={true}
        onChange={handleChange}
        style={{width: '100%'}}
      >
        {statuses.map(d => (
          <Option key={d}
                  value={d}
                  filter={d + intl.formatMessage({
                    id: `${langPrefix}.${d}`
                  })}>
            {intl.formatMessage({
              id: `${langPrefix}.${d}`
            })}
          </Option>
        ))}
      </Select>
    )}
  </Form.Item>


}